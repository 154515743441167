import React, {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import Log from "wapplr-react/dist/common/Log";
import DefaultLogo from "wapplr-react/dist/common/Logo";

import clsx from "clsx";

import MaterialDrawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import MaterialMenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';

import {storage as defaultLocalStorage} from "../../utils/localStorage";

import AppContext from "../App/context";
import Menu from "../Menu";

import {withMaterialStyles, withMaterialTheme} from "./withMaterial";
import {materialMediaQuery, materialTheme} from "./materialTheme";

import defaultStyle from "./style.css";
import materialStyle from "./materialStyle";
import {storage as defaultMemoStorage} from "../../utils/memoStorage";

const containers = {};

function Template(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const utils = getUtils(context);

    const {wapp} = context;
    const {siteName = "Wapplr"} = wapp.config;

    const {

        /*style*/
        style = defaultStyle,
        materialStyle,
        position = "relative",
        fullPage,
        heightByParent,
        breakPoint = 960,
        transparentAppBar = false,
        pageContentNoPadding = false,
        disableFooter = false,
        MenuIcon = MaterialMenuIcon,

        /*content*/
        title = "",
        appBarCenterChildren,
        children,
        Logo = DefaultLogo,
        logoHref = "/",
        FooterLogo,
        copyright = `${siteName} ${new Date().getFullYear()} ©`,
        getMenu = function () {
            return []
        },
        getTopMenu = function () {
            return []
        },
        getFooterMenu = function () {
            return []
        },
        menuProps = {},
        topMenuProps = {},
        footerMenuProps = {},

        /*utils*/
        subscribe,
        onStickyChange,
        onDrawerChange,
        onNarrowChange,

        user = utils.getRequestUser(),

    } = props;

    const appBarPosition = position;
    const fixedDrawer = (appBarPosition === "fixed");

    //const materialTheme = useTheme();

    wapp.styles.use(style);

    const {
        storage = function (data, memo) {
            if (memo) {
                return defaultMemoStorage((data) ? data : {}, wapp.globals.NAME);
            }
            return defaultLocalStorage(data, wapp.globals.NAME);
        }
    } = appContext;

    if (!containers[wapp.globals.WAPP]) {
        containers[wapp.globals.WAPP] = {};
    }

    const urlKey = wapp.client?.history.getState().key || "initial";

    const container = useRef();
    const drawerContainer = useRef();

    const initialState = (typeof window !== "undefined" && window[wapp.config.appStateName]) || {req: {timestamp: Date.now()}};
    const firstRender = (utils.getGlobalState("req.timestamp") === initialState.req.timestamp || wapp.target === "node");
    const scrollTop = storage(undefined, true)["scrollTop_" + urlKey] || 0;

    const [open, __setOpen] = useState((firstRender) ? false : storage().drawerOpen);
    const [sticky, _setSticky] = useState(scrollTop > 0);
    const [narrow, _setNarrow] = useState((!firstRender && typeof window !== "undefined" && containers[wapp.globals.WAPP].current) ? containers[wapp.globals.WAPP].current.offsetWidth < breakPoint : false);

    async function _setOpen(value) {
        if (onDrawerChange) {
            onDrawerChange({open: value, narrow, sticky})
        }
        await __setOpen(value)
    }

    const handleDrawerClose = useMemo(() => async function () {
        if (open) {
            storage({drawerOpen: false});
            await _setOpen(false);
        }
    }, [open, storage]);

    async function handleDrawerToggle() {
        storage({drawerOpen: !open});
        await _setOpen(!open);
    }

    async function handleDrawerOpen() {
        if (!open) {
            storage({drawerOpen: true});
            await _setOpen(true);
        }
    }

    const drawerCloseNarrow = useMemo(() =>
        async function () {
            if (narrow) {
                await handleDrawerClose();
                await new Promise(resolve => setTimeout(resolve, 500));
            }
        }, [handleDrawerClose, narrow]);

    const storeDrawerScrollTop = useMemo(() => function () {
        const drawerScrollTop = drawerContainer?.current?.parentElement?.scrollTop || 0;
        storage({drawerScrollTop: drawerScrollTop}, true);
    }, [storage]);

    const setLastDrawerScrollTop = useMemo(() => function () {
        const drawerScrollTop = storage(undefined, true).drawerScrollTop || 0;
        if (drawerContainer?.current?.parentElement) {
            drawerContainer.current.parentElement.scrollTop = drawerScrollTop;
        }
    }, [storage]);

    const storeScrollTop = useMemo(() => function (p = {}) {
        const {
            urlKey,
            scrollTop = (appBarPosition === "sticky" && container.current || appBarPosition === "absolute" && container.current) ? container.current.scrollTop : window.scrollY
        } = p;
        if (container.current) {
            storage({["scrollTop_" + urlKey]: scrollTop}, true);
        }
    }, [appBarPosition, storage]);

    const setLastScrollTop = useMemo(() => async function ({urlKey}) {
        if (container.current) {

            const scrollTop = storage(undefined, true)["scrollTop_" + urlKey] || 0;

            if (appBarPosition === "sticky" || appBarPosition === "absolute" && container.current) {
                if (container.current) {
                    container.current.scrollTop = scrollTop;
                    await new Promise(resolve => setTimeout(resolve, 1));
                }
            } else if (appBarPosition === "fixed" || appBarPosition === "absolute" && !container.current) {
                if (window.scrollY !== scrollTop) {
                    window.scrollTo(0, scrollTop);
                    await new Promise(resolve => setTimeout(resolve, 1));
                }
            }
        }

    }, [appBarPosition, storage]);

    const actions = useMemo(() => {
        return {
            scrollTop: async function (scrollTop) {
                if (typeof scrollTop === "number" && scrollTop >= 0) {
                    storeScrollTop({urlKey, scrollTop})
                }
                await setLastScrollTop({urlKey, scrollTop});
            },
            getState: function () {
                return {open, sticky, narrow}
            },
            getStyle: function () {
                return style || {}
            },
            drawerOpen: handleDrawerOpen,
            drawerClose: handleDrawerClose,
            drawerCloseNarrow: drawerCloseNarrow,
            drawerToggle: handleDrawerToggle,
            storeDrawerScrollTop,
            setLastDrawerScrollTop
        }
    }, [drawerCloseNarrow, handleDrawerClose, narrow, open, setLastDrawerScrollTop, setLastScrollTop, sticky, storeDrawerScrollTop, style, urlKey]);

    const onClick = useMemo(() => async function (e, {href}) {
        if (href) {
            await drawerCloseNarrow();
            wapp.client.history.push({
                search: "",
                hash: "",
                ...wapp.client.history.parsePath(href)
            });
        }
        e.preventDefault();
        // eslint-disable-next-line
    }, [wapp, drawerCloseNarrow, actions]);

    if (wapp.target !== "node") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useLayoutEffect(function () {
            setLastDrawerScrollTop();
            setLastScrollTop({urlKey});
        }, [setLastDrawerScrollTop, setLastScrollTop, urlKey])
    }

    useEffect(function didMount() {

        wapp.client.history.globalHistory.scrollRestoration = "manual";

        function setOpen(value) {
            if (open !== value) {
                _setOpen(value)
            }
        }

        function setSticky(value) {
            if (sticky !== value) {
                if (onStickyChange) {
                    onStickyChange({open, sticky: value, narrow})
                }
                _setSticky(value)
            }
        }

        function setNarrow(value) {
            if (narrow !== value) {
                if (onNarrowChange) {
                    onNarrowChange({open, sticky, narrow: value})
                }
                _setNarrow(value)
            }
        }

        function onScroll(e) {
            if (appBarPosition === "sticky" || appBarPosition === "absolute" && container.current) {
                if (container.current) {
                    if (container.current.scrollTop > 0) {
                        setSticky(true);
                    } else {
                        setSticky(false);
                    }
                }
            } else if (appBarPosition === "fixed" || appBarPosition === "absolute" && !container.current) {
                if (window.scrollY > 0) {
                    setSticky(true);
                } else {
                    setSticky(false);
                }
            } else {
                setSticky(false);
            }
            if (e) {
                storeScrollTop({urlKey})
            }
        }

        function onDrawerScroll() {
            const drawerScrollTop = drawerContainer?.current?.parentElement?.scrollTop || 0;
            storage({drawerScrollTop: drawerScrollTop}, true);
        }

        function onResize() {
            if (container.current) {
                if (container.current.offsetWidth > breakPoint) {
                    setNarrow(false);
                } else {
                    setNarrow(true);
                }
            } else {
                if (window.innerWidth > breakPoint) {
                    setNarrow(false);
                } else {
                    setNarrow(true);
                }
            }
        }

        function addScrollListeners() {
            if (appBarPosition === "sticky" || appBarPosition === "absolute" && container.current) {
                if (container.current) {
                    container.current.addEventListener("scroll", onScroll);
                    return function removeEventListener() {
                        if (container.current) {
                            container.current.removeEventListener("scroll", onScroll);
                        }
                    }
                }
            } else if (appBarPosition === "fixed" || appBarPosition === "absolute" && !container.current) {
                window.addEventListener("scroll", onScroll);
                return function removeEventListener() {
                    window.removeEventListener("scroll", onScroll);
                }
            } else {
                return function removeEventListener() {
                }
            }
        }

        function addDrawerScrollListener() {
            const drawerElement = drawerContainer?.current?.parentElement;
            if (drawerElement) {
                drawerElement.addEventListener("scroll", onDrawerScroll);
                return function removeEventListener() {
                    if (drawerElement) {
                        drawerElement.removeEventListener("scroll", onDrawerScroll);
                    }
                }
            }
            return function removeEventListener() {
            }
        }

        function addResizeListeners() {
            if (container.current && typeof ResizeObserver !== "undefined") {
                const resizeObserver = new ResizeObserver((entries) => {
                    onResize(entries);
                });
                resizeObserver.observe(container.current);
                return function removeEventListener() {
                    resizeObserver.disconnect();
                }
            } else {
                window.addEventListener("resize", onResize);
                return function removeEventListener() {
                    window.removeEventListener("resize", onResize);
                }
            }
        }

        const removeScrollListeners = addScrollListeners();
        const removeDrawerScrollListeners = addDrawerScrollListener();
        const removeResizeListeners = addResizeListeners();

        const storageDrawerOpen = storage().drawerOpen;
        if (typeof storageDrawerOpen == "boolean" && open !== storageDrawerOpen) {
            setOpen(storageDrawerOpen);
        }

        onResize();
        onScroll();

        containers[wapp.globals.WAPP].current = container.current;

        if (props.effect) {
            props.effect({
                actions: actions
            })
        }

        return function willUnmount() {

            removeScrollListeners();
            removeDrawerScrollListeners();
            removeResizeListeners();

            if (props.effect) {
                props.effect(null);
            }

        }

    }, [actions, appBarPosition, breakPoint, narrow, open, props, sticky, storage, storeScrollTop, subscribe, urlKey, wapp.client?.history.globalHistory, wapp.globals.WAPP]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const menu = useMemo(() => getMenu({appContext, context}), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const topMenu = useMemo(() => getTopMenu({appContext, context}), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const footerMenu = useMemo(() => getFooterMenu({appContext, context}), []);

    const reallyDoesOpen = !!(open && menu?.length);

    return (
        <div
            ref={container}
            className={
                clsx(
                    materialStyle.root,
                    style.root,
                    {[style.fullPage]: fullPage},
                    {[style.narrow]: narrow},
                    {[style.drawerOpen]: reallyDoesOpen},
                    {[style.stickyStyle]: appBarPosition === "sticky"},
                    {[style.transparentAppBar]: transparentAppBar},
                    {[style.heightByParent]: heightByParent},
                )
            }>
            <AppBar
                position={appBarPosition}
                className={
                    clsx(
                        materialStyle.appBar,
                        style.appBar,
                        {[style.appBarPositionFixed]: appBarPosition === "fixed"},
                        {[style.appBarPositionSticky]: appBarPosition === "sticky"},
                        {[materialStyle.appBarSticky]: sticky},
                        {[style.appBarSticky]: sticky},
                    )}
            >
                <Toolbar className={style.toolbar}>
                    <>
                        {(menu?.length) ?
                            <IconButton
                                color={"inherit"}
                                aria-label={"open drawer"}
                                onClick={handleDrawerToggle}
                                className={clsx(materialStyle.menuButton, style.menuButton)}
                            >
                                {(reallyDoesOpen) ? <CloseIcon/> : <MenuIcon/>}
                            </IconButton>
                            :
                            null
                        }
                        {
                            (Logo) ?
                                <div
                                    className={clsx(
                                        style.logo,
                                        {[style.cursorPointer]: logoHref}
                                    )}
                                    onClick={(e) => onClick(e, {href: logoHref})}
                                >
                                    <Logo/>
                                </div>
                                : null
                        }
                        {
                            (title) ?
                                <div
                                    className={clsx(
                                        style.title,
                                        {[style.cursorPointer]: logoHref}
                                    )}
                                    onClick={(e) => onClick(e, {href: logoHref})}
                                >
                                    <Typography variant={"h6"} noWrap>
                                        {title}
                                    </Typography>
                                </div> : null
                        }
                        {(appBarCenterChildren) ? <div className={style.appBarCenterChildrenContainer}>
                            <div className={style.appBarCenterChildren}>{appBarCenterChildren}</div>
                        </div> : null}
                        {(!appBarCenterChildren && !title) ? <div style={{width: "100%"}}/> : null}
                        {
                            (topMenu?.length) ?
                                <div className={style.topMenu}>
                                    <Menu
                                        menu={topMenu}
                                        menuProperties={{user}}
                                        {...topMenuProps}
                                    />
                                </div>
                                : null
                        }
                    </>
                </Toolbar>
            </AppBar>
            <div className={style.mainContainer}>
                <MaterialDrawer
                    variant={"permanent"}
                    style={{marginLeft: reallyDoesOpen ? "0px" : "-360px"}}
                    className={clsx(
                        style.drawer,
                        {
                            [style.drawerNarrow]: narrow,
                            [style.drawerFixed]: fixedDrawer,
                            [materialStyle.drawerOpen]: reallyDoesOpen,
                            [materialStyle.drawerClose]: !reallyDoesOpen,
                            [style.drawerOpen]: reallyDoesOpen,
                            [style.drawerClose]: !reallyDoesOpen,
                        },
                    )}
                    classes={{
                        paper: clsx(
                            style.drawerPaper,
                            {
                                [style.drawerPaperFixed]: fixedDrawer,
                                [style.drawerPaperAbsoluteWithStickyAppBar]: !fixedDrawer && appBarPosition === "sticky",
                                [materialStyle.drawerOpen]: reallyDoesOpen,
                                [materialStyle.drawerClose]: !reallyDoesOpen,
                                [style.drawerOpen]: reallyDoesOpen,
                                [style.drawerClose]: !reallyDoesOpen,
                            }
                        )
                    }}
                    open={reallyDoesOpen}
                    PaperProps={{
                        style: {marginLeft: reallyDoesOpen ? "0px" : "-360px"},
                    }}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <div
                        className={materialStyle.drawerContainer}
                        ref={drawerContainer}
                    >
                        <Divider/>
                        {
                            (menu && menu.length) ?
                                <Menu
                                    menu={menu}
                                    menuProperties={{user}}
                                    list={true}
                                    {...menuProps}
                                />
                                : null
                        }
                    </div>
                </MaterialDrawer>
                <main className={clsx(
                    style.content,
                    {[style.narrowAndOpen]: narrow && reallyDoesOpen}
                )}>
                    <div
                        className={
                            clsx(
                                materialStyle.drawerLayer,
                                style.drawerLayer,
                                {[style.drawerLayerShow]: narrow && reallyDoesOpen}
                            )
                        }
                        onClick={handleDrawerClose}
                    />
                    <div className={style.page}>
                        {(appBarPosition === "fixed" && fixedDrawer) ? <div className={style.pagePaddingTop}/> : null}
                        <div className={clsx(
                            style.pageContent,
                            {[style.pageContentNoPadding]: pageContentNoPadding},
                        )}>
                            {children}
                        </div>
                    </div>
                    {(!disableFooter) ?
                        <footer className={style.footer}>
                            <div className={style.footerOneColumn}>
                                {
                                    (footerMenu?.length) ?
                                        <div className={style.footerMenu}>
                                            <Menu
                                                menu={footerMenu}
                                                menuProperties={{user}}
                                                {...footerMenuProps}
                                            />
                                        </div>
                                        : null
                                }
                                {FooterLogo ?
                                    <div className={style.footerLogo}>
                                        <FooterLogo/>
                                    </div>
                                    :
                                    null
                                }
                                <div className={style.copyright}>
                                    {copyright}
                                </div>
                                {(wapp.globals.DEV) ?
                                    <div className={style.log}>
                                        <Log Parent={null} Logo={null}/>
                                    </div> : null
                                }
                            </div>
                        </footer>
                        :
                        null
                    }
                </main>
            </div>
        </div>
    )
}

const WappComponent = withWapp(Template);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;

// noinspection JSUnusedGlobalSymbols
export const ThemedComponent = withMaterialTheme({
    theme: materialTheme,
    mediaQuery: materialMediaQuery
}, StyledComponent);
