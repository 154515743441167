import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import clsx from "clsx";

import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

import {copyObject} from "wapplr/dist/common/utils";
import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import CircularProgressMaterial from "@mui/material/CircularProgress";
import CheckBoxOutlined from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlined from "@mui/icons-material/CheckBoxOutlineBlankOutlined";

import {withMaterialStyles} from "../../../components/Template/withMaterial";
import PostContext from "../../../components/Post/context";
import AppContext from "../../../components/App/context";

import {postTypesConfig, runPostTypesConfigSync} from "../../index";

import capitalize from "../../../utils/capitalize";
import Dialog from "../../../components/Dialog";

import materialStyle from "./materialStyle";
import style from "./style.css";

async function parseExcel({file, onLoad, onError}) {

    const XLSX = await import('xlsx');

    const reader = new FileReader();

    reader.onload = function (e) {
        let data = e.target.result;
        if (data) {
            data = data.replace(/;0.\d+;/gm, (str) => str.replace(",", "."))
        }
        const workbook = XLSX.read(data, {type: "binary"});
        onLoad({workbook});
    };

    reader.onerror = function (e) {
        onError(e)
    };

    reader.readAsBinaryString(file);
}

function Editable(props) {

    const {
        className, helperText = "", error, disabled, ValueComponent = ({children, ...rest}) => {
            return <div {...rest}>{children}</div>
        }
    } = props;

    const [value, setValue] = useState(props.value);
    const [edit, setEdit] = useState(false);

    function onClick(e) {
        if (disabled) {
            return;
        }
        e.preventDefault();
        if (!edit) {
            setEdit(true)
        }
    }

    function onChange(e) {
        e.preventDefault();
        const v = e.target.value;
        if (v !== value) {
            setValue(v);
        }
    }

    function onBlur(e) {
        e.preventDefault();
        onChange(e);
        if (edit) {
            setEdit(false);
        }
        if (props.onChange) {
            props.onChange(e.target.value)
        }
    }

    return (
        <>
            {(edit) ?
                <TextField
                    classes={{
                        root: clsx(
                            style.inputText,
                            {
                                [style[className]]: style[className],
                                [style[error]]: error
                            }
                        ),
                    }}
                    FormHelperTextProps={{
                        classes: {
                            root: style.helperText
                        }
                    }}
                    variant={"outlined"}
                    type={"text"}
                    label={""}
                    value={value}
                    error={error}
                    helperText={helperText}
                    onChange={onChange}
                    onBlur={onBlur}
                    multiline={true}
                    autoFocus={true}
                    disabled={disabled}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault()
                        }
                    }}
                    onFocus={(e) => {
                        e.target.setSelectionRange(e.target.value.length, e.target.value.length)
                    }}
                /> :
                <>
                    <ValueComponent
                        className={clsx(
                            style.valueText,
                            {
                                [style[className]]: style[className],
                                [style.error]: error
                            }
                        )}
                        onClick={onClick}
                    >
                        {value}
                    </ValueComponent>
                    {(helperText) ?
                        <div
                            className={clsx(
                                style.helperText,
                                {
                                    [style.error]: error
                                }
                            )}
                            onClick={onClick}
                        >
                            {helperText}
                        </div> : null
                    }
                </>
            }
        </>
    )
}

function Progress(props) {

    const [show, setShow] = useState();

    useEffect(() => {
        if (props.effect) {
            props.effect({actions: {setShow}})
        }
        return function () {
            if (props.effect) {
                props.effect({actions: {setShow: () => null}})
            }
        }
    }, []);

    return (
        <>
            {(show) ?
                <div className={style.progress}>
                    <CircularProgressMaterial size={32} thickness={2.4}/>
                </div>
                : null
            }
        </>
    )
}

function Import() {

    const appContext = useContext(AppContext);
    const postContext = useContext(PostContext);
    const {name, user} = postContext;
    const N = capitalize(name);
    const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
    const Ns = capitalize(ns);

    const context = useContext(WappContext);
    const {wapp} = context;
    const utils = getUtils(context);

    const {storage} = appContext;

    wapp.styles.use(style);

    const NewFile = useMemo(() => postTypesConfig["file"] && postTypesConfig["file"].getPages ? postTypesConfig["file"].getPages().new : null, []);

    const [file, setFile] = useState(storage()[name + "ImportFile"] || null);
    const [rows, setRows] = useState(storage()[name + "ImportRows"] || []);
    const [posts, setPosts] = useState([]);
    const [autoRun, setAutoRun] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");

    const colNumbers = {
        _id: 1,
        ean: 2,
        isbn: 3,
        dUrl: 4,
        dUrl2: 5,
        title: 6,
        price1: 7,
        price2: 8,
        price3: 9,
        vatRate: 10,
        piecesInStock: 11,
        enableDistributorStock: 12,
        distributorAvailability: 13,
        disableProduct: 14,
        categories: 15,
        cover: 16,
        dMargin: 17,
        cPrice: 18,
        cUrl: 19
    };

    const enableStockAdd = false;

    const titlePattern = /^.{1,250}$/;
    const idPattern = /^[0-9A-Fa-f]{24}$/;
    const eanPattern = /^(\d{13})?$/;
    const isbnPattern = /^(\d{10,13})?$/;
    const urlPattern = /^(http(s)?:\/\/)[\w.-]+\.[\w.-]+.+$/;

    const filePostTypeName = "file";
    const filePostType = wapp.getTargetObject().postTypes.findPostType({name: filePostTypeName});
    const fileStatusManager = filePostType.statusManager;

    async function rowValidation({values, errors = []}) {

        async function getImage(url) {
            let response = await fetch(appContext.routes[name + "Route"] + "/api/getexternalimage", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({url}),
                credentials: "same-origin",
            });
            if (response && response.json) {
                response = response.json();
            }
            return response;
        }

        async function upload(e, args) {

            const filesArray = Array.from(e.target.files || []);
            const filesLength = filesArray.length;
            const filePropertyName = "upload";
            const multiple = false;

            if (!filesLength) {
                return;
            }

            const requestProps = {
                requestName: filePostTypeName + "New",
                args: {
                    ...args,
                    records: [
                        ...(args.records) ? args.records : []
                    ],
                    [filePropertyName]: (multiple) ? (filesLength) ? filesArray.map(() => null) : [] : null
                },
                multipart: true,
                callbackMultipartFormData: function ({formData}) {
                    const map = JSON.stringify((filesLength) ? filesArray.reduce((o, file, i) => {
                        const t = (multiple) ? "variables." + filePropertyName + "." + (i) : "variables." + filePropertyName;
                        o[(i)] = [t];
                        return o;
                    }, {}) : {});
                    formData.append("map", map);
                    if (filesLength) {
                        filesArray.forEach((file, i) => {
                            formData.append((i).toString(), file, file.title);
                        })
                    }
                },
                formatBeforeRequest: function (url, options) {
                    delete options.headers["Content-Type"];
                    return [url, options];
                },
                fetch: function (url, options = {}) {
                    return new Promise((response, reject) => {
                        let xhr = new XMLHttpRequest();
                        const {onProgress, ...restOptions} = options;
                        xhr.open(restOptions.method || "get", url);
                        for (let k in restOptions.headers || {}) {
                            xhr.setRequestHeader(k, restOptions.headers[k]);
                        }
                        xhr.responseType = "json";
                        xhr.onload = () => response(xhr.response);
                        xhr.onerror = reject;
                        if (xhr.upload && onProgress) {
                            xhr.upload.onprogress = onProgress;
                        }
                        xhr.send(options.body);
                    });
                },
            };

            const response = await utils.sendRequest(requestProps);

            let foundSuccess = -1;
            let newSuccessFiles = [];

            if (response.records && response.records.length) {
                const errors = (response.error?.errors) ? response.error.errors : (response.errors) ? response.errors : (response.error) ? [response.error] : null;
                filesArray.forEach((file, i) => {
                    const thereIsError = (errors?.length) ? errors.find((error) => {
                        return typeof error.path == "string" && error.path && Number(error.path) === i
                    }) : false;
                    let r = {title: file.name};
                    if (!thereIsError) {
                        foundSuccess = foundSuccess + 1;
                        if (response.records[foundSuccess]) {
                            r = {...r, ...response.records[foundSuccess]};
                            newSuccessFiles.push(r);
                        }
                    }
                });
            }

            if ((response && response.error) || (response && response.errors)) {
                const message = (response.error?.message) || (response.errors && response.errors[0] && response.errors[0].message) || appContext.messages["new" + Ns + "ClientErrorMessage"];
                const errors = response.error?.errors || response.errors || [response.error];
                return {
                    error: {
                        message,
                        errors
                    }
                }
            } else if (response) {
                return newSuccessFiles[0];
            }
        }

        function createError(valueName, message) {
            return {
                message: message || appContext.messages[name + "ImportXlsParseError"]({rowNumber: colNumbers[valueName] + 1}),
                path: valueName,
                value: values[valueName]
            }
        }

        if (typeof values._id !== "undefined") {
            let id = values._id;
            if (typeof id == "string" && id.match(idPattern)) {

            } else {
                errors.push(createError("_id"))
            }
        }

        if (typeof values.ean !== "undefined") {
            let ean = values.ean;
            if (typeof ean == "number" && ean) {
                ean = ean.toString();
            }
            if (typeof ean == "string" && ean.match(eanPattern)) {
                values.ean = ean;
            } else {
                errors.push(createError("ean"))
            }
        }

        if (typeof values.isbn !== "undefined") {
            let isbn = values.isbn;
            if (typeof isbn == "number" && isbn) {
                isbn = isbn.toString();
            }
            if (typeof isbn == "string" && isbn.match(isbnPattern)) {
                values.isbn = isbn;
            } else {
                errors.push(createError("isbn"))
            }
        }

        if (typeof values.title !== "undefined") {
            let title = values.title;
            if (typeof title === "string" && title && title.match(titlePattern)) {
            } else {
                errors.push(createError("title"))
            }
        }

        function priceValidator(key) {
            if (typeof values[key] !== "undefined") {
                let price = values[key];
                if (typeof price === "string" && price) {
                    price = price.replace(/,/g, ".");
                    price = price.replace(/ /g, "");
                }
                if (!isNaN(Number(price))) {
                    price = Number(price);
                }
                if (price >= 0) {
                    values[key] = price;
                } else {
                    errors.push(createError(key))
                }
            }
        }

        priceValidator("price1")
        priceValidator("price2")
        priceValidator("price3")
        priceValidator("cPrice")

        if (typeof values.vatRate !== "undefined") {
            let vatRate = values.vatRate;
            if (!isNaN(Number(vatRate))) {
                vatRate = Number(vatRate);
            }
            if (vatRate >= 0) {
                values.vatRate = vatRate;
            } else {
                errors.push(createError("vatRate"))
            }
        }

        if (typeof values.dMargin !== "undefined") {
            let dMargin = values.dMargin;
            if (typeof dMargin === "string" && dMargin) {
                dMargin = dMargin.replace(/,/g, ".");
                dMargin = dMargin.replace(/ /g, "");
            }
            if (!isNaN(Number(dMargin))) {
                dMargin = Number(dMargin);
            }
            if (dMargin >= 0) {
                values.dMargin = dMargin;
            } else {
                errors.push(createError("dMargin"))
            }
        }

        if (typeof values.piecesInStock !== "undefined") {
            let piecesInStock = values.piecesInStock;
            if (!isNaN(Number(piecesInStock))) {
                piecesInStock = Number(piecesInStock);
            }
            if (piecesInStock >= 0) {
                values.piecesInStock = piecesInStock;
            } else {
                errors.push(createError("piecesInStock"))
            }
        }

        if (typeof values.enableDistributorStock !== "undefined") {
            let enableDistributorStock = values.enableDistributorStock;
            if (!isNaN(Number(enableDistributorStock))) {
                enableDistributorStock = Number(enableDistributorStock);
            }
            if (enableDistributorStock === 1) {

            } else if (enableDistributorStock === 0) {

            } else {
                errors.push(createError("enableDistributorStock"))
            }
        }

        if (typeof values.distributorAvailability !== "undefined") {
            let distributorAvailability = values.distributorAvailability;
            if (!isNaN(Number(distributorAvailability))) {
                distributorAvailability = Number(distributorAvailability);
            }
            if (distributorAvailability === 1) {

            } else if (distributorAvailability === 0) {

            } else {
                errors.push(createError("distributorAvailability"))
            }
        }

        if (typeof values.disableProduct !== "undefined") {
            let disableProduct = values.disableProduct;
            if (!isNaN(Number(disableProduct))) {
                disableProduct = Number(disableProduct);
            }
            if (disableProduct === 1) {

            } else if (disableProduct === 0) {

            } else {
                errors.push(createError("disableProduct"))
            }
        }

        if (typeof values.categories !== "undefined") {
            let category = values.categories;
            if (typeof category == "string" && category.match(idPattern)) {

            } else {
                try {
                    category = JSON.parse(category)
                    const invalid = category.find((_id) => (_id && !_id.match(idPattern) || !_id))
                    if (invalid) {
                        errors.push(createError("categories"))
                    }
                } catch (e) {
                    errors.push(createError("categories"))
                }
            }
        }

        async function urlValidator(key, success) {
            if (typeof values[key] !== "undefined") {
                let url = values[key];
                if (typeof url === "string" && url && url.match(urlPattern)) {
                    if (success) {
                        await success();
                    }
                } else {
                    errors.push(createError(key))
                }
            }
        }

        await urlValidator("dUrl");
        await urlValidator("dUrl2");
        await urlValidator("cUrl");

        await urlValidator("cover", async function () {
            let cover = values.cover;
            if (values._imagePost && values._imagePost.checksum !== cover) {
                values._imagePost = null;
            }
            if (cover) {
                let response =
                    (values._imagePost && values._imagePost?.checksum !== cover) ?
                        {items: [values._imagePost]} :
                        await utils.sendRequest({
                            requestName: filePostTypeName + "FindMany",
                            args: {
                                filter: {
                                    checksum: cover,
                                    _author: (user?._id) ? user._id : user,
                                    _operators: {
                                        _status: {gt: fileStatusManager.getMinStatus() - 1},
                                    },
                                }
                            }
                        });

                if (typeof response[filePostTypeName + "FindMany"] !== "undefined") {
                    response = response[filePostTypeName + "FindMany"];
                }
                if (response.items?.length) {
                    values._imagePost = response.items[0];
                } else {
                    try {
                        const data = await getImage(cover);
                        if (data?.status === 200 && data?.base64) {
                            const r = await fetch(data?.base64);
                            const blob = await r.blob();
                            let title = cover.substring(cover.lastIndexOf('/') + 1);
                            if (!title) {
                                title = (typeof values.title === "string") ? values.title : "cover";
                            }
                            blob.title = title;
                            const uploadResponse = await upload({target: {files: [blob]}}, {records: [{checksum: cover}]});
                            if (uploadResponse?._id && uploadResponse?.checksum === cover) {
                                values._imagePost = uploadResponse;
                            } else {
                                errors.push(createError("cover", uploadResponse?.error?.message));
                            }
                        } else {
                            errors.push(createError("cover", appContext.messages[name + "ImportImageCouldNotOpenError"]))
                        }
                    } catch (e) {
                        errors.push(createError("cover", e.message))
                    }
                }
            }
        });

        return {
            ...(errors.length) ? {
                error: {
                    message: (errors.length) ? errors[0].message : "Error",
                    errors
                }
            } : {},
        }

    }

    async function getRows(file) {

        const XLSX = await import('xlsx');

        if (file) {

            const {workbook} = file;
            const sheetName = workbook.SheetNames[0];
            const rows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);

            const r = await Promise.all(rows.map(async (row) => {

                const values = {...row};

                return {
                    ...await rowValidation({values}),
                    values
                }
            }));

            return (r?.length) ? r.filter(({values}) => (
                (values._id && values._id.match(idPattern)) ||
                (values.ean && values.ean.match(eanPattern)) ||
                (values.isbn && values.isbn.match(isbnPattern)) ||
                (
                    (values.title && values.title.match(titlePattern)) &&
                    (values.dUrl && values.dUrl.match(urlPattern))
                )
            )) : [];

        }
        return [];
    }

    async function getPosts(rows) {
        const posts = [];
        if (Array.isArray(rows) && rows.length) {
            const res = await Promise.all(
                rows.map(async ({values}) => {
                    if (values._id) {
                        let response = await utils.sendRequest({
                            requestName: name + "FindById",
                            args: {_id: values._id}
                        });
                        if (typeof response[name + "FindById"] !== "undefined") {
                            response = response[name + "FindById"];
                        }
                        return response;
                    }
                    if (values.ean) {
                        let response = await utils.sendRequest({
                            requestName: name + "FindByEan",
                            args: {ean: values.ean}
                        });
                        if (typeof response[name + "FindByEan"] !== "undefined") {
                            response = response[name + "FindByEan"];
                        }
                        return response;
                    }
                    if (values.isbn) {
                        let response = await utils.sendRequest({
                            requestName: name + "FindByIsbn",
                            args: {isbn: values.isbn}
                        });
                        if (typeof response[name + "FindByIsbn"] !== "undefined") {
                            response = response[name + "FindByIsbn"];
                        }
                        return response;
                    }
                    if (values.dUrl) {
                        let response = await utils.sendRequest({
                            requestName: name + "FindMany",
                            args: {
                                filter: {
                                    dUrl: values.dUrl
                                },
                            }
                        });
                        if (typeof response[name + "FindMany"] !== "undefined") {
                            response = response[name + "FindMany"];
                        }
                        if (response && response.items && response.items[0]) {
                            return response.items[0];
                        }
                        return null;
                    }
                    return null;
                })
            );
            posts.push(...res);
        }
        setPosts(posts);
        return posts;
    }

    const onSuccess = async function (files) {
        const file = files[0];
        await progress.actions.setShow(true);
        const rows = await getRows(file);

        await setFile(file);

        if (file && !rows?.length) {
            setSnackMessage(appContext.messages[name + "ImportFileError"]);
        }

        await setRows(rows);

        if (file && rows?.length) {
            storage({[name + "ImportRows"]: rows});
            storage({[name + "ImportFile"]: {}});
            await getPosts(rows);
        }
        await progress.actions.setShow(false);
    };

    async function close() {
        storage({[name + "ImportRows"]: [], [name + "ImportFile"]: null});
        await setRows([]);
        await setPosts([]);
        await setFile(null);
    }

    function handleCloseSnackbar() {
        if (snackMessage) {
            setSnackMessage("");
        }
    }

    const newRequestFormData = utils.getGlobalState("res.graphql.mutation." + name + "New.formData");

    const propertyNames = Object.keys(colNumbers);

    const dialog = useRef();
    const dialogEffect = ({actions}) => {
        dialog.actions = actions
    };

    const form = useRef();

    const progress = useRef();
    const progressEffect = ({actions}) => {
        progress.actions = actions
    };

    async function onClick(e, post, rows, row, i, saved) {

        if (saved) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (dialog.actions) {

            function DialogContent() {

                const context = useContext(WappContext);

                const {wapp, req, res} = context;

                //const ean = post?.ean || "";
                const route = appContext.routes[name + "Route"];
                const postId = post?._id;

                const path = (route && postId) ? route + "/" + postId + "/edit" : route + "/new";

                const newContext = (path) ?
                    {
                        wapp,
                        req: {
                            user: req.user,
                            wappRequest: {
                                user: req.wappRequest.user,
                                query: {}
                            }
                        },
                        res: {
                            wappResponse: {
                                route: {
                                    requestPath: path,
                                    ...(postId) ? {params: {page: "edit", _id: postId}} : {params: {page: "new"}},
                                },
                                store: res.wappResponse.store,
                                status: res.wappResponse.status
                            }
                        }
                    } : context;

                const PostTypesComponent = (path) ? runPostTypesConfigSync({
                    action: "getComponent",
                    p: {context: newContext, appContext}
                }).filter((C) => !!(C))[0] : null;

                const {
                    _imagePost,
                    cover,
                    categories,
                    enableDistributorStock,
                    distributorAvailability,
                    disableProduct,
                    ...restValues
                } = row.values;

                const newPost = {...(post) ? post : {}, ...restValues};

                if (post && enableStockAdd) {
                    const currentStock = typeof post.piecesInStock == "number" ? post.piecesInStock : 0;
                    const value = typeof row.values.piecesInStock == "number" ? row.values.piecesInStock : 0;
                    newPost.piecesInStock = currentStock + value;
                }

                if (cover && _imagePost && cover === _imagePost.checksum) {
                    newPost.cover = copyObject(_imagePost);
                    newPost.gallery = [copyObject(_imagePost)];
                }

                if (enableDistributorStock === 1 || enableDistributorStock === 0) {
                    newPost.enableDistributorStock = !!(enableDistributorStock)
                }

                if (distributorAvailability === 1 || distributorAvailability === 0) {
                    newPost.distributorAvailability = !!(distributorAvailability)
                }

                if (disableProduct === 1 || disableProduct === 0) {
                    newPost.disableProduct = !!(disableProduct)
                }

                if (typeof categories === 'string' && categories.match(idPattern)) {
                    newPost.categories = [{_id: categories}]
                } else if (typeof categories === 'string') {
                    try {
                        const categoriesObject = JSON.parse(categories);
                        newPost.categories = [
                            ...categoriesObject.filter((_id) => _id && _id.match(idPattern)).map((_id) => ({_id}))
                        ]
                    } catch (e) {

                    }
                }

                return (
                    <WappContext.Provider value={newContext}>
                        <div>
                            <PostTypesComponent
                                getInitialResponse={() => newPost}
                                getMenu={() => null}
                                onBackClick={(e) => {
                                    e.preventDefault();
                                    dialog.actions.close()
                                }}
                                pageProps={{
                                    initialValues: {
                                        ...(newPost) ? Object.keys(newPost).reduce((o, key) => {
                                            o["record." + key] = newPost[key];
                                            return o
                                        }, {}) : {},
                                    },
                                    formProps: ({formData}) => ({
                                        ref: (e) => {
                                            form.current = e
                                        },
                                        successMessage: null,
                                        formData: {...formData, submit: {...formData.submit, hidden: true}}
                                    }),
                                    requestProps: {redirect: null, go: 0}
                                }}
                            />
                        </div>
                    </WappContext.Provider>
                )
            }

            const isNotDeleted = post && post._status_isNotDeleted;

            const onSubmit = async (e) => {
                e.preventDefault();
                if (form.current) {
                    if (form.current.progress || form.current.done) {
                        return null;
                    }
                    const response = await form.current.onSubmit(e);
                    if ((response && response.error) || (response && response.errors)) {
                        if (autoRun) {
                            await progress.actions.setShow(false);
                        }
                        await dialog.actions.show();
                    } else if (response?.record._id) {

                        const newRows = JSON.parse(JSON.stringify(rows));
                        const newRow = JSON.parse(JSON.stringify(row));
                        newRow.saved = response.record;
                        newRows[i] = {...newRow};

                        await dialog.actions.close();
                        storage({[name + "ImportRows"]: newRows});

                        if (autoRun) {
                            const saveAbleRow = newRows.find(({error, saved}) => !error && !saved);
                            const fI = newRows.indexOf(saveAbleRow);
                            if (saveAbleRow && fI > -1) {
                                const foundPost = posts && posts.find((post) => (
                                    (post?._id && post._id === saveAbleRow.values._id) ||
                                    (post?.ean && post.ean === saveAbleRow.values.ean) ||
                                    (post?.isbn && post.isbn === saveAbleRow.values.isbn) ||
                                    (post?.dUrl && post.dUrl === saveAbleRow.values.dUrl)
                                ));
                                await onClick(e, foundPost, newRows, saveAbleRow, fI, saveAbleRow.saved)
                            } else {
                                await setRows(newRows);
                                await progress.actions.setShow(false);
                            }
                        } else {
                            await setRows(newRows);
                        }

                    } else {
                        setSnackMessage(appContext.messages["save" + N + "DefaultFail"])
                    }
                }
            };

            if (autoRun) {
                await progress.actions.setShow(true);
                await dialog.actions.hide();
            }

            form.current = null;

            await dialog.actions.open({
                dialogTitle: "",
                dialogContent: <>
                    <DialogContent/>
                </>,
                cancelText: appContext.labels["cancel" + N + "Text"],
                submitText: post?._id ? isNotDeleted ? appContext.labels[name + "ImportEditLabel"] : appContext.labels[name + "ImportRestoreLabel"] : appContext.labels["new" + N + "SubmitLabel"],
                onCancel: async (e) => {
                    e.preventDefault();
                    await dialog.actions.close();
                },
                onSubmit: onSubmit,
                dialogContentClassName: style.dialogContent
            });

            if (autoRun) {
                await new Promise((resolve) => {
                    const int = setInterval(() => {
                        if (form.current) {
                            clearInterval(int);
                            resolve()
                        }
                    }, 100)
                });
                await onSubmit(e);
            }

        }
    }

    async function getPostsWithProgress() {
        await progress.actions.setShow(true);
        await getPosts(rows);
        await progress.actions.setShow(false);
    }

    useEffect(() => {
        if (file && rows?.length && !posts?.length) {
            getPostsWithProgress();
        }
    }, []);

    return (
        <div className={style.import}>
            <Progress effect={progressEffect}/>
            {(!file || file && !rows?.length) ?
                <NewFile
                    request={async function ({filesArray}) {

                        const records = [];
                        const errors = [];

                        await new Promise((resolve, reject) => {
                            parseExcel({
                                file: filesArray[0],
                                onLoad: ({workbook}) => {
                                    records.push({workbook});
                                    resolve();
                                },
                                onError: (e) => {
                                    errors.push({
                                        message: e.message,
                                        path: "0"
                                    });
                                    reject();
                                }
                            });
                        });

                        return {
                            records,
                            ...(errors.length) ? {error: {message: errors[0].message, errors}} : {}
                        }
                    }}
                    name={name}
                    onSuccess={async ({files}) => {
                        const newFiles = files && files.length && files.filter((file) => file && file.workbook);
                        if (newFiles.length) {
                            await new Promise((resolve) => setTimeout(resolve, 500));
                            await onSuccess(newFiles);
                        }
                    }}
                    dropZone={false}
                    multiple={false}
                    accept={"application/vnd.ms-excel"}
                    label={appContext.labels[name + "ImportSelectFileLabel"]}
                    successOpenInNew={false}
                /> : null
            }
            {(file && rows?.length) ?
                <div className={style.closeContainer}>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={close}
                    >
                        {appContext.labels[name + "ImportRestartLabel"]}
                    </Button>
                </div> : null
            }
            {(file && rows?.length) ?
                <div className={style.autoRunContainer}>
                    <Button
                        onClick={() => setAutoRun(!autoRun)}
                        startIcon={(autoRun) ? <CheckBoxOutlined color={"secondary"}/> :
                            <CheckBoxOutlineBlankOutlined/>}
                    >
                        {appContext.labels[name + "ImportAutoRunLabel"]}
                    </Button>
                </div>
                : null
            }
            {
                (file && rows?.length) ?
                    <div className={style.tableContainer}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {propertyNames.map((propertyName) => {

                                        const label = (newRequestFormData["record." + propertyName] && newRequestFormData["record." + propertyName].label) || propertyName;

                                        return (
                                            <TableCell key={propertyName}>
                                                {label}
                                            </TableCell>
                                        )
                                    })}
                                    {(posts.length) ?
                                        <TableCell key={"save"}>
                                            {appContext.labels["save" + N + "SubmitLabel"]}
                                        </TableCell>
                                        : null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(({values, error, saved}, i) => {

                                    const foundPost = posts && posts.find((post) => {
                                        return (
                                            (values._id && post?._id === values._id) ||
                                            (values.ean && post?.ean === values.ean) ||
                                            (values.isbn && post?.isbn === values.isbn) ||
                                            (values.dUrl && post?.dUrl === values.dUrl)
                                        )
                                    });
                                    const isNotDeleted = foundPost?._status_isNotDeleted;

                                    return (
                                        <TableRow
                                            key={i}
                                            className={clsx(
                                                style.tableRow,
                                                {[style.savedRow]: rows[i].saved}
                                            )}
                                        >
                                            {propertyNames.map((propertyName) => {

                                                const isError = !!(error?.errors && error?.errors.find(({path}) => path === propertyName));
                                                let helperText = isError && error.errors.filter(({path}) => path === propertyName).map(({message}) => message).join(", ");

                                                if (!isError && propertyName === "piecesInStock" && foundPost && !saved && enableStockAdd) {
                                                    const currentStock = typeof foundPost.piecesInStock == "number" ? foundPost.piecesInStock : 0;
                                                    const value = typeof values[propertyName] == "number" ? values[propertyName] : 0;
                                                    const newStock = currentStock + value;
                                                    helperText = appContext.messages[name + "HelperTextStock"]({
                                                        current: currentStock,
                                                        newValue: newStock
                                                    })
                                                }

                                                if (!isError && propertyName === "piecesInStock" && saved && enableStockAdd) {
                                                    const savedStock = saved.piecesInStock || 0;
                                                    helperText = appContext.messages[name + "HelperTextStockSaved"]({saved: savedStock})
                                                }

                                                return (
                                                    <TableCell key={propertyName}>
                                                        {(propertyName === "_id" || propertyName === "ean" || propertyName === "isbn" || propertyName === "dUrl") ?
                                                            values[propertyName] :
                                                            <Editable
                                                                className={propertyName}
                                                                value={values[propertyName]}
                                                                error={isError}
                                                                helperText={helperText}
                                                                disabled={!!(saved)}
                                                                onChange={async (value) => {
                                                                    if (values[propertyName] !== value) {
                                                                        values[propertyName] = value;
                                                                        await progress.actions.setShow(true);
                                                                        rows[i] = {
                                                                            ...await rowValidation({values}),
                                                                            values,
                                                                            saved
                                                                        };
                                                                        const newRows = [...rows];
                                                                        storage({[name + "ImportRows"]: newRows});
                                                                        await setRows(newRows);
                                                                        await progress.actions.setShow(false);
                                                                    }
                                                                }}
                                                                ValueComponent={({children, ...rest}) => {
                                                                    if (propertyName === "cover" && values._imagePost) {
                                                                        return (
                                                                            <div
                                                                                {...rest}
                                                                                className={
                                                                                    clsx({
                                                                                            [rest.className]: rest.className
                                                                                        },
                                                                                        style.coverImage
                                                                                    )
                                                                                }
                                                                                style={{backgroundImage: "url(" + values._imagePost.thumb + ")"}}
                                                                            />
                                                                        )
                                                                    }
                                                                    return (
                                                                        <div {...rest}>
                                                                            {children}
                                                                        </div>
                                                                    )
                                                                }}
                                                            />
                                                        }
                                                    </TableCell>
                                                )
                                            })}
                                            {(posts.length) ?
                                                <TableCell
                                                    className={style.saveCell}
                                                    key={"save"}
                                                >
                                                    <Button
                                                        variant={"contained"}
                                                        color={(!!(error?.errors?.length) || !!(saved)) ? "secondary" : "secondary"}
                                                        onClick={(e) => onClick(e, foundPost, rows, rows[i], i, saved)}
                                                        href={(saved) ? appContext.routes[name + "Route"] + "/" + saved._id : null}
                                                        target={(saved) ? "_blank" : null}
                                                        disabled={!!(error?.errors?.length)}
                                                        startIcon={(saved) ? <OpenInNewIcon/> : null}
                                                    >
                                                        {(saved) ?
                                                            appContext.labels[name + "ImportSavedAlreadyLabel"] :
                                                            (foundPost) ?
                                                                isNotDeleted ? appContext.labels[name + "ImportEditLabel"] : appContext.labels[name + "ImportRestoreLabel"] :
                                                                appContext.labels["new" + N + "SubmitLabel"]}
                                                    </Button>
                                                </TableCell>
                                                : null
                                            }
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    :
                    (file) ?
                        <div className={style.error}>
                            {appContext.messages[name + "ImportFileError"]}
                        </div>
                        : null
            }
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={!!(snackMessage)}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackMessage}
            />
            <Dialog
                fullWidth
                maxWidth={"md"}
                effect={dialogEffect}
                className={style.dialog}
                paperClassName={style.dialogPaper}
            />
        </div>
    )
}

const WappComponent = withWapp(Import);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
