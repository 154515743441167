import React, {useContext, useMemo} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../../components/App/context";
import {withMaterialStyles} from "../../../components/Template/withMaterial";
import Form, {defaultComponents} from "../../../components/Form";
import PostContext from "../../../components/Post/context";
import capitalize from "../../../components/../utils/capitalize";

import materialStyle from "./materialStyle";
import style from "./style.css";

function Edit(props) {

    const {formProps = {}, requestProps = {}} = props;

    const postContext = useContext(PostContext);
    const {name, post, user, parentRoute, /*statusManager*/} = postContext;

    const N = capitalize(name);

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const utils = getUtils(context);
    //const {materialStyle} = props;

    const {wapp, req} = context;
    wapp.styles.use(style);

    const query = req.wappRequest.query;

    async function onSubmit(e, formData) {

        const initialState = window[wapp.config.appStateName] || {req: {timestamp: Date.now()}};
        const firstRender = (utils.getGlobalState("req.timestamp") === initialState.req.timestamp);

        const redirect = query.redirect ?
            {pathname: query.redirect} :
            !firstRender ?
                null :
                {pathname: parentRoute + "/" + post._id, search: "", hash: ""};

        const go = (redirect) ? 0 : -1;

        return await utils.sendRequest({
            requestName: name + "Save",
            args: formData,
            redirect,
            go,
            timeOut: 1000, ...requestProps
        });
    }

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation." + name + "Save.formData");
    } catch (e) {
        console.log(e)
    }

    const isNotDeleted = post && post._status_isNotDeleted;

    const formData = {
        ...formDataFromResolvers,
        submit: {
            label: (isNotDeleted) ? appContext.labels["save" + N + "SubmitLabel"] : appContext.labels["restore" + N + "SubmitLabel"]
        }
    };

    if (post?._id) {
        formData._id.value = post;
        formData._id.disabled = true;
        formData._id.refPostType = name;
    }

    const isAdmin = user?._status_isFeatured;

    if ((post?.orderStatus > 10 && !isAdmin) ||
        (!post?._status_isNotDeleted && !isAdmin)) {
        Object.keys(formData).forEach((key) => formData[key].disabled = true)
    }

    if (post?.orderStatus > 10 && isAdmin) {
        Object.keys(formData).forEach((key) => {
            if (key.match("company")) {
                formData[key].disabled = true;
                formData[key].hidden = true;
            }
        })
    }

    if (post?.orderStatus > 20 && isAdmin) {
        Object.keys(formData).forEach((key) => {
            if (key.match("shipping")) {
                formData[key].disabled = true;
                formData[key].hidden = true;
            }
        })
    }

    if (post?._id) {
        Object.keys(formData).forEach(function (key) {
            if (key.startsWith("record.")) {
                const ka = key.split(".");
                let value = post;
                ka.forEach(function (nk) {
                    if (nk !== "record") {
                        if ((value && value[nk]) || (value && value[nk] === 0)) {
                            value = value[nk];
                        } else {
                            value = null;
                        }
                    }
                });
                if (value || value === 0) {
                    formData[key].value = value;
                }
            }
        })
    }

    if (!post?.isShipping) {
        Object.keys(formData).forEach((key) => {
            if (key.match("shipping")) {
                formData[key].hidden = true;
            }
        });
    } else {
        Object.keys(formData).forEach((key) => {
            if (key.match("shipping")) {
                formData[key].required = true;
            }
        });
    }

    const initialOrderStatus = useMemo(() => post?.orderStatus, [post._id]);

    if (user?._status_isFeatured && formData["record.orderStatus"] && formData["record.orderStatus"].options) {
        formData["record.orderStatus"].options.map((option) => {
            if (option.value < initialOrderStatus) {
                option.disabled = true;
            }
            if (initialOrderStatus < 20 && option.value >= 20) {
                option.disabled = true;
            }
        })
    }

    return (
        <div className={style.edit}>
            <Form
                formData={formData}
                onSubmit={onSubmit}
                successMessage={
                    appContext.messages["save" + N + "SuccessMessage"]
                }
                components={{
                    ...defaultComponents,
                }}
                {...(typeof formProps == "function") ? formProps({formData}) : formProps}
            />
        </div>
    )
}

const WappComponent = withWapp(Edit);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
