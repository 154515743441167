// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I7FUV .Q6ere{margin-bottom:16px}.I7FUV .qBMdT:last-child>.pv42G{font-weight:500}.I7FUV .pv42G{padding:8px}.I7FUV .pv42G:first-child{width:100%}.I7FUV .pv42G:last-child{text-align:right;white-space:nowrap}.I7FUV .pv42G:nth-last-child(2){text-align:right;white-space:nowrap}.GbPOi{font-weight:400;line-height:1.66;letter-spacing:.03333em;text-align:left;padding:8px;font-size:12px;color:var(--error-color)}.DuPju{margin:0;padding:8px;font-size:12px;line-height:normal}.DuPju.GbPOi{color:var(--error-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary": "I7FUV",
	"table": "Q6ere",
	"tableRow": "qBMdT",
	"tableCell": "pv42G",
	"error": "GbPOi",
	"helperText": "DuPju"
};
module.exports = ___CSS_LOADER_EXPORT___;
