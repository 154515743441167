import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../App/context";
import {withMaterialStyles} from "../../Template/withMaterial";
import Form from "../../Form";
import Dialog from "../../Dialog";

import materialStyle from "./materialStyle";
import style from "./style.css";
import AccountContext from "../context";
import capitalize from "../../../utils/capitalize";
import {runPostTypesConfigSync} from "../../../postTypes";

function Signup(props) {

    const accountContext = useContext(AccountContext);
    const {
        name,
        documentName = "document",
        termsSlug = "terms",
        privacySlug = "privacy",
        acceptTermsPropertyName = "acceptTerms",
        acceptPrivacyPropertyName = "acceptPrivacy",
    } = accountContext;

    const N = capitalize(name);

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const utils = getUtils(context);
    //const {materialStyle} = props;

    const {wapp, req} = context;

    wapp.styles.use(style);

    const form = useRef();
    const dialog = useMemo(() => {
        return {}
    }, []);
    const dialogEffect = useMemo(() => ({actions}) => {
        dialog.actions = actions;
    }, [dialog]);

    const openTermsAndPrivacy = useMemo(() => (e, slug, propertyName) => {
        e.preventDefault();
        if (dialog.actions) {

            function DialogContent({slug}) {

                const context = useContext(WappContext);
                const {wapp, req, res} = context;

                const requestName = documentName + "FindBySlug";
                const posts = [utils.getGlobalState("res.responses." + documentName + "FindById"), utils.getGlobalState("res.responses." + requestName)];
                const initialPost = posts.find((p) => p?.slug === slug);

                const [response, setResponse] = useState(initialPost ? {[requestName]: initialPost} : null);

                const post = useMemo(() => response && response[requestName]?._id ? response[requestName] : null, [requestName, response]);

                const path = useMemo(() => (appContext.routes[documentName + "Route"] && slug) ? appContext.routes[documentName + "Route"] + "/" + slug : null, [slug]);

                const newContext = useMemo(() => {
                    return (path && slug) ?
                        {
                            wapp,
                            req: {
                                user: req.user,
                                wappRequest: {
                                    user: req.wappRequest.user
                                }
                            },
                            res: {
                                wappResponse: {
                                    route: {
                                        requestPath: path,
                                        params: {_id: slug}
                                    },
                                    store: res.wappResponse.store,
                                    status: res.wappResponse.status
                                }
                            }
                        } : context;
                }, [path, slug, wapp, req.user, req.wappRequest.user, res.wappResponse.store, res.wappResponse.status, context]);

                const PostTypesComponent = (path) ? runPostTypesConfigSync({
                    action: "getComponent",
                    p: {context: newContext, appContext}
                }).filter((C) => !!(C))[0] : null;

                const content = useMemo(() => wapp.contents.get(documentName), [wapp.contents]);

                const request = useMemo(() => async function request({wapp, req, res}) {
                    if (content && content.request) {
                        const response = await content.request({wapp, req, res});
                        await setResponse(response)
                    }
                }, [content]);

                useEffect(() => {
                    if (path && !post?._id) {
                        request(newContext);
                    }
                }, [path, request, newContext, post]);

                return (
                    <WappContext.Provider value={newContext}>
                        <div>
                            {(post && PostTypesComponent) ? <PostTypesComponent post={post}/> : null}
                        </div>
                    </WappContext.Provider>
                )
            }

            dialog.actions.open({
                dialogTitle: appContext.titles[propertyName + "Title"],
                dialogContent: <>
                    <DialogContent slug={slug}/>
                </>,
                cancelText: appContext.labels["cancel" + N + "Text"],
                submitText: appContext.labels[propertyName + "Label"],
                onCancel: async (e) => {
                    e.preventDefault();
                    if (form.current) {
                        await form.current.setState({
                            formData: {
                                ...form.current.state.formData,
                                [propertyName]: {
                                    ...form.current.state.formData[propertyName],
                                    value: false,
                                    helperText: "",
                                    error: false
                                }
                            }
                        })
                    }
                    await dialog.actions.close();
                },
                onSubmit: async (e) => {
                    e.preventDefault();
                    if (form.current) {
                        await form.current.setState({
                            formData: {
                                ...form.current.state.formData,
                                [propertyName]: {
                                    ...form.current.state.formData[propertyName],
                                    value: true,
                                    helperText: "",
                                    error: false
                                }
                            }
                        })
                    }
                    await dialog.actions.close();
                },
                dialogContentClassName: style.dialogContent
            })

        }
    }, [dialog.actions, appContext, N, documentName, utils]);

    const onSubmit = useMemo(() => async function onSubmit(e, formData) {
        const query = req.wappRequest.query;
        let response = await utils.signup({
            requestName: name + "Signup",
            args: formData,
            ...typeof props.redirect !== "undefined" ?
                {
                    redirect: props.redirect
                } : {
                    redirect: {pathname: query.redirect || appContext.routes.userRoute + "/:_id", search: "", hash: ""}
                }
        });

        if (props.onSubmit) {
            const newResponse = await props.onSubmit(e, response)
            if (newResponse) {
                return newResponse;
            }
        }
        return response;

    }, [appContext.routes.userRoute, name, req.wappRequest.query, utils]);

    const replace = useMemo(() => function replace(props) {
        const {text, word, href, onClick} = props;
        const textA = (typeof text === "string") ? [text] : text;
        return textA.map((text) => {
            if (typeof text === "string" && text.match(word)) {
                return [
                    text.split(word)[0],
                    <span><a href={href} onClick={onClick}>{word}</a></span>,
                    text.split(word)[1]
                ]
            }
            return [text];
        }).reduce((a, value) => {
            a.push(...value);
            return a
        }, [])
    }, []);

    const getFormData = useMemo(() => function getFormData(acceptProperties) {

            let formDataFromResolvers = {};
            try {
                formDataFromResolvers = utils.getGlobalState("res.graphql.mutation." + name + "Signup.formData");
            } catch (e) {
            }

            const formData = {
                ...formDataFromResolvers,
                submit: {
                    label: appContext.labels.signupSubmitLabel
                }
            };

            acceptProperties.forEach(({propertyName, slug, word}) => {
                if (formData[propertyName] && typeof formData[propertyName].label == "string") {

                    const data = {...formData[propertyName]};

                    if (data.required && data.label.slice(-2) !== " *") {
                        data.label = data.label + " *";
                    }

                    const url = (documentName && appContext.routes[documentName + "Route"] && slug) ? appContext.routes[documentName + "Route"] + "/" + slug : "";

                    const newLabelParts = replace({
                        text: data.label,
                        word,
                        onClick: (url) ? (e) => openTermsAndPrivacy(e, slug, propertyName) : null,
                        href: url
                    });

                    if (newLabelParts.length > 1) {
                        data.label = <>{newLabelParts.map((value, i) => <React.Fragment
                            key={i}>{value}</React.Fragment>)}</>;
                        formData[propertyName] = data;
                    } else {
                        data.label = <a href={url}
                                        onClick={(url) ? (e) => openTermsAndPrivacy(e, slug, propertyName) : null}>{data.label}</a>;
                        formData[propertyName] = data;
                    }
                }
            });

            return formData;

        },
        [appContext.labels.signupSubmitLabel, appContext.routes, documentName, name, openTermsAndPrivacy, replace, utils]
    );


    const formData = getFormData([
        ...termsSlug && acceptTermsPropertyName ? [{
            propertyName: acceptTermsPropertyName,
            slug: termsSlug,
            word: appContext.labels.termsWord || "terms",
        }] : [],
        ...privacySlug && acceptPrivacyPropertyName ? [{
            propertyName: acceptPrivacyPropertyName,
            slug: privacySlug,
            word: appContext.labels.privacyWord || "privacy",
        }] : []
    ]);

    return (
        <>
            <Form
                ref={form}
                formData={formData}
                onSubmit={onSubmit}
            />
            <Dialog
                fullWidth
                maxWidth={"md"}
                effect={dialogEffect}
                className={style.dialog}
                paperClassName={style.dialogPaper}
            />
        </>
    )
}

const WappComponent = withWapp(Signup);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
