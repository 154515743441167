import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

import {withMaterialStyles} from "../../../components/Template/withMaterial";
import DefaultNew from "../../../components/Post/New";
import PostContext from "../../../components/Post/context";
import AppContext from "../../../components/App/context";

import style from "./style.css";
import materialStyle from "./materialStyle";
import {defaultComponents} from "../../../components/Form";
import capitalize from "../../../utils/capitalize";
import {tableComponents, tableIcons} from "../../order/Content";
import disabledPostsStyle from "./disabledPostsStyle.css";

function New(props) {

    const {initialValues = {}, formProps = {}, requestProps = {}, disabled, autoSubmit = 5} = props;

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const utils = getUtils(context);

    const {wapp} = context;
    wapp.styles.use(style);
    wapp.styles.use(disabledPostsStyle);

    const postContext = useContext(PostContext);

    const {name} = postContext;
    const N = capitalize(name);

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation." + name + "New.formData");
    } catch (e) {
    }

    const formData = {
        ...formDataFromResolvers,
        info: {
            componentName: "Info",
            order: 100,
        },
        submit: {
            label: appContext.labels["save" + N + "SubmitLabel"],
            startIcon: <PointOfSaleIcon/>,
            order: 101,
        },
        ...autoSubmit ? {
            autoSubmit: {
                componentName: "AutoSubmit",
                order: 102
            }
        } : {}
    };

    if (initialValues && Object.keys(initialValues).length) {
        Object.keys(formData).forEach(function (key) {
            if (typeof initialValues[key] !== "undefined" && initialValues[key] !== null) {
                formData[key].value = initialValues[key];
            }
        })
    }

    formData["record.order"].componentName = "PostsOrder";
    if (disabled) {
        formData["record.order"].disabled = true;
        if (formData["_author"]) {
            formData["_author"].disabled = true;
        }
    }

    if (formData["_author"]) {
        formData["_author"].componentName = "PostsAuthor";
    }

    const order = useMemo(() => ({actions: {}}), []);
    const info = useMemo(() => ({actions: {}}), []);
    const state = useMemo(() => {
        const paymentType = formData["record.order"]?.isBankcard ? 20 : formData["record.order"]?.paymentType;
        return {paymentType: paymentType || 0}
    }, []);
    const setState = (newState = {}) => Object.keys(newState).forEach((key) => state[key] = newState[key]);
    const formRef = useRef();

    async function updatePaymentType(formData) {
        const orders = order.actions.getPosts();
        const selectedOrder = orders && orders[0];

        let paymentType = selectedOrder?.isBankcard ? 20 : selectedOrder?.paymentType;
        if (!paymentType) {
            paymentType = 0;
        }

        if (state.paymentType !== paymentType) {
            setState({paymentType});
            info.actions.setShow(!!(selectedOrder?.isBankcard));
            if (!formData.submit._defaultLabel) {
                formData.submit._defaultLabel = formData.submit.label;
            }
            formData.submit.label = selectedOrder ? (selectedOrder.isBankcard) ? appContext.labels["newOnline" + N + "SubmitLabel"] : (selectedOrder.isTransfer) ? appContext.labels["newTransfer" + N + "SubmitLabel"] : appContext.labels["newCash" + N + "SubmitLabel"] : formData.submit._defaultLabel;
            await formRef.current.setNewFormData(formData);
            return true;
        }
    }

    useEffect(() => {
        updatePaymentType(formData)
    }, []);

    const onSubmit = async function onSubmit(e, formData) {

        const orders = order.actions.getPosts();
        const selectedOrder = orders && orders[0];
        const isBankcard = selectedOrder?.isBankcard;

        const orderId = selectedOrder?._id;
        const redirect = (!isBankcard && orderId) ? appContext.routes.orderRoute + "/" + orderId : "";
        const r = await utils.sendRequest({
            requestName: name + "New",
            args: formData, ...requestProps,
            redirect,
            timeOut: (redirect) ? 1000 : 0,
            go: 0
        });
        if (r.record?.barionPaymentId) {
            window.location.href = appContext.routes.barionRoute + "?paymentId=" + r.record.barionPaymentId;
            return null;
        }
        return r;
    };

    const fP = (typeof formProps == "function") ? formProps({formData, onSubmit}) : formProps;

    return (
        <div className={style.new}>
            <DefaultNew
                {...props}
                formProps={{
                    formData: formData,
                    onSubmit: onSubmit,
                    ...fP,
                    ref: (form) => {
                        formRef.current = form;
                        if (typeof fP.ref == "function") {
                            fP.ref(form);
                        } else if (fP.ref) {
                            fP.ref.current = form;
                        }
                    },
                    onChange: async (e, key, formData) => {

                        if (key === "record.order") {
                            await updatePaymentType(formData);
                        }

                        if (fP.onChange) {
                            await fP.onChange(e, key, formData)
                        }
                    },
                    components: {
                        ...defaultComponents,
                        Posts: {
                            ...defaultComponents.Posts,
                            props: {
                                ...defaultComponents.Posts.props,
                                getStageProps: () => ({
                                    ...(disabled) ? {style: disabledPostsStyle} : {}
                                }),
                            }
                        },
                        PostsAuthor: {
                            ...defaultComponents.Posts,
                            props: {
                                ...defaultComponents.Posts.props,
                                getStageProps: () => ({
                                    ...(disabled) ? {style: disabledPostsStyle} : {}
                                }),
                            }
                        },
                        PostsOrder: {
                            ...defaultComponents.Posts,
                            props: {
                                ...defaultComponents.Posts.props,
                                effect: ({actions}) => order.actions = actions,
                                getStageProps: () => ({
                                    tableProps: {
                                        components: tableComponents,
                                        icons: tableIcons,
                                        className: null
                                    },
                                    ...(disabled) ? {style: disabledPostsStyle} : {}
                                }),
                                getDialogProps: () => ({
                                    tableProps: {
                                        components: tableComponents,
                                        icons: tableIcons,
                                        className: null
                                    },
                                }),
                            }
                        },
                        AutoSubmit: {
                            props: {
                                submit: ()=>{
                                    if (formRef.current) {
                                        formRef.current.onSubmit()
                                    }
                                }
                            },
                            Component: (props)=>{

                                const [s, setS] = useState(autoSubmit)

                                useEffect(()=>{
                                    if (s > 0) {
                                        setTimeout(() => setS(s-1), 1000)
                                    } else {
                                        if (props.submit) {
                                            props.submit()
                                        }
                                    }
                                })

                                return (
                                    <div>
                                        {appContext.messages[name + "AutoSubmit"](s)}
                                    </div>
                                )
                            }
                        },
                        Info: {
                            props: {},
                            Component: () => {

                                const [show, setShow] = useState(false);

                                useEffect(() => {
                                    info.actions = {
                                        setShow
                                    };
                                    return () => {
                                        info.actions = {
                                            setShow: () => null
                                        }
                                    }
                                });

                                return (
                                    <>
                                        {
                                            (show) ?
                                                <div className={style.info}>
                                                    <div className={style.barionLogo}>
                                                        <div/>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </>
                                )
                            }
                        },
                    }
                }}
            />
        </div>
    )
}

const WappComponent = withWapp(New);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
