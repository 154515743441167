import React from "react";
import TextField from "@mui/material/TextField";

export default function NumberField(props) {

    const {onChange, ...rest} = props;

    let {value} = rest;

    if (typeof value === "undefined") {
        value = ""
    }

    if (typeof value === "number" && value === 0) {
        value = "0";
    }

    return (
        <TextField
            {...rest}
            onChange={(e) => {
                let value = e.target?.value || "";
                const isEmptyString = typeof value === "string" && !value;
                value = value.replace(/ /g, "");
                onChange({target: {value: (!isEmptyString && !isNaN(Number(value))) ? Number(value) : value}});
            }}
            value={value}
        />
    )
}
