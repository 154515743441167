function getMenu(props = {}) {

    const {addMenuItems, ...rest} = props;

    return [
        ...(addMenuItems) ? addMenuItems({...rest, addMenuItems}) : []
    ];

}

export default getMenu;
