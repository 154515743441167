import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import {WappContext} from "wapplr-react/dist/common/Wapp";

import messages from "../../config/constants/messages";
import labels from "../../config/constants/labels";
import titles from "../../config/constants/titles";
import routes from "../../config/constants/routes";
import menus from "../../config/constants/menus";

import {runPostTypesConfigSync} from "../../postTypes";

import {withMaterialTheme} from "../Template/withMaterial";
import Template from "../Template";
import {materialMediaQuery, materialTheme} from "./materialTheme";

import Account from "../Account";
import NotFound from "../NotFound";
import Home from "../Home";
import Logo from "../Logo";

import AppContext from "./context";
//import ExternalContext from "./externalContext";
import style from "./style.css";
import templateStyle from "./templateStyle.css";
import getMenu from "./menu";
import getTopMenu from "./topMenu";
import getFooterMenu from "./footerMenu";

import {storage as defaultLocalStorage} from "../../utils/localStorage";
import {storage as defaultMemoStorage} from "../../utils/memoStorage";
import Cookies from "../Cookies";

export default function App(props) {

    const context = useContext(WappContext);
    //const externalContext = useContext(ExternalContext);
    //console.log("External settings", externalContext);

    const {wapp, res} = context;
    const utils = getUtils(context);
    const {subscribe, userPostTypeName = "user"} = props;

    wapp.styles.use(style);

    const ThemedTemplate = useMemo(() => withMaterialTheme({
        theme: materialTheme,
        mediaQuery: materialMediaQuery,
        classNamePrefix: wapp.globals.WAPP
    }, Template), [wapp.globals.WAPP]);

    const container = useRef();

    const [url, setUrl] = useState(utils.getRequestUrl());
    const [position] = useState(wapp.globals.NAME === wapp.globals.RUN ? "fixed" : "sticky");
    const [fullscreen] = useState(wapp.globals.NAME === wapp.globals.RUN);
    const [user, setUser] = useState(utils.getRequestUser());

    const storageName = wapp.globals.NAME;
    const template = useMemo(() => {
        return {}
    }, []);

    useEffect(function () {

        async function onLocationChange(newUrl) {
            if (url !== newUrl) {
                await setUrl(newUrl);
            }
        }

        async function onUserChange(newUser) {


            const orderPostTypeName = 'order';

            if (!user?._id && newUser?._id) {

                function copyLocalStorage() {

                    const storageName = user?._id ? orderPostTypeName + "_" + user._id : orderPostTypeName;
                    const storageData = storage()[storageName];
                    const cart = (storageData['record.cart']) ? JSON.parse(storageData['record.cart']) : [];

                    const newStorageName = newUser?._id ? orderPostTypeName + "_" + newUser._id : orderPostTypeName;
                    const newStorageData = storage()[storageName];

                    if (cart.length) {

                        storage({
                            [storageName]: {
                                ...storageData,
                                ["record.cart"]: JSON.stringify([])
                            },
                            [newStorageName]: {
                                ...newStorageData,
                                ["record.cart"]: JSON.stringify(cart)
                            }
                        });

                        return true;

                    }
                }

                function copyMemoStorage() {

                    const storageName = user?._id ? orderPostTypeName + "_" + user._id : orderPostTypeName;
                    const storageData = storage(undefined, true)[storageName];

                    const newStorageName = newUser?._id ? orderPostTypeName + "_" + newUser._id : orderPostTypeName;
                    const newStorageData = storage(undefined, true)[storageName];

                    storage({
                        [storageName]: {},
                        [newStorageName]: {
                            ...newStorageData,
                            ...storageData,
                        }
                    }, true);
                }

                const updated = copyLocalStorage();
                if (updated) {
                    copyMemoStorage();
                }

            }
            else if (user?._id && !newUser?._id) {
                const storageName = user?._id ? orderPostTypeName + "_" + user._id : orderPostTypeName;
                storage({[storageName]: {}}, true);
            }

            await setUser((newUser?._id) ? newUser : null);
        }

        const unsub1 = subscribe.locationChange(onLocationChange);
        const unsub2 = subscribe.userChange(onUserChange);

        return function useUnsubscribe() {
            unsub1();
            unsub2();
        }
    }, [subscribe, url, template.actions]);

    const storage = function (data, memo) {
        if (memo) {
            return defaultMemoStorage((data) ? data : {}, storageName);
        }
        return defaultLocalStorage(data, storageName);
    };

    const userStatusManager = wapp.getTargetObject().postTypes.findPostType({name: userPostTypeName}).statusManager;
    const route = res.wappResponse.route;
    const requestPath = route.requestPath;

    const appContext = {messages, labels, titles, routes, menus, userStatusManager, storage, template};

    const PostTypesComponent = runPostTypesConfigSync({
        action: "getComponent",
        p: {context, appContext}
    }).filter((C) => !!(C))[0];

    return (
        <AppContext.Provider value={appContext}>
            <div
                ref={container}
                className={style.app}
            >
                <ThemedTemplate
                    position={position}
                    fullscreen={fullscreen}
                    getMenu={getMenu}
                    getTopMenu={getTopMenu}
                    getFooterMenu={getFooterMenu}
                    effect={(p) => {
                        template.actions = p?.actions
                    }}
                    Logo={() => <Logo />}
                    pageContentNoPadding={(requestPath === "/")}
                    transparentAppBar={(requestPath === "/")}
                    style={templateStyle}
                    copyright={
                        <div>
                            <div style={{height: "12px"}}>
                            <span>
                                <Logo/>
                            </span>
                                <span style={{marginLeft: "4px"}}>{`${new Date().getFullYear()} ©`}</span>
                            </div>
                            <a href={"https://barion.com"} target={"_blank"} className={style.barionLogo}>
                                <div/>
                            </a>
                        </div>
                    }
                    user={user}
                >
                    {
                        (requestPath === "/") ?
                            <Home/>
                            :
                            (requestPath.startsWith(routes.accountRoute)) ?
                                <Account/>
                                :
                                (PostTypesComponent) ?
                                    <PostTypesComponent/>
                                    :
                                    <NotFound/>
                    }
                    <Cookies/>
                </ThemedTemplate>
            </div>
        </AppContext.Provider>
    );
}
