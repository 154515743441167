export function getConfig(p = {}) {

    const {config = {}} = p;

    const commonConfig = config.common || {};
    const globalsConfig = config.globals || {};

    const {WAPP, DEV} = globalsConfig;

    const common = {
        ...commonConfig,
        siteName: "Könyv és Játék webáruház",
        description: "Üdvözlünk a Könyv és Játék webáruházban! Gyere és merülj el velünk a mesekönyvek és a játékok világában! Küldetésünk, hogy támogassuk a szülőket a tökéletes könyvek és játékok kiválasztásában, legyen szó születésnapról, névnapról vagy karácsonyról. A legkedveltebb kiadók már velünk vannak, mint a Scolar és a Pagony, de kínálatunk folyamatosan frissül...",
        graphql: {
            route: (DEV) ? "/graphql" : "/g" + WAPP
        }
    };

    return {
        config: {
            ...config,
            common: common
        },
    }
}
