import React, {useContext} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
//import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import Menu from "../../Menu";
import AppContext from "../../App/context";
import {withMaterialStyles} from "../../Template/withMaterial";
import PostContext from "../../Post/context";

import getMenu from "./menu";

import materialStyle from "./materialStyle";
import style from "./style.css";

function Content(props) {

    const postContext = useContext(PostContext);
    const {user, post, name, parentRoute} = postContext;

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    //const utils = getUtils(context);
    const {materialStyle, addMenuItems, menuProps = {}} = props;

    const {wapp} = context;

    wapp.styles.use(style);

    const statusManager = wapp.getTargetObject().postTypes.findPostType({name: name}).statusManager;

    return (
        <div className={style.post}>
            <div className={style.content}>
                <Menu
                    parentRoute={parentRoute}
                    menu={getMenu({appContext, addMenuItems, statusManager})}
                    materialStyle={materialStyle}
                    menuProperties={{user, post}}
                    list={true}
                    {...menuProps}
                />
            </div>
        </div>
    )
}

const WappComponent = withWapp(Content);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
