// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RFlLv{padding-top:8px;width:100%;overflow:hidden}.oXeqh{display:-ms-flexbox;display:flex;gap:16px}.oXeqh>div{border-radius:20px;border-style:solid;border-width:1px;border-color:var(--border-color);padding:16px;-ms-flex:1 1 50%;flex:1 1 50%}.oXeqh h4{margin-top:0}@media(max-width: 799px){.oXeqh{-ms-flex-direction:column;flex-direction:column}}div[name=record]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:16px}div[name=record]>*{-ms-flex:0 1 auto;flex:0 1 auto}div[name=record]>div[name=\"record.shipping\"],div[name=record]>div[name=\"record.company\"],div[name=record]>div[name=\"record.total\"]{-ms-flex:1 0 32%;flex:1 0 32%}.wrTTi{top:-15px;left:9px;font-size:12px;padding:4px;padding-right:8px;margin-bottom:8px;position:absolute;background:var(--bg-color);width:auto}div[name=record]>div[name=\"record.total\"],div[name=record]>div[name=\"record.cart\"]{-ms-flex-item-align:auto;align-self:auto;width:100%;border-style:solid;border-width:1px;border-color:var(--border-color);padding:24px 16px;border-radius:20px;position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new": "RFlLv",
	"loginOrSignup": "oXeqh",
	"containerLabel": "wrTTi"
};
module.exports = ___CSS_LOADER_EXPORT___;
