// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".acWUY{width:100%;position:relative;overflow:hidden}._9T2aN{border-spacing:0;margin:0;padding:8px}._9T2aN th{vertical-align:top;text-align:left;padding:6px 0;line-height:normal}._9T2aN td{vertical-align:inherit;text-align:left;padding:6px 0 6px 16px}._9T2aN .utgL1,._9T2aN .z9eLH{width:100%;display:grid}.DUNJU{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.DUNJU .a\\+n9W>span{word-break:keep-all;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.DUNJU .vUtKc{margin-right:8px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.\\+fPBh{word-break:keep-all;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.\\+fPBh.eSH8C{word-break:normal;white-space:normal}.\\+fPBh.eSH8C.j1YCJ{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden}.\\+fPBh ._5vetD{margin-left:3px}.z4HV4{word-break:keep-all;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.ieQ59{word-break:keep-all;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.zVgBG{display:-ms-flexbox;display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "acWUY",
	"table": "_9T2aN",
	"thInner": "utgL1",
	"tdInner": "z9eLH",
	"label": "DUNJU",
	"labelText": "a+n9W",
	"icon": "vUtKc",
	"textField": "+fPBh",
	"multiline": "eSH8C",
	"maxRows": "j1YCJ",
	"textFieldEndFix": "_5vetD",
	"status": "z4HV4",
	"date": "ieQ59",
	"checkbox": "zVgBG"
};
module.exports = ___CSS_LOADER_EXPORT___;
