import React from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ToysIcon from "@mui/icons-material/Toys";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings";
import Logo from "../Logo";
import Avatar from "../Avatar/me";
import {runPostTypesConfigSync} from "../../postTypes";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

function getMenu(props = {}) {

    const {appContext, context} = props;
    const {menus, routes, /*userStatusManager*/} = appContext;

    async function onClickBefore() {
        if (appContext.template?.actions) {
            appContext.template.actions.storeDrawerScrollTop();
        }
        if (appContext.template?.actions) {
            await appContext.template.actions.drawerCloseNarrow();
        }
    }

    const utils = getUtils(context);

    const categoryMenuResponse = utils.getGlobalState("res.responses.categoryMenu");

    const categoryMenu = (categoryMenuResponse?.items?.length) ? categoryMenuResponse.items.map((category) => ({
        name: category.title,
        href: routes.productsRoute + "/categories/" + category.slug,
        onClickBefore,
        props: {
            itemTextProps: {
                inset: true
            }
        }
    })) : [];

    const ageMenuResponse = utils.getGlobalState("res.responses.ageMenu");

    const ageMenu = (ageMenuResponse?.items?.length) ? ageMenuResponse.items.map((age) => ({
        name: age.title,
        href: routes.productsRoute + "/ages/" + age.slug,
        onClickBefore,
        props: {
            itemTextProps: {
                inset: true
            }
        }
    })) : [];

    return [
        {
            name: menus.productsMenu,
            href: (categoryMenu.length === 1) ? categoryMenu[0].href : routes.productsRoute,
            Icon: ToysIcon,
            role: function (p) {
                return true //(p?.user?._id);
            },
            onClickBefore,
            ...(categoryMenu.length > 1) ? {items: categoryMenu.map((m)=>({...m, isSelected: ({inner, hrefWithParentRoute, context})=>(inner && context.req.wappRequest.path.startsWith(hrefWithParentRoute))}))} : {
                props: {
                    itemTextProps: {
                        secondary: categoryMenu && categoryMenu[0] && categoryMenu[0].name
                    }
                }
            }
        },
        {
            name: menus.agesMenu,
            href: (ageMenu.length === 1) ? ageMenu[0].href : routes.productsRoute,
            Icon: Diversity3Icon,
            role: function (p) {
                return true //(p?.user?._id);
            },
            onClickBefore,
            ...(ageMenu.length > 1) ? {items: ageMenu.map((m)=>({...m, isSelected: ({inner, hrefWithParentRoute, context})=>(inner && context.req.wappRequest.path.startsWith(hrefWithParentRoute))}))} : {
                props: {
                    itemTextProps: {
                        secondary: ageMenu && ageMenu[0] && ageMenu[0].name
                    }
                }
            }
        },
        {
            name: menus.loginMenu,
            href: routes.accountRoute + "/login",
            role: function (p) {
                return !(p?.user?._id);
            },
            Icon: AccountCircleIcon,
            onClickBefore
        },
        {
            name: menus.dashboardMenu,
            href: function (p) {
                return (p?.user?._id) ? routes.userRoute + "/" + p.user._id : routes.userRoute;
            },
            role: function (p) {
                return p?.user?._id;
            },
            Icon: function (props) {
                return (
                    <Avatar size={"small"} {...props}/>
                )
            },
            onClickBefore
        },
        {divider: true, role: (p) => p.user && p.user._status_isFeatured},
        {
            name: "Admin",
            role: function (p) {
                return p.user && p.user._status_isFeatured;
            },
            items: [
                ...runPostTypesConfigSync({action: "adminMenu", p: props}).filter((m) => m).map((menu) => {
                    menu.items.forEach((item) => {
                        item.onClickBefore = onClickBefore;
                    });
                    return menu;
                })
            ],
            onClickBefore,
            Icon: AdminPanelSettings
        },
        {divider: true},
        {
            name: menus.logoutMenu,
            href: routes.accountRoute + "/logout",
            role: function (p) {
                return p?.user?._id;
            },
            Icon: ExitToAppIcon,
            onClickBefore
        },
    ];

}

export default getMenu;
