// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mlfEF{display:grid;list-style:none;padding:0;grid-template-columns:repeat(3, 1fr);grid-gap:8px;gap:8px}.ZIZYX.i0Xev .mlfEF{grid-template-columns:repeat(1, 1fr)}.Cidh2{aspect-ratio:1}._4UM1b{overflow:hidden;height:100%;width:100%;border-radius:20px;cursor:pointer}._4UM1b img{-o-object-fit:contain;object-fit:contain;width:100%;height:100%}._7AmNP{overflow:hidden;background-color:#f7f3f0;height:100%}@media(prefers-color-scheme: dark){._7AmNP{background-color:var(--bg-color)}}._7AmNP *{-webkit-box-sizing:border-box;box-sizing:border-box}._7AmNP .react-swipeable-view-container>div:first-child img,._7AmNP .react-swipeable-view-container>div:first-child video{max-height:calc(100vh - 64px)}.sKW2E{position:absolute;width:48px;height:48px;top:0;right:0;z-index:1;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;visibility:hidden;cursor:pointer}.sKW2E svg{color:var(--font-color)}.MiYA1{padding:0 !important}.MiYA1>div{margin:0;height:100%}._3RhjX>div:first-child{-webkit-transition:all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;transition:all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important}.HulwI{height:100%}@media(max-width: 799px){._3RhjX>div:first-child{background-color:#f7f3f0}._3RhjX div[role=dialog]{border-radius:0}.HulwI{border-radius:0;max-width:100%;width:100%;margin:0;max-height:100%}.sKW2E{visibility:visible}}@media(prefers-color-scheme: dark)and (max-width: 799px){._3RhjX>div:first-child{background-color:var(--bg-color)}}@media(max-width: 599px){.mlfEF{gap:4px}._3RhjX div[role=dialog]{border-radius:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "mlfEF",
	"gallery": "ZIZYX",
	"startJustOne": "i0Xev",
	"child": "Cidh2",
	"item": "_4UM1b",
	"swipeableContainer": "_7AmNP",
	"close": "sKW2E",
	"dialogContentClassName": "MiYA1",
	"dialog": "_3RhjX",
	"dialogPaper": "HulwI"
};
module.exports = ___CSS_LOADER_EXPORT___;
