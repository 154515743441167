import React, {useContext, useEffect, useRef, useState} from "react";

import {WappContext} from "wapplr-react/dist/common/Wapp";

import {withMaterialStyles} from "../Template/withMaterial";

import MaterialDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

import clsx from "clsx";

import materialStyle from "./materialStyle";
import style from "./style.css";

function Dialog(props) {

    const context = useContext(WappContext);

    const {wapp} = context;

    wapp.styles.use(style);

    const {
        cancelText = "Cancel",
        submitText = "Submit",
        dialogTitle = "Alert",
        dialogContent = "Are you sure?",
        maxWidth,
        fullWidth,
        className,
        paperClassName
    } = props;

    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(true);
    const [snackMessage, setSnackMessage] = useState("");
    const [dialogProps, setDialogProps] = useState({cancelText, submitText, dialogTitle, dialogContent});

    const scrollElement = useRef();

    const Form = dialogProps.Form;

    const form = useRef();

    const formSubmit = async function (e, formData) {
        return await handleSubmit(e, formData);
    };

    const handleOpen = async (dialogProps) => {
        if (dialogProps) {
            await setDialogProps(dialogProps)
        }
        await setOpen(true);
    };

    const handleClose = async () => {
        await setOpen(false);
    };

    const handleCancel = (e) => {
        setOpen(false);
        if (dialogProps.onCancel) {
            dialogProps.onCancel(e)
        }
    };

    const handleSubmit = async (e, formData) => {
        if (!formData && form.current) {
            e.preventDefault();
            return await form.current.onSubmit(e);
        }
        if (e) {
            e.preventDefault();
        }
        if (dialogProps.onSubmit) {

            const response = await dialogProps.onSubmit(e, formData);

            if ((response && response.error) || (response && response.errors)) {

                if (form.current) {
                    return response;
                } else {
                    await setOpen(false);
                }

                const message = response.error?.message || response.errors?.["0"]?.message;

                if (message && snackMessage !== message) {
                    setSnackMessage(message);
                }

            } else if (response) {
                if (dialogProps.successMessage) {
                    setSnackMessage(dialogProps.successMessage);
                }
                await setOpen(false);
            }

        } else {
            await setOpen(false);
        }
    };

    const handleCloseSnackbar = function (/*e, reason*/) {
        if (snackMessage) {
            setSnackMessage("")
        }
    };

    const handleShow = async () => {
        if (!show) {
            await setShow(true);
        }
    };

    const handleHide = async () => {
        if (show) {
            await setShow(false);
        }
    };

    useEffect(function () {

        const actions = {
            open: handleOpen,
            close: handleClose,
            cancel: handleCancel,
            submit: handleSubmit,
            show: handleShow,
            hide: handleHide,
            getScrollElement: () => {
                return scrollElement.current
            }
        };

        if (props.effect) {
            props.effect({
                actions
            })
        }

    });

    const firstDivChild = context.res.wappResponse.container && context.res.wappResponse.container.getElementsByTagName("div")[0];
    const container = firstDivChild || context.res.wappResponse.container || typeof document !== "undefined" && document.body || undefined

    return (
        <>
            <MaterialDialog
                className={clsx(
                    style.dialog,
                    {
                        [style[className]]: className && style[className],
                        [className]: className && !style[className],
                        [style.hidden]: !show
                    }
                )}
                open={open}
                onClose={handleCancel}
                aria-labelledby={"post-dialog-title"}
                aria-describedby={"post-dialog-description"}
                classes={
                    (paperClassName) ? {paper: paperClassName} : {}
                }
                {...{
                    maxWidth,
                    fullWidth,
                }}
                container={container}
            >
                {(dialogProps.dialogTitle) ?
                    <DialogTitle id={"post-dialog-title"}>{dialogProps.dialogTitle}</DialogTitle> : null}
                <DialogContent ref={scrollElement} className={clsx(
                    style.dialogContent,
                    dialogProps.dialogContentClassName,
                )}>
                    <DialogContentText
                        component={"div"}
                        id={"post-dialog-description"}
                    >
                        {dialogProps.dialogContent}
                    </DialogContentText>
                    {(Form) ?
                        <Form
                            setFormRef={form}
                            onSubmit={formSubmit}
                        />
                        : null
                    }
                </DialogContent>
                {(dialogProps.cancelText || dialogProps.submitText) ?
                    <DialogActions>
                        {(dialogProps.cancelText) ?
                            <Button onClick={handleCancel} >
                                {dialogProps.cancelText}
                            </Button> : null}
                        {(dialogProps.submitText) ?
                            <Button onClick={handleSubmit} autoFocus color={'secondary'}>
                                {dialogProps.submitText}
                            </Button> : null}
                    </DialogActions> : null}
            </MaterialDialog>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={!!(snackMessage)}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackMessage}
            />
        </>
    )
}

export default withMaterialStyles(materialStyle, Dialog);
