import React, {useEffect, useState} from "react";

import {withWapp} from "wapplr-react/dist/common/Wapp";

import clsx from "clsx";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import {withMaterialStyles} from "../Template/withMaterial";

import materialStyle from "./materialStyle";
import style from "./style.css";

function AddToCart(props) {

    const {post, onChange} = props;
    const [q, setQ] = useState(typeof props.q === "number" ? props.q : 0);

    useEffect(() => {
        if (typeof post.q !== "undefined" && post.q !== q) {
            setQ(post.q);
        }
    }, []);

    function getQByStock(value) {
        let q = typeof value == "string" ? value.replace(/\D/g, "") || 1 : value;
        q = (!isNaN(Number(q))) ? Number(q) : 1;
        q = q < 0 ? 0 : q;
        if (post && q) {
            if (post.piecesInThePackage) {
                const min = Math.floor(q / post.piecesInThePackage) * post.piecesInThePackage;
                const round = Math.round(q / post.piecesInThePackage) * post.piecesInThePackage;
                const max = min + post.piecesInThePackage;
                q = round || max;
            }
            q = (typeof post.piecesAvailable === "number") ? Math.min(Math.max(0, post.piecesAvailable), q) : q;
            if (!post.available) {
                q = 0;
            }
            if (post.disableProduct) {
                q = 0;
            }
            if (!post.price) {
                q = 0;
            }
        }
        return q || 0;
    }

    async function onClick(e, qAction = 1) {
        e.preventDefault();
        const piecesInThePackage = post.piecesInThePackage || 1;
        const newQ = getQByStock(q + (piecesInThePackage * qAction));
        if (post) {
            post.q = newQ;
        }
        await setQ(newQ);
        if (onChange) {
            await onChange({post, q: newQ})
        }
    }

    const error = q && post.piecesAvailable < q;
    const outOfStock = !post.piecesAvailable;
    const notAvailable = !post.price || post.disableProduct || !post.available;

    return (
        <div
            className={clsx(
                style.addToCart,
                {
                    [style.added]: q > 0,
                    [style.error]: error,
                    [style.notAvailable]: outOfStock || notAvailable
                }
            )}
        >
            {(!outOfStock && !notAvailable || error) ?
                <div className={style.removeButton}>
                    <IconButton
                        color={"inherit"}
                        onClick={(e) => onClick(e, -1)}
                    >
                        <RemoveIcon/>
                    </IconButton>
                </div> : null
            }
            <div className={style.quantity}>
                {(!outOfStock && !notAvailable || error) ?
                    <IconButton
                        onClick={(e) => onClick(e, 1)}
                    >
                        <Badge badgeContent={q} color="secondary">
                            <ShoppingCartIcon />
                        </Badge>
                    </IconButton>
                    : post.availableText
                }
            </div>
            {(!outOfStock && !notAvailable || error) ?
                <div className={style.addButton}>
                    <IconButton
                        color={"inherit"}
                        onClick={(e) => onClick(e, 1)}
                    >
                        <AddIcon/>
                    </IconButton>
                </div> : null
            }
        </div>
    )
}

const WappComponent = withWapp(AddToCart);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
