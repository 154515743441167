import React, {useContext} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../../components/App/context";
import {withMaterialStyles} from "../../../components/Template/withMaterial";
import Form, {defaultComponents} from "../../../components/Form";
import PostContext from "../../../components/Post/context";
import capitalize from "../../../components/../utils/capitalize";

import materialStyle from "./materialStyle";
import style from "./style.css";

import Content from "../Content";

function Send(props) {

    const {formProps = {}, requestProps = {}} = props;

    const postContext = useContext(PostContext);
    const {name, post} = postContext;

    const N = capitalize(name);

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const utils = getUtils(context);
    //const {materialStyle} = props;

    const {wapp} = context;
    wapp.styles.use(style);

    async function onSubmit(e, formData) {
        return await utils.sendRequest({
            requestName: name + "SendNewsLetter",
            args: formData,
            timeOut: 1000, ...requestProps
        });
    }

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation." + name + "SendNewsLetter.formData");
    } catch (e) {
        console.log(e)
    }

    const isNotDeleted = post && post._status_isNotDeleted;

    const formData = {
        ...formDataFromResolvers,
        submit: {
            label: appContext.labels["send" + N + "SubmitLabel"],
            disabled: (!isNotDeleted)
        }
    };

    if (post?._id) {
        formData._id.value = post;
        formData._id.disabled = true;
        formData._id.refPostType = name;
    }

    if (post?._id) {
        Object.keys(formData).forEach(function (key) {
            if (key.startsWith("record.")) {
                const ka = key.split(".");
                let value = post;
                ka.forEach(function (nk) {
                    if (nk !== "record") {
                        if ((value && value[nk]) || (value && value[nk] === 0)) {
                            value = value[nk];
                        } else {
                            value = null;
                        }
                    }
                });
                if (value || value === 0) {
                    formData[key].value = value;
                }
            }
        })
    }

    return (
        <div className={style.send}>
            <Content/>
            <Form
                formData={formData}
                onSubmit={onSubmit}
                successMessage={
                    appContext.messages["send" + N + "SuccessMessage"]
                }
                components={{
                    ...defaultComponents,
                }}
                {...(typeof formProps == "function") ? formProps({formData}) : formProps}
            />
        </div>
    )
}

const WappComponent = withWapp(Send);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
