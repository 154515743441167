// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R2uyx>div{margin-bottom:16px}.R2uyx>div:last-child{margin-bottom:0}._9NsJ9{font-size:14px;line-height:20px;color:var(--font-color);letter-spacing:normal}._9NsJ9 pre{background-color:#303338 !important}@media(prefers-color-scheme: dark){._9NsJ9 pre{background:rgba(0, 0, 0, 0.14) !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "R2uyx",
	"content": "_9NsJ9"
};
module.exports = ___CSS_LOADER_EXPORT___;
