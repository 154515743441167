import React, {useContext} from "react";
import style from "./style.css";

import {WappContext} from "wapplr-react/dist/common/Wapp";

export default function Logo(props) {

    const {wapp} = useContext(WappContext);

    wapp.styles.use(style);

    const {type = "inline"} = props;

    return (
        <div className={style.logo} >
            {type === "inline" ?
                <svg className={style.svg} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1"
                     viewBox="0 0 317.45 42.12">
                    <path className={style['cls-1']}
                          d="M31.06,14.17H34.8a.61.61,0,0,0,.58-.48l1.23-6.44A.6.6,0,0,0,36,6.54h-6.2a.6.6,0,0,0-.58.71l1.23,6.44A.6.6,0,0,0,31.06,14.17Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-2']}
                          d="M21.32,13.66h19a0,0,0,0,1,0,0v10a0,0,0,0,1,0,0H22.37a1,1,0,0,1-1-1v-9a0,0,0,0,1,0,0Z"
                          transform="translate(61.4 34.39) rotate(-180)"/>
                    <path className={style['cls-3']}
                          d="M5.24,30.44h41.9a.65.65,0,0,0,.57-1l-4.77-8.82a.64.64,0,0,0-.57-.34H5.24a.65.65,0,0,0-.65.65v8.82A.65.65,0,0,0,5.24,30.44Z"
                          transform="translate(-0.21 -2.94)"/>
                    <polygon className={style['cls-4']}
                             points="21.11 3.36 2.76 3.36 7.09 17.38 21.11 17.38 21.11 3.36"/>
                    <rect className={style['cls-2']} x="0.21" y="3.7" width="22.24" height="2.6" rx="0.63"
                          transform="translate(22.45 7.06) rotate(-180)"/>
                    <circle className={style['cls-3']} cx="13.53" cy="13.43" r="5.38"
                            transform="translate(-0.14 24) rotate(-89.9)"/>
                    <circle className={style['cls-5']} cx="36.2" cy="28.56" r="4.92"
                            transform="matrix(0, -1, 1, 0, 7.38, 61.79)"/>
                    <path className={style['cls-1']}
                          d="M34.38,28.56a1.83,1.83,0,1,0,1.82-1.82A1.83,1.83,0,0,0,34.38,28.56Z"
                          transform="translate(-0.21 -2.94)"/>
                    <circle className={style['cls-5']} cx="13.73" cy="28.56" r="4.92"
                            transform="translate(-15.06 39.32) rotate(-89.92)"/>
                    <path className={style['cls-1']}
                          d="M11.91,28.56a1.83,1.83,0,1,0,1.82-1.82A1.82,1.82,0,0,0,11.91,28.56Z"
                          transform="translate(-0.21 -2.94)"/>
                    <rect className={style['cls-2']} x="18.59" y="15.33" width="10.81" height="47.13" rx="1.18"
                          transform="translate(62.68 11.95) rotate(90)"/>
                    <rect className={style['cls-6']} x="37.78" y="37.72" width="10.81" height="2.35"
                          transform="translate(81.87 -7.23) rotate(90)"/>
                    <rect className={style['cls-6']} x="33.63" y="37.72" width="10.81" height="2.35"
                          transform="translate(77.72 -3.09) rotate(90)"/>
                    <rect className={style['cls-6']} x="3.41" y="37.72" width="10.81" height="2.35"
                          transform="translate(47.49 27.14) rotate(90)"/>
                    <rect className={style['cls-6']} x="-0.74" y="37.72" width="10.81" height="2.35"
                          transform="translate(43.35 31.29) rotate(90)"/>
                    <path className={style['cls-3']}
                          d="M78.38,44.13a13.22,13.22,0,0,1-3.15.35c-.56,0-1.35,0-2.35-.09-.33,0-.94,0-1.82,0a17.91,17.91,0,0,1-2.13-3.17c-.63-1.17-1.32-2.63-2.09-4.37a6.13,6.13,0,0,1-.38-1,4.64,4.64,0,0,1-.15-.65l-.14-.53L66,34.05c0,1.51,0,3.39.08,5.64.06,1.84.09,3.3.09,4.39a13.73,13.73,0,0,1-3.72.45,17.74,17.74,0,0,1-4.09-.45q-.18-3.86-.18-8.74,0-5.28.14-10.52T58.45,13c1,0,2.35,0,4.13,0,.56,0,1.61.05,3.15.05,0,1.12,0,3.88,0,8.25v.89a47.71,47.71,0,0,0,2.4-4.26,44.9,44.9,0,0,1,3-5l2.93,0a22.56,22.56,0,0,1,4.26.23,20.45,20.45,0,0,1-1.46,3.7q-.93,1.85-2.62,4.77l-.26.45a47.48,47.48,0,0,0-3.24,6.12Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-3']}
                          d="M97.51,23.49l0,.8v.8c-.06,1-.09,2.41-.09,4.3,0,2.1,0,3.7-.14,4.8l-.13,1.46a12.75,12.75,0,0,1-1.82,6.13,5.6,5.6,0,0,1-2,1.64,14,14,0,0,1-2.93,1.15,13.42,13.42,0,0,1-3.86.49c-.68,0-1.15,0-1.42,0a11.58,11.58,0,0,1-3.88-1,5.15,5.15,0,0,1-2.51-2.28,11.37,11.37,0,0,1-1.15-5l-.09-1.42a14.12,14.12,0,0,1,0-1.46q-.23-3.65-.22-7c0-1.36,0-2.72.13-4.09a18.48,18.48,0,0,1,.78-4.32,10.52,10.52,0,0,1,2-3.71l1-.53a14.68,14.68,0,0,1,10.23-.82,10.33,10.33,0,0,1,3.31,1.62,7.11,7.11,0,0,1,2,2.79,14.42,14.42,0,0,1,.76,2.84C97.47,21.61,97.51,22.54,97.51,23.49Zm-17-14.91,0-.94V6.89l0-.49V6a9.9,9.9,0,0,1,1.53-.31A13.22,13.22,0,0,1,84,5.51a6.69,6.69,0,0,1,2.8.45,4.43,4.43,0,0,1,0,.71L86.64,8l-.23,2.8-5.72-.05A11.15,11.15,0,0,1,80.51,8.58Zm10,17-.18-2.31a5.42,5.42,0,0,0-.6-2.19,3.09,3.09,0,0,0-1.31-1.36,2,2,0,0,0-.71-.13,3.65,3.65,0,0,0-2.09.71,5.54,5.54,0,0,0-1,1.26,7.61,7.61,0,0,0-.78,1.67,23.43,23.43,0,0,0-.44,5c0,1,0,1.76.05,2.26v0a20.1,20.1,0,0,1,.26,2.35l0,.62a17.92,17.92,0,0,0,.48,3.29v-.05a2.92,2.92,0,0,0,1.18,1.36A3.18,3.18,0,0,0,87,38.4a3.51,3.51,0,0,0,1.45-.33,3.56,3.56,0,0,0,1.26-1,3.19,3.19,0,0,0,.56-1,5.28,5.28,0,0,0,.11-1.24,9.91,9.91,0,0,1,0-1.11l0-.58,0-.84v-.62l0-.85V28.77l-.09-3Zm-2.27-17,0-.94V6.89l0-.49V6a10.43,10.43,0,0,1,1.53-.31,13.24,13.24,0,0,1,1.85-.14A6.67,6.67,0,0,1,94.49,6a4.61,4.61,0,0,1,0,.71L94.36,8l-.22,2.8-5.73-.05A11.15,11.15,0,0,1,88.23,8.58Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-3']}
                          d="M110.47,40.49q-2.79-6.12-3.68-8.48v8.52c0,1.84,0,3.1,0,3.78a9.43,9.43,0,0,1-2.62.35c-.29,0-.74,0-1.33-.09s-1.12-.09-1.42-.09l-2.18-.09V41.07c0-1.9,0-4.59-.08-8.08q0-2.63,0-8,0-7.14.4-12a10.5,10.5,0,0,1,2.66-.31,19.13,19.13,0,0,1,2.38.18,4.71,4.71,0,0,1,1.93.53c.41.77.92,1.84,1.53,3.22s1.19,2.74,1.75,4.1l1.78,4.09q-.09-3.12-.09-7.33c0-2.28,0-3.84.05-4.7a19.85,19.85,0,0,1,3.86-.36,17.54,17.54,0,0,1,3.59.4V44.35a2.08,2.08,0,0,0-.53.13,4.61,4.61,0,0,1-1.55.18,19.5,19.5,0,0,1-2.36-.13l-1.35-.09a4.34,4.34,0,0,0-1,0Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-3']}
                          d="M125.65,44.39c-.44,0-1.05,0-1.82.09l-1.87.05a17.92,17.92,0,0,1-2-.14q1.06-3.2,4.09-11l1.82-4.62a30.59,30.59,0,0,0-1.11-3.48c-.45-1.14-1-2.5-1.69-4.06-.8-2-1.4-3.51-1.8-4.58a29.53,29.53,0,0,1-1-3.37,18.72,18.72,0,0,0,2.62-.13,18.39,18.39,0,0,1,2.08-.18,5.13,5.13,0,0,1,2.58.58c.26,1.09.67,2.45,1.22,4.08s1.07,3.08,1.57,4.35c.15-.29.63-1.64,1.43-4,.88-2.66,1.39-4.11,1.5-4.35A25.07,25.07,0,0,1,138,13.1a16.3,16.3,0,0,1,2.58.18A60.62,60.62,0,0,1,138,21q-1.56,3.7-4.22,9.3-2.13,4.35-3.55,7.61t-2.53,6.64A16.27,16.27,0,0,0,125.65,44.39Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-3']}
                          d="M156.85,27.88q-1.72,6.93-4.35,16.51a12.33,12.33,0,0,0-1.33,0l-.44,0a14.8,14.8,0,0,1-1.65.09,14.17,14.17,0,0,1-4.35-.53,157.19,157.19,0,0,1-5.19-30,3,3,0,0,1,1.64-.66,15.55,15.55,0,0,1,2.76-.23,19.43,19.43,0,0,1,3.19.31l.27,3.95c.17,2.93.37,5.47.57,7.62s.51,4.27.89,6.37c.36-1.63.9-4.59,1.64-8.88q1.33-7.86,1.78-9.37a19.48,19.48,0,0,0,3.95.36,18.88,18.88,0,0,1,3.15.18H160Q158.58,21,156.85,27.88Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-2']}
                          d="M187.92,44.31c-1.3,0-3.22,0-5.77.08l-5.81,0q-4,0-7.9-.27c0-.94,0-4.63-.14-11.05-.09-3.64-.13-6.43-.13-8.39q0-6.07.31-11.32l3.91-.13c4.37-.18,7.72-.27,10-.27,2,0,3.9.08,5.77.23a12.49,12.49,0,0,1,.17,2.17c0,.45,0,1.17-.08,2.18s-.09,1.68-.09,2.22a28.55,28.55,0,0,1-5.95.57l-5.77.05a33.09,33.09,0,0,0-.22,3.46,6.1,6.1,0,0,0,.26,1.86l2.93-.35c.42,0,1,0,1.87.09l.57,0a17.36,17.36,0,0,1,.23,2.89L182,32a14.91,14.91,0,0,1-3.82.4l-1.15,0a.6.6,0,0,0-.4.09l-.18,2.31a21,21,0,0,0,.18,3c1.53,0,3,0,4.48.13L183,38c.74,0,1.62,0,2.62,0l2.71-.09c.06,1.37.09,2.15.09,2.36A15.11,15.11,0,0,1,187.92,44.31ZM183.75,8a13.09,13.09,0,0,0-3,3.07,22.75,22.75,0,0,1-2.75.13c-.83,0-1.68-.05-2.55-.13a14.94,14.94,0,0,1-2-.31,38.2,38.2,0,0,0,3-2.93,68.41,68.41,0,0,1,5.19-4.93l3.6,3.77Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-2']}
                          d="M205.41,23.71a13.32,13.32,0,0,1-3.82-.49,3.58,3.58,0,0,0,.09-.62,2.12,2.12,0,0,0-.76-1.68,2.44,2.44,0,0,0-1.68-.67,1.73,1.73,0,0,0-1.78,1.69,2.19,2.19,0,0,0-.13.71l0,.4v.08a2.2,2.2,0,0,0,.91,1.29,4,4,0,0,0,1.45.49,12.17,12.17,0,0,1,4.48,1.64,12.75,12.75,0,0,1,3.64,3.11,10,10,0,0,1,1.33,2.55,8.12,8.12,0,0,1,.49,2.73,10.43,10.43,0,0,1-1,4.48,8.53,8.53,0,0,1-3.55,3.87,10.43,10.43,0,0,1-5.24,1.37,14.65,14.65,0,0,1-5.62-1.13,10.83,10.83,0,0,1-4.41-3.26,6.94,6.94,0,0,1-1.51-4.93v-.4a16.55,16.55,0,0,1,2.84-.22c.89,0,1.64,0,2.26,0s1.57.05,3,.05a2.93,2.93,0,0,0,.82,2.08,2.49,2.49,0,0,0,1.8.76,2.05,2.05,0,0,0,1.49-.58,2.16,2.16,0,0,0,.6-1.64,3.2,3.2,0,0,0-.05-.62,2.81,2.81,0,0,0-1.55-2.31,14.83,14.83,0,0,0-3.6-1.11l-.66-.13A7.13,7.13,0,0,1,192,29.7a10.65,10.65,0,0,1-2.4-3.33,8.59,8.59,0,0,1-.8-3.55,9.42,9.42,0,0,1,1.76-5.43,10.1,10.1,0,0,1,4.55-3.71,11.4,11.4,0,0,1,4.3-.71,14.72,14.72,0,0,1,6.08,1.24,8.87,8.87,0,0,1,3.24,2.8,7,7,0,0,1,1.2,4.08,12.89,12.89,0,0,1-.27,2.31h-.53A22,22,0,0,1,205.41,23.71Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-5']}
                          d="M237.68,14.83c0,1.34,0,3.39.08,6.17l.05,1.74c.06,1.77.09,3.22.09,4.34s0,2-.05,2.53c0,.39,0,1,0,1.82a48.36,48.36,0,0,1-.4,5.42,10.48,10.48,0,0,1-1.49,4.08,6.74,6.74,0,0,1-3.62,2.8v0A11.51,11.51,0,0,1,227,44.79a16.58,16.58,0,0,1-3.46-.35A7,7,0,0,1,219,40.16a19.23,19.23,0,0,1-1.36-7.39l0-.67v-.75a13.32,13.32,0,0,1,3.46-.36,24.65,24.65,0,0,1,4.75.44v.36q.36,4.71,2.13,4.84a1.13,1.13,0,0,0,1.31-.69,5.92,5.92,0,0,0,.6-2.2q-.18-7.46-.18-12.56,0-4,.13-8.17v-.53l7.82-.09Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-5']}
                          d="M255.21,44.66a20.41,20.41,0,0,1-4.26-.49c-.21-.56-.54-1.74-1-3.55A25.93,25.93,0,0,0,248.91,37l-.89,0-.76,0a26.74,26.74,0,0,0-.57,2.62,28.67,28.67,0,0,1-.63,2.82,7,7,0,0,1-.88,2,7.28,7.28,0,0,1-1.33.09q-.4,0-1.74-.09c-2.57-.11-4-.17-4.3-.17q0-.22.09-1c.06-.54.15-1.16.26-1.84l1-5.59q1.16-6.89,2.2-12T244.07,13a12.22,12.22,0,0,1,1.68-.09H246c1.92,0,3.8-.07,5.64-.22.35,1.39.68,2.76,1,4.11s.57,2.55.8,3.61c.95,4.12,1.58,6.85,1.91,8.21l2.93,15.76A14.17,14.17,0,0,1,255.21,44.66ZM253.57,8a13.09,13.09,0,0,0-3,3.07,22.87,22.87,0,0,1-2.75.13c-.83,0-1.68-.05-2.55-.13a15.15,15.15,0,0,1-2-.31,39.78,39.78,0,0,0,3-2.93,68.41,68.41,0,0,1,5.19-4.93l3.59,3.77Zm-5.51,14.38,0-.44-.09,1.07c-.09,2.21-.22,4-.4,5.28l-.4,3H249Q248,22.42,248.06,22.42Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-5']}
                          d="M275.31,16A30.49,30.49,0,0,1,275,20.6a14.28,14.28,0,0,0-2.3-.26l-2.09-.05c-.77,0-1.15.05-1.15.14a23.19,23.19,0,0,0-.09,2.62q0,3.9.31,21a17.77,17.77,0,0,1-3,.23h-1.29a24.52,24.52,0,0,0-4.08.31,2.39,2.39,0,0,1-.2-.91,6.82,6.82,0,0,1,0-1.27V40.27l0-4q0-8.07-.35-15.75a3.41,3.41,0,0,0-1.38-.23l-1.78.05a7.86,7.86,0,0,1-2.17-.22,5.52,5.52,0,0,1-.22-1.47c-.06-.8-.09-1.7-.09-2.71,0-1.48,0-2.54,0-3.19h1.91l1.73-.05h2.49l2.57,0a95.22,95.22,0,0,1,10.79.49h.58C275.28,14.15,275.31,15.1,275.31,16Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-5']}
                          d="M295.86,44.31c-1.3,0-3.22,0-5.77.08l-5.81,0c-2.63,0-5.27-.09-7.9-.27,0-.94,0-4.63-.13-11.05-.09-3.64-.14-6.43-.14-8.39,0-4.05.11-7.82.31-11.32l3.91-.13q6.57-.27,10-.27c2,0,3.91.08,5.77.23a11.51,11.51,0,0,1,.18,2.17c0,.45,0,1.17-.09,2.18s-.09,1.68-.09,2.22a28.48,28.48,0,0,1-5.95.57l-5.77.05a33.09,33.09,0,0,0-.22,3.46,5.79,5.79,0,0,0,.27,1.86l2.93-.35q.62,0,1.86.09l.58,0a17.41,17.41,0,0,1,.22,2.89L290,32a14.91,14.91,0,0,1-3.82.4l-1.15,0a.6.6,0,0,0-.4.09l-.18,2.31a21,21,0,0,0,.18,3c1.54,0,3,0,4.48.13l1.87.09c.74,0,1.61,0,2.62,0l2.7-.09c.06,1.37.09,2.15.09,2.36A15.11,15.11,0,0,1,295.86,44.31ZM291.69,8a12.76,12.76,0,0,0-3,3.07,22.87,22.87,0,0,1-2.75.13c-.83,0-1.68-.05-2.56-.13a14.94,14.94,0,0,1-2-.31,38.2,38.2,0,0,0,3-2.93,66.31,66.31,0,0,1,5.2-4.93l3.59,3.77Z"
                          transform="translate(-0.21 -2.94)"/>
                    <path className={style['cls-5']}
                          d="M317.66,44.13a13.22,13.22,0,0,1-3.15.35c-.57,0-1.35,0-2.36-.09-.32,0-.93,0-1.82,0a17.91,17.91,0,0,1-2.13-3.17q-.93-1.76-2.08-4.37a5.33,5.33,0,0,1-.38-1,5.64,5.64,0,0,1-.16-.65l-.13-.53-.18-.58q0,2.27.09,5.64c.06,1.84.09,3.3.09,4.39a13.81,13.81,0,0,1-3.73.45,17.71,17.71,0,0,1-4.08-.45q-.18-3.86-.18-8.74c0-3.52.05-7,.13-10.52s.14-7.5.14-11.85c1,0,2.35,0,4.13,0,.56,0,1.61.05,3.15.05,0,1.12,0,3.88,0,8.25v.89a47.71,47.71,0,0,0,2.4-4.26,42,42,0,0,1,3-5l2.93,0a22.56,22.56,0,0,1,4.26.23,20.45,20.45,0,0,1-1.46,3.7c-.62,1.23-1.5,2.82-2.62,4.77l-.27.45A50.11,50.11,0,0,0,310,28.28Z"
                          transform="translate(-0.21 -2.94)"/>
                </svg> :
                <svg className={style.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 732.74 581.57">
                    <g id="Layer_1" data-name="Layer 1">
                        <path className={style['cls-1']}
                              d="M727.71,476.8h34.46a5.46,5.46,0,0,0,5.37-4.44l11.37-59.49a5.47,5.47,0,0,0-5.37-6.5h-57.2a5.47,5.47,0,0,0-5.38,6.5l11.38,59.49A5.46,5.46,0,0,0,727.71,476.8Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-2']}
                              d="M637.8,472.12H812.88a0,0,0,0,1,0,0v92.51a0,0,0,0,1,0,0H647.49a9.68,9.68,0,0,1-9.68-9.68V472.12A0,0,0,0,1,637.8,472.12Z"
                              transform="translate(1301.3 806.81) rotate(-180)"/>
                        <path className={style['cls-3']}
                              d="M489.32,627.12H876.1a6,6,0,0,0,5.29-8.87l-44-81.47a6,6,0,0,0-5.29-3.15H489.32a6,6,0,0,0-6,6v81.47A6,6,0,0,0,489.32,627.12Z"
                              transform="translate(-149.38 -229.93)"/>
                        <polygon className={style['cls-4']}
                                 points="488.43 174.19 318.99 174.19 358.93 303.69 488.43 303.69 488.43 174.19"/>
                        <rect className={style['cls-2']} x="442.89" y="380.13" width="205.3" height="24" rx="5.79"
                              transform="translate(941.7 554.32) rotate(-180)"/>
                        <circle className={style['cls-3']} cx="565.86" cy="470.03" r="49.68"
                                transform="translate(-242.58 -86.64) rotate(-13.34)"/>
                        <circle className={style['cls-5']} cx="775.16" cy="609.8" r="45.48"
                                transform="translate(-307.34 60.47) rotate(-19.3)"/>
                        <path className={style['cls-1']}
                              d="M758.31,609.8A16.85,16.85,0,1,0,775.16,593,16.84,16.84,0,0,0,758.31,609.8Z"
                              transform="translate(-149.38 -229.93)"/>
                        <circle className={style['cls-5']} cx="567.71" cy="609.8" r="45.48"
                                transform="translate(-340.65 36.21) rotate(-22.69)"/>
                        <path className={style['cls-1']}
                              d="M550.87,609.8A16.85,16.85,0,1,0,567.71,593,16.84,16.84,0,0,0,550.87,609.8Z"
                              transform="translate(-149.38 -229.93)"/>
                    </g>
                    <g id="Layer_2" data-name="Layer 2">
                        <rect className={style['cls-2']} x="195.95" width="97.03" height="423.3" rx="10.62"/>
                        <rect className={style['cls-6']} x="195.95" y="28.78" width="97.03" height="21.1"/>
                        <rect className={style['cls-6']} x="195.95" y="66.02" width="97.03" height="21.1"/>
                        <rect className={style['cls-6']} x="195.95" y="337.51" width="97.03" height="21.1"/>
                        <rect className={style['cls-6']} x="195.95" y="374.75" width="97.03" height="21.1"/>
                        <rect className={style['cls-4']} x="98.97" y="87.13" width="97.03" height="335.6" rx="10.62"/>
                        <rect className={style['cls-6']} x="98.97" y="337.51" width="97.03" height="21.1"/>
                        <rect className={style['cls-6']} x="98.97" y="374.75" width="97.03" height="21.1"/>
                        <circle className={style['cls-6']} cx="147.49" cy="138.49" r="29.36"/>
                        <rect className={style['cls-3']} x="2" y="87.13" width="97.03" height="335.6" rx="10.62"/>
                        <rect className={style['cls-6']} x="13.47" y="474.31" width="335.6" height="21.1"
                              transform="translate(516.75 73.66) rotate(90)"/>
                        <rect className={style['cls-6']} x="50.71" y="474.31" width="335.6" height="21.1"
                              transform="translate(554 36.42) rotate(90)"/>
                        <path className={style['cls-3']}
                              d="M206.25,808.88a38,38,0,0,1-8.87,1c-1.59,0-3.79-.09-6.63-.26-.91-.08-2.62-.12-5.12-.12a50.56,50.56,0,0,1-6-8.94q-2.62-4.93-5.88-12.31a16.14,16.14,0,0,1-1.06-2.81,13.09,13.09,0,0,1-.44-1.82l-.37-1.5-.5-1.62q0,6.38.25,15.88.24,7.74.25,12.37a39.57,39.57,0,0,1-10.5,1.25,50.8,50.8,0,0,1-11.5-1.25q-.51-10.87-.5-24.63,0-14.86.37-29.62.38-15,.38-33.38,4.13-.12,11.62.13c1.59.08,4.54.13,8.88.13q.12,4.74.12,23.24v2.5a134.08,134.08,0,0,0,6.75-12,122.61,122.61,0,0,1,8.38-14l8.25.13a61.4,61.4,0,0,1,12,.63A58.73,58.73,0,0,1,202,732.31q-2.62,5.19-7.37,13.44l-.75,1.25a137.49,137.49,0,0,0-9.13,17.25Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-3']}
                              d="M260.13,750.75,260,753v2.25q-.24,4.13-.25,12.13,0,8.86-.37,13.5L259,785a35.85,35.85,0,0,1-5.12,17.25,16.07,16.07,0,0,1-5.63,4.63,40.5,40.5,0,0,1-8.25,3.24,38.15,38.15,0,0,1-10.87,1.38c-1.92,0-3.25,0-4-.12a33.1,33.1,0,0,1-10.94-2.69,14.46,14.46,0,0,1-7.06-6.44q-3-5.62-3.25-14.13l-.25-4c-.08-.66-.13-2-.13-4.12q-.62-10.25-.62-19.62,0-5.76.37-11.5a52.25,52.25,0,0,1,2.19-12.19A29.62,29.62,0,0,1,211,726.25l2.75-1.5a41.64,41.64,0,0,1,28.82-2.31,29,29,0,0,1,9.31,4.56,20.34,20.34,0,0,1,5.75,7.88,40,40,0,0,1,2.12,8Q260.13,746.75,260.13,750.75Zm-47.88-42,.13-2.63V704l.12-1.38v-1.24a27.85,27.85,0,0,1,4.32-.88,37.52,37.52,0,0,1,5.18-.38q5.25,0,7.88,1.26a11.88,11.88,0,0,1-.13,2l-.25,3.74-.62,7.88-16.13-.12A32.17,32.17,0,0,1,212.25,708.75Zm28.13,47.75-.5-6.5a15.37,15.37,0,0,0-1.69-6.19A8.62,8.62,0,0,0,234.5,740a5.63,5.63,0,0,0-2-.38,10.2,10.2,0,0,0-5.87,2,15,15,0,0,0-2.94,3.57,20.46,20.46,0,0,0-2.19,4.69,65,65,0,0,0-1.25,14c0,2.83.05,5,.13,6.37v-.13a52.24,52.24,0,0,1,.75,6.63l.12,1.75a50.9,50.9,0,0,0,1.38,9.25v-.13a8.28,8.28,0,0,0,3.31,3.82,9,9,0,0,0,4.69,1.31,9.68,9.68,0,0,0,4.06-.94,10.06,10.06,0,0,0,3.56-2.69,8.69,8.69,0,0,0,1.57-2.74,14.77,14.77,0,0,0,.31-3.5,29.15,29.15,0,0,1,.12-3.13l.13-1.63.12-2.37V774l.13-2.38v-6l-.25-8.5ZM234,708.75l.13-2.63V704l.12-1.38v-1.24a27.85,27.85,0,0,1,4.32-.88,37.52,37.52,0,0,1,5.18-.38q5.25,0,7.88,1.26a11.88,11.88,0,0,1-.13,2l-.25,3.74-.62,7.88-16.13-.12A32.17,32.17,0,0,1,234,708.75Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-3']}
                              d="M296.63,798.62q-7.87-17.25-10.38-23.87v24q0,7.75.13,10.63a26.13,26.13,0,0,1-7.38,1c-.83,0-2.08-.09-3.75-.26-1.83-.16-3.16-.24-4-.24l-6.12-.26v-9.37q0-8-.25-22.75-.13-7.38-.13-22.38,0-20.11,1.13-33.74a29.57,29.57,0,0,1,7.5-.88,55.26,55.26,0,0,1,6.69.5,13.55,13.55,0,0,1,5.43,1.5q1.75,3.25,4.32,9.06t4.93,11.56l5,11.5q-.24-8.75-.25-20.62,0-9.63.13-13.25a56.39,56.39,0,0,1,10.87-1,47.7,47.7,0,0,1,10.13,1.13V809.5a5.77,5.77,0,0,0-1.5.38,13.31,13.31,0,0,1-4.38.5,60.06,60.06,0,0,1-6.62-.38c-1-.08-2.27-.17-3.81-.25a13.13,13.13,0,0,0-2.69,0Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-3']}
                              d="M339.38,809.62c-1.25,0-3,.09-5.13.26L329,810a48.7,48.7,0,0,1-5.75-.38q3-9,11.5-31l5.13-13a82.84,82.84,0,0,0-3.13-9.81c-1.25-3.2-2.83-7-4.75-11.43q-3.37-8.39-5.06-12.88A85.86,85.86,0,0,1,324,722a53.6,53.6,0,0,0,7.38-.38,53.52,53.52,0,0,1,5.87-.5,14.62,14.62,0,0,1,7.25,1.63q1.13,4.62,3.44,11.5t4.44,12.25q.62-1.25,4-11.38,3.75-11.25,4.25-12.24a69,69,0,0,1,13.62-1.38,46.77,46.77,0,0,1,7.25.5,169.14,169.14,0,0,1-7.37,21.69q-4.38,10.44-11.88,26.19-6,12.24-10,21.43T345.13,810A46.48,46.48,0,0,0,339.38,809.62Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-3']}
                              d="M427.25,763.12q-4.87,19.5-12.25,46.5a37,37,0,0,0-3.75.13l-1.25.13a43.54,43.54,0,0,1-4.62.24q-7.87,0-12.25-1.5Q382.26,770.25,378.5,724q1.13-1.25,4.63-1.88a44.83,44.83,0,0,1,7.75-.62,52.22,52.22,0,0,1,9,.88l.75,11.12q.75,12.38,1.62,21.44t2.5,17.94q1.5-6.89,4.63-25,3.75-22.14,5-26.38a55.38,55.38,0,0,0,11.12,1,54.42,54.42,0,0,1,8.88.5H436Q432.13,743.63,427.25,763.12Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-2']}
                              d="M514.75,809.38q-5.5,0-16.25.24l-16.37.13q-11.13,0-22.25-.75.12-4-.38-31.12-.37-15.39-.37-23.63,0-17.13.87-31.87l11-.38q18.5-.75,28.25-.75,8.37,0,16.25.63A33.26,33.26,0,0,1,516,728q0,1.88-.25,6.12-.25,4-.25,6.26A79.53,79.53,0,0,1,498.75,742l-16.25.12a94.25,94.25,0,0,0-.62,9.76,16.55,16.55,0,0,0,.75,5.24l8.25-1c1.16,0,2.91.09,5.25.26l1.62.12a49.11,49.11,0,0,1,.63,8.12l-.25,10a40.7,40.7,0,0,1-10.75,1.13l-3.25-.13a1.68,1.68,0,0,0-1.13.26l-.5,6.5a60.35,60.35,0,0,0,.5,8.5q6.5,0,12.63.37c1.41.08,3.16.17,5.25.25s4.54.12,7.37.12l7.63-.24q.24,5.75.24,6.62A42.92,42.92,0,0,1,514.75,809.38ZM503,707.25q-7,5.88-8.37,8.63a61.39,61.39,0,0,1-7.75.37c-2.34,0-4.73-.13-7.19-.37a39.93,39.93,0,0,1-5.56-.88,104.3,104.3,0,0,0,8.37-8.25,187.06,187.06,0,0,1,14.63-13.87l10.12,10.62Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-2']}
                              d="M564,751.38A37.71,37.71,0,0,1,553.25,750a10.18,10.18,0,0,0,.25-1.75,6,6,0,0,0-2.13-4.75,7,7,0,0,0-4.75-1.88,5.06,5.06,0,0,0-3.31,1.19,5.12,5.12,0,0,0-1.69,3.57,6.32,6.32,0,0,0-.37,2l.12,1.12v.25a6.34,6.34,0,0,0,2.57,3.63,11.5,11.5,0,0,0,4.06,1.37,34.21,34.21,0,0,1,12.62,4.63,35.53,35.53,0,0,1,10.25,8.74,27.18,27.18,0,0,1,3.75,7.19A23.2,23.2,0,0,1,576,783a29.34,29.34,0,0,1-2.88,12.62,23.79,23.79,0,0,1-10,10.88,29.13,29.13,0,0,1-14.75,3.88,41,41,0,0,1-15.81-3.19A30.43,30.43,0,0,1,520.12,798a19.65,19.65,0,0,1-4.25-13.88V783a47,47,0,0,1,8-.62q3.75,0,6.38.12c1.58.08,4.42.12,8.5.12a8.21,8.21,0,0,0,2.31,5.88,7,7,0,0,0,5.06,2.12,5.8,5.8,0,0,0,4.19-1.62,6.09,6.09,0,0,0,1.69-4.62,8.89,8.89,0,0,0-.13-1.76,7.94,7.94,0,0,0-4.37-6.5A41,41,0,0,0,537.37,773l-1.87-.38a19.89,19.89,0,0,1-9.38-4.37,30.75,30.75,0,0,1-6.75-9.37,24.23,24.23,0,0,1-2.25-10,26.62,26.62,0,0,1,4.94-15.32,28.46,28.46,0,0,1,12.81-10.44,32.19,32.19,0,0,1,12.13-2,41.14,41.14,0,0,1,17.12,3.5,24.78,24.78,0,0,1,9.13,7.88,19.69,19.69,0,0,1,3.37,11.5,36.88,36.88,0,0,1-.75,6.5h-1.5A63.31,63.31,0,0,1,564,751.38Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-5']}
                              d="M654.87,726.38c0,3.74.09,9.54.25,17.37l.13,4.87q.24,7.5.25,12.26c0,3.16,0,5.54-.13,7.12-.08,1.08-.12,2.79-.12,5.12a136.59,136.59,0,0,1-1.13,15.26,29.85,29.85,0,0,1-4.18,11.5,19.19,19.19,0,0,1-10.19,7.87v-.13a32.37,32.37,0,0,1-14.88,3.13,47,47,0,0,1-9.75-1Q606,807,602.19,797.69a54.33,54.33,0,0,1-3.82-20.81l.13-1.88v-2.12a37.8,37.8,0,0,1,9.75-1,68.33,68.33,0,0,1,13.37,1.24v1q1,13.26,6,13.63a3.18,3.18,0,0,0,3.69-1.94,16.4,16.4,0,0,0,1.69-6.19q-.51-21-.5-35.37,0-11.37.37-23v-1.5l22-.25Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-5']}
                              d="M704.25,810.38a58,58,0,0,1-12-1.38q-.87-2.37-2.75-10a71.09,71.09,0,0,0-3-10.25l-2.5-.13-2.13.13q-.5,1.5-1.62,7.37c-.58,3.26-1.17,5.9-1.75,7.94a20.22,20.22,0,0,1-2.5,5.56,20.17,20.17,0,0,1-3.75.26c-.75,0-2.38-.09-4.88-.26q-10.87-.5-12.12-.5c0-.41.08-1.39.25-2.93s.42-3.27.75-5.19L659,785.25q3.25-19.38,6.19-33.75t7.68-30.38a36.8,36.8,0,0,1,4.75-.24h.75q8.13,0,15.88-.63,1.5,5.88,2.75,11.56T699.25,742q4,17.37,5.37,23.12l8.25,44.38A40.46,40.46,0,0,1,704.25,810.38Zm-4.63-103.13q-7,5.88-8.37,8.63a61.28,61.28,0,0,1-7.75.37c-2.33,0-4.73-.13-7.19-.37a39.93,39.93,0,0,1-5.56-.88,104.3,104.3,0,0,0,8.37-8.25,188.88,188.88,0,0,1,14.63-13.87l10.12,10.62Zm-15.5,40.5L684,746.5l-.25,3q-.37,9.38-1.13,14.88l-1.12,8.37h5.25Q684,747.75,684.12,747.75Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-5']}
                              d="M760.87,729.62a87.42,87.42,0,0,1-.87,13,38.45,38.45,0,0,0-6.5-.74l-5.88-.13c-2.16,0-3.25.13-3.25.37a68.54,68.54,0,0,0-.25,7.38q0,11,.88,59.25a50.27,50.27,0,0,1-8.5.63h-3.63a70.59,70.59,0,0,0-11.5.87,7.19,7.19,0,0,1-.56-2.56,21.61,21.61,0,0,1-.06-3.57V798l.12-11.25q0-22.75-1-44.37a9.49,9.49,0,0,0-3.87-.63l-5,.13a21.21,21.21,0,0,1-6.13-.63,15.51,15.51,0,0,1-.62-4.13c-.17-2.24-.25-4.79-.25-7.62q0-6.25.12-9h5.38l4.87-.12h7l7.25-.13A273,273,0,0,1,759,721.62h1.62C760.79,724.46,760.87,727.12,760.87,729.62Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-5']}
                              d="M818.75,809.38q-5.5,0-16.25.24l-16.38.13q-11.11,0-22.25-.75.14-4-.37-31.12-.37-15.39-.38-23.63,0-17.13.88-31.87l11-.38q18.5-.75,28.25-.75,8.37,0,16.25.63A33.26,33.26,0,0,1,820,728c0,1.25-.09,3.29-.25,6.12q-.25,4-.25,6.26A79.6,79.6,0,0,1,802.75,742l-16.25.12a94.65,94.65,0,0,0-.63,9.76,16.55,16.55,0,0,0,.75,5.24l8.25-1c1.17,0,2.92.09,5.25.26l1.63.12a50.26,50.26,0,0,1,.62,8.12l-.25,10a40.65,40.65,0,0,1-10.75,1.13l-3.25-.13a1.65,1.65,0,0,0-1.12.26l-.5,6.5a58.88,58.88,0,0,0,.5,8.5q6.5,0,12.62.37c1.42.08,3.17.17,5.25.25s4.54.12,7.38.12l7.62-.24q.25,5.75.25,6.62A43.34,43.34,0,0,1,818.75,809.38ZM807,707.25q-7,5.88-8.38,8.63a61.28,61.28,0,0,1-7.75.37c-2.33,0-4.73-.13-7.18-.37a40.12,40.12,0,0,1-5.57-.88,105.76,105.76,0,0,0,8.38-8.25,187.3,187.3,0,0,1,14.62-13.87l10.13,10.62Z"
                              transform="translate(-149.38 -229.93)"/>
                        <path className={style['cls-5']}
                              d="M880.12,808.88a38,38,0,0,1-8.87,1q-2.38,0-6.63-.26c-.92-.08-2.62-.12-5.12-.12a50.56,50.56,0,0,1-6-8.94q-2.62-4.93-5.88-12.31a15.37,15.37,0,0,1-1.06-2.81,13.09,13.09,0,0,1-.44-1.82l-.37-1.5-.5-1.62q0,6.38.25,15.88.24,7.74.25,12.37a39.57,39.57,0,0,1-10.5,1.25,50.8,50.8,0,0,1-11.5-1.25q-.51-10.87-.5-24.63,0-14.86.37-29.62.38-15,.38-33.38,4.13-.12,11.62.13c1.58.08,4.54.13,8.88.13q.12,4.74.12,23.24v2.5a134.08,134.08,0,0,0,6.75-12,122.61,122.61,0,0,1,8.38-14l8.25.13a61.4,61.4,0,0,1,12,.63,58.73,58.73,0,0,1-4.13,10.43q-2.62,5.19-7.37,13.44l-.75,1.25a137.49,137.49,0,0,0-9.13,17.25Z"
                              transform="translate(-149.38 -229.93)"/>
                    </g>
                </svg>
            }
        </div>
    )
}
