// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../components/App/barion-card-strip-intl__small.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZwHV1{padding-top:8px;width:100%;overflow:hidden}.ZwHV1 div.xfUiX{margin-bottom:8px}.etzkZ{display:block}.etzkZ>div{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-position:50%;background-repeat:no-repeat;height:20px;width:220px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new": "ZwHV1",
	"info": "xfUiX",
	"barionLogo": "etzkZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
