import React, {useContext, useRef} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import PaymentsIcon from "@mui/icons-material/Payments";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import {withMaterialStyles} from "../../../components/Template/withMaterial";

import PostContext from "../../../components/Post/context";
import Table, {valueToTableData} from "../../../components/Table";
import AppContext from "../../../components/App/context";

import materialStyle from "./materialStyle";
import style from "./style.css";
import tableStyle from "./tableStyle.css";

export const tableIcons = {
    methodOfPayment: PaymentsIcon,
    paymentStatus: DoneAllIcon,
    cart: ShoppingCartIcon,
};

export const tableComponents = {
    PaymentStatus: {
        props: {
            value: 20,
            options: [],
        },
        Component: (props) => {
            const {options} = props;
            const foundOrderStatusOption = options && options.find(({value}) => value === props.value);
            const statusText = foundOrderStatusOption?.label;

            if (statusText) {
                return (
                    <div className={tableStyle.status}>
                        {statusText}
                    </div>
                )
            }

            return null;
        }
    },
    ConnectedOrderLink: {
        props: {
            value: "",
            name: "payment"
        },
        Component: (props) => {

            const {value, name} = props;
            const appContext = useContext(AppContext);
            const context = useContext(WappContext);
            const {wapp} = context;
            wapp.styles.use(tableStyle);

            const _id = value?._id || value;

            if (_id) {

                function onClick(e, menu) {

                    const href = menu.href;

                    wapp.client.history.push({
                        search: "",
                        hash: "",
                        ...wapp.client.history.parsePath(href)
                    });

                    e.preventDefault();
                    e.stopPropagation();

                }

                const href = appContext.routes.orderRoute + "/" + _id;

                return (
                    <div className={tableStyle.connectedOrderLink}>
                        <Button
                            size={"small"}
                            variant={"text"}
                            href={href}
                            onClick={(e) => onClick(e, {href})}
                        >
                            {appContext.labels[name + "OpenConnectedOrderText"]}
                        </Button>
                    </div>
                )

            }

            return null;
        }
    }
};

function Content() {

    const container = useRef();
    const postContext = useContext(PostContext);
    const context = useContext(WappContext);
    const appContext = useContext(AppContext);

    const utils = getUtils(context);
    const {wapp} = context;
    wapp.styles.use(style);

    const {
        post,
        user = utils.getRequestUser(),
        name = "payment",
        statusManager = wapp.getTargetObject().postTypes.findPostType({name: name}).statusManager
    } = postContext;

    const listDataObject = utils.getGlobalState("res.graphql.query." + name + "FindMany.listData");
    const tableData = valueToTableData({tableData: listDataObject.table, post});

    return (
        <div className={style.post} ref={container}>
            <div className={style.content}>
                <div className={style.dataContainer}>
                    {(tableData && Object.keys(tableData).length) ?
                        <div className={style.orderData}>
                            <div>
                                <div
                                    className={style.dataTableLabel}>{appContext.labels[name + "ContentPaymentData"]}</div>
                                <Table
                                    tableData={tableData}
                                    post={post}
                                    user={user}
                                    name={name}
                                    statusManager={statusManager}
                                    icons={tableIcons}
                                    components={tableComponents}
                                />
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

const WappComponent = withWapp(Content);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
