import {runPostTypesConfigSync} from "../../postTypes";

const messages = {
    notFoundNotAvailable: "Ez a tartalom nem elérhető",
    notFoundLoginText: "Jelentkezz be mert vannak oldalak, amiket az csak láthat, akinek van jogosultsága hozzá",
    thereAreNoEntries: "Nincsenek bejegyzések",
    cookieShortDesc: "Csak az oldal működéséhez szükséges cookie-kat használunk. Az oldal használatával elfogadod ezt.",
    ...runPostTypesConfigSync({action: "getConstants", rKey: "messages"}).reduce((a, v) => {
        return {...a, ...v}
    }, {})
};

export default messages;
