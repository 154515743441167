// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8RGj7{height:20px;overflow:hidden;-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connectedOrderLink": "_8RGj7"
};
module.exports = ___CSS_LOADER_EXPORT___;
