// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sOWMh>div{margin-bottom:16px}.sOWMh>div:last-child{margin-bottom:0}.aX1VZ{font-size:14px;line-height:20px;color:var(--font-color);letter-spacing:normal}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "sOWMh",
	"content": "aX1VZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
