import React, {useContext} from "react";

import {WappContext} from "wapplr-react/dist/common/Wapp";
import AppContext from "../../components/App/context";
import User from "../../components/User";

import defaultPostTypeConfig from "../post";

import {runPostTypesConfigSync} from "../index";

export function Component(props) {

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);

    const {
        name = "user"
    } = props;

    const p = {context, appContext, postContext: {name}};

    const pages = {
        ...runPostTypesConfigSync({action: "userPageFunctions", rKey: "pages", p})
            .reduce((a, v) => {
                return {...a, ...v}
            }, {})
    };

    function getDashBoardTitle({user, post, page}) {
        return runPostTypesConfigSync({action: "userPageFunctions", rKey: "getDashBoardTitle", p})
            .map((f) => (f) ? f({user, post, page}) : null)
            .filter((r) => r)[0];
    }

    function addMenuItems(props) {
        return [
            ...runPostTypesConfigSync({action: "userPageFunctions", rKey: "addMenuItems", p})
                .map((f) => (f) ? f(props) : null)
                .filter((r) => !!(r && r.length))
                .reduce((a, v) => {
                    return [...a, ...v]
                }, [])
        ];
    }

    function addContentMenuItems(props) {
        return [
            ...runPostTypesConfigSync({action: "userPageFunctions", rKey: "addContentMenuItems", p})
                .map((f) => (f) ? f(props) : null)
                .filter((r) => !!(r && r.length))
                .reduce((a, v) => {
                    return [...a, ...v]
                }, [])
        ]
    }

    function getPageName(props) {
        return runPostTypesConfigSync({action: "userPageFunctions", rKey: "getPageName", p})
            .map((f) => (f) ? f(props) : null)
            .filter((r) => r)[0];
    }

    return (
        <User
            {...props}
            pages={pages}
            getDashBoardTitle={getDashBoardTitle}
            addMenuItems={addMenuItems}
            addContentMenuItems={addContentMenuItems}
            getPageName={getPageName}
        />
    )
}

export default function getComponent(p) {

    const {context, appContext, name = "user"} = p;
    const {routes} = appContext;

    const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";

    const {res, req} = context;
    const route = res.wappResponse.route;
    const {params} = route;
    const {_id} = params;
    const requestPath = route.requestPath;
    const user = req.wappRequest.user;

    const isAdmin = user && user._status_isFeatured;
    const isAuthor = ((user?._id && user._id.toString() === _id));
    const isAuthorOrAdmin = (isAuthor || isAdmin);

    if (requestPath.startsWith(routes[ns + "Route"]) && !isAdmin) {
        return null;
    }

    if (requestPath.startsWith(routes[name + "Route"]) && !isAuthorOrAdmin) {
        return null;
    }

    return defaultPostTypeConfig.getComponent({
        ...p,
        name,
        Component,
        archiveComponentProps: {disableAvatars: true}
    })
}
