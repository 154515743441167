// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(max-width: 799px){._1sSes{min-height:100%}._1sSes{border-radius:0 !important;max-width:100%;width:100%;margin:0;max-height:100%}.jiaZL{padding:16px;color:var(--font-color)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogPaper": "_1sSes",
	"dialogContent": "jiaZL"
};
module.exports = ___CSS_LOADER_EXPORT___;
