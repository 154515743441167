// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gXgP4{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:100%;height:100%;min-height:inherit}.gXgP4 .dLyx5{margin-bottom:128px;padding-right:0;padding-left:0}.gXgP4 ._0\\+GRG{-webkit-box-shadow:none;box-shadow:none}.gXgP4 ._0\\+GRG:first-child{border-top-left-radius:8px;border-top-right-radius:8px}.gXgP4 .bFcUH{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;padding-top:32px;background-color:rgba(0, 0, 0, 0.04)}.gXgP4 .pgJqr{padding:8px;margin-bottom:32px;display:table;margin-left:auto;margin-right:auto}.gXgP4 .gbb93{margin-left:auto;margin-right:auto;width:auto;display:table;padding:8px;cursor:pointer}.gXgP4 .J\\+f1b{text-align:center;margin-left:auto;margin-right:auto;width:auto;display:table;padding:8px;cursor:pointer}.gXgP4 .IYqQZ,.gXgP4 .IYqQZ>*{letter-spacing:-0.16px;line-height:normal;margin-bottom:4px}.gXgP4 .Qi39W{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}.gXgP4 .Qi39W>*{font-size:12px;line-height:normal}.gXgP4 .CB4D3{min-height:128px;padding:0 32px 32px}.gXgP4.qe0lV .CB4D3{padding-top:32px;min-height:0}.gXgP4.BpHA7 .CB4D3{min-height:0}.gXgP4 .OQNWY{width:100%;overflow:hidden;padding-top:24px;padding-bottom:24px}.gXgP4 .pqSL9,.gXgP4 .pqSL9>*{line-height:normal}@media(max-width: 599px){.gXgP4 .CB4D3{padding:0 16px 16px}.gXgP4.qe0lV .CB4D3{padding-top:16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"account": "gXgP4",
	"container": "dLyx5",
	"appBar": "_0+GRG",
	"userLayout": "bFcUH",
	"userBox": "pgJqr",
	"avatar": "gbb93",
	"userNameContainer": "J+f1b",
	"userName": "IYqQZ",
	"status": "Qi39W",
	"content": "CB4D3",
	"logout": "qe0lV",
	"deleteaccount": "BpHA7",
	"titleContainer": "OQNWY",
	"title": "pqSL9"
};
module.exports = ___CSS_LOADER_EXPORT___;
