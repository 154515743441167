import React, {useContext} from "react";
import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import {copyObject} from "wapplr/dist/common/utils";

import clsx from "clsx";

import Button from "@mui/material/Button";
import {withMaterialStyles} from "../Template/withMaterial";
import LabelIcon from "@mui/icons-material/Label";
import MoneyOutlinedIcon from "@mui/icons-material/MoneyOutlined";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import TitleIcon from "@mui/icons-material/Title";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

import getStatus from "../../utils/getStatus";
import capitalize from "../../utils/capitalize";
import AppContext from "../App/context";

import style from "./style.css";
import materialStyle from "./materialStyle";

export function valueToTableData(p) {

    if (p.tableData && Object.keys(p.tableData).length) {

        const tableData = copyObject(p.tableData);

        Object.keys(tableData).forEach(function (key) {
            const ka = key.split(".");
            let currentObject = p.post;
            let value;
            try {
                ka.forEach((nk, i) => {
                    if (i === ka.length - 1) {
                        if (currentObject && typeof currentObject == "object" && typeof currentObject[nk] !== "undefined") {
                            value = currentObject[nk];
                        }
                    } else {
                        if (currentObject && typeof currentObject == "object" && typeof currentObject[nk] == "object") {
                            currentObject = currentObject[nk];
                        }
                    }
                });
            } catch (e) {
            }

            tableData[key].value = (
                (typeof value == "undefined" && tableData[key].required && typeof tableData[key].default !== "undefined") ||
                (value === null && tableData[key].required && typeof tableData[key].default !== "undefined")
            ) ? tableData[key].default : value;

        });

        return tableData;

    }

    return p.tableData;

}

export function getTableDataKeys({tableData, forceShowNull, post, user, show}) {

    const author = post?._author?._id || post?._author;
    const isAuthor = (author && user?._id && user?._id === author);
    const isAdmin = user?._status_isFeatured;
    const isAuthorOrAdmin = !!(isAuthor || isAdmin);
    const isAdminOrAuthorIfPostDeleted = (isAdmin || (isAuthor && post?._status_isDeleted));

    const roles = {
        isAuthor,
        isAdmin,
        isAuthorOrAdmin,
        isAdminOrAuthorIfPostDeleted
    };

    let tableDataKeys = Object.keys(tableData).sort((a, b) => {
        const aO = tableData[a].order || 0;
        const bO = tableData[b].order || 0;
        if (a === "submit") {
            return 1;
        }
        return (aO > bO) ? 1 : (aO < bO) ? -1 : 0;
    });

    tableDataKeys = tableDataKeys.filter(function (key) {

        let go = (
            (!tableData[key].hidden && !tableData[key].role) ||
            (!tableData[key].hidden && tableData[key].role && roles[tableData[key].role])
        );

        if ((!forceShowNull && go && typeof tableData[key].value == "undefined" && !tableData[key].required) ||
            (!forceShowNull && go && tableData[key].value === null && !tableData[key].required)
        ) {
            go = false;
        }

        if (go && show && tableData[key].show?.length) {
            if (tableData[key].show.indexOf(show) === -1) {
                go = false;
            }
        }

        return go;
    });

    return tableDataKeys;

}

function TextField(props = {}) {

    const {value = "", multiline = false, maxRows = null, endFix} = props;

    return (
        <div
            className={clsx(
                style.textField,
                {
                    [style.multiline]: multiline,
                    [style.maxRows]: maxRows
                }
            )}
            style={(!isNaN(Number(maxRows)) && Number(maxRows) > 2) ? {WebkitLineClamp: maxRows} : {}}
        >
            {((endFix && value) || (endFix && value === 0)) ? <><span>{value}</span><span
                className={style.textFieldEndFix}>{endFix}</span></> : value}
        </div>
    )
}

function Label(props = {}) {

    const {label = "", Icon, labelClassName, labelWithIconClassName, labelTextClassName} = props;

    return (
        <div className={clsx(
            style.label,
            {
                [labelClassName]: labelClassName,
                [labelWithIconClassName]: Icon && labelWithIconClassName
            }
        )}>
            {(Icon) ?
                <div className={style.icon}>
                    <Icon/>
                </div>
                : null
            }
            <div className={clsx(
                style.labelText,
                {[labelTextClassName]: labelTextClassName}
            )}>
                <Typography
                    variant={"body2"}
                    color={"text.secondary"}
                    component={"span"}
                    display={"block"}
                >
                    {label}
                </Typography>
            </div>
        </div>
    )
}

export const defaultComponents = {
    Button: {
        props: {
            variant: "text",
            color: "inherit",
            children: "Open",
            value: "",
            target: null
        },
        Component: (props) => {

            const context = useContext(WappContext);
            const {wapp} = context;

            const {target, value} = props;
            const inner = !(target === "_blank" || (value && value.slice(0, 7) === "http://") || (value && value.slice(0, 8) === "https://"));

            return (
                <Button
                    {...props}
                    onClick={inner ? (e) => {
                        e.preventDefault();

                        if (value) {
                            const pushProps = {
                                search: "",
                                hash: "",
                                ...wapp.client.history.parsePath(value)
                            };
                            wapp.client.history.push(pushProps);
                        }

                    } : null}
                    href={value}
                    target={(!inner) ? "_blank" : target}
                />
            )
        }
    },
    TextField: {
        props: {
            value: "",
            multiline: false,
            maxRows: null,
            endFix: ""
        },
        Component: TextField
    },
    Status: {
        props: {
            value: "",
            user: null,
            post: null,
            name: "post"
        },
        Component: (props) => {
            const context = useContext(WappContext);
            const {wapp} = context;
            const appContext = useContext(AppContext);
            const {value, name, style} = props;
            const user = props.user || appContext.user;
            const statusManager = props.statusManager || wapp.getTargetObject().postTypes.findPostType({name: name}).statusManager;
            const post = props.post?._status === value ? props.post : {
                ...(props.post) ? props.post : {},
                _status: value
            };
            const statusText = post._status && getStatus({user, post, appContext, statusManager, name});

            if (statusText) {
                return (
                    <div className={style.status}>
                        {statusText}
                    </div>
                )
            }

            return null;
        }
    },
    Date: {
        props: {
            value: "",
            name: "post"
        },
        Component: (props) => {

            const appContext = useContext(AppContext);
            const {value, name = "post", style} = props;
            const N = capitalize(name);

            const dateText = value && appContext.labels["date" + N + "Format"]({dateText: value});

            if (dateText) {
                return (
                    <div className={style.date}>
                        {dateText}
                    </div>
                )
            }

            return null;
        }
    },
    Checkbox: {
        props: {
            value: false,
        },
        Component: (props) => {

            const {value, style} = props;

            return (
                <div className={style.checkbox}>
                    {(value) ? <CheckIcon/> : <CloseIcon/>}
                </div>
            );

        }
    }
};

export const defaultIcons = {
    label: LabelIcon,
    price: MoneyOutlinedIcon,
    subtitle: SubtitlesIcon,
    title: TitleIcon,
    content: FormatQuoteIcon,
    date: DateRangeIcon,
    status: FormatLineSpacingIcon,
    trueOrFalse: function ({post, user, tableData, condition, ...rest}) {
        return (
            <>
                {post[condition] ? <DoneIcon {...rest} /> : <CloseIcon {...rest} />}
            </>
        )
    }
};

function getComponentName({data}) {
    if (data.componentName) {
        return data.componentName;
    }
    let componentName = "TextField";
    if (data.schemaType) {
        switch (data.schemaType) {
            case "String":
                componentName = "TextField";
                break;
            case "MongoID":
                componentName = "TextField";
                break;
            case "Boolean":
                componentName = "Checkbox";
                break;
            default:
                componentName = "TextField";
        }
    }
    return componentName;
}

function generatePropsAndSelectComponent({components, tableData, key}) {

    const data = {...tableData[key]};

    const componentName = getComponentName({data});

    const Component = components[componentName]?.Component || TextField;
    const defaultProps = {...components[componentName]?.props || {}};

    const props = Object.keys(defaultProps).reduce(function (a, key) {
        a[key] = (typeof data[key] !== "undefined") ? data[key] : defaultProps[key];
        return a;
    }, {});

    if (!props.id) {
        props.id = key;
    }

    props.key = key;

    if ((data.value === "undefined" && data.required && typeof data.default !== "undefined") ||
        (data.value === null && data.required && typeof data.default !== "undefined")) {
        props.value = data.default;
    }

    return {props, Component};

}

class Table extends React.Component {
    constructor(props, context) {

        super(props, context);

        this.state = {
            tableData: {...copyObject(props.tableData) || {}}
        };

        this.addStyle = this.addStyle.bind(this);
        this.removeStyle = null;

        const {wapp} = context;
        if (wapp.target === "node") {
            this.addStyle();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.tableData !== prevProps.tableData) {
            this.setState({
                tableData: {...copyObject(this.props.tableData) || {}}
            })
        }
    }

    componentDidMount() {
        this.addStyle();
    }

    componentWillUnmount() {
        if (this.removeStyle) {
            this.removeStyle();
        }
        this.isUnmounted = true;
    }

    addStyle() {
        if (this.removeStyle) {
            this.removeStyle();
        }
        const {wapp} = this.context;
        this.removeStyle = wapp.styles.add(style);
    }

    render() {

        const {
            tableData,
        } = this.state;

        //const {materialStyle} = this.props;

        const {
            post,
            user,
            name,
            statusManager,
            className,
            labelClassName,
            labelWithIconClassName,
            labelTextClassName,
            forceShowNull,
            show,
        } = this.props;

        const icons = {...defaultIcons, ...(this.props.icons) ? this.props.icons : {}};
        const components = {...defaultComponents, ...(this.props.components) ? this.props.components : {}};

        const tableDataKeys = getTableDataKeys({tableData, forceShowNull, post, user, show});

        const tdWithProps = tableDataKeys.map(function (key) {
            return generatePropsAndSelectComponent({components, tableData, key});
        });

        const Parent = this.props.Parent || function DefaultParent(props) {
            return (
                <>
                    {(tableDataKeys.length) ?
                        <div className={clsx(style.tableContainer, {[className]: className})}>
                            <table className={clsx(
                                style.table
                            )}>
                                <tbody>{props.children}</tbody>
                            </table>
                        </div> : null
                    }
                </>
            )
        };

        const Tr = this.props.Tr || function DefaultTr(props) {
            const {children, ...rest} = props;
            return (
                <tr {...rest}>{children}</tr>
            )
        };

        const Th = this.props.Th || function DefaultTh(props) {
            const {children, ...rest} = props;
            return (
                <th {...rest}>{children}</th>
            )
        };

        const Td = this.props.Td || function DefaultTd(props) {
            const {children, ...rest} = props;
            return (
                <td {...rest}>{children}</td>
            )
        };

        return (
            <Parent>
                {
                    tableDataKeys.map(function (key, i) {

                        const {props, Component} = tdWithProps[i];

                        const label = tableData[key].label || key.slice(0, 1).toUpperCase() + key.slice(1);
                        const Icon = tableData[key].icon && icons[tableData[key].icon];
                        const iconCondition = tableData[key].iconCondition;

                        return (
                            <Tr key={i}>
                                <Th>
                                    <div className={style.thInner}>
                                        <Label
                                            label={label}
                                            Icon={(iconCondition) ?
                                                (props) => <Icon {...props} post={post} user={user}
                                                                 tableData={tableData[key]} condition={iconCondition}/>
                                                : Icon
                                            }
                                            labelClassName={labelClassName}
                                            labelWithIconClassName={labelWithIconClassName}
                                            labelTextClassName={labelTextClassName}
                                        />
                                    </div>
                                </Th>
                                <Td>
                                    <div className={style.tdInner}>
                                        <Component {...props} post={post} user={user} style={style}
                                                   name={name} statusManager={statusManager}/>
                                    </div>
                                </Td>
                            </Tr>
                        )
                    })
                }
            </Parent>
        )
    }
}

Table.contextType = WappContext;

const WappComponent = withWapp(Table);

export default withMaterialStyles(materialStyle, WappComponent);
