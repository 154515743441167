import React, {useContext, useEffect, useMemo, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";

import clsx from "clsx";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import HideImageOutlinedIcon from "@mui/icons-material/HideImageOutlined";

import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import {withMaterialStyles} from "../Template/withMaterial";
import AppContext from "../App/context";

import Posts from "../Form/Posts";
import {defaultComponents} from "../Form";

import materialStyle from "./materialStyle";
import style from "./style.css";

function DefaultValueComponent({children, ...rest}) {
    return <div {...rest}>
        <div>{children}</div>
    </div>
}

function Editable(props) {

    const {className, helperText = "", error, disabled, ValueComponent = DefaultValueComponent} = props;

    const [value, setValue] = useState(props.value);
    const [edit, setEdit] = useState(!disabled);

    useEffect(() => {
        props.effect({
            setValue
        });
        return () => {
            props.effect({
                setValue: () => null
            })
        }
    }, [props.effect]);

    function onClick(e) {
        if (disabled) {
            return;
        }
        e.preventDefault();
        if (!edit) {
            setEdit(true)
        }
    }

    function onChange(e) {
        e.preventDefault();
        const v = e.target.value;
        if (v !== value) {
            setValue(v);
        }
    }

    async function onBlur(e) {
        e.preventDefault();
        onChange(e);
        if (props.onChange) {
            await props.onChange(e.target.value)
        }
    }

    return (
        <>
            {(edit) ?
                <TextField
                    classes={{
                        root: clsx(
                            style.inputText,
                            {
                                [style[className]]: style[className],
                                [style[error]]: error
                            }
                        ),
                    }}
                    FormHelperTextProps={{
                        classes: {
                            root: style.helperText
                        }
                    }}
                    variant={"outlined"}
                    type={"text"}
                    label={""}
                    value={value}
                    error={error}
                    helperText={helperText}
                    onChange={onChange}
                    onBlur={onBlur}
                    multiline={false}
                    disabled={disabled}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault()
                        }
                    }}
                    onFocus={(e) => {
                        e.target.setSelectionRange(e.target.value.length, e.target.value.length)
                    }}
                /> :
                <>
                    <ValueComponent
                        className={clsx(
                            style.valueText,
                            {
                                [style[className]]: style[className],
                                [style.error]: error
                            }
                        )}
                        onClick={onClick}
                    >
                        {value}
                    </ValueComponent>
                    {(helperText) ?
                        <div
                            className={clsx(
                                style.helperText,
                                {
                                    [style.error]: error
                                }
                            )}
                            onClick={onClick}
                        >
                            {helperText}
                        </div> : null
                    }
                </>
            }
        </>
    )
}

function Cart(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);

    const {
        onChange,
        editable,
        enableAddProducts,
        disabled,
        name = "order",
        refPostType = "product",
        postsProps = {},
        effect,
        enableCartItemNavigate,
    } = props;
    let inputValue = [];
    try {
        inputValue = JSON.parse(props.value)
    } catch (e) {
    }

    const [value, setValue] = useState(inputValue);
    const [errors, setErrors] = useState(props.errors || []);
    const [error, setError] = useState(props.error);
    const [helperText, setHelperText] = useState(props.helperText);

    const {wapp} = context;

    wapp.styles.use(style);

    const v = [...(value?.length) ? value : []];

    const qRefs = useMemo(() => ({}), []);

    function getTotal() {
        return {
            total: v.reduce((n, cartItem) => {
                const total = cartItem.price * cartItem.q || 0;
                n = n + total;
                return n;
            }, 0),
            totalNet: v.reduce((n, cartItem) => {
                const vatRate = cartItem.vatRate || 0;
                const netPrice = (cartItem.price / (100 + vatRate)) * 100;
                const total = netPrice * cartItem.q || 0;
                n = n + total;
                return n;
            }, 0)
        }
    }

    const utils = getUtils(context);

    function createSubtitle(cartItem) {
        if (cartItem.subtitle) {
            if (cartItem.subtitle.match("<")) {
                return <div dangerouslySetInnerHTML={{__html: cartItem.subtitle}}/>
            }
            return cartItem.subtitle;
        }
    }

    async function updateCart() {

        let newValue = [];

        await Promise.all(v.map(async (cartItem) => {
            if (cartItem?._id) {
                const requestName = refPostType + "FindById";
                let response = await utils.sendRequest({requestName, args: {_id: cartItem._id}});
                if (typeof response[requestName] !== "undefined") {
                    response = response[requestName];
                }
                if (response?._id) {

                    let q = cartItem.q;

                    if (!response._status_isNotDeleted || !response._author_status_isNotDeleted) {
                        q = 0;
                    }

                    if (q) {
                        if (response.piecesInThePackage) {
                            const min = Math.floor(q / response.piecesInThePackage) * response.piecesInThePackage;
                            const round = Math.round(q / response.piecesInThePackage) * response.piecesInThePackage;
                            const max = min + response.piecesInThePackage;
                            q = round || max;
                        }
                        q = (typeof response.piecesAvailable === "number") ? Math.min(Math.max(0, response.piecesAvailable), q) : q;
                        if (!response.price || response.disableProduct || !response.available) {
                            q = 0;
                        }
                    }

                    newValue.push({
                        ...response,
                        q,
                    });

                }
            }

        }));

        newValue = newValue.filter((cartItem) => cartItem?._id && cartItem?.q > 0);

        await setValue(newValue);

        await onChange({target: {value: JSON.stringify(newValue)}});

    }

    useEffect(() => {
        if (effect) {
            effect({actions: {getTotal, updateCart}})
        }
        return () => {
            if (effect) {
                effect({actions: {getTotal, updateCart: () => null}})
            }
        }
    });

    return (
        <div className={clsx(
            style.cart,
            {[style.disabled]: disabled || !editable}
        )}>
            {v.length ?
                <Table className={style.table}>
                    <TableHead>
                        <TableRow className={style.tableRow}>
                            <TableCell className={style.tableCell}>
                                {appContext.labels[name + "ProductTitleLabel"]}
                            </TableCell>
                            <TableCell className={style.tableCell}>
                                {appContext.labels[name + "QuantityLabel"]}
                            </TableCell>
                            <TableCell className={style.tableCell}>
                                {appContext.labels[name + "TotalTextLabel"]}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {v.map((cartItem, i) => {

                            const total = cartItem.price * cartItem.q || 0;
                            const totalText = appContext.labels[name + "TotalText"]({total});

                            const subtitle = createSubtitle(cartItem);
                            const error = errors.find(({path}) => path === i);

                            return (
                                <React.Fragment key={i}>
                                    <TableRow className={style.tableRow}>
                                        <TableCell className={style.tableCell}>
                                            <ListItem alignItems="flex-start" className={style.productTitleContainer}>
                                                <ListItemAvatar
                                                    className={enableCartItemNavigate && cartItem._id ? style.cartItemIsButton : null}
                                                    onClick={()=>{
                                                        if (enableCartItemNavigate && cartItem._id) {
                                                            window.open("/"+refPostType+"/"+cartItem._id, '_blank')
                                                        }
                                                    }}
                                                >
                                                    {(cartItem.thumb) ?
                                                        <Avatar alt={cartItem.title} src={cartItem.thumb}/> :
                                                        <div className={style.emptyThumb}><HideImageOutlinedIcon/>
                                                        </div>}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    className={enableCartItemNavigate && cartItem._id ? style.cartItemIsButton : null}
                                                    primary={cartItem.title}
                                                    secondary={subtitle}
                                                    onClick={()=>{
                                                        if (enableCartItemNavigate && cartItem._id) {
                                                            window.open("/"+refPostType+"/"+cartItem._id, '_blank')
                                                        }
                                                    }}
                                                />
                                            </ListItem>
                                        </TableCell>
                                        <TableCell className={style.tableCell}>
                                            {
                                                !(!editable && !disabled) ?
                                                    <div
                                                        className={style.clearItem}
                                                        onClick={async (e) => {
                                                            e.preventDefault();

                                                            const newValue = [...v];
                                                            newValue.splice(i, 1);

                                                            await setValue(newValue);

                                                            if (error || errors.length) {
                                                                await setError(false);
                                                                await setErrors([]);
                                                                await setHelperText("");
                                                            }

                                                            await onChange({target: {value: JSON.stringify(newValue)}});
                                                        }}
                                                    >
                                                        {"x"}
                                                    </div>
                                                    : null
                                            }
                                            <Editable
                                                effect={({setValue}) => {
                                                    qRefs[i] = {setValue};
                                                }}
                                                className={"quantity"}
                                                value={cartItem.q}
                                                error={false}
                                                helperText={""}
                                                disabled={!editable && !disabled}
                                                onChange={async (value) => {
                                                    let q = value && value.replace(/\D/g, "") || 1;
                                                    q = (!isNaN(Number(q))) ? Number(q) : 1;
                                                    q = q < 0 ? 0 : q;
                                                    if (v[i] && q) {
                                                        if (v[i].piecesInThePackage) {
                                                            const min = Math.floor(q / v[i].piecesInThePackage) * v[i].piecesInThePackage;
                                                            const round = Math.round(q / v[i].piecesInThePackage) * v[i].piecesInThePackage;
                                                            const max = min + v[i].piecesInThePackage;
                                                            q = round || max;
                                                        }
                                                        q = (typeof v[i].piecesAvailable === "number") ? Math.min(Math.max(0, v[i].piecesAvailable), q) : q;
                                                        if (!v[i].price || v[i].disableProduct || !v[i].available) {
                                                            q = 0;
                                                        }
                                                    }
                                                    let newValue = [...v];
                                                    newValue[i] = {
                                                        ...(v[i]) ? v[i] : {},
                                                        q,
                                                    };
                                                    newValue = newValue.filter((cartItem) => cartItem?._id && cartItem?.q > 0);
                                                    qRefs[i].setValue(q);

                                                    await setValue(newValue);

                                                    if (error || errors.length) {
                                                        await setError(false);
                                                        await setErrors([]);
                                                        await setHelperText("");
                                                    }

                                                    await onChange({target: {value: JSON.stringify(newValue)}});

                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={style.tableCell}>
                                            {totalText}
                                        </TableCell>
                                    </TableRow>
                                    {(error) ?
                                        <TableRow>
                                            <TableCell
                                                className={clsx(
                                                    style.helperText,
                                                    {[style.error]: error}
                                                )}
                                            >
                                                {error.message}
                                            </TableCell>
                                            <TableCell/>
                                            <TableCell/>
                                        </TableRow> : null
                                    }
                                </React.Fragment>
                            )
                        })}
                        <TableRow className={style.tableRow}>
                            <TableCell
                                className={style.tableCell}>{appContext.labels[name + "CartSummaryLabel"]}</TableCell>
                            <TableCell className={style.tableCell}/>
                            <TableCell className={style.tableCell}>
                                {appContext.labels[name + "TotalText"]({
                                    total: v.reduce((n, cartItem) => {
                                        const total = cartItem.price * cartItem.q || 0;
                                        n = n + total;
                                        return n;
                                    }, 0)
                                })}
                            </TableCell>
                        </TableRow>
                        {(helperText) ?
                            <TableRow>
                                <TableCell
                                    className={clsx(
                                        style.helperText,
                                        {[style.error]: error}
                                    )}
                                >
                                    {helperText}
                                    {(error && editable) ? <Button className={style.updateCartButton}
                                                                   onClick={updateCart}>{appContext.labels[name + "CartUpdateCartLabel"]}</Button> : null}
                                </TableCell>
                                <TableCell/>
                                <TableCell/>
                            </TableRow> : null
                        }
                    </TableBody>
                </Table>
                :
                (editable) ?
                    <div className={style.emptyCart}>
                        <Typography variant={"subtitle1"}>
                            {appContext.messages[name + "EmptyCart"]}
                        </Typography>
                    </div> : null
            }
            {(editable && enableAddProducts) ?
                <Posts
                    {...defaultComponents.Posts.props}
                    refPostType={refPostType}
                    disableFindByAuthor={true}
                    value={[]}
                    label={""}
                    thereAreNoEntriesMessage={"Adj hozzá a termékeket"}
                    disableTable={"stage"}
                    disableFab={true}
                    disabled={!editable && !disabled}
                    multiple={true}
                    selectDisabled={({post}) => v.find(({_id}) => post._id === _id)}
                    onChange={async (e) => {

                        let newValue = [...v];

                        const values = e.target.value;

                        if (values.length) {

                            await Promise.all(values.map(async (cartItem, i) => {
                                if (cartItem?._id && Object.keys(cartItem).length < 2) {
                                    const requestName = refPostType + "FindById";
                                    let response = await utils.sendRequest({requestName, args: {_id: cartItem._id}});
                                    if (typeof response[requestName] !== "undefined") {
                                        response = response[requestName];
                                    }
                                    if (response?._id) {
                                        values[i] = {...response};
                                    }
                                }
                            }));

                            newValue.push(...values.map((value) => {
                                return (value) ? {
                                    ...value,
                                    q: value.piecesInThePackage || 1,
                                } : {}
                            }))
                        }

                        newValue = newValue.filter((cartItem) => cartItem?._id && cartItem?.q > 0);

                        await setValue(newValue);

                        if (error || errors.length) {
                            await setError(false);
                            await setErrors([]);
                            await setHelperText("");
                        }

                        await onChange({target: {value: JSON.stringify(newValue)}});

                    }}
                    {...postsProps}
                />
                : null
            }
            {(!editable && !v.length) ?
                <div className={style.emptyCart}>
                    <Typography variant={"subtitle1"}>
                        {appContext.messages[name + "EmptyCart"]}
                    </Typography>
                </div> : null
            }
            {(helperText && !v.length) ?
                <TableRow>
                    <TableCell
                        className={clsx(
                            style.helperText,
                            {[style.error]: error}
                        )}
                    >
                        {helperText}
                    </TableCell>
                    <TableCell/>
                    <TableCell/>
                </TableRow> : null
            }
        </div>
    )
}

const WappComponent = withWapp(Cart);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
