import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "file";

    const n = name;
    const N = capitalize(n);
    const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
    const Ns = capitalize(ns);

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels,
            ["new" + N + "SubmitLabel"]: "Mentés",
            ["restore" + N + "SubmitLabel"]: "Visszaállítás",
            ["save" + N + "SubmitLabel"]: "Mentés",
            ["cancel" + N + "Text"]: "Mégsem",
            ["delete" + N + "Text"]: "Törlés",
            ["delete" + Ns + "Text"]: "Fileok törlése",
            ["ban" + N + "Text"]: "Tiltás",
            ["approve" + N + "Text"]: "Jóváhagyás",
            ["protection" + N + "Text"]: "Védettnek jelölés",
            ["removeProtection" + N + "Text"]: "Védettség megszüntetése",
            ["select" + N + "Text"]: "Kiválasztás",
            ["add" + N + "Text"]: "Hozzáadás",
            ["date" + N + "Format"]: ({dateText = ""}) => {
                if (!dateText) {
                    return dateText;
                }
                try {
                    return new Date(dateText).toLocaleString("hu-HU");
                } catch (e) {
                }
                return dateText;
            },
            [ns + "SortRelevance"]: "Relevancia",

            ["cancelAbortUpload" + Ns + "Text"]: "Mégsem",
            ["abortUpload" + Ns + "Text"]: "Megszakítás",
        },
        menus: {
            ...menus,
            ["user" + Ns + "Menu"]: "Fileok",
            ["my" + Ns + "Menu"]: "Fileaim",
            ["new" + N + "Menu"]: "Fileok feltöltése",
            [ns + "Menu"]: "Fileok",
            ["deleted" + Ns + "Menu"]: "Törölt fileok",
            ["banned" + Ns + "Menu"]: "Véglegesen törölt fileok",
            ["protection" + Ns + "Menu"]: "Védett fileok",
            ["missingData" + Ns + "Menu"]: "Hiányzó adatok",
            ["lowAuthorStatus" + Ns + "Menu"]: "Szerző alacsony státusszal",

            ["edit" + N + "Menu"]: "Szerkesztés",
            ["delete" + N + "Menu"]: "Törlés",
            ["ban" + N + "Menu"]: "Tiltás",
            ["approve" + N + "Menu"]: "Jóváhagyás",
            ["protection" + N + "Menu"]: "Védettnek jelölés",
            ["removeProtection" + N + "Menu"]: "Védettség törlése",
        },
        messages: {
            ...messages,
            ["new" + Ns + "SuccessMessage"]: "Fileok feltöltve",
            ["save" + N + "SuccessMessage"]: "A file mentésre került",
            ["delete" + N + "Success"]: "A file törölve lett",
            ["delete" + Ns + "Success"]: "A fileok törölve lettek",
            ["delete" + N + "Question"]: "Biztosan törli a file-t?",
            ["delete" + Ns + "Question"]: "Biztosan törli a fileokat?",
            ["ban" + N + "Success"]: "A file véglegesen törölve lett",
            ["ban" + N + "Question"]: "Biztos, hogy a file-t véglegesen törölni akarod?",
            ["approve" + N + "Success"]: "A file jóvá lett hagyva",
            ["approve" + N + "Question"]: "Biztosan jóváhagyod a file-t",
            ["protection" + N + "Success"]: "A file védettnek jelölve",
            ["protection" + N + "Question"]: "Biztosan védettnek jelölöd a file-t?",
            ["removeProtection" + N + "Success"]: "A védettnek jelölés törölve lett",
            ["removeProtection" + N + "Question"]: "Biztosan törlöd a védettnek jelölést?",
            ["thereAreNo" + Ns]: "Nincsenek fileok",

            ["abortUpload" + Ns + "Question"]: "Megszkítod a feltöltést?",
            ["abortUpload" + Ns + "Success"]: "Feltöltés megszakítva",
            ["new" + Ns + "ClientErrorMessage"]: "Hiba történt a file megnyitásakor, próbáld újratölteni az oldalt",
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
            [n + "Title"]: "File",
            [ns + "Title"]: "Fileok",
            ["new" + N + "Title"]: "Új file",
            ["edit" + N + "Title"]: "File szerkesztése",
            ["user" + Ns + "Title"]: "Fileok",
            ["userDeleted" + Ns + "Title"]: "Törölt fileok",
            ["my" + Ns + "Title"]: "Fileaim",
            ["myDeleted" + Ns + "Title"]: "Törölt fileaim",

            ["dialogDelete" + N + "Title"]: "File törlése",
            ["dialogBan" + N + "Title"]: "File törlése véglegesen",
            ["dialogApprove" + N + "Title"]: "File jóváhagyása",
            ["dialogProtection" + N + "Title"]: "File védettnek jelölése",
            ["dialogRemoveProtection" + N + "Title"]: "File védettségének megszüntetése",

            ["dialogDelete" + Ns + "Title"]: "Fileok törlése",
            ["dialogAbortUpload" + Ns + "Title"]: "Feltöltés megszakítása",

            ["statusBanned" + N + "Title"]: "Véglegesen törölt",
            ["statusDeleted" + N + "Title"]: "Törölt",
            ["statusAuthorDeleted" + N + "Title"]: "Szerző törölve",
            ["statusMissingData" + N + "Title"]: "Hiányzó adatok",
            ["statusApproved" + N + "Title"]: "Jóváhagyott",
            ["statusFeatured" + N + "Title"]: "Védett",
            ["statusCreated" + N + "Title"]: "Létrehozva",

            ["banned" + Ns + "Title"]: "Véglegesen törölt fileok",
            ["deleted" + Ns + "Title"]: "Törölt fileok",
            ["missingData" + Ns + "Title"]: "Hiányzó adatok",
            ["protected" + Ns + "Title"]: "Védett fileok",
            ["lowAuthorStatus" + Ns + "Title"]: "Szerző alacsony státusszal",

            ["select" + Ns]: "Fileok kiválasztása",
            ["select" + N]: "File kiválasztása"
        }
    }
}
