import React, {useContext, useMemo, useState} from "react";

import ToysIcon from "@mui/icons-material/Toys";
import DeleteIcon from "@mui/icons-material/Delete";
import ToysOutlined from "@mui/icons-material/ToysOutlined";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";

import {WappContext} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";
import Content, {tableComponents, tableIcons} from "./Content";
import Import from "./Import";

import getConstants from "./constants";
import {postTypesConfig, runPostTypesConfig} from "../index";
import ArticleOutlined from "@mui/icons-material/ArticleOutlined";
import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import StarIcon from "@mui/icons-material/Star";
import ShortTextIcon from "@mui/icons-material/ShortText";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import Posts from "./Posts";

import getMenu from "./getMenu";

function base64ToDownload(response) {

    if (response?.base64) {

        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, {type: contentType});
        }

        const blob = b64toBlob(response.base64, "application/octet-stream");
        const blobUrl = URL.createObjectURL(blob);

        function saveData() {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "display";
            a.href = blobUrl;
            a.download = 'products.xls';
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(blobUrl);
        }

        saveData();

    }

}

function ExportButton(props) {

    const context = useContext(WappContext);
    const utils = getUtils(context);

    return (
        <ListItem
            style={{cursor: 'pointer'}}
            onClick={async (e) => {

                e.preventDefault();

                const response = await utils.sendRequest({requestName: props.name + "ExportExcel"});

                base64ToDownload(response);

            }}
        >
            <ListItemIcon>
                <ArticleOutlined/>
            </ListItemIcon>
            <ListItemText>
                {props.label}
            </ListItemText>
        </ListItem>
    )
}

function ExportFromDistributorButton(props) {

    const context = useContext(WappContext);
    const utils = getUtils(context);

    return (
        <ListItem
            style={{cursor: 'pointer'}}
            onClick={async (e) => {

                e.preventDefault();

                const response = await utils.sendRequest({requestName: props.name + "ExportExcelFromDistributor"});

                if (response?.base64) {

                    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
                        const byteCharacters = atob(b64Data);
                        const byteArrays = [];

                        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                            const slice = byteCharacters.slice(offset, offset + sliceSize);

                            const byteNumbers = new Array(slice.length);
                            for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                            }

                            const byteArray = new Uint8Array(byteNumbers);
                            byteArrays.push(byteArray);
                        }

                        return new Blob(byteArrays, {type: contentType});
                    }

                    const blob = b64toBlob(response.base64, "application/octet-stream");
                    const blobUrl = URL.createObjectURL(blob);

                    function saveData() {
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style.display = "display";
                        a.href = blobUrl;
                        a.download = 'products.xls';
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(blobUrl);
                    }

                    saveData();

                }
            }}
        >
            <ListItemIcon>
                <ArticleOutlined/>
            </ListItemIcon>
            <ListItemText>
                {props.label}
            </ListItemText>
        </ListItem>
    )
}

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    title: {type: String},
                },
                ...(p.config) ? p.config : {}
            },
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {

        const {wapp, routes, getTitle, titles} = p;
        const name = "product";
        const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
        const N = capitalize(name);

        wapp.router.add([
            {path: routes[name + "Route"] + "/import", contentName: name + "Import"},
        ]);

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams: [
                "/page/:pagination",
                "/sort/:sort",
                "/limit/:limit",
                "/categories/:categories",
                "/ages/:ages",
            ]
        });

        let reqUserForPost = null;

        const slugPattern = /^[a-z0-9](-?[a-z0-9]){1,150}$/;
        const eanPattern = /^(\d{13})?$/;
        const objectIdPattern = /^[0-9a-fA-F]{24}$/;

        const contentProps = wapp.contents.get(name);

        function getPost(res) {
            const wappResponse = res.wappResponse;
            const route = wappResponse.route;
            const {params} = route;
            const {_id} = params;
            const isEanRequest = !_id?.match(objectIdPattern) && _id?.match(eanPattern);
            const isSlugRequest = !_id?.match(objectIdPattern) && !isEanRequest && _id?.match(slugPattern);

            const posts = [
                wappResponse.store.getState("res.responses." + name + "FindById"),
                wappResponse.store.getState("res.responses." + name + "FindBySlug"),
                wappResponse.store.getState("res.responses." + name + "FindByEan"),
            ];

            return posts.find((p) => isSlugRequest ? p?.slug === _id : isEanRequest ? p?.ean === _id : p?._id === _id);
        }

        wapp.contents.add({
            [name]: {
                ...contentProps,
                title: (context)=>{

                    const {res} = context

                    const post = getPost(res);

                    let title = contentProps.title(context);

                    if (post?._id) {
                        title = post.title + " | " + title.split(" | ")[1];
                    }

                    return title;
                },
                description: (context)=>{

                    const {res} = context

                    const post = getPost(res);

                    let description = contentProps.description(context);

                    if (post?._id) {
                        description = post.title + " " + post.content_extract + " | " + description
                    }

                    return description;
                },
                image: (context)=>{

                    const {res} = context

                    const post = getPost(res);

                    return post?.cover?.thumb ? post.cover : null;
                },
                imageAlt: (context)=>{

                    const {res} = context

                    const post = getPost(res);

                    return post?.title || "";
                },
                type: ()=>{
                    return "og:product";
                },
                price: (context)=>{

                    const {res} = context

                    const post = getPost(res);

                    return post?.price ? post.price : 0
                },
                currency: (context)=>{
                    const {res} = context
                    const post = getPost(res);
                    return post?._id ? "HUF" : "";
                },
                ldJson: (context)=>{

                    const {res, req} = context
                    const post = getPost(res);

                    if (post?._id) {

                        const date = new Date();

                        date.setDate(date.getDate() + 30);

                        return JSON.stringify({
                            "@context": "https://schema.org/",
                            "@type": "Product",
                            "name": post.title,
                            "image": [
                                ...post.gallery ? post.gallery.map((image)=>image.thumb) : (post.cover?.thumb) ? [post.cover?.thumb] : post.thumb ? [post.thumb] : []
                            ].map((url)=>{
                                return req.wappRequest.protocol +"://"+req.wappRequest.hostname + url
                            }),
                            "description": post.content,
                            "sku": post.isbn || post.ean || post.slug,
                            "brand": {
                                "@type": "Brand",
                                "name": post.productAuthor || post.productIllustrator || post.publisher || post.productEditor || post.productTranslator
                            },
                            "offers": {
                                "@type": "Offer",
                                "url": req.wappRequest.protocol+"://"+req.wappRequest.hostname+"/product/"+(post.slug || post._id),
                                "priceCurrency": "HUF",
                                "price": post.price,
                                "priceValidUntil": date.toISOString().split('T')[0],
                                "itemCondition": "https://schema.org/NewCondition",
                                "availability": post.available ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
                            }
                        })

                    }

                    return null;
                },
                request: async function ({wapp, req, res}) {

                    const wappResponse = res.wappResponse;
                    const route = wappResponse.route;
                    const {params} = route;
                    const {_id} = params;
                    const isEanRequest = !_id?.match(objectIdPattern) && _id?.match(eanPattern);
                    const isSlugRequest = !_id?.match(objectIdPattern) && !isEanRequest && _id?.match(slugPattern);
                    const requestName = _id?.match(objectIdPattern) ? name + "FindById" : isSlugRequest ? name + "FindBySlug" : isEanRequest ? name + "FindByEan" : null;

                    const statusManager = wapp.getTargetObject().postTypes.findPostType({name}).statusManager;
                    await runPostTypesConfig({action: "requestFor" + N + "Page", p: {wapp, req, res, statusManager}});

                    if (requestName) {

                        const posts = [
                            wappResponse.store.getState("res.responses." + name + "FindById"),
                            wappResponse.store.getState("res.responses." + name + "FindBySlug"),
                            wappResponse.store.getState("res.responses." + name + "FindByEan"),
                        ];

                        const post = posts.find((p) => isSlugRequest ? p?.slug === _id : isEanRequest ? p?.ean === _id : p?._id === _id);

                        const args = isSlugRequest ? {slug: _id} : isEanRequest ? {ean: _id} : {_id};

                        if (
                            (reqUserForPost?._id !== req.wappRequest.user?._id) ||
                            (_id && !post) ||

                            (!isSlugRequest && !isEanRequest && post?._id !== _id) ||
                            (isSlugRequest && post?.slug !== _id) ||
                            (isEanRequest && post?.ean !== _id)
                        ) {
                            reqUserForPost = {_id: req.wappRequest.user?._id};
                            return await wapp.requests.send({requestName, args, req, res});

                        } else {
                            return {
                                [requestName]: post
                            }
                        }

                    }
                }
            },
            [name + "Import"]: {
                ...wapp.contents.get(name),
                request: null,
                title: (p) => {
                    return getTitle({...p, title: titles[ns + "ImportTitle"]})
                },
                description: () => {
                    const {wapp} = p;
                    const config = wapp.getTargetObject().config;
                    const {description} = config;
                    return (description) ? description : wapp.contents.get(name).title(p).split(" | ")[0];
                }
            }
        });

        return r;
    },
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name: "product"
        })
    },
    getComponent: function (p) {

        const name = "product";
        const N = capitalize(name);
        const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
        const Ns = capitalize(ns);

        const {context, appContext} = p;
        const {routes, /*userStatusManager*/} = appContext;

        const {res, req, wapp} = context;
        const route = res.wappResponse.route;
        const {params} = route;
        const {categories, ages} = params;
        const requestPath = route.requestPath;
        const user = req.wappRequest.user;

        const isAdmin = user?._status_isFeatured;

        const pages = {
            content: Content,
            import: Import
        };

        if ((requestPath.startsWith(routes[name + "Route"])) || (requestPath.startsWith(routes[ns + "Route"]))) {

            if (requestPath === routes[name + "Route"] + "/import") {
                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    componentProps: {
                        pages,
                        getPageName: () => "import",
                        getTitle: () => appContext.titles[ns + "ImportTitle"],
                        getSubtitle: () => appContext.titles[ns + "ImportSubtitle"],
                        maxWidth: "xl"
                    }
                });
            }

            if (requestPath === routes[name + "Route"] + "/new" && !isAdmin && user) {
                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    componentProps: {pages, getPageName: () => "notFound", layoutType: "article"}
                });
            }

            const requestKeys = [name + "FindById", name + "FindBySlug", name + "FindByEan"];
            const slugPattern = /^[a-z0-9](-?[a-z0-9]){1,150}$/;
            const eanPattern = /^(\d{13})?$/;
            const objectIdPattern = /^[0-9a-fA-F]{24}$/;

            const componentProps = {
                ...p,
                name,
                archiveComponentProps: {
                    type: categories || ages ? "card" : (isAdmin) ? "table" : "card",
                    tableProps: {
                        components: tableComponents,
                        icons: tableIcons,
                        className: null,
                        show: categories || ages ? "card" : (isAdmin) ? "table" : "card"
                    },
                    ...function () {

                        const isAdmin = user?._status_isFeatured;

                        const selectable = () => {
                            return isAdmin && !categories && !ages;
                        };
                        const selectFunctions = (selectable) ? [
                            {
                                role: function ({posts}) {
                                    const deletionIsPossible = (posts?.length) ? posts.filter((post) => post._status_isNotDeleted) : false;
                                    return deletionIsPossible.length && deletionIsPossible.length === posts?.length;
                                },
                                label: appContext.titles["dialogDelete" + Ns + "Title"],
                                func: (e, {dialog, selected}) => {
                                    dialog.actions.open({
                                        dialogTitle: appContext.titles["dialogDelete" + Ns + "Title"],
                                        dialogContent: appContext.messages["delete" + Ns + "Question"],
                                        cancelText: appContext.labels["cancel" + N + "Text"],
                                        submitText: appContext.labels["delete" + Ns + "Text"],
                                        onSubmit: async function () {

                                            const errors = [];

                                            const responses = await Promise.allSettled(selected.value.map(async (postId) => {
                                                return await wapp.requests.send({
                                                    requestName: name + "Delete",
                                                    args: {_id: postId}
                                                });
                                            }));

                                            const deletedFiles = responses.filter((r, i) => {
                                                const {status} = r;
                                                let resp = r.value;
                                                if (resp[name + "Delete"]) {
                                                    resp = resp[name + "Delete"];
                                                }
                                                if (!resp?.record?._id || status !== "fulfilled") {
                                                    errors.push({
                                                        message: resp?.error?.message || (resp?.errors && resp.errors[0]?.message) || "Error",
                                                        path: selected.value[i]
                                                    });
                                                    return false;
                                                }
                                                return true;
                                            }).map((r) => {
                                                return r.value
                                            });

                                            if (!errors.length && deletedFiles.length === selected.value.length) {

                                                setTimeout(() => {
                                                    wapp.client.history.push(wapp.wappRequest.path);
                                                }, 1000);

                                                return true;
                                            }
                                            return {errors}

                                        },
                                        successMessage: appContext.messages["delete" + Ns + "Success"]
                                    })
                                }
                            },
                            {
                                role: function ({posts}) {
                                    return true;
                                },
                                label: appContext.titles["dialog" + Ns + "EmptyStockTitle"],
                                func: (e, {dialog, selected, posts, setPosts, deselectAll}) => {
                                    dialog.actions.open({
                                        dialogTitle: appContext.titles["dialog" + Ns + "EmptyStockTitle"],
                                        dialogContent: appContext.messages["emptyStock" + Ns + "Question"],
                                        cancelText: appContext.labels["cancel" + N + "Text"],
                                        submitText: appContext.labels["empty" + Ns + "Text"],
                                        onSubmit: async function () {

                                            const errors = [];

                                            const responses = await Promise.allSettled(selected.value.map(async (postId) => {
                                                return await wapp.requests.send({
                                                    requestName: name + "EmptyStock",
                                                    args: {
                                                        _id: postId,
                                                    }
                                                });
                                            }));

                                            const emptyFiles = responses.filter((r, i) => {
                                                const {status} = r;
                                                let resp = r.value;
                                                if (resp[name + "EmptyStock"]) {
                                                    resp = resp[name + "EmptyStock"];
                                                }
                                                if (!resp?.record?._id || status !== "fulfilled") {
                                                    errors.push({
                                                        message: resp?.error?.message || (resp?.errors && resp.errors[0]?.message) || "Error",
                                                        path: selected.value[i]
                                                    });
                                                    return false;
                                                }
                                                return true;
                                            }).map((r) => {
                                                return r.value
                                            });

                                            if (!errors.length && emptyFiles.length === selected.value.length) {

                                                emptyFiles.forEach((resp) => {
                                                    if (resp[name + "EmptyStock"]) {
                                                        resp = resp[name + "EmptyStock"];
                                                    }
                                                    const post = resp.record;
                                                    if (post?._id) {
                                                        const p = posts.find((p) => p._id === post._id);
                                                        if (p) {
                                                            Object.keys(post).forEach((key) => {
                                                                p[key] = post[key];
                                                            });
                                                        }
                                                    }
                                                });

                                                await deselectAll(e);
                                                await setPosts(posts);

                                                setTimeout(() => {
                                                    wapp.client.history.push(wapp.wappRequest.path);
                                                }, 1000);

                                                return true;
                                            }

                                            return {errors}

                                        },
                                        successMessage: appContext.messages["emptyStock" + Ns + "Success"]
                                    })
                                }
                            },
                            {
                                role: function ({posts}) {
                                    return true;
                                },
                                label: appContext.titles["dialog" + Ns + "UpdateFromDistributorAllTitle"],
                                func: (e, {dialog, selected, posts, setPosts, deselectAll}) => {
                                    dialog.actions.open({
                                        dialogTitle: appContext.titles["dialog" + Ns + "UpdateFromDistributorAllTitle"],
                                        dialogContent: appContext.messages["updateFromDistributorAll" + Ns + "Question"],
                                        cancelText: appContext.labels["cancel" + N + "Text"],
                                        submitText: appContext.labels["updateFromDistributorAll" + Ns + "Text"],
                                        onSubmit: async function () {

                                            const errors = [];
                                            const updatedFiles = [];

                                            let postI = -1;

                                            async function update() {
                                                postI = postI + 1;
                                                const postId = selected.value[postI];
                                                if (postId) {
                                                    let resp = await wapp.requests.send({
                                                        requestName: name + "UpdateFromDistributor",
                                                        args: {
                                                            _id: postId,
                                                            forceUpdate: typeof window !== "undefined" ? !!(window.forceUpdateFromDistributor) : false
                                                        }
                                                    });
                                                    if (resp[name + "UpdateFromDistributor"]) {
                                                        resp = resp[name + "UpdateFromDistributor"];
                                                    }
                                                    if (resp?.record?._id) {
                                                        updatedFiles.push(resp)
                                                    } else {
                                                        errors.push({
                                                            message: resp?.error?.message || (resp?.errors && resp.errors[0]?.message) || "Error",
                                                            path: selected.value[postI]
                                                        });
                                                    }
                                                    await update();
                                                }
                                            }

                                            await update();

                                            if (!errors.length && updatedFiles.length === selected.value.length) {

                                                updatedFiles.forEach((resp) => {
                                                    if (resp[name + "UpdateFromDistributor"]) {
                                                        resp = resp[name + "UpdateFromDistributor"];
                                                    }
                                                    const post = resp.record;
                                                    if (post?._id) {
                                                        const p = posts.find((p) => p._id === post._id);
                                                        if (p) {
                                                            Object.keys(post).forEach((key) => {
                                                                p[key] = post[key];
                                                            });
                                                        }
                                                    }
                                                });

                                                await deselectAll(e);
                                                await setPosts(posts);

                                                setTimeout(() => {
                                                    wapp.client.history.push(wapp.wappRequest.path);
                                                }, 1000);

                                                return true;
                                            }

                                            return {errors}

                                        },
                                        successMessage: appContext.messages["updateFromDistributorAll" + Ns + "Success"]
                                    })
                                }
                            },
                            {
                                role: function ({posts}) {
                                    return true;
                                },
                                label: appContext.titles["dialog" + Ns + "UpdateAvailableFromWholesaleAllTitle"],
                                func: (e, {dialog, selected, posts, setPosts, deselectAll}) => {
                                    dialog.actions.open({
                                        dialogTitle: appContext.titles["dialog" + Ns + "UpdateAvailableFromWholesaleAllTitle"],
                                        dialogContent: appContext.messages["updateAvailableFromWholesaleAll" + Ns + "Question"],
                                        cancelText: appContext.labels["cancel" + N + "Text"],
                                        submitText: appContext.labels["updateAvailableFromWholesaleAll" + Ns + "Text"],
                                        onSubmit: async function () {

                                            const errors = [];
                                            const updatedFiles = [];

                                            let postI = -1;

                                            async function update() {
                                                postI = postI + 1;
                                                const postId = selected.value[postI];
                                                if (postId) {
                                                    let resp = await wapp.requests.send({
                                                        requestName: name + "UpdateAvailableFromWholesale",
                                                        args: {
                                                            _id: postId,
                                                            forceUpdate: typeof window !== "undefined" ? !!(window.forceUpdateAvailableFromWholesale) : false
                                                        }
                                                    });
                                                    if (resp[name + "UpdateAvailableFromWholesale"]) {
                                                        resp = resp[name + "UpdateAvailableFromWholesale"];
                                                    }
                                                    if (resp?.record?._id) {
                                                        updatedFiles.push(resp)
                                                    } else {
                                                        errors.push({
                                                            message: resp?.error?.message || (resp?.errors && resp.errors[0]?.message) || "Error",
                                                            path: selected.value[postI]
                                                        });
                                                    }
                                                    await update();
                                                }
                                            }

                                            await update();

                                            if (!errors.length && updatedFiles.length === selected.value.length) {

                                                updatedFiles.forEach((resp) => {
                                                    if (resp[name + "UpdateAvailableFromWholesale"]) {
                                                        resp = resp[name + "UpdateAvailableFromWholesale"];
                                                    }
                                                    const post = resp.record;
                                                    if (post?._id) {
                                                        const p = posts.find((p) => p._id === post._id);
                                                        if (p) {
                                                            Object.keys(post).forEach((key) => {
                                                                p[key] = post[key];
                                                            });
                                                        }
                                                    }
                                                });

                                                await deselectAll(e);
                                                await setPosts(posts);

                                                setTimeout(() => {
                                                    wapp.client.history.push(wapp.wappRequest.path);
                                                }, 1000);

                                                return true;
                                            }

                                            return {errors}

                                        },
                                        successMessage: appContext.messages["updateAvailableFromWholesaleAll" + Ns + "Success"]
                                    })
                                }
                            },
                            {
                                role: function ({posts}) {
                                    return true;
                                },
                                label: appContext.titles["dialog" + Ns + "UpdateCheapestPriceAllTitle"],
                                func: (e, {dialog, selected, posts, setPosts, deselectAll}) => {
                                    dialog.actions.open({
                                        dialogTitle: appContext.titles["dialog" + Ns + "UpdateCheapestPriceAllTitle"],
                                        dialogContent: appContext.messages["updateCheapestPriceAll" + Ns + "Question"],
                                        cancelText: appContext.labels["cancel" + N + "Text"],
                                        submitText: appContext.labels["updateCheapestPriceAll" + Ns + "Text"],
                                        onSubmit: async function () {

                                            const errors = [];
                                            const updatedFiles = [];

                                            let postI = -1;

                                            async function update() {
                                                postI = postI + 1;
                                                const postId = selected.value[postI];
                                                if (postId) {
                                                    let resp = await wapp.requests.send({
                                                        requestName: name + "UpdateCheapestPrice",
                                                        args: {
                                                            _id: postId,
                                                            forceUpdate: typeof window !== "undefined" ? !!(window.forceUpdateCheapestPrice) : false
                                                        }
                                                    });
                                                    if (resp[name + "UpdateCheapestPrice"]) {
                                                        resp = resp[name + "UpdateCheapestPrice"];
                                                    }
                                                    if (resp?.record?._id) {
                                                        updatedFiles.push(resp)
                                                    } else {
                                                        errors.push({
                                                            message: resp?.error?.message || (resp?.errors && resp.errors[0]?.message) || "Error",
                                                            path: selected.value[postI]
                                                        });
                                                    }
                                                    await update();
                                                }
                                            }

                                            await update();

                                            if (!errors.length && updatedFiles.length === selected.value.length) {

                                                updatedFiles.forEach((resp) => {
                                                    if (resp[name + "UpdateCheapestPrice"]) {
                                                        resp = resp[name + "UpdateCheapestPrice"];
                                                    }
                                                    const post = resp.record;
                                                    if (post?._id) {
                                                        const p = posts.find((p) => p._id === post._id);
                                                        if (p) {
                                                            Object.keys(post).forEach((key) => {
                                                                p[key] = post[key];
                                                            });
                                                        }
                                                    }
                                                });

                                                await deselectAll(e);
                                                await setPosts(posts);

                                                setTimeout(() => {
                                                    wapp.client.history.push(wapp.wappRequest.path);
                                                }, 1000);

                                                return true;
                                            }

                                            return {errors}

                                        },
                                        successMessage: appContext.messages["updateCheapestPriceAll" + Ns + "Success"]
                                    })
                                }
                            },
                        ] : null;

                        return {
                            selectable,
                            selectFunctions
                        }

                    }()
                },
                componentProps: {
                    pages,
                    layoutType: "article",
                    showAuthor: ({user}) => user?._status_isFeatured,
                    showCreatedDate: ({user, author}) => user?._id && user._id === author?._id,
                    requestKeys,
                    getInitialResponse: function () {
                        const wappResponse = res.wappResponse;
                        const route = wappResponse.route;
                        const {params} = route;
                        const {_id} = params;

                        const isEanRequest = !_id?.match(objectIdPattern) && _id?.match(eanPattern);
                        const isSlugRequest = !_id?.match(objectIdPattern) && !isEanRequest && _id?.match(slugPattern);
                        const requestName = _id?.match(objectIdPattern) ? name + "FindById" : isSlugRequest ? name + "FindBySlug" : isEanRequest ? name + "FindByEan" : null;
                        if (requestName) {
                            const posts = [
                                wappResponse.store.getState("res.responses." + name + "FindById"),
                                wappResponse.store.getState("res.responses." + name + "FindBySlug"),
                                wappResponse.store.getState("res.responses." + name + "FindByEan"),
                            ];
                            return posts.find((p) => isSlugRequest ? p?.slug === _id : isEanRequest ? p?.ean === _id : p?._id === _id);
                        }

                        return null;

                    },
                    getMenu: (p) => getMenu({name, ...p}),
                }
            };

            if (requestPath.startsWith(routes[ns + "Route"])) {
                const Component = defaultPostTypeConfig.getComponent(componentProps);
                return function ComponentWithCart(props) {
                    return <Posts
                        {...props}
                        DefaultPostsComponent={Component}
                    />
                }
            }

            return defaultPostTypeConfig.getComponent(componentProps);
        }
    },
    userPageFunctions: function (p) {

        const {context, /*appContext, */postContext} = p;

        const n = "product";
        const ns = (n.endsWith("y")) ? n.slice(0, -1) + "ies" : n + "s";
        const N = capitalize(n);
        const Ns = capitalize(ns);

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p, name: n, nsPageProps: {
                type: "table",
                tableProps: {
                    components: tableComponents,
                    icons: tableIcons,
                    className: null
                }
            }
        });

        const {res} = context;

        const wappResponse = res.wappResponse;
        const route = wappResponse.route;
        const {params} = route;
        const {pageType} = params;

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (isAuthor) ? menus["my" + Ns + "Menu"] : menus[postContext.name + Ns + "Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/" + p.post._id + routes[postContext.name + Ns + "Route"] : routes[postContext.name + Ns + "Route"];
                    },
                    role: function (p) {
                        return ((p.user && p.user._status_isFeatured) || (p.post && p.post._status_isFeatured));
                    },
                    Icon: ToysIcon,
                    order: 11,
                },
                {
                    name: function () {
                        return menus["deleted" + Ns + "Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/" + p.post._id + routes[postContext.name + Ns + "Route"] + "/deleted" : routes[postContext.name + Ns + "Route"] + "/deleted"
                    },
                    role: function (p) {
                        const isAdmin = p.user && p.user._status_isFeatured;
                        const isPostsPage = ((p.page === ns && !p.pageType) || (p.page === ns && !isNaN(Number(p.pageType)) && Number(pageType) >= 1));
                        return (isPostsPage && isAdmin);
                    },
                    Icon: DeleteIcon,
                    onlyIcon: true,
                    featured: true,
                },
            ];
        }

        function addContentMenuItems(props) {

            const n = "product";
            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: menus["new" + N + "Menu"],
                    href: routes[n + "Route"] + "/new",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    Icon: ToysIcon,
                    disableParentRoute: true,
                    order: 10,
                },
                {
                    name: function () {
                        return menus[ns + "ImportMenu"];
                    },
                    href: function () {
                        return routes[n + "Route"] + "/import"
                    },
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    Icon: ArticleOutlined,
                    disableParentRoute: true,
                    order: 11,
                },
                {
                    Element: ()=>{

                        const context = useContext(WappContext);
                        const utils = getUtils(context);
                        const name = "product";

                        const NewFile = useMemo(() => postTypesConfig["file"] && postTypesConfig["file"].getPages ? postTypesConfig["file"].getPages().new : null, []);

                        const [snackMessage, setSnackMessage] = useState("");

                        const handleCloseSnackbar = function (/*e, reason*/) {
                            if (snackMessage) {
                                setSnackMessage("")
                            }
                        };

                        return (
                            <ListItem
                                style={{cursor: 'pointer'}}
                                onClick={async (e) => {
                                    return null;
                                }}
                            >
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    open={!!(snackMessage)}
                                    autoHideDuration={6000}
                                    onClose={handleCloseSnackbar}
                                    message={snackMessage}
                                />
                                <NewFile
                                    buttonInheritStyle={true}
                                    buttonIconListStyle={true}
                                    request={async function ({filesArray}) {

                                        const filesLength = filesArray.length;

                                        if (!filesLength) {
                                            return;
                                        }

                                        const requestProps = {
                                            requestName: name + "ImportExcel",
                                            args: {
                                                upload: null
                                            },
                                            multipart: true,
                                            callbackMultipartFormData: function ({formData}) {
                                                const map = JSON.stringify((filesLength) ? filesArray.reduce((o, file, i) => {
                                                    const t = "variables.upload";
                                                    o[(i)] = [t];
                                                    return o;
                                                }, {}) : {});
                                                formData.append("map", map);
                                                if (filesLength) {
                                                    filesArray.forEach((file, i) => {
                                                        formData.append((i).toString(), file, file.title);
                                                    })
                                                }
                                            },
                                            formatBeforeRequest: function (url, options) {
                                                delete options.headers["Content-Type"];
                                                return [url, options];
                                            },
                                            fetch: function (url, options = {}) {
                                                return new Promise((response, reject) => {
                                                    let xhr = new XMLHttpRequest();
                                                    const {onProgress, ...restOptions} = options;
                                                    xhr.open(restOptions.method || "get", url);
                                                    for (let k in restOptions.headers || {}) {
                                                        xhr.setRequestHeader(k, restOptions.headers[k]);
                                                    }
                                                    xhr.responseType = "json";
                                                    xhr.onload = () => response(xhr.response);
                                                    xhr.onerror = reject;
                                                    if (xhr.upload && onProgress) {
                                                        xhr.upload.onprogress = onProgress;
                                                    }
                                                    xhr.send(options.body);
                                                });
                                            },
                                            redirect: null,
                                        };

                                        const response = await utils.sendRequest(requestProps);

                                        base64ToDownload({base64: response.base64forRestore});

                                        return response;


                                    }}
                                    name={name}
                                    onSuccess={async ({files}) => {
                                        const newFiles = files && files.length && files.filter((file) => file && file.workbook);
                                        if (newFiles.length) {
                                            console.log(newFiles)
                                        }
                                    }}
                                    dropZone={false}
                                    multiple={false}
                                    accept={"application/vnd.ms-excel"}
                                    label={menus[ns + "ServerImportMenu"]}
                                    successOpenInNew={false}
                                />
                            </ListItem>
                        )
                    },
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    order: 11,
                },
                {
                    Element: () => <ExportButton label={menus[ns + "ExportMenu"]} name={n}/>,
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    order: 11,
                },
                {
                    Element: () => <ExportFromDistributorButton label={menus[ns + "ExportFromDistributorMenu"]} name={n}/>,
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    order: 11,
                },
                {
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    divider: true,
                    order: 12,
                },
                {
                    name: function (p) {
                        const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                        return (isAuthor) ? menus["my" + Ns + "Menu"] : menus[postContext.name + Ns + "Menu"];
                    },
                    href: function (p) {
                        return (p?.post?._id) ? "/" + p.post._id + routes[postContext.name + Ns + "Route"] : routes[postContext.name + Ns + "Route"];
                    },
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (!isAuthor && p.post && p.post._status_isFeatured);
                    },
                    Icon: ToysIcon,
                    order: 13,
                },
            ]
        }

        function getPageName({user, post, page, /*statusManager*/}) {

            const isAdmin = user && user._status_isFeatured;
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id));
            const isAuthorOrAdmin = (isAdmin || isAuthor);
            const authorIsAdmin = post && post._status_isFeatured;

            return ((page === ns && !pageType) || (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) || (page === ns && pageType === "deleted" && isAuthorOrAdmin)) ? (isAdmin || authorIsAdmin) ? page : "notFound" : null;

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function (p) {
        const {appContext} = p;
        const {menus, routes} = appContext;

        const name = "product";
        const N = capitalize(name);
        const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
        const Ns = capitalize(ns);

        return {
            name: menus[ns + "Menu"],
            Icon: ToysIcon,
            order: 1,
            items: [
                {
                    name: function () {
                        return menus[ns + "Menu"];
                    },
                    href: function () {
                        return routes[ns + "Route"] + "/limit/100";
                    },
                    Icon: ToysOutlined,
                },
                {
                    name: menus["new" + N + "Menu"],
                    href: routes[name + "Route"] + "/new",
                    Icon: AddIcon,
                },
                {
                    name: function () {
                        return menus[ns + "ImportMenu"];
                    },
                    href: function () {
                        return routes[name + "Route"] + "/import"
                    },
                    Icon: ArticleOutlined,
                },
                {
                    Element: () => <ExportButton label={menus[ns + "ExportMenu"]} name={name}/>,
                },
                {
                    Element: () => <ExportFromDistributorButton label={menus[ns + "ExportFromDistributorMenu"]} name={name}/>,
                },
                {divider: true},
                {
                    name: function () {
                        return menus["deleted" + Ns + "Menu"];
                    },
                    href: function () {
                        return routes[ns + "Route"] + "/deleted" + "/limit/100";
                    },
                    Icon: DeleteIcon,
                },
                {
                    name: function () {
                        return menus["banned" + Ns + "Menu"];
                    },
                    href: function () {
                        return routes[ns + "Route"] + "/banned" + "/limit/100";
                    },
                    Icon: BlockIcon,
                },
                {
                    name: function () {
                        return menus["protection" + Ns + "Menu"];
                    },
                    href: function () {
                        return routes[ns + "Route"] + "/protected" + "/limit/100";
                    },
                    Icon: StarIcon,
                },
                {
                    name: function () {
                        return menus["missingData" + Ns + "Menu"];
                    },
                    href: function () {
                        return routes[ns + "Route"] + "/missingdata" + "/limit/100";
                    },
                    Icon: ShortTextIcon,
                },
                {
                    name: function () {
                        return menus["lowAuthorStatus" + Ns + "Menu"];
                    },
                    href: function () {
                        return routes[ns + "Route"] + "/lowauthorstatus" + "/limit/100";
                    },
                    Icon: MoodBadIcon,
                },
            ]
        };

    }
};

export default postTypeConfig;
