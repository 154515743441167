import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "order";

    const n = name;
    const N = capitalize(n);
    const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
    const Ns = capitalize(ns);

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels,
            ["new" + N + "SubmitLabel"]: "Megrendelem",
            ["restore" + N + "SubmitLabel"]: "Visszaállítás",
            ["save" + N + "SubmitLabel"]: "Mentés",
            ["cancel" + N + "Text"]: "Mégsem",
            ["continuousShopping" + N + "Text"]: "Tovább vásárolok",
            ["checkout" + N + "Text"]: "Rendelés véglegesítése",
            ["delete" + N + "Text"]: "Törlés",
            ["delete" + Ns + "Text"]: "Rendelések törlése",
            ["ban" + N + "Text"]: "Tiltás",
            ["approve" + N + "Text"]: "Jóváhagyás",
            ["protection" + N + "Text"]: "Védettnek jelölés",
            ["removeProtection" + N + "Text"]: "Védettség megszüntetése",
            ["select" + N + "Text"]: "Kiválasztás",
            ["add" + N + "Text"]: "Hozzáadás",
            ["date" + N + "Format"]: ({dateText = ""}) => {
                if (!dateText) {
                    return dateText;
                }
                try {
                    return new Date(dateText).toLocaleString("hu-HU");
                } catch (e) {
                }
                return dateText;
            },
            [ns + "SortRelevance"]: "Relevancia",
            [n + "TotalText"]: ({total}) => (total) ? "" + Math.round(total) + " Ft" : "0 Ft",
            [n + "ProductTitleLabel"]: "Termék",
            [n + "QuantityLabel"]: "Mennyiség",
            [n + "TotalTextLabel"]: "Összesen",
            [n + "CartBoxLabel"]: "Kosár",
            [n + "CartSummaryLabel"]: "Termékek ára összesen",
            [n + "NetPriceLabel"]: "Nettó ár",
            [n + "BrPriceLabel"]: "Bruttó ár",
            [n + "SummaryLabel"]: "Összesen",
            [n + "SummaryBoxLabel"]: "Összesítő",

            [n + "ContainerLabel_record_company"]: "Számlázási adatok",
            [n + "ContainerLabel_record_shipping"]: "Szállítási adatok",
            [n + "ContainerLabel_record_payments"]: "Fizetések",
            [n + "ContentOrderData"]: "Rendelés adatai",

            [n + "CartUpdateCartLabel"]: "Kosár frissítése",
            [n + "SummaryProducts"]: "Termékek",
            [n + "OtherFees"]: "Egyéb díjak"
        },
        menus: {
            ...menus,
            ["user" + Ns + "Menu"]: "Rendelések",
            ["my" + Ns + "Menu"]: "Rendeléseim",
            ["new" + N + "Menu"]: "Új rendelés",
            [ns + "Menu"]: "Rendelések",
            ["deleted" + Ns + "Menu"]: "Törölt rendelések",
            ["banned" + Ns + "Menu"]: "Véglegesen törölt rendelések",
            ["protection" + Ns + "Menu"]: "Védett rendelések",
            ["missingData" + Ns + "Menu"]: "Hiányzó adatok",
            ["lowAuthorStatus" + Ns + "Menu"]: "Szerző alacsony státusszal",

            ["edit" + N + "Menu"]: "Szerkesztés",
            ["delete" + N + "Menu"]: "Törlés",
            ["ban" + N + "Menu"]: "Tiltás",
            ["approve" + N + "Menu"]: "Jóváhagyás",
            ["protection" + N + "Menu"]: "Védettnek jelölés",
            ["removeProtection" + N + "Menu"]: "Védettség törlése",
        },
        messages: {
            ...messages,
            ["new" + N + "SuccessMessage"]: "Az új rendelés mentésre került",
            ["save" + N + "SuccessMessage"]: "A rendelés mentésre került",
            ["delete" + N + "Success"]: "A rendelés törölve lett",
            ["delete" + Ns + "Success"]: "A rendelések törölve lettek",
            ["delete" + N + "Question"]: "Biztosan törli a rendelést?",
            ["delete" + Ns + "Question"]: "Biztosan törli a rendelésekat?",
            ["ban" + N + "Success"]: "A rendelés véglegesen törölve lett",
            ["ban" + N + "Question"]: "Biztos, hogy a rendelést véglegesen törölni akarod?",
            ["approve" + N + "Success"]: "A rendelés jóvá lett hagyva",
            ["approve" + N + "Question"]: "Biztosan jóváhagyod a rendelést",
            ["protection" + N + "Success"]: "A rendelés védettnek jelölve",
            ["protection" + N + "Question"]: "Biztosan védettnek jelölöd a rendelést?",
            ["removeProtection" + N + "Success"]: "A védettnek jelölés törölve lett",
            ["removeProtection" + N + "Question"]: "Biztosan törlöd a védettnek jelölést?",
            ["thereAreNo" + Ns]: "Nincsenek rendelések",
            [n + "EmptyCart"]: "A kosár üres"
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
            [n + "Title"]: "Rendelés",
            [ns + "Title"]: "Rendelések",
            ["new" + N + "Title"]: "Rendelés véglegesítése",
            ["edit" + N + "Title"]: "Rendelés szerkesztése",
            ["user" + Ns + "Title"]: "Rendelések",
            ["userDeleted" + Ns + "Title"]: "Törölt rendelések",
            ["my" + Ns + "Title"]: "Rendeléseim",
            ["myDeleted" + Ns + "Title"]: "Törölt rendeléseim",

            ["dialogDelete" + N + "Title"]: "Rendelés törlése",
            ["dialogBan" + N + "Title"]: "Rendelés törlése véglegesen",
            ["dialogApprove" + N + "Title"]: "Rendelés jóváhagyása",
            ["dialogProtection" + N + "Title"]: "Rendelés védettnek jelölése",
            ["dialogRemoveProtection" + N + "Title"]: "Rendelés védettségének megszüntetése",

            ["dialogDelete" + Ns + "Title"]: "Rendelések törlése",

            ["statusBanned" + N + "Title"]: "Véglegesen törölt",
            ["statusDeleted" + N + "Title"]: "Törölt",
            ["statusAuthorDeleted" + N + "Title"]: "Szerző törölve",
            ["statusMissingData" + N + "Title"]: "Hiányzó adatok",
            ["statusApproved" + N + "Title"]: "Jóváhagyott",
            ["statusFeatured" + N + "Title"]: "Védett",
            ["statusCreated" + N + "Title"]: "Létrehozva",

            ["banned" + Ns + "Title"]: "Véglegesen törölt rendelések",
            ["deleted" + Ns + "Title"]: "Törölt rendelések",
            ["missingData" + Ns + "Title"]: "Hiányzó adatok",
            ["protected" + Ns + "Title"]: "Védett rendelések",
            ["lowAuthorStatus" + Ns + "Title"]: "Szerző alacsony státusszal",

            ["select" + Ns]: "Rendelések kiválasztása",
            ["select" + N]: "Rendelés kiválasztása"
        }
    }
}
