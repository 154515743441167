import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "user";

    const n = name;
    const N = capitalize(n);
    const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
    const Ns = capitalize(ns);

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels,

            ["new" + N + "SubmitLabel"]: "Mentés",
            ["restore" + N + "SubmitLabel"]: "Visszaállítás",
            ["save" + N + "SubmitLabel"]: "Mentés",
            ["cancel" + N + "Text"]: "Mégsem",
            ["delete" + N + "Text"]: "Törlés",
            ["delete" + Ns + "Text"]: "Felhasználók törlése",
            ["ban" + N + "Text"]: "Tiltás",
            ["approve" + N + "Text"]: "Jóváhagyás",
            ["protection" + N + "Text"]: "Adminnak jelölés",
            ["removeProtection" + N + "Text"]: "Admin jog elvétele",
            ["select" + N + "Text"]: "Kiválasztás",
            ["add" + N + "Text"]: "Hozzáadás",
            ["date" + N + "Format"]: ({dateText = ""}) => {
                if (!dateText) {
                    return dateText;
                }
                try {
                    return new Date(dateText).toLocaleString("hu-HU");
                } catch (e) {
                }
                return dateText;
            },
            [ns + "SortRelevance"]: "Relevancia",

            [n + "ContainerLabel_record_name"]: "Név",
            [n + "ContainerLabel_record_company"]: "Számlázási adatok",
            [n + "ContainerLabel_record_shipping"]: "Szállítási adatok",

            loginSubmitLabel: "Belépés",
            logoutSubmitLabel: "Kilépés",
            signupSubmitLabel: "Regisztráció",
            forgotPasswordSubmitLabel: "Email küldése",
            resetPasswordSubmitLabel: "Jelszó visszaállítása",
            changeEmailSubmitLabel: "Email megváltoztatása",
            changePasswordSubmitLabel: "Jelszó megváltozatása",
            emailConfirmationSubmitLabel: "Email megerősítése",
            deleteAccountLabel: "Fiók törlése",
            emailConfirmationSendAgainSubmitLabel: "Megerősítő kulcs újraküldése",
            acceptTermsLabel: "ÁSZF jóváhagyása",
            acceptPrivacyLabel: "Adatvédelem jóváhagyása",
            termsWord: "ÁSZF",
            privacyWord: "Adatvédelem"
        },
        menus: {
            ...menus,
            ["user" + Ns + "Menu"]: "Felhasználók",
            ["my" + Ns + "Menu"]: "Felhasználóim",
            ["new" + N + "Menu"]: "Új felhasználó",
            [ns + "Menu"]: "Felhasználók",
            ["deleted" + Ns + "Menu"]: "Törölt felhasználók",
            ["banned" + Ns + "Menu"]: "Tiltott felhasználók",
            ["protection" + Ns + "Menu"]: "Adminok",
            ["missingData" + Ns + "Menu"]: "Hiányzó adatok",
            ["lowAuthorStatus" + Ns + "Menu"]: "",

            ["edit" + N + "Menu"]: "Szerkesztés",
            ["delete" + N + "Menu"]: "Törlés",
            ["ban" + N + "Menu"]: "Tiltás",
            ["approve" + N + "Menu"]: "Jóváhagyás",
            ["protection" + N + "Menu"]: "Adminnak jelölés",
            ["removeProtection" + N + "Menu"]: "Admin jog törlése",

            loginMenu: "Belépés",
            signupMenu: "Regisztráció",
            changeData: "Adatok módosítása",
            changeEmail: "Email módosítása",
            emailConfirmation: "Email megerősítése",
            changePassword: "Jelszó megváltoztatása",
            forgotPasswordMenu: "Elfelejtett jelszó",
            deleteAccount: "Fiók törlése",
            logoutMenu: "Kilépés",

            accountSettingsMenu: "Fiók beállítások",
            dashboardMenu: "Fiók",
            userProfileMenu: "Profil",
        },
        messages: {
            ...messages,

            ["new" + N + "SuccessMessage"]: "Az új felhasználó mentésre került",
            ["save" + N + "SuccessMessage"]: "A felhasználó mentésre került",
            ["delete" + N + "Success"]: "A felhasználó törölve lett",
            ["delete" + Ns + "Success"]: "A felhasználók törölve lettek",
            ["delete" + N + "Question"]: "Biztosan törli a felhasználót?",
            ["delete" + Ns + "Question"]: "Biztosan törli a felhasználókat?",
            ["ban" + N + "Success"]: "A felhasználó le lett tiltva",
            ["ban" + N + "Question"]: "Biztos, hogy a felhasználót tiltani akarod?",
            ["approve" + N + "Success"]: "A felhasználó jóvá lett hagyva",
            ["approve" + N + "Question"]: "Biztosan jóváhagyod a felhasználót?",
            ["protection" + N + "Success"]: "A felhasználó adminnak jelölve",
            ["protection" + N + "Question"]: "Biztosan adminnak jelölöd a felhasználót?",
            ["removeProtection" + N + "Success"]: "Az admin jog törölve lett",
            ["removeProtection" + N + "Question"]: "Biztosan törlöd az admin jogot?",
            ["thereAreNo" + Ns]: "Nincsenek felhasználók",

            forgotPasswordSuccessMessage: "Az üzenet el lett küldve az email címedre",
            resetPasswordSuccessMessage: "A jelszó frissítve lett",
            changePasswordSuccessMessage: "A jelszó frissítve lett",
            changeEmailSuccessMessage: "Az email mentésre került",
            changeDataSuccessMessage: "Az adatai mentésre kerültek",
            emailConfirmationSuccessMessage: "Az email cím megerősítésre került",
            emailConfirmationSendAgainSuccessMessage: "Az email megerősítő kulcs újra el lett küldve",
            deleteAccountSuccessMessage: "A fiók törölve lett",
            deleteAccountQuestion: "Biztosan törlöd a fiókot?",
            deleteAccountSuccess: "A fiók törölve lett",
        },
        routes: {
            ...routes,
            ["user" + Ns + "Route"]: "/" + ns,
            [n + "Route"]: "/" + n,
            [ns + "Route"]: "/" + ns,
        },
        titles: {
            ...titles,
            [n + "Title"]: "Felhasználó",
            [ns + "Title"]: "Felhasználók",
            ["new" + N + "Title"]: "Új felhasználó",
            ["edit" + N + "Title"]: "Felhasználó szerkesztése",
            ["user" + Ns + "Title"]: "Felhasználók",
            ["userDeleted" + Ns + "Title"]: "Törölt felhasználók",
            ["my" + Ns + "Title"]: "Felhasználóim",
            ["myDeleted" + Ns + "Title"]: "Törölt felhasználóim",

            ["dialogDelete" + N + "Title"]: "Felhasználó törlése",
            ["dialogBan" + N + "Title"]: "Felhasználó tiltása",
            ["dialogApprove" + N + "Title"]: "Felhasználó jóváhagyása",
            ["dialogProtection" + N + "Title"]: "Felhasználó adminnak jelölése",
            ["dialogRemoveProtection" + N + "Title"]: "Felhasználó admin jogának megszüntetése",

            ["dialogDelete" + Ns + "Title"]: "Felhasználók törlése",

            ["statusBanned" + N + "Title"]: "Tiltva",
            ["statusDeleted" + N + "Title"]: "Törölt",
            ["statusAuthorDeleted" + N + "Title"]: "Szerző törölve",
            ["statusMissingData" + N + "Title"]: "Hiányzó adatok",
            ["statusApproved" + N + "Title"]: "Jóváhagyott",
            ["statusFeatured" + N + "Title"]: "Admin",
            ["statusCreated" + N + "Title"]: "Regisztrált",

            ["banned" + Ns + "Title"]: "Véglegesen törölt felhasználók",
            ["deleted" + Ns + "Title"]: "Törölt felhasználók",
            ["missingData" + Ns + "Title"]: "Hiányzó adatok",
            ["protected" + Ns + "Title"]: "Adminok",
            ["lowAuthorStatus" + Ns + "Title"]: "",

            ["select" + Ns]: "Felhasználók kiválasztása",
            ["select" + N]: "Felhasználó kiválasztása",

            ["get" + N + "Description"]: (name) => name + " oldala",

            loginTitle: "Belépés",
            accountTitle: "Fiók",
            changeDataTitle: "Adatok módosítása",
            changeEmailTitle: "Email módosítása",
            emailConfirmationTitle: "Email megerősítése",
            signupTitle: "Regisztráció",
            logoutTitle: "Kilépés",
            forgotPasswordTitle: "Elfelejtett jelszó",
            resetPasswordTitle: "Jelszó visszaállítása",
            changePasswordTitle: "Jelszó módosítása",
            deleteAccountTitle: "Fiók törlése",

            dialogDeleteAccountTitle: "Fiók törlése",
            userProfileTitle: "Profil",
            dashboardTitle: "Vezérlőpult",
            acceptTermsTitle: "ÁSZF jóváhagyása",
            acceptPrivacyTitle: "Adatvédelem jóváhagyása"
        }
    };
}
