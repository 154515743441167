// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OPNKs pre{background-color:#303338 !important}@media(prefers-color-scheme: dark){.OPNKs pre{background:rgba(0, 0, 0, 0.14) !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "OPNKs"
};
module.exports = ___CSS_LOADER_EXPORT___;
