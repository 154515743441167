const defaultStorageName = "konyvesjatek-webshop";

export function storage(newData = {}, storageName = defaultStorageName) {
    if (typeof window !== "undefined") {
        try {
            const currentData = JSON.parse(window.localStorage.getItem(storageName) || JSON.stringify({}));
            const tempCurrentDataString = JSON.stringify(currentData);
            Object.keys(newData).forEach(function (key) {
                currentData[key] = newData[key];
            });
            if (tempCurrentDataString !== JSON.stringify(currentData)) {
                window.localStorage.setItem(storageName, JSON.stringify(currentData));
            }
            return currentData;
        } catch (e) {
            return newData;
        }
    }
    return newData;
}
