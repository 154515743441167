// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IqJDp{position:fixed;width:100%;height:100%;background-color:rgba(0,0,0,.231372549);left:0;top:0;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;z-index:1}.SHGNG{font-weight:400;font-size:12px;font-size:.75rem;line-height:1.66;letter-spacing:.03333em;text-align:left;padding:8px;color:var(--error-color)}.HcuF6{margin-bottom:16px}.oPtnG{overflow-y:auto;width:100%}.LVu3S._4PoFv{background-color:var(--secondary-bg);color:var(--secondary-color)}.LVu3S._4PoFv>*{color:var(--secondary-color)}.LVu3S._4PoFv>*>button{background-color:transparent;color:var(--secondary-color)}.LVu3S>td{min-width:114px;position:relative;padding:8px;font-size:12px}.LVu3S>td:last-child{padding-right:16px}.LVu3S>td:first-child{padding-left:16px}.FesC-{white-space:nowrap}.iF7Qq{padding:8px;border-style:solid;border-width:1px;border-radius:4px;border-color:var(--border-color);min-height:32px;cursor:text;max-width:150px;font-size:12px;line-height:normal;overflow-wrap:break-word;color:var(--font-color)}.G6btl{background-size:cover;background-repeat:no-repeat;background-position:50%;width:50px;height:50px;border-radius:50%;overflow:hidden}.LVu3S._4PoFv .iF7Qq{cursor:default}.iF7Qq.SHGNG{border-color:var(--error-color)}._1wZra{margin:0;padding:8px;font-size:12px;line-height:normal}._1wZra.SHGNG{color:var(--error-color)}.AKCaB{min-width:40px}.AKCaB>div{padding:8px;max-width:150px}.AKCaB textarea{font-size:12px;line-height:normal}.VrDWB{min-width:100px}.mIdQR{min-width:60px}@media(max-width: 799px){.hgcse{min-height:100%}.hgcse{border-radius:0;max-width:100%;width:100%;margin:0;max-height:100%}.Umqv9{padding:16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": "IqJDp",
	"error": "SHGNG",
	"closeContainer": "HcuF6",
	"tableContainer": "oPtnG",
	"tableRow": "LVu3S",
	"savedRow": "_4PoFv",
	"saveCell": "FesC-",
	"valueText": "iF7Qq",
	"coverImage": "G6btl",
	"helperText": "_1wZra",
	"inputText": "AKCaB",
	"title": "VrDWB",
	"price": "mIdQR",
	"dialogPaper": "hgcse",
	"dialogContent": "Umqv9"
};
module.exports = ___CSS_LOADER_EXPORT___;
