import React, {useContext, useEffect, useMemo, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";

import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import clsx from "clsx";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";

import AppContext from "../App/context";
import {withMaterialStyles} from "../Template/withMaterial";
import Dialog from "../Dialog";

import materialStyle from "./materialStyle";
import style from "./style.css";
import {runPostTypesConfigSync} from "../../postTypes";

function Cookies(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);

    const {wapp} = context;
    wapp.styles.use(style);

    const utils = getUtils(context);
    const {subscribe/*, materialStyle*/} = props;

    const {documentName = "document", cookiesSlug = "cookies", cookiesPropertyName = "acceptCookies"} = props;

    const {storage} = appContext;

    const initialState = (typeof window !== "undefined" && window[wapp.config.appStateName]) || {req: {timestamp: Date.now()}};
    const firstRender = (utils.getGlobalState("req.timestamp") === initialState.req.timestamp || wapp.target === "node");

    const [user, setUser] = useState(utils.getRequestUser());
    const [acceptCookies, setAcceptCookies] = useState(user?._id ? true : firstRender ? false : storage()[cookiesPropertyName] || false);
    const [show, setShow] = useState(firstRender ? false : !acceptCookies);

    const dialog = useMemo(() => {
        return {}
    }, []);
    const dialogEffect = useMemo(() => ({actions}) => {
        dialog.actions = actions;
    }, [dialog]);

    function onUserChange(user) {
        setUser((user?._id) ? user : null);
    }

    useEffect(function () {
        const unsub = subscribe.userChange(onUserChange);
        return function useUnsubscribe() {
            unsub();
        }
    }, [subscribe, user]);

    useEffect(function () {
        const newAcceptCookies = user?._id ? true : storage()[cookiesPropertyName] || false;
        if (newAcceptCookies !== acceptCookies) {
            setAcceptCookies(newAcceptCookies);
            setShow(!newAcceptCookies);
        } else if (!newAcceptCookies !== show) {
            setShow(!newAcceptCookies);
        }
    }, [acceptCookies, cookiesPropertyName, show, storage, user?._id]);

    const onAccept = useMemo(() => async function onAccept(e) {
        e.preventDefault();
        storage({[cookiesPropertyName]: true});
        await setAcceptCookies(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        await setShow(true);
    }, [cookiesPropertyName, storage]);

    const replace = useMemo(() => function replace(props) {
        const {text, word, href, onClick} = props;
        const textA = (typeof text === "string") ? [text] : text;
        return textA.map((text) => {
            if (typeof text === "string" && text.match(word)) {
                return [
                    text.split(word)[0],
                    <span><a href={href} onClick={onClick}>{word}</a></span>,
                    text.split(word)[1]
                ]
            }
            return [text];
        }).reduce((a, value) => {
            a.push(...value);
            return a
        }, [])
    }, []);

    const cookiesUrl = (documentName && appContext.routes[documentName + "Route"] && cookiesSlug) ? appContext.routes[documentName + "Route"] + "/" + cookiesSlug : "";

    const openCookies = useMemo(() => (e, slug, propertyName) => {
        e.preventDefault();
        if (dialog.actions) {

            function DialogContent({slug}) {

                const context = useContext(WappContext);
                const {wapp, req, res} = context;

                const requestName = documentName + "FindBySlug";
                const posts = [utils.getGlobalState("res.responses." + documentName + "FindById"), utils.getGlobalState("res.responses." + requestName)];
                const initialPost = posts.find((p) => p?.slug === slug);

                const [response, setResponse] = useState(initialPost ? {[requestName]: initialPost} : null);

                const post = useMemo(() => response && response[requestName]?._id ? response[requestName] : null, [requestName, response]);

                const path = useMemo(() => (appContext.routes[documentName + "Route"] && slug) ? appContext.routes[documentName + "Route"] + "/" + slug : null, [slug]);

                const newContext = useMemo(() => {
                    return (path && slug) ?
                        {
                            wapp,
                            req: {
                                user: req.user,
                                wappRequest: {
                                    user: req.wappRequest.user
                                }
                            },
                            res: {
                                wappResponse: {
                                    route: {
                                        requestPath: path,
                                        params: {_id: slug}
                                    },
                                    store: res.wappResponse.store,
                                    status: res.wappResponse.status
                                }
                            }
                        } : context;
                }, [path, slug, wapp, req.user, req.wappRequest.user, res.wappResponse.store, res.wappResponse.status, context]);

                const PostTypesComponent = (path) ? runPostTypesConfigSync({
                    action: "getComponent",
                    p: {context: newContext, appContext}
                }).filter((C) => !!(C))[0] : null;

                const content = useMemo(() => wapp.contents.get(documentName), [wapp.contents]);

                const request = useMemo(() => async function request({wapp, req, res}) {
                    if (content && content.request) {
                        const response = await content.request({wapp, req, res});
                        await setResponse(response)
                    }
                }, [content]);

                useEffect(() => {
                    if (path && !post?._id) {
                        request(newContext);
                    }
                }, [path, request, newContext, post]);

                return (
                    <WappContext.Provider value={newContext}>
                        <div>
                            {(post && PostTypesComponent) ? <PostTypesComponent post={post}/> : null}
                        </div>
                    </WappContext.Provider>
                )
            }

            dialog.actions.open({
                dialogTitle: appContext.titles[propertyName + "Title"],
                dialogContent: <>
                    <DialogContent slug={slug}/>
                </>,
                cancelText: appContext.labels[propertyName + "Close"],
                submitText: appContext.labels[propertyName + "Label"],
                onCancel: async (e) => {
                    e.preventDefault();
                    await dialog.actions.close();
                },
                onSubmit: async (e) => {
                    e.preventDefault();
                    await dialog.actions.close();
                    await onAccept(e);
                },
                dialogContentClassName: style.dialogContent
            })

        }
    }, [dialog.actions, appContext, documentName, utils, onAccept]);

    if (user?._id) {
        return null;
    }

    return (
        <div className={clsx(
            style.cookies,
            {[style.show]: show}
        )}>
            <span>
                {
                    replace({
                        text: appContext.messages.cookieShortDesc,
                        onClick: (e) => openCookies(e, cookiesSlug, cookiesPropertyName),
                        href: cookiesUrl,
                        word: "cookies"
                    }).map((value, i) => <React.Fragment key={i}>{value}</React.Fragment>)
                }
            </span>
            <span>
                <Button
                    className={style.acceptButton}
                    variant={"contained"}
                    color={"secondary"}
                    onClick={onAccept}
                    startIcon={(acceptCookies) ? <DoneIcon/> : null}
                    disabled={acceptCookies}
                >
                        {appContext.labels[cookiesPropertyName + "Label"]}
                    </Button>
            </span>
            <Dialog
                fullWidth
                maxWidth={"md"}
                effect={dialogEffect}
                className={style.dialog}
                paperClassName={style.dialogPaper}
            />
        </div>
    )
}

const WappComponent = withWapp(Cookies);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
