export const materialTheme = function (p = {}) {

    const {mediaQuery = {}} = p;
    //const prefersDarkMode = mediaQuery["(prefers-color-scheme: dark)"];
    const printMode = mediaQuery["print"];

    return {
        colorSchemes: {
            light: {
                palette: {
                    primary: {
                        main: "#000000",
                    },
                    secondary: {
                        main: "#000000",
                    },
                    background: {
                        paper: "#ffffff",
                    },
                    error: {
                        main: "rgba(244, 67, 54, 1)"
                    }
                }
            },
            dark: {
                palette: {
                    primary: {
                        main: (!printMode) ? "#ffffff" : "#000000",
                    },
                    secondary: {
                        main: (!printMode) ? "#ffffff" : "#000000",
                    },
                    background: {
                        paper: (!printMode) ? "#303338" : "#ffffff",
                    },
                    error: {
                        main: "rgba(244, 67, 54, 1)"
                    }
                }
            }
        }
    }
};

export const materialMediaQuery = ["(prefers-color-scheme: dark)", "print"];
