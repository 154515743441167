import commonPostTypeConfig from "../../common/postTypes/product";

export default function initPostType({wapp}) {
    const postType = wapp.client.postTypes.getPostType({
        name: "product",
        addIfThereIsNot: true,
        statusManager: commonPostTypeConfig.getStatusManager(),
    });

    postType.subscribeUpdateFindById({addKeys: ["productUpdateFromDistributor", "productUpdateAvailableFromWholesale", "productUpdateCheapestPrice"]})

    return postType;
}
