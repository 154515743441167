import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "age";

    const n = name;
    const N = capitalize(n);
    const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
    const Ns = capitalize(ns);

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels,
            ["new" + N + "SubmitLabel"]: "Mentés",
            ["restore" + N + "SubmitLabel"]: "Visszaállítás",
            ["save" + N + "SubmitLabel"]: "Mentés",
            ["cancel" + N + "Text"]: "Mégsem",
            ["delete" + N + "Text"]: "Törlés",
            ["delete" + Ns + "Text"]: "Korcsoportok törlése",
            ["ban" + N + "Text"]: "Tiltás",
            ["approve" + N + "Text"]: "Jóváhagyás",
            ["protection" + N + "Text"]: "Védettnek jelölés",
            ["removeProtection" + N + "Text"]: "Védettség megszüntetése",
            ["select" + N + "Text"]: "Kiválasztás",
            ["add" + N + "Text"]: "Hozzáadás",
            ["date" + N + "Format"]: ({dateText = ""}) => {
                if (!dateText) {
                    return dateText;
                }
                try {
                    return new Date(dateText).toLocaleString("hu-HU");
                } catch (e) {
                }
                return dateText;
            },
            [ns + "SortRelevance"]: "Relevancia",
        },
        menus: {
            ...menus,
            ["user" + Ns + "Menu"]: "Korcsoportok",
            ["my" + Ns + "Menu"]: "Korcsoportjaim",
            ["new" + N + "Menu"]: "Új korcsoport",
            [ns + "Menu"]: "Korcsoportok",
            ["deleted" + Ns + "Menu"]: "Törölt korcsoportok",
            ["banned" + Ns + "Menu"]: "Véglegesen törölt korcsoportok",
            ["protection" + Ns + "Menu"]: "Védett korcsoportok",
            ["missingData" + Ns + "Menu"]: "Hiányzó adatok",
            ["lowAuthorStatus" + Ns + "Menu"]: "Szerző alacsony státusszal",

            ["edit" + N + "Menu"]: "Szerkesztés",
            ["delete" + N + "Menu"]: "Törlés",
            ["ban" + N + "Menu"]: "Tiltás",
            ["approve" + N + "Menu"]: "Jóváhagyás",
            ["protection" + N + "Menu"]: "Védettnek jelölés",
            ["removeProtection" + N + "Menu"]: "Védettség törlése",
        },
        messages: {
            ...messages,
            ["new" + N + "SuccessMessage"]: "Az új korcsoport mentésre került",
            ["save" + N + "SuccessMessage"]: "A korcsoport mentésre került",
            ["delete" + N + "Success"]: "A korcsoport törölve lett",
            ["delete" + Ns + "Success"]: "A korcsoportok törölve lettek",
            ["delete" + N + "Question"]: "Biztosan törli a korcsoportot?",
            ["delete" + Ns + "Question"]: "Biztosan törli a korcsoportoket?",
            ["ban" + N + "Success"]: "A korcsoport véglegesen törölve lett",
            ["ban" + N + "Question"]: "Biztos, hogy a korcsoportot véglegesen törölni akarod?",
            ["approve" + N + "Success"]: "A korcsoport jóvá lett hagyva",
            ["approve" + N + "Question"]: "Biztosan jóváhagyod a korcsoportot",
            ["protection" + N + "Success"]: "A korcsoport védettnek jelölve",
            ["protection" + N + "Question"]: "Biztosan védettnek jelölöd a korcsoportot?",
            ["removeProtection" + N + "Success"]: "A védettnek jelölés törölve lett",
            ["removeProtection" + N + "Question"]: "Biztosan törlöd a védettnek jelölést?",
            ["thereAreNo" + Ns]: "Nincsenek korcsoportok"
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
            [n + "Title"]: "Korcsoport",
            [ns + "Title"]: "Korcsoportok",
            ["new" + N + "Title"]: "Új korcsoport",
            ["edit" + N + "Title"]: "Korcsoport szerkesztése",
            ["user" + Ns + "Title"]: "Korcsoportok",
            ["userDeleted" + Ns + "Title"]: "Törölt korcsoportok",
            ["my" + Ns + "Title"]: "Korcsoportjaim",
            ["myDeleted" + Ns + "Title"]: "Törölt korcsoportjaim",

            ["dialogDelete" + N + "Title"]: "Korcsoport törlése",
            ["dialogBan" + N + "Title"]: "Korcsoport törlése véglegesen",
            ["dialogApprove" + N + "Title"]: "Korcsoport jóváhagyása",
            ["dialogProtection" + N + "Title"]: "Korcsoport védettnek jelölése",
            ["dialogRemoveProtection" + N + "Title"]: "Korcsoport védettségének megszüntetése",

            ["dialogDelete" + Ns + "Title"]: "Korcsoportok törlése",

            ["statusBanned" + N + "Title"]: "Véglegesen törölt",
            ["statusDeleted" + N + "Title"]: "Törölt",
            ["statusAuthorDeleted" + N + "Title"]: "Szerző törölve",
            ["statusMissingData" + N + "Title"]: "Hiányzó adatok",
            ["statusApproved" + N + "Title"]: "Jóváhagyott",
            ["statusFeatured" + N + "Title"]: "Védett",
            ["statusCreated" + N + "Title"]: "Létrehozva",

            ["banned" + Ns + "Title"]: "Véglegesen törölt korcsoportok",
            ["deleted" + Ns + "Title"]: "Törölt korcsoportok",
            ["missingData" + Ns + "Title"]: "Hiányzó adatok",
            ["protected" + Ns + "Title"]: "Védett korcsoportok",
            ["lowAuthorStatus" + Ns + "Title"]: "Szerző alacsony státusszal",

            ["select" + Ns]: "Korcsoportok kiválasztása",
            ["select" + N]: "Korcsoport kiválasztása"
        }
    }
}
