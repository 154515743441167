import React, {useContext, useMemo, useRef} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../App/context";
import {withMaterialStyles} from "../../Template/withMaterial";

import Form, {defaultComponents} from "../../Form";
import PostContext from "../../Post/context";

import materialStyle from "./materialStyle";
import style from "./style.css";
import capitalize from "../../../utils/capitalize";
import {postTypesConfig} from "../../../postTypes";

function New(props) {

    const {initialValues = {}, formProps = {}, requestProps = {}} = props;

    const postContext = useContext(PostContext);

    const {name, user, parentRoute} = postContext;

    const N = capitalize(name);

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const utils = getUtils(context);

    //const {materialStyle} = props;

    const {wapp} = context;
    wapp.styles.use(style);

    async function onSubmit(e, formData) {
        return await utils.sendRequest({
            requestName: name + "New",
            args: formData,
            redirect: {pathname: parentRoute + "/:_id", search: "", hash: ""},
            timeOut: 1000, ...requestProps
        });
    }

    const authors = useMemo(() => ({actions: {}}), []);
    const formRef = useRef();

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation." + name + "New.formData");
    } catch (e) {
    }

    const formData = {
        ...formDataFromResolvers,
        submit: {
            label: appContext.labels["new" + N + "SubmitLabel"]
        }
    };

    if (initialValues && Object.keys(initialValues).length) {
        Object.keys(formData).forEach(function (key) {
            if (typeof initialValues[key] !== "undefined" && initialValues[key] !== null) {
                formData[key].value = initialValues[key];
            }
        })
    }

    if (formData["_author"]) {
        formData["_author"].componentName = "PostsAuthor";
    }

    Object.keys(formData).forEach((key) => {
        if ((formData[key].componentName?.startsWith("Posts") && formData[key].enableNew) || (formData[key].schemaType === "MongoID" && formData[key].enableNew)) {
            const refPostType = formData[key].refPostType;
            formData[key].NewComponent = postTypesConfig[refPostType] && postTypesConfig[refPostType].getPages ? postTypesConfig[refPostType].getPages().new : null;
        }
    });

    const fP = (typeof formProps == "function") ? formProps({formData}) : formProps;

    return (
        <Form
            formData={formData}
            onSubmit={onSubmit}
            successMessage={
                appContext.messages["new" + N + "SuccessMessage"]
            }
            {...fP}
            ref={(form) => {
                formRef.current = form;
                if (typeof fP.ref == "function") {
                    fP.ref(form);
                } else if (fP.ref) {
                    fP.ref.current = form;
                }
            }}
            onChange={async (e, key, formData) => {

                let shouldUpdate = false;

                if (user?._status_isFeatured && key === "_author" && formData._author && authors?.actions) {
                    const author = authors.actions.getPosts();
                    const selectedAuthor = author && author[0];
                    Object.keys(formData).forEach((fKey) => {
                        if (key !== fKey) {
                            if (formData[fKey].componentName?.startsWith("Posts") || formData[fKey].schemaType === "MongoID") {
                                formData[fKey].author = selectedAuthor;
                                shouldUpdate = true;
                            }
                        }
                    });
                }

                if (shouldUpdate) {
                    await formRef.current.setNewFormData(formData);
                }

                if (fP.onChange) {
                    await fP.onChange(e, key, formData)
                }
            }}
            components={{
                ...defaultComponents,
                ...(fP.components) ? fP.components : {},
                PostsAuthor: {
                    ...defaultComponents.Posts,
                    ...(fP.components?.PostsAuthor) ? fP.components?.PostsAuthor : {},
                    props: {
                        ...defaultComponents.Posts.props,
                        ...(fP.components?.PostsAuthor?.props) ? fP.components?.PostsAuthor.props : {},
                        effect: ({actions}) => {
                            authors.actions = actions;
                            if (fP.components?.PostsAuthor?.props?.effect) {
                                fP.components.PostsAuthor.props.effect({actions});
                            }
                        }
                    }
                },
            }}
        />
    )
}

const WappComponent = withWapp(New);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
