import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import SwipeableViewsWithoutMemo from "react-swipeable-views";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";

import VideoPlayer from "../VideoPlayer";

import {withMaterialStyles} from "../Template/withMaterial";

import materialStyle from "./materialStyle";
import style from "./style.css";
import clsx from "clsx";
import AppContext from "../App/context";

const SwipeableViews = React.memo(SwipeableViewsWithoutMemo, (pp, np) => pp.index === np.index);
const Image = React.memo((props) => <img alt={props.alt} {...props}/>, () => true);

function Points(props) {

    const [index, setIndex] = useState(props.index || 0);

    const gallery = props.gallery;

    const handleClick = (e, i) => {
        if (props.onClick) {
            props.onClick(i);
        } else {
            setIndex(i)
        }
    };

    useEffect(() => {
        if (props.index !== index) {
            setIndex(props.index);
        }
    }, [index, props.index]);

    const getPrevItemData = () => {
        const {enableLoop} = props;
        const prevItem = (gallery[index - 1] || !enableLoop) ? index - 1 : gallery.length - 1;
        return gallery[prevItem];
    };

    const getNextItemData = () => {
        const {enableLoop} = props;
        const nextItem = (gallery[index + 1] || !enableLoop) ? index + 1 : 0;
        return gallery[nextItem];
    };

    const getMarginLeftStyle = () => {
        const r = {};

        const dotWidth = 24;
        let marginLeft = (dotWidth * index) + (dotWidth / 2);

        r.marginLeft = "calc(50% - " + marginLeft + "px)";

        return r;
    };

    const prevItemData = getPrevItemData();
    const nextItemData = getNextItemData();
    const marginLeft = getMarginLeftStyle();

    const post = gallery[index];
    const isVideo = post?.fileType === "video" && post?.fileUrl;

    return (
        <>
            {(prevItemData || nextItemData) ?
                <div className={clsx(style.pagination, {[style.isVideo]: isVideo})} style={marginLeft}>
                    {(gallery.map(function (post, i) {
                        return <div
                            key={i}
                            className={clsx(
                                style.dot,
                                {[style.currentDot]: (i === index)},
                                {[style.nearDot]: (i + 1 === index || i - 1 === index)},
                            )}
                            onClick={function (e) {
                                handleClick(e, i)
                            }}
                        />
                    }))}
                </div>
                : null
            }
        </>
    )
}

function GalleryWithoutMemo(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);

    const {wapp} = context;

    wapp.styles.use(style);

    const {gallery = [], fixHeight} = props;

    const [index, setIndex] = useState((typeof props.index !== "undefined") ? props.index : (typeof props.initialIndex !== "undefined") ? props.initialIndex : 0);

    const handleChangeIndex = async (index) => {
        const current = refs.current[index];
        if (current?.pause) {
            current.pause();
        }
        await setIndex(index);
        if (props.onChange) {
            await props.onChange(index);
        }
    };

    useEffect(() => {
        if (props.effect) {
            props.effect({
                actions: {
                    getIndex: function () {
                        return index;
                    },
                    setIndex: async function (index) {
                        await setIndex(index);
                    }
                }
            });
            return function () {
                props.effect(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, props.effect]);

    useEffect(() => {
        if (typeof props.index == "number" && index !== props.index) {
            setIndex(props.index);
        }
    }, [index, props.index]);

    const refs = useRef({current: {}});

    const renderItemComponents = useMemo(() => props.renderItemComponents || function ({gallery, style}) {

        return gallery.filter((post) => (post?.fileUrl || post?.thumb)).map((post, i) => {
            const src = post.fileUrl || post.thumb;
            const isVideo = post.fileType === "video" && post.fileUrl;
            return (
                <div
                    key={i}
                    className={
                        clsx(
                            style.itemContainer,
                            {
                                [style.fixHeight]: fixHeight && i === 0,
                                [style.isVideo]: isVideo,
                            }
                        )
                    }
                >
                    {(isVideo) ?
                        <VideoPlayer
                            ref={(e) => {
                                refs.current[i] = e
                            }}
                            className={style.item}
                            src={src}
                            cover={post.thumb}
                            id={"video-file"}
                            appContext={appContext}
                            layerInteraction={"pause"}
                            autoplay={false}
                            title={post.title}

                            bigPlayButton={true}
                            simpleControls={true}
                            relative={false}
                            theme={"darkTheme"}
                            backgroundColor={"transparentBackground"}
                            bigPlayButtonTheme={"whitePlayButtonTheme"}

                        /> :
                        <Image
                            src={src}
                            className={style.item}
                            alt={post.content || "gallery item"}
                        />
                    }
                </div>
            )
        })
    }, [appContext, fixHeight, props.renderItemComponents]);

    const renderedItems = useMemo(() => renderItemComponents({gallery, style}), [gallery, renderItemComponents]);

    return (
        <div className={clsx(
            style.swipeableContainer,
            {[style.fixHeight]: fixHeight}
        )}>
            <SwipeableViews
                enableMouseEvents
                index={index}
                onChangeIndex={handleChangeIndex}
                disableLazyLoading={true}
            >
                {renderedItems}
            </SwipeableViews>
            <Points index={index} gallery={gallery} onClick={handleChangeIndex}/>
        </div>
    )
}

const Gallery = React.memo(
    GalleryWithoutMemo,
    (prevProps, nextProps) => (
        prevProps.gallery &&
        nextProps.gallery &&
        JSON.stringify(prevProps.gallery) === JSON.stringify(nextProps.gallery)
    )
);

const WappComponent = withWapp(Gallery);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
