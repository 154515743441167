import React, {useContext, useMemo, useRef} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../App/context";
import {withMaterialStyles} from "../../Template/withMaterial";
import Form from "../../Form";

import materialStyle from "./materialStyle";
import style from "./style.css";
import AccountContext from "../context";

function Login(props) {

    const accountContext = useContext(AccountContext);
    const {name} = accountContext;

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const utils = getUtils(context);
    //const {materialStyle} = props;

    const {wapp, req} = context;

    const query = req.wappRequest.query;

    async function onSubmit(e, formData) {
        let response = await utils.login({
            requestName: name + "Login",
            args: formData,
            ...typeof props.redirect !== "undefined" ?
                {
                    redirect: props.redirect
                } : {
                    redirect: {pathname: query.redirect || appContext.routes.userRoute + "/:_id", search: "", hash: ""}
                }
        });
        if (props.onSubmit) {
            const newResponse = await props.onSubmit(e, response)
            if (newResponse) {
                return newResponse;
            }
        }
        return response;
    }

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation." + name + "Login.formData");
    } catch (e) {
        console.log(e)
    }

    const formData = {
        ...formDataFromResolvers,
        submit: {
            label: appContext.labels.loginSubmitLabel
        }
    };

    wapp.styles.use(style);

    return (
        <Form formData={formData} onSubmit={onSubmit}/>
    )
}

const WappComponent = withWapp(Login);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
