// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iTuz0{width:100%;height:auto;position:fixed;display:-ms-flexbox;display:flex;bottom:-100%;left:0;z-index:1201;background-color:var(--bg-color);-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-shadow:0 0 2px 0 var(--border-color);box-shadow:0 0 2px 0 var(--border-color);-webkit-transition:all 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;transition:all 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms}.iTuz0.JSwJA{bottom:0}.iTuz0>span{padding:16px}.iTuz0>span:first-child{padding-right:0}@media(max-width: 799px){.otWI2{min-height:100%}.otWI2{border-radius:0;max-width:100%;width:100%;margin:0;max-height:100%}.Tcn0N{padding:16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cookies": "iTuz0",
	"show": "JSwJA",
	"dialogPaper": "otWI2",
	"dialogContent": "Tcn0N"
};
module.exports = ___CSS_LOADER_EXPORT___;
