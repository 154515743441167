import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";

import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";
import Content, {tableComponents, tableIcons} from "./Content";
import New from "./New";
import Edit from "./Edit";
import getConstants from "./constants";
import getMenu from "./menu";
import AddIcon from "@mui/icons-material/Add";

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    orderId: {type: String},
                },
                ...(p.config) ? p.config : {}
            },
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const name = "order";

        return defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams: [
                "/page/:pagination",
                "/sort/:sort",
                "/limit/:limit"
            ]
        });

    },
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name: "order"
        })
    },
    getPages: function () {
        return {
            content: Content,
            new: New,
            edit: Edit
        }
    },
    getComponent: function (p) {

        const name = "order";
        const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";

        const {context, appContext} = p;
        const {routes, /*userStatusManager*/} = appContext;

        const {res, req} = context;
        const route = res.wappResponse.route;
        const requestPath = route.requestPath;
        const user = req.wappRequest.user;

        const isAdmin = user && user._status_isFeatured;

        const pages = {
            content: Content,
            new: New,
            edit: Edit
        };

        if ((requestPath.startsWith(routes[name + "Route"])) || (requestPath.startsWith(routes[ns + "Route"]))) {

            if (requestPath.startsWith(routes[ns + "Route"]) && !isAdmin) {
                return null;
            }

            const requestKeys = [name + "FindById"];
            const post = requestKeys.map((requestName) => res.wappResponse.store.getState("res.responses." + requestName)).find((r) => r?._id);
            const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id));

            const isAuthorOrAdmin = (isAuthor || isAdmin);

            if (requestPath.startsWith(routes[name + "Route"]) && requestPath !== routes[name + "Route"] + "/new" && !isAuthorOrAdmin) {
                return null;
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps: {
                    type: (isAdmin) ? "table" : "list",
                    tableProps: {
                        components: tableComponents,
                        icons: tableIcons,
                        className: null
                    },
                    getMenu: (p) => getMenu(p).map((m) => {
                        delete m.featured;
                        return m;
                    })
                },
                componentProps: {
                    pages,
                    layoutType: "article",
                    showAuthor: ({user}) => user?._status_isFeatured,
                    showCreatedDate: ({user, author}) => user?._id && user._id === author?._id,
                    getMenu
                }
            });
        }
    },
    userPageFunctions: function (p) {

        const {context, /*appContext, */postContext} = p;

        const n = "order";
        const ns = (n.endsWith("y")) ? n.slice(0, -1) + "ies" : n + "s";
        const N = capitalize(n);
        const Ns = capitalize(ns);

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p, name: n, nsPageProps: {
                tableProps: {
                    components: tableComponents,
                    icons: tableIcons,
                    className: null
                },
                getMenu
            }
        });

        const {res} = context;

        const wappResponse = res.wappResponse;
        const route = wappResponse.route;
        const {params} = route;
        const {pageType} = params;

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (isAuthor) ? menus["my" + Ns + "Menu"] : menus[postContext.name + Ns + "Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/" + p.post._id + routes[postContext.name + Ns + "Route"] : routes[postContext.name + Ns + "Route"];
                    },
                    role: function (p) {
                        const isAdmin = p.user && p.user._status_isFeatured;
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (isAdmin || isAuthor);
                    },
                    Icon: ShoppingCartIcon,
                    order: 25,
                },
                {
                    name: function () {
                        return menus["deleted" + Ns + "Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/" + p.post._id + routes[postContext.name + Ns + "Route"] + "/deleted" : routes[postContext.name + Ns + "Route"] + "/deleted"
                    },
                    role: function (p) {
                        const isAdmin = p.user && p.user._status_isFeatured;
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAuthorOrAdmin = (isAdmin || isAuthor);
                        const isPostsPage = ((p.page === ns && !p.pageType) || (p.page === ns && !isNaN(Number(p.pageType)) && Number(pageType) >= 1));
                        return (isPostsPage && isAuthorOrAdmin);
                    },
                    Icon: DeleteIcon,
                    onlyIcon: true,
                    featured: true,
                },
            ];
        }

        function addContentMenuItems(props) {

            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                /*{
                    name: menus["new" + N + "Menu"],
                    href: routes[n + "Route"] + "/new",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return !!(isAuthor);
                    },
                    Icon: AddIcon,
                    disableParentRoute: true,
                    order: 25,
                },*/
                {
                    name: function (p) {
                        const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                        return (isAuthor) ? menus["my" + Ns + "Menu"] : menus[postContext.name + Ns + "Menu"];
                    },
                    href: function (p) {
                        return (p?.post?._id) ? "/" + p.post._id + routes[postContext.name + Ns + "Route"] : routes[postContext.name + Ns + "Route"];
                    },
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return ((!p.post?._status_isFeatured && !isAuthor && isAdmin) || (isAuthor && !isAdmin));
                    },
                    Icon: ShoppingCartIcon,
                    order: 26,
                },
            ]
        }

        function getPageName({user, post, page, /*statusManager*/}) {

            const isAdmin = user && user._status_isFeatured;
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id));
            const isAuthorOrAdmin = (isAdmin || isAuthor);

            return ((page === ns && !pageType) || (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) || (page === ns && pageType === "deleted" && isAuthorOrAdmin)) ? (isAuthorOrAdmin) ? page : "notFound" : null;

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function (p) {
        const name = "order";
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({...p, name});
        defaultAdminMenu.Icon = ShoppingCartIcon;
        defaultAdminMenu.order = 2.5;
        defaultAdminMenu.items[0].Icon = ShoppingCartOutlined;
        defaultAdminMenu.items = defaultAdminMenu.items.filter((item, i) => {
            return (i === 0 || i === 1 || i === 2 || i === 3 || i === 6 || i === 7)
        });
        return defaultAdminMenu;
    }
};

export default postTypeConfig;
