import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const {name = "post"} = p;

    const n = name;
    const N = capitalize(n);
    const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
    const Ns = capitalize(ns);

    return {
        labels: {
            ["new" + N + "SubmitLabel"]: "Mentés",
            ["restore" + N + "SubmitLabel"]: "Visszaállítás",
            ["save" + N + "SubmitLabel"]: "Mentés",
            ["cancel" + N + "Text"]: "Mégsem",
            ["delete" + N + "Text"]: "Törlés",
            ["delete" + Ns + "Text"]: "Bejegyzések törlése",
            ["ban" + N + "Text"]: "Tiltás",
            ["approve" + N + "Text"]: "Jóváhagyás",
            ["protection" + N + "Text"]: "Védettnek jelölés",
            ["removeProtection" + N + "Text"]: "Védettség megszüntetése",
            ["select" + N + "Text"]: "Kiválasztás",
            ["add" + N + "Text"]: "Hozzáadás",
            ["date" + N + "Format"]: ({dateText = ""}) => {
                if (!dateText) {
                    return dateText;
                }
                try {
                    return new Date(dateText).toLocaleString("hu-HU");
                } catch (e) {
                }
                return dateText;
            },
            [ns + "SortRelevance"]: "Relevancia",
            [n + "CoverLabel"]: "Borítókép",
            [n + "AuthorLabel"]: "Szerző",
            [n + "TitleLabel"]: "Cím",
        },
        menus: {
            ["user" + Ns + "Menu"]: "Bejegyzések",
            ["my" + Ns + "Menu"]: "Bejegyzéseim",
            ["new" + N + "Menu"]: "Új bejegyzés",
            [ns + "Menu"]: "Bejegyzések",
            ["deleted" + Ns + "Menu"]: "Törölt bejegyzések",
            ["banned" + Ns + "Menu"]: "Véglegesen törölt bejegyzések",
            ["protection" + Ns + "Menu"]: "Védett bejegyzések",
            ["missingData" + Ns + "Menu"]: "Hiányzó adatok",
            ["lowAuthorStatus" + Ns + "Menu"]: "Szerző alacsony státusszal",

            ["edit" + N + "Menu"]: "Szerkesztés",
            ["delete" + N + "Menu"]: "Törlés",
            ["ban" + N + "Menu"]: "Tiltás",
            ["approve" + N + "Menu"]: "Jóváhagyás",
            ["protection" + N + "Menu"]: "Védettnek jelölés",
            ["removeProtection" + N + "Menu"]: "Védettség törlése",
        },
        messages: {
            ["new" + N + "SuccessMessage"]: "Az új bejegyzés mentésre került",
            ["save" + N + "SuccessMessage"]: "Az bejegyzés mentésre került",
            ["delete" + N + "Success"]: "A bejegyzés törölve lett",
            ["delete" + Ns + "Success"]: "A bejegyzések törölve lettek",
            ["delete" + N + "Question"]: "Biztosan törli a bejegyzést?",
            ["delete" + Ns + "Question"]: "Biztosan törli a bejegyzéseket?",
            ["ban" + N + "Success"]: "A bejegyzés véglegesen törölve lett",
            ["ban" + N + "Question"]: "Biztos, hogy a bejegyzést véglegesen törölni akarod?",
            ["approve" + N + "Success"]: "A bejegyzés jóvá lett hagyva",
            ["approve" + N + "Question"]: "Biztosan jóváhagyod a bejegyzést",
            ["protection" + N + "Success"]: "A bejegyzés védettnek jelölve",
            ["protection" + N + "Question"]: "Biztosan védettnek jelölöd a bejegyzést?",
            ["removeProtection" + N + "Success"]: "A védettnek jelölés törölve lett",
            ["removeProtection" + N + "Question"]: "Biztosan törlöd a védettnek jelölést?",
            ["thereAreNo" + Ns]: "Nincsenek bejegyzések"
        },
        routes: {
            ["user" + Ns + "Route"]: "/" + ns,
            [n + "Route"]: "/" + n,
            [ns + "Route"]: "/" + ns,
        },
        titles: {
            [n + "Title"]: "Bejegyzés",
            [ns + "Title"]: "Bejegyzések",
            ["new" + N + "Title"]: "Új bejegyzés",
            ["edit" + N + "Title"]: "Bejegyzés szerkesztése",
            ["user" + Ns + "Title"]: "Bejegyzések",
            ["userDeleted" + Ns + "Title"]: "Törölt bejegyzések",
            ["my" + Ns + "Title"]: "Bejegyzéseim",
            ["myDeleted" + Ns + "Title"]: "Törölt bejegyzéseim",

            ["dialogDelete" + N + "Title"]: "Bejegyzés törlése",
            ["dialogBan" + N + "Title"]: "Bejegyzés törlése véglegesen",
            ["dialogApprove" + N + "Title"]: "Bejegyzés jóváhagyása",
            ["dialogProtection" + N + "Title"]: "Bejegyzés védettnek jelölése",
            ["dialogRemoveProtection" + N + "Title"]: "Bejegyzés védettségének megszüntetése",

            ["dialogDelete" + Ns + "Title"]: "Bejegyzések törlése",

            ["statusBanned" + N + "Title"]: "Véglegesen törölt",
            ["statusDeleted" + N + "Title"]: "Törölt",
            ["statusAuthorDeleted" + N + "Title"]: "Szerző törölve",
            ["statusMissingData" + N + "Title"]: "Hiányzó adatok",
            ["statusApproved" + N + "Title"]: "Jóváhagyott",
            ["statusFeatured" + N + "Title"]: "Védett",
            ["statusCreated" + N + "Title"]: "Létrehozva",

            ["banned" + Ns + "Title"]: "Véglegesen törölt bejegyzések",
            ["deleted" + Ns + "Title"]: "Törölt bejegyzések",
            ["missingData" + Ns + "Title"]: "Hiányzó adatok",
            ["protected" + Ns + "Title"]: "Védett bejegyzések",
            ["lowAuthorStatus" + Ns + "Title"]: "Szerző alacsony státusszal",

            ["select" + Ns]: "Bejegyzések kiválasztása",
            ["select" + N]: "Bejegyzés kiválasztása"
        }
    };
}
