import React, {useContext} from "react";

import {WappContext} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import Form from "../../Form";
import PostContext from "../../Post/context";

function RemoveFeaturedForm(props) {

    const postContext = useContext(PostContext);

    const {
        onSubmit,
        setFormRef,
        name = postContext.name,
        post = postContext.post
    } = props;

    const context = useContext(WappContext);
    const utils = getUtils(context);

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation." + name + "RemoveFeatured.formData");
    } catch (e) {
        console.log(e)
    }

    const formData = {
        ...formDataFromResolvers,
    };

    delete formData.submit;

    if (post?._id) {
        formData._id.value = post._id;
        formData._id.disabled = true;
    }

    return (
        <div>
            <Form
                ref={function (e) {
                    setFormRef.current = e;
                }}
                formData={formData}
                onSubmit={onSubmit}
            />
        </div>
    )
}

export default RemoveFeaturedForm
