import React, {useEffect, useMemo, useRef, useState} from "react";

import PlayIcon from "@mui/icons-material/PlayArrow";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeOnIcon from "@mui/icons-material/VolumeUp";

import clsx from "clsx";

export default class Player extends React.Component {
    constructor(props) {

        super(props);
        const {src = "", autoplay, muted = false} = this.props;

        this.refElements = {};

        this.state = {
            src: src,
            isPlaying: autoplay,
            muted: muted
        };
    }

    componentDidMount() {
        const addListener = this.addListener;

        setTimeout(function () {
            addListener();
        }, 100)
    }

    componentWillUnmount() {
        this.removeListener();
    }

    setData = (a) => {
        let changes = null;
        const state = this.state;
        Object.keys(a).forEach(function (d) {
            if (a[d] !== state[d]) {
                changes = true;
            }
        });
        if (changes) {
            this.setState(a);
        }
    };
    addListener = () => {
        const topLayer = this.refElements["topLayer"];
        const onEnterTopLayer = this.onEnterTopLayer;
        const onLeaveTopLayer = this.onLeaveTopLayer;
        if (topLayer) {
            this.removeListener();
            topLayer.addEventListener("mouseenter", onEnterTopLayer, false);
            topLayer.addEventListener("mouseleave", onLeaveTopLayer, false);
        }
    };
    removeListener = () => {
        const topLayer = this.refElements["topLayer"];
        const onEnterTopLayer = this.onEnterTopLayer;
        const onLeaveTopLayer = this.onLeaveTopLayer;
        if (topLayer) {
            topLayer.removeEventListener("mouseenter", onEnterTopLayer, false);
            topLayer.removeEventListener("mouseleave", onLeaveTopLayer, false);
        }
    };
    onEnterTopLayer = (e) => {
        const {onEnterTopLayer} = this.props;
        if (onEnterTopLayer) {
            onEnterTopLayer(e, this.state);
        }
    };
    onLeaveTopLayer = (e) => {
        const {onLeaveTopLayer} = this.props;
        if (onLeaveTopLayer) {
            onLeaveTopLayer(e, this.state);
        }
    };

    onClick = (e, d) => {

        const {onClick, layerInteraction} = this.props;
        const {type} = d;

        if (type === "topLayer" && onClick) {

            if (this.state.isPlaying) {
                if (layerInteraction === "pause") {
                    this.playPause();
                } else {
                    this.muteUnmute();
                }
            } else {
                this.play();
            }

            onClick({type});
        }

        e.preventDefault();
    };
    playPause = () => {
        const isPlaying = this.state.isPlaying;
        if (!isPlaying) {
            this.play();
        } else {
            this.pause();
        }
    };
    play = () => {
        this.setData({
            isPlaying: true,
        })
    };
    pause = () => {
        this.setData({
            isPlaying: false,
        })
    };
    stop = () => {
        this.setData({
            isPlaying: false,
        });
        this.onStop();
    };
    muteUnmute = () => {
        const muted = this.state.muted;
        if (!muted) {
            this.mute();
        } else {
            this.unmute();
        }
    };
    mute = () => {
        this.setData({
            muted: true
        });
        this.onMute()
    };
    unmute = () => {
        this.setData({
            muted: false
        });
        this.onUnmute()
    };

    onPlay = () => {
        const {onPlay} = this.props;
        if (onPlay) {
            onPlay();
        }
    };
    onPause = () => {

        const {onPause} = this.props;
        const media = this.refElements["media"];
        const duration = (media && media.getDuration) ? media.getDuration() : 0;
        const currentTime = (media && media.getCurrentTime) ? media.getCurrentTime() : 0;

        if (currentTime && duration === currentTime) {
            this.onStop();
        } else {
            if (onPause) {
                onPause();
            }
            this.setData({
                isPlaying: false,
                currentTime
            })
        }
    };
    onStop = () => {
        const media = this.refElements["media"];
        media.seekTo(0);
        this.setData({
            isPlaying: false,
            currentTime: 0
        });
        const {onStop} = this.props;
        if (onStop) {
            onStop();
        }
    };
    onMute = () => {
        const {onMute} = this.props;
        if (onMute) {
            onMute();
        }
    };
    onUnmute = () => {
        const {onUnmute} = this.props;
        if (onUnmute) {
            onUnmute();
        }
    };
    setRef = (a, e) => {
        const {setRef} = this.props;
        this.refElements[a] = e;
        if (setRef) {
            setRef(a, e);
        }
    };

    render() {

        const {style, topLayerColor = "rgba(0, 0, 0, 0.1)", layerInteraction, cover, bigPlayButton} = this.props;

        const onClick = this.onClick;

        const setRef = this.setRef;
        const onPlay = this.onPlay;
        const onPause = this.onPause;

        const enableCover = (cover && !this.state.isPlaying && !this.state.currentTime);

        const topLayerClassName = clsx(style.topLayer, {
            [style.cover]: enableCover,
            [style.gif]: enableCover && cover.match(".gif")
        });

        const topLayerStyle = (topLayerColor) ? {backgroundColor: topLayerColor} : {};
        if (enableCover) {
            topLayerStyle.backgroundImage = "url(" + cover + ")";
        }

        return (
            <div className={style.mediaCont}>
                {(this.state.src) ?
                    <div className={style.media}>
                        <div style={topLayerStyle}
                             className={topLayerClassName}
                             onClick={function (e) {
                                 onClick(e, {type: "topLayer"})
                             }}
                             ref={function (e) {
                                 setRef("topLayer", e)
                             }}
                        >
                        </div>
                        {(this.state.isPlaying && layerInteraction !== "pause") ?
                            <div
                                className={style.muteIcon}
                                onClick={function (e) {
                                    onClick(e, {type: "topLayer"})
                                }}
                            >
                                {(this.state.muted) ? <VolumeOffIcon/> : <VolumeOnIcon/>}
                            </div>
                            : null
                        }
                        {(this.state.isPlaying) ? null :
                            <div
                                className={(bigPlayButton) ? style.playerIcon + " " + style.bigPlayButton : style.playerIcon}
                                onClick={function (e) {
                                    onClick(e, {type: "topLayer"})
                                }}
                            >
                                <PlayIcon/>
                            </div>
                        }
                        <div className={style.mediaPlayer}>
                            <Video
                                url={this.state.src}
                                onPlay={onPlay}
                                onPause={onPause}
                                playsinline

                                playing={this.state.isPlaying}
                                volume={1}
                                muted={this.state.muted}

                                effect={function (e) {
                                    setRef("media", e)
                                }}
                            />
                        </div>
                        <div className={style.mediaControls}/>
                    </div>
                    : null
                }
            </div>
        )
    }
}

const Video = function (props) {

    const {url, playsInline, onPlay, onPause} = props;

    const [playing, setPlaying] = useState(props.playing || false);
    const [muted, setMuted] = useState(props.muted || false);
    const [volume, setVolume] = useState(props.volume || 1);

    const element = useRef();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const Element = useMemo(() => React.forwardRef((props, ref) => <video
        ref={ref} {...props} />), [url, playsInline, onPlay, onPause]);

    useEffect(function () {
        if (playing !== props.playing) {
            setPlaying(props.playing);
        }
        if (muted !== props.muted) {
            setMuted(props.muted);
        }
        if (volume !== props.volume) {
            setVolume(props.volume);
        }
        if (props.effect) {
            props.effect({
                getDuration: () => element?.current?.duration || 0,
                getCurrentTime: () => element?.current?.currentTime || 0,
                seekTo: (sec) => (element?.current) ? element.current.currentTime = sec : 0
            })
        }
        // eslint-disable-next-line
    }, [element, muted, playing, volume, props.muted, props.playing, props.volume, props.effect]);

    useEffect(() => {
        const video = element.current;
        if (video) {
            if (video.muted !== muted) {
                video.muted = muted;
            }
            if (video.volume !== volume) {
                video.volume = volume;
            }
            if (playing !== !video.paused) {
                if (playing) {
                    video.play();
                } else {
                    video.pause();
                }
            }
        }
    }, [element, playing, muted, volume]);

    return (
        <Element ref={element} src={url} {...(playsInline) ? {playsInline: ""} : {}} onPlay={onPlay} onPause={onPause}/>
    )
};
