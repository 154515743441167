import React, {useContext} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import AppContext from "../App/context";
import {withMaterialStyles} from "../Template/withMaterial";
import DefaultPost from "../Post";
import EditPost from "../Post/Edit";
import {defaultGetPageName, showPageOrNotFound} from "../Post/utils";
import Posts from "../Posts";

import materialStyle from "./materialStyle";
import style from "./style.css";

import getMenu, {getTopMenu} from "./menu";

import Content from "./Content";

function User(props) {

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);

    const {
        name = "user",
        Post = DefaultPost,
        addMenuItems,
        addContentMenuItems,
    } = props;

    const {wapp, res} = context;

    wapp.styles.use(style);

    const wappResponse = res.wappResponse;
    const route = wappResponse.route;
    const {params} = route;
    const {pageType} = params;

    const statusManager = wapp.getTargetObject().postTypes.findPostType({name: name}).statusManager;

    const menuProperties = {
        pageType,
        statusManager,
        name
    };

    const pages = {
        content: (props) => <Content {...props} addMenuItems={addContentMenuItems}
                                     menuProps={{menuKey: name + "_content"}}/>,
        new: null,
        edit: EditPost,
        posts: Posts,
        ...(props.pages) ? props.pages : {}
    };

    function getDashboardTitle({user, post, page}) {
        const isAuthor = ((user?._id && user?._id === post?._author) || (user?._id && user?._id === post?._author?._id));
        return (props.getDashBoardTitle && props.getDashBoardTitle({user, post, page})) ?
            props.getDashBoardTitle({user, post, page}) :
            (isAuthor) ?
                appContext.titles.dashboardTitle :
                appContext.titles[name + "ProfileTitle"];
    }

    function getPageName(p) {
        return (!showPageOrNotFound(p)) ?
            null :
            (props.getPageName && props.getPageName(p)) ?
                props.getPageName(p) :
                defaultGetPageName(p)
    }

    return (
        <Post
            name={name}
            parentRoute={appContext.routes[name + "Route"]}
            getTitle={({post/*, page*/}) => post?.title || appContext.titles[name + "Title"]}
            getSubtitle={() => ""}
            getMenu={(props = {}) => getMenu({...props, ...menuProperties, addMenuItems})}
            getTopMenu={(props = {}) => getTopMenu({...props, ...menuProperties})}
            menuProperties={menuProperties}
            pages={pages}
            layoutType={"user"}
            maxWidth={"sm"}
            getPageName={getPageName}
            getDashboardTitle={getDashboardTitle}
        />
    )

}

const WappComponent = withWapp(User);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
