import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import HeightIcon from "@mui/icons-material/Height";
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LayersIcon from '@mui/icons-material/Layers';
import LanguageIcon from '@mui/icons-material/Language';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AbcIcon from '@mui/icons-material/Abc';
import PaletteIcon from '@mui/icons-material/Palette';
import TranslateIcon from '@mui/icons-material/Translate';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import YardIcon from '@mui/icons-material/Yard';
import PublishIcon from '@mui/icons-material/Publish';
import LinkIcon from '@mui/icons-material/Link';
import GoogleIcon from '@mui/icons-material/Google';
import MoneyOutlinedIcon from "@mui/icons-material/MoneyOutlined";
import QrCodeIcon from '@mui/icons-material/QrCode';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';

import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import ReactMarkdown from "react-markdown";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import syntaxStyle from "react-syntax-highlighter/dist/cjs/styles/prism/okaidia";

import {withMaterialStyles} from "../../../components/Template/withMaterial";

import PostContext from "../../../components/Post/context";
import Gallery from "../../../components/Gallery";
import Table, {valueToTableData} from "../../../components/Table";

import Dialog from "../../../components/Dialog";
import MiniCart from "../../../components/Cart/MiniCart";
import capitalize from "../../../utils/capitalize";
import AppContext from "../../../components/App/context";

import {postTypesConfig} from "../../index";

import materialStyle from "./materialStyle";
import style from "./style.css";
import AddToCart from "../../../components/Cart/AddToCart";

export const tableIcons = {
    productAuthor: LocalLibraryIcon,
    productIllustrator: PaletteIcon,
    productTranslator: TranslateIcon,
    productEditor: SpellcheckIcon,
    productLanguage: LanguageIcon,
    ageGroup: Diversity3Icon,
    binding: LayersIcon,
    pages: AutoStoriesIcon,
    height: HeightIcon,
    width: () => <HeightIcon style={{transform: 'rotate(90deg)'}}/>,
    weight: MonitorWeightIcon,
    publisher: YardIcon,
    publicationYear: PublishIcon,
    ean: QrCodeIcon,
    isbn: QrCodeIcon,
    quality: AbcIcon,
    dUrl: LinkIcon,
    dUrl2: LinkIcon,
    gsUrl: GoogleIcon,
    cUrl: LinkIcon,
    packaging: CalendarViewMonthIcon,
    piecesInStock: HouseSidingIcon,
    rrPriceText: MoneyOutlinedIcon,
    dMarginText: MoneyOutlinedIcon,
    cPriceText: MoneyOutlinedIcon,
    cart: ShoppingCartIcon,
};

export const tableComponents = {};

const renderers = {
    code: ({language, value}) => {
        return <SyntaxHighlighter style={syntaxStyle} language={language} children={value}/>
    }
};

function Content() {

    const container = useRef();
    const postContext = useContext(PostContext);
    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const utils = getUtils(context);
    const {wapp, req} = context;
    wapp.styles.use(style);

    const {
        user = utils.getRequestUser(),
        name = "product",
        statusManager = wapp.getTargetObject().postTypes.findPostType({name: name}).statusManager
    } = postContext;

    const orderPostTypeName = "order";
    const O = capitalize(orderPostTypeName);

    const {storage} = appContext;
    const storageName = user?._id ? orderPostTypeName + "_" + user._id : orderPostTypeName;

    function getStorageData() {
        const localStorageData = storage()[storageName] || {};
        const memoData = storage(undefined, true)[storageName] || {};
        return {...localStorageData, ...memoData};
    }

    const initialPost = {...(postContext.post) ? postContext.post : {}};

    const storageData = getStorageData();
    const cart = (storageData['record.cart']) ? JSON.parse(storageData['record.cart']) : [];

    cart.forEach((cartItem) => {
        const samePost = (cartItem?._id) ? cartItem._id === initialPost?._id : null;
        if (samePost) {
            initialPost.q = cartItem.q || 0;
        }
    });

    const [post, setPost] = useState(initialPost);

    useEffect(function () {
        if (container.current) {
            const aa = container.current.getElementsByTagName("a");
            for (let i = 0; i < aa.length; i++) {
                if (aa[i].getAttribute("href").startsWith("http")) {
                    aa[i].setAttribute("target", "_blank");
                }
            }
        }
    }, [container]);

    const contentDataObject = utils.getGlobalState("res.graphql.query." + name + "FindById.contentData");
    const tableData = valueToTableData({tableData: contentDataObject.table, post});

    const NewOrder = useMemo(() => postTypesConfig[orderPostTypeName] && postTypesConfig[orderPostTypeName].getPages ? postTypesConfig[orderPostTypeName].getPages().new : null, []);
    const orderStatusManager = useMemo(() => wapp.getTargetObject().postTypes.findPostType({name: orderPostTypeName}).statusManager, []);

    const dialog = useMemo(() => {
        return {}
    }, []);
    const dialogEffect = useMemo(() => ({actions}) => {
        dialog.actions = actions;
    }, [dialog]);
    const miniCart = useMemo(() => {
        return {}
    }, []);
    const miniCartEffect = useMemo(() => ({actions}) => {
        miniCart.actions = actions;
    }, [miniCart]);

    const form = useRef();

    function updateMiniCart() {
        if (miniCart.actions?.update) {
            miniCart.actions.update();
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (form.current) {
            const response = await form.current.onSubmit(e);
            if ((response && response.error) || (response && response.errors)) {

            } else if (response?.record._id) {
                await dialog.actions.close();
                wapp.client.history.push({
                    search: "",
                    hash: "",
                    pathname: appContext.routes[orderPostTypeName + "Route"] + "/" + response.record._id
                });
            }
        }
    };

    const onCancel = async (e) => {
        e.preventDefault();
        await dialog.actions.close();
        await new Promise((resolve) => setTimeout(resolve, 500));
        await reloadPage()
    };

    async function reloadPage() {
        const response = await utils.sendRequest({requestName: name + "FindById", args: {_id: post?._id}});
        if (response._id) {

            const storageData = getStorageData();
            const cart = (storageData['record.cart']) ? JSON.parse(storageData['record.cart']) : [];

            cart.forEach((cartItem) => {
                const samePost = (cartItem?._id) ? cartItem._id === response?._id : null;
                if (samePost) {
                    response.q = cartItem.q || 0;
                }
            });

            if (!response.q) {
                response.q = 0;
            }

            if (JSON.stringify(response) !== JSON.stringify(post)) {
                await setPost(response)
            }
        }
    }

    const open = useMemo(() => (e) => {

        if (e) {
            e.preventDefault();
        }

        if (dialog.actions) {

            dialog.actions.open({
                dialogTitle: user?._id ? appContext.titles["new" + O + "Title"] : "Belépés vagy regisztráció",
                dialogContent: <>
                    {(NewOrder) ?
                        <PostContext.Provider
                            value={{
                                name: orderPostTypeName,
                                user,
                                post: null,
                                parentRoute: appContext.routes[orderPostTypeName + "Route"],
                                statusManager: orderStatusManager
                            }}>
                            <NewOrder
                                {...{
                                    formProps: ({formData}) => ({
                                        ref: form,
                                        formData: {...formData, submit: {...formData.submit, hidden: true}},
                                        onChange: () => updateMiniCart()
                                    }),
                                    requestProps: {redirect: null, go: 0}
                                }}
                            />
                        </PostContext.Provider>
                        : null
                    }
                </>,
                cancelText: user?._id ? appContext.labels["continuousShopping" + O + "Text"] : "Bezár",
                submitText: user?._id ? appContext.labels["new" + O + "SubmitLabel"] : "",
                onSubmit: onSubmit,
                onCancel: onCancel
            })

        }
    }, [post]);

    useEffect(()=>{
        if (wapp.client.shouldOpenOrder) {
            open()
            wapp.client.shouldOpenOrder = false;
        }
    }, [])

    return (
        <div className={style.post} ref={container}>
            <div className={style.content}>
                <div className={style.dataContainer}>
                    {
                        (post?.gallery?.length) ?
                            <div className={style.gallery}>
                                <Gallery gallery={post.gallery} startJustOne={true}/>
                            </div>
                            : null
                    }
                    <div className={style.table}>
                        {(tableData && Object.keys(tableData).length) ?
                            <Table
                                tableData={tableData}
                                post={post}
                                user={user}
                                name={name}
                                statusManager={statusManager}
                                icons={tableIcons}
                                components={tableComponents}
                            /> : null
                        }
                        <AddToCart
                            q={post.q}
                            post={post}
                            onChange={async ({post}) => {
                                const storageData = storage()[storageName];
                                if (post?._id) {
                                    const cart = (storageData["record.cart"]) ? JSON.parse(storageData["record.cart"]) : [];
                                    const cartItem = cart.find((cartItem) => cartItem?._id === post._id);
                                    if (cartItem) {
                                        if (cartItem.q !== post.q) {
                                            cartItem.q = post.q;
                                            const filteredCart = cart.filter((cartItem) => cartItem.q > 0);
                                            storage({
                                                [storageName]: {
                                                    ...storageData,
                                                    ["record.cart"]: JSON.stringify(filteredCart)
                                                }
                                            });
                                            await updateMiniCart();
                                        }
                                    } else {

                                        const enableKeys = [
                                            "price",
                                            "q",
                                            "vatRate",
                                            "subtitle",
                                            "_id",
                                            "thumb",
                                            "title",
                                            "piecesInThePackage",
                                            "disableProduct",
                                            "available",
                                        ]

                                        cart.push({...enableKeys.reduce((cartItem, key)=>{
                                                cartItem[key] = post[key];
                                                return cartItem
                                            }, {})
                                        });

                                        const filteredCart = cart.filter((cartItem) => cartItem.q > 0);
                                        storage({
                                            [storageName]: {
                                                ...storageData,
                                                ["record.cart"]: JSON.stringify(filteredCart)
                                            }
                                        });
                                        await updateMiniCart();
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <ReactMarkdown renderers={renderers}>
                    {post?.content ? post?.content.replace(/\n/g, '\n\n') : ''}
                </ReactMarkdown>
            </div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                effect={dialogEffect}
                className={style.dialog}
                paperClassName={style.dialogPaper}
            />
            <MiniCart
                onClick={open}
                effect={miniCartEffect}
            />
        </div>
    )
}

const WappComponent = withWapp(Content);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
