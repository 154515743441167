// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HiIVN{position:relative}.HiIVN.C99AK{padding:8px;background-color:rgba(0,0,0,.1215686275);display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;width:100%;height:auto;-ms-flex-align:center;align-items:center;min-height:300px;border-radius:8px;border-style:dashed;border-color:var(--border-color);border-width:2px}.HiIVN.C99AK._57EOG{opacity:.8}._5xsVz{position:relative}._5xsVz.dQKZz .u6dae{height:auto;background-color:transparent;border-style:none;-webkit-box-shadow:none;box-shadow:none;padding:0;text-transform:none;font-size:1em;font-weight:inherit}._9CHm9{display:-ms-flexbox;display:flex}._9CHm9.zzID6{min-width:56px}.Mt64e{display:none}.RAUxb{position:relative;width:32px;height:48px;margin-left:8px;margin-right:-4px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.RAUxb .NTD8P{top:0;left:0;bottom:0;right:0;position:absolute;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.RAUxb .NTD8P>div{font-size:11px;line-height:normal}.ojCYD{position:absolute;left:50%;bottom:-20px;width:10px;height:10px}.bBYnG{margin-right:-16px}.g71q-{width:350px;max-height:350px}.Fs\\+6b{opacity:1 !important}.ZIB\\+7 svg{color:var(--error-color) !important}.OseJl{white-space:nowrap;text-overflow:ellipsis;overflow:hidden;font-weight:500}.Loi10{white-space:normal;text-overflow:initial;min-width:200px}.wKEDb{color:var(--error-color) !important}.ZmR1k{padding:8px;display:-ms-inline-flexbox;display:inline-flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload": "HiIVN",
	"dropZone": "C99AK",
	"dragEnter": "_57EOG",
	"uploadButton": "_5xsVz",
	"inheritStyle": "dQKZz",
	"fab": "u6dae",
	"icon": "_9CHm9",
	"iconListStyle": "zzID6",
	"input": "Mt64e",
	"circularProgress": "RAUxb",
	"percent": "NTD8P",
	"hiddenAnchorForMenu": "ojCYD",
	"menuContainer": "bBYnG",
	"menuComponent": "g71q-",
	"menuItem": "Fs+6b",
	"errorItem": "ZIB+7",
	"primaryText": "OseJl",
	"secondaryText": "Loi10",
	"menuIconError": "wKEDb",
	"secondaryMenuIcon": "ZmR1k"
};
module.exports = ___CSS_LOADER_EXPORT___;
