const defaultStorageName = "konyvesjatek-webshop";

const memoStorage = {};

function getItem(storageName) {
    return memoStorage[storageName];
}

function setItem(storageName, item) {
    return memoStorage[storageName] = item;
}

export function storage(newData = {}, storageName = defaultStorageName) {
    if (typeof window !== "undefined") {
        try {
            const currentData = JSON.parse(getItem(storageName) || JSON.stringify({}));
            const tempCurrentDataString = JSON.stringify(currentData);
            Object.keys(newData).forEach(function (key) {
                currentData[key] = newData[key];
            });
            if (tempCurrentDataString !== JSON.stringify(currentData)) {
                setItem(storageName, JSON.stringify(currentData));
            }
            return currentData;
        } catch (e) {
            return newData;
        }
    }
    return newData;
}
