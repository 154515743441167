import React, {useContext, useMemo, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";

import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import {withMaterialStyles} from "../Template/withMaterial";
import AppContext from "../App/context";

import style from "./style.css";
import materialStyle from "./materialStyle";

const DefaultFormElement = React.memo(
    (props) => <form {...props} children={undefined}>{props.children}</form>,
    (prevProps, nextProps) => {
        return !((prevProps.children !== nextProps.children) ||
            prevProps.onSubmit !== nextProps.onSubmit);
    }
);

export function Search(props) {

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const {wapp, req} = context;
    const query = req.wappRequest.query;

    const FormElement = useMemo(() => props.FormElement || DefaultFormElement, [props.FormElement]);

    wapp.styles.use(style);

    const [searchText, setSearchText] = useState(typeof props.searchText == "string" ? props.searchText : typeof query.search == "string" ? query.search : "");

    const getBaseUrl = useMemo(() => (url) => {
        let urlWithoutSearch = url.split("?")[0];
        if (urlWithoutSearch.slice(-1) === "/") {
            urlWithoutSearch = urlWithoutSearch.slice(0, -1);
        }
        const sortPart = urlWithoutSearch.match(/\/sort\/\w+/);
        const pagePart = urlWithoutSearch.match(/\/page\/\d+/);
        return {
            pathname: sortPart || pagePart ? urlWithoutSearch.split(sortPart).join("").split(pagePart).join("") : urlWithoutSearch,
            search: url.split("?")[1],
        };
    }, []);

    const onChange = props.onChange;

    const onSubmit = useMemo(() => (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onChange) {
            onChange({searchText});
        } else {

            const {pathname} = getBaseUrl(req.wappRequest.path);

            wapp.client.history.push({
                pathname: (searchText) ? pathname + "/sort/TEXTSCORE" : pathname,
                search: (searchText) ? "?search=" + searchText : "",
                hash: ""
            })
        }
    }, [getBaseUrl, onChange, req.wappRequest.path, searchText, wapp.client?.history]);

    const onBlur = useMemo(() => (e) => {
        e.preventDefault();
        if (onChange) {
            onChange({searchText});
        } else {
            const querySearch = query.search || "";
            if (searchText !== querySearch) {

                const {pathname} = getBaseUrl(req.wappRequest.path);

                wapp.client.history.push({
                    pathname: (searchText) ? pathname + "/sort/TEXTSCORE" : pathname,
                    search: (searchText) ? "?search=" + searchText : "",
                    hash: ""
                })
            }
        }
    }, [getBaseUrl, onChange, query.search, req.wappRequest.path, searchText, wapp.client?.history]);

    return (
        <div className={style.search}>
            <FormElement onSubmit={onSubmit}>
                <div className={style.searchIcon}>
                    <SearchIcon/>
                </div>
                <InputBase
                    placeholder={appContext.labels.searchLabel || "Search"}
                    classes={{
                        root: style.searchInputRoot,
                        input: style.searchInputInput,
                    }}
                    inputProps={{"aria-label": "search"}}
                    onChange={(e) => {
                        setSearchText(e.target.value)
                    }}
                    onBlur={onBlur}
                    value={searchText}
                />
            </FormElement>
        </div>
    )
}

const WappComponent = withWapp(Search);

export default withMaterialStyles(materialStyle, WappComponent);
