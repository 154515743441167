import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import Fab from "@mui/material/Fab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgressMaterial from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DoneIcon from "@mui/icons-material/Done";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MoreIcon from "@mui/icons-material/MoreVert";
import ErrorIcon from "@mui/icons-material/Error";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import AppContext from "../../../components/App/context";
import PostContext from "../../../components/Post/context";
import {withMaterialStyles} from "../../../components/Template/withMaterial";
import capitalize from "../../../utils/capitalize";

import materialStyle from "./materialStyle";
import style from "./style.css";

import Snackbar from "@mui/material/Snackbar";
import Dialog from "../../../components/Dialog";
import clsx from "clsx";
import IconButton from "@mui/material/IconButton";

function CircularProgress(props) {

    const {style, effect} = props;

    const [progress, setProgress] = useState(props.progress || 0);

    useEffect(() => {
        if (effect) {
            effect({setProgress})
        }
        return () => {
            if (effect) {
                effect({setProgress: null})
            }
        }
    }, [setProgress, effect]);

    return (
        <span className={style.circularProgress}>
            <CircularProgressMaterial size={32} thickness={2.4}/>
            {(progress > 0.1) ?
                <div className={style.percent}>
                    <Typography variant={"caption"} component={"div"} color={"text.secondary"}>
                        {`${Math.round(progress)}%`}
                    </Typography>
                </div> :
                null
            }
        </span>
    )

}

function New(props) {

    const postContext = useContext(PostContext);

    const name = props.name || postContext.name;
    const parentRoute = props.parentRoute || postContext.parentRoute;

    const N = capitalize(name);
    const Ns = (N.endsWith("y")) ? N.slice(0, -1) + "ies" : N + "s";

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const utils = getUtils(context);

    //const {materialStyle} = props;

    const {wapp} = context;
    wapp.styles.use(style);

    let formDataFromResolvers = {};

    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation." + name + "New.formData");
    } catch (e) {
    }

    const {filePropertyName = Object.keys(formDataFromResolvers).length ? Object.keys(formDataFromResolvers)[0] : "upload"} = props;

    const {
        label = formDataFromResolvers[filePropertyName].label || "Upload",
        accept = formDataFromResolvers[filePropertyName].accept || "image/*,video/*",
        multiple = formDataFromResolvers[filePropertyName].multiple || false,
        dropZone = true,
        successOpenInNew = true,
        successMessage = appContext.messages["new" + Ns + "SuccessMessage"],
        onSuccess,
        args = {},
        buttonInheritStyle,
        buttonIconListStyle,
        request
    } = props;

    const [errors, setErrors] = useState([]);
    const [progress, setProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const [openFiles, setOpenFiles] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const circular = useMemo(() => {
        return {}
    }, []);
    const dialog = useMemo(() => {
        return {}
    }, []);
    const xhrStore = useMemo(() => {
        return {}
    }, []);
    const input = useRef();
    const container = useRef();
    const menuIcon = useRef();
    const menuActions = useRef();
    const menuAutoClose = useMemo(() => {
        return {}
    }, []);

    function handleMenuClose(e) {
        e.preventDefault();
        e.stopPropagation();
        clearMenuAutoClose();
        setOpenFiles(false);
    }

    function handleMenuOpen(e) {
        e.preventDefault();
        e.stopPropagation();
        clearMenuAutoClose();
        setOpenFiles(true);
    }

    const clearMenuAutoClose = useMemo(() => function clearMenuAutoClose() {
        if (menuAutoClose.wait) {
            clearTimeout(menuAutoClose.wait);
        }
    }, [menuAutoClose.wait]);

    const menuAutoCloseTimeout = useMemo(() => function menuAutoCloseTimeout() {
        clearMenuAutoClose();
        menuAutoClose.wait = setTimeout(() => {
            setOpenFiles(false)
        }, 2500);
    }, [clearMenuAutoClose, menuAutoClose]);

    const menuClick = useMemo(() => function menuClick(e, {href}) {
        wapp.client.history.push({
            search: "",
            hash: "",
            ...wapp.client.history.parsePath(href)
        });

        e.preventDefault();
        e.stopPropagation();
        // eslint-disable-next-line
    }, []);

    function handleCloseSnackbar() {
        if (snackMessage) {
            setSnackMessage("");
        }
    }

    const onClick = useMemo(() => function onClick(e) {
        if (progress) {
            e.preventDefault();
            dialog.actions.open({
                dialogTitle: appContext.titles["dialogAbortUpload" + Ns + "Title"],
                dialogContent: appContext.messages["abortUpload" + Ns + "Question"],
                cancelText: appContext.labels["cancelAbortUpload" + Ns + "Text"],
                submitText: appContext.labels["abortUpload" + Ns + "Text"],
                onSubmit: async function () {
                    await dialog.actions.close();
                    if (xhrStore.xhr && progress) {
                        xhrStore.xhr.abort();
                        await setProgress(0);
                        await setFiles([]);
                        await setOpenFiles(false);
                        if (input.current) {
                            input.current.value = "";
                        }
                    }
                },
                successMessage: appContext.messages["abortUpload" + Ns + "Success"]
            })
        }
    }, [Ns, appContext.labels, appContext.messages, appContext.titles, dialog.actions, progress, xhrStore.xhr]);

    const onChange = useMemo(() => async function (e) {

        const filesArray = Array.from(e.target.files || []);
        const filesLength = filesArray.length;

        if (!filesLength) {
            return;
        }

        await setErrors([]);
        await setFiles(filesArray.map((file) => {
            return {title: file.name}
        }));
        await setOpenFiles(true);
        menuAutoCloseTimeout();
        await setProgress(0.1);

        const requestProps = {
            requestName: name + "New",
            args: {
                ...args,
                records: [
                    ...(args.records) ? args.records : []
                ],
                [filePropertyName]: (multiple) ? (filesLength) ? filesArray.map(() => null) : [] : null
            },
            multipart: true,
            callbackMultipartFormData: function ({formData}) {
                const map = JSON.stringify((filesLength) ? filesArray.reduce((o, file, i) => {
                    const t = (multiple) ? "variables." + filePropertyName + "." + (i) : "variables." + filePropertyName;
                    o[(i)] = [t];
                    return o;
                }, {}) : {});
                formData.append("map", map);
                if (filesLength) {
                    filesArray.forEach((file, i) => {
                        formData.append((i).toString(), file, file.title);
                    })
                }
            },
            formatBeforeRequest: function (url, options) {
                delete options.headers["Content-Type"];
                options.onProgress = function (e) {
                    if (e.lengthComputable) {
                        const progress = 100 * (e.loaded / e.total);
                        if (circular.actions?.setProgress) {
                            circular.actions.setProgress(progress);
                        }
                    }
                };
                return [url, options];
            },
            fetch: function (url, options = {}) {
                return new Promise((response, reject) => {
                    let xhr = new XMLHttpRequest();
                    xhrStore.xhr = xhr;
                    const {onProgress, ...restOptions} = options;
                    xhr.open(restOptions.method || "get", url);
                    for (let k in restOptions.headers || {}) {
                        xhr.setRequestHeader(k, restOptions.headers[k]);
                    }
                    xhr.responseType = "json";
                    xhr.onload = () => response(xhr.response);
                    xhr.onerror = reject;
                    if (xhr.upload && onProgress) {
                        xhr.upload.onprogress = onProgress;
                    }
                    xhr.send(options.body);
                });
            },
            redirect: {pathname: parentRoute + "/:_id", search: "", hash: ""},
            timeOut: 1000
        };

        const response = (request) ? await request({requestProps, filesArray}) : await utils.sendRequest(requestProps);

        if (input.current) {
            input.current.value = "";
        }

        await setProgress(0);

        let foundSuccess = -1;
        let newSuccessFiles = [];

        if (response.records && response.records.length) {
            const errors = (response.error?.errors) ? response.error.errors : (response.errors) ? response.errors : (response.error) ? [response.error] : null;
            const newFiles = filesArray.map((file, i) => {
                const thereIsError = (errors?.length) ? errors.find((error) => {
                    return typeof error.path == "string" && error.path && Number(error.path) === i
                }) : false;
                let r = {title: file.name};
                if (!thereIsError) {
                    foundSuccess = foundSuccess + 1;
                    if (response.records[foundSuccess]) {
                        r = {...r, ...response.records[foundSuccess]};
                        newSuccessFiles.push(r);
                    }
                }
                return r;
            });
            await setFiles(newFiles)
        }

        if ((response && response.error) || (response && response.errors)) {

            const message = (response.error?.message) || (response.errors && response.errors[0] && response.errors[0].message) || appContext.messages["new" + Ns + "ClientErrorMessage"];
            const errors = response.error?.errors || response.errors || [response.error];

            clearMenuAutoClose();
            await setErrors(errors);
            await setOpenFiles(true);
            await setSnackMessage(message);

        } else if (response) {
            clearMenuAutoClose();
            await setErrors([]);
            await setOpenFiles(false);
            if (successMessage) {
                await setSnackMessage(successMessage);
            }
        }

        if (foundSuccess > -1) {
            if (onSuccess) {
                await onSuccess({files: newSuccessFiles})
            }
        }

    }, [menuAutoCloseTimeout, utils, name, args, filePropertyName, multiple, parentRoute, circular.actions, xhrStore, appContext.messages, Ns, clearMenuAutoClose, successMessage, onSuccess]);

    const addDropZoneStyle = useMemo(() => (e) => {
        e.preventDefault();
        if (container.current && dropZone) {
            container.current.className = clsx(style.upload, style.dropZone, style.dragEnter)
        }
    }, [dropZone]);

    const removeDropZoneStyle = useMemo(() => (e) => {
        e.preventDefault();
        if (container.current && dropZone) {
            container.current.className = clsx(style.upload, style.dropZone)
        }
    }, [dropZone]);

    const onDrop = useMemo(() => async function onDrop(e) {

        removeDropZoneStyle(e);
        e.preventDefault();

        const files = e.dataTransfer.files;

        if (progress && files.length) {
            dialog.actions.open({
                dialogTitle: appContext.titles["dialogAbortUpload" + Ns + "Title"],
                dialogContent: appContext.messages["abortUpload" + Ns + "Question"],
                cancelText: appContext.labels["cancelAbortUpload" + Ns + "Text"],
                submitText: appContext.labels["abortUpload" + Ns + "Text"],
                onSubmit: async function () {

                    await dialog.actions.close();

                    if (progress && xhrStore.xhr) {
                        xhrStore.xhr.abort();
                    }

                    if (input.current) {
                        input.current.files = files;
                    }

                    await new Promise((resolve) => setTimeout(resolve, 500));
                    await onChange({target: {files}});
                },
                successMessage: appContext.messages["abortUpload" + Ns + "Success"]
            })
        } else {
            if (input.current) {
                input.current.files = e.dataTransfer.files;
                await new Promise((resolve) => setTimeout(resolve, 500));
                await onChange({target: input.current});
            }
        }
    }, [Ns, appContext.labels, appContext.messages, appContext.titles, dialog.actions, onChange, progress, removeDropZoneStyle, xhrStore.xhr]);

    const memoInput = useMemo(() => {
        return (
            <input
                ref={input}
                accept={accept}
                multiple={multiple}
                type={"file"}
                onChange={onChange}
                className={style.input}
            />
        )
    }, [accept, multiple, onChange]);

    const memoDialog = useMemo(() => {
        return <Dialog effect={({actions}) => {
            dialog.actions = actions
        }}/>
    }, [dialog]);

    useEffect(() => {
        if (menuActions.current) {
            menuActions.current.updatePosition();
        }
    });

    const globalError = errors.find((error) => !error.path) && !files.find((file) => file._id);

    // noinspection RequiredAttributes
    return (
        <div
            className={clsx(
                style.upload,
                {[style.dropZone]: dropZone}
            )}
            ref={container}
            onDragOver={addDropZoneStyle}
            onDragEnter={addDropZoneStyle}
            onDragLeave={removeDropZoneStyle}
            onDragEnd={removeDropZoneStyle}
            onDrop={onDrop}
        >
            <div
                className={clsx(
                    style.uploadButton,
                    {[style.inheritStyle] : buttonInheritStyle }
                )}
            >
                <label>
                    {memoInput}
                    <Fab
                        variant={"extended"}
                        component={"span"}
                        onClick={onClick}
                        className={style.fab}
                    >
                        <span
                            className={clsx(
                                style.icon,
                                {[style.iconListStyle]: buttonIconListStyle}
                            )}
                        >
                            {(progress) ? <CloseIcon sx={{mr: 1}}/> : <CloudUploadIcon sx={{mr: 1}}/>}
                        </span>
                        {label}
                        {(progress) ? <CircularProgress
                            progress={progress}
                            style={style}
                            effect={(actions) => {
                                circular.actions = actions
                            }}
                        /> : null}
                        <div
                            className={style.hiddenAnchorForMenu}
                            ref={menuIcon}
                        />
                        {(files?.length) ?
                            <div className={style.menuContainer}>
                                <IconButton
                                    color={"inherit"}
                                    onClick={handleMenuOpen}
                                    aria-controls={"upload-menu"}
                                    aria-haspopup={"true"}
                                    aria-label={"upload-menu"}
                                    className={clsx(
                                        style.menuIcon,
                                        {[style.menuIconError]: errors?.length && !openFiles}
                                    )}
                                >
                                    {(!progress && !errors?.length && !globalError) ? <DoneIcon/> : <MoreIcon/>}
                                </IconButton>
                                <Menu
                                    anchorEl={menuIcon.current}
                                    open={openFiles}
                                    className={style.menuComponent}
                                    onClose={handleMenuClose}
                                    id={"upload-menu"}
                                    anchorOrigin={{vertical: "top", horizontal: "center",}}
                                    transformOrigin={{vertical: "top", horizontal: "center",}}
                                    action={menuActions}
                                >
                                    {files.map((file, i) => {
                                        const thereIsError = errors.find((error) => {
                                            return error.path && Number(error.path) === i
                                        });
                                        const filename = file.title;
                                        const href = file._id ? appContext.routes[name + "Route"] + "/" + file._id : "";
                                        return (
                                            <MenuItem
                                                key={i}
                                                disabled={!(href)}
                                                className={clsx(
                                                    style.menuItem,
                                                    {[style.errorItem]: thereIsError}
                                                )}
                                                onClick={!(successOpenInNew) ? (e) => menuClick(e, {href}) : null}
                                                href={href}
                                                target={"_blank"}
                                                component={"a"}
                                            >
                                                <>
                                                    {(!progress) ?
                                                        <ListItemIcon>
                                                            {(thereIsError || globalError) ? <ErrorIcon/> : <DoneIcon/>}
                                                        </ListItemIcon>
                                                        : null
                                                    }
                                                </>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant={"inherit"} className={style.primaryText}>
                                                            {filename}
                                                        </Typography>
                                                    }
                                                    secondary={(thereIsError) ?
                                                        <Typography variant={"inherit"} className={style.secondaryText}>
                                                            {thereIsError.message}
                                                        </Typography>
                                                        : ""
                                                    }
                                                />
                                                {(href) ?
                                                    <span className={style.secondaryMenuIcon}>
                                                        <OpenInNewIcon/>
                                                    </span>
                                                    : null
                                                }
                                            </MenuItem>
                                        )
                                    })}
                                </Menu>
                            </div>
                            : null
                        }
                    </Fab>
                </label>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={!!(snackMessage)}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackMessage}
            />
            {memoDialog}
        </div>
    )
}

const WappComponent = withWapp(New);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
