// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NTwtE>div{margin-bottom:16px}.NTwtE>div:last-child{margin-bottom:0}.-vGXE{font-size:14px;line-height:20px;color:var(--font-color);letter-spacing:normal}.-vGXE pre{background-color:#303338 !important}.pfh5N{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-align:start;align-items:flex-start}.rB7Kg{-ms-flex:1 1 320px;flex:1 1 320px;max-width:50%}.ur85b{-ms-flex-preferred-size:auto;flex-basis:auto;width:50%}.pfh5N>div>div{padding:8px}.pfh5N>*:only-child{width:100%;max-width:none}.RHPF\\+{font-weight:500}@media(max-width: 621px){.rB7Kg{max-width:none}.ur85b{width:100%}}@media(prefers-color-scheme: dark){.-vGXE pre{background:rgba(0, 0, 0, 0.14) !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "NTwtE",
	"content": "-vGXE",
	"dataContainer": "pfh5N",
	"moreData": "rB7Kg",
	"orderData": "ur85b",
	"dataTableLabel": "RHPF+"
};
module.exports = ___CSS_LOADER_EXPORT___;
