import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import {copyObject} from "wapplr/dist/common/utils";

import AppContext from "../../../components/App/context";
import {withMaterialStyles} from "../../../components/Template/withMaterial";

import {defaultComponents} from "../../../components/Form";
import Summary from "../../../components/Summary";
import PostContext from "../../../components/Post/context";
import Cart from "../../../components/Cart";

import Login from '../../../components/Account/Login'
import Signup from '../../../components/Account/Signup'

import capitalize from "../../../utils/capitalize";

import {tableComponents, tableIcons} from "../../product/Content";

import postsStyle from "./postsStyle.css";
import materialStyle from "./materialStyle";
import style from "./style.css";

import DefaultNew from "../../../components/Post/New";

function LoginOrSignup(props) {

    const {wapp, style} = props;

    return (
        <div className={style.loginOrSignup}>
            <div>
                <div>
                    <h4>
                        {"Lépj be ha már korábban regisztráltál"}
                    </h4>
                </div>
                <Login
                    redirect={false}
                    onSubmit={(e, response)=>{
                        e.preventDefault();
                        if (response?.record?._id) {
                            wapp.client.shouldOpenOrder = true;
                        }
                    }}
                />
            </div>
            <div>
                <div>
                    <h4>
                        {"Regisztrálj, ha most vásárolsz először"}
                    </h4>
                </div>
                <Signup
                    redirect={false}
                    onSubmit={(e, response)=>{
                        e.preventDefault();
                        if (response?.record?._id) {
                            wapp.client.shouldOpenOrder = true;
                        }
                    }}
                />
            </div>
        </div>
    )
}

function New(props) {

    const {initialValues = {}, formProps = {}, requestProps = {}} = props;

    const postContext = useContext(PostContext);

    const {name, user, parentRoute} = postContext;

    const N = capitalize(name);

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const utils = getUtils(context);

    //const {materialStyle} = props;

    const {wapp} = context;
    wapp.styles.use(style);

    if (!user?._id) {
        return (
            <LoginOrSignup style={style} wapp={wapp}/>
        )
    }

    const {storage} = appContext;
    const [savedData, setSavedData] = useState({});

    const storageName = user?._id ? name + "_" + user._id : name;

    useEffect(() => {

        const localStorageData = storage()[storageName] || {};
        const memoData = storage(undefined, true)[storageName] || {};

        setSavedData({...localStorageData, ...memoData})
    }, []);

    async function onSubmit(e, formData) {
        const args = {
            ...formData,
            ...(formData._author) ? {_author: formData._author?._id || formData._author} : {},
            ...(formData.record) ? {
                record: {
                    ...formData.record,
                    ...(formData.record.cart) ? {
                        cart: JSON.stringify(JSON.parse(formData.record.cart).map((cartItem) => ({
                            ...cartItem,
                            ...(cartItem.categories) ? {categories: cartItem.categories.map((category) => ({_id: category._id}))} : {},
                            ...(cartItem.ages) ? {ages: cartItem.ages.map((age) => ({_id: age._id}))} : {},
                            ...(cartItem.gallery) ? {gallery: cartItem.gallery.map((image) => ({_id: image._id}))} : {},
                            ...(cartItem._author) ? {_author: {_id: cartItem._author._id}} : {},
                            ...(cartItem.cover) ? {cover: {_id: cartItem.cover._id}} : {},
                        })))
                    } : {},
                }
            } : {},
        };
        const r = await utils.sendRequest({
            requestName: name + "New",
            args,
            redirect: {pathname: parentRoute + "/:_id", search: "", hash: ""},
            timeOut: 1000, ...requestProps
        });
        if (!r?.error && !r?.errors && r) {
            storage({[storageName]: {}}, true);
            storage({[storageName]: {}})
        }
        return r;
    }

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation." + name + "New.formData");
    } catch (e) {
    }

    const formData = {
        ...formDataFromResolvers,
        submit: {
            label: appContext.labels["new" + N + "SubmitLabel"]
        }
    };

    if (savedData) {
        Object.keys(savedData).forEach((key) => {
            initialValues[key] = savedData[key];
        })
    }

    if (user?.company && !user?._status_isFeatured) {

        const notFullEmpty = Object.keys(user.company).find((key) => {
            return initialValues["record.company." + key];
        });

        if (!notFullEmpty) {
            Object.keys(user.company).forEach((key) => {
                initialValues["record.company." + key] = user.company[key]
            });
        }
    }

    if (user?.shipping && !user?._status_isFeatured) {

        const notFullEmpty = Object.keys(user.shipping).find((key) => {
            return initialValues["record.shipping." + key];
        });

        if (!notFullEmpty) {

            Object.keys(user.shipping).forEach((key) => {
                initialValues["record.shipping." + key] = user.shipping[key]
            });

        }
    }

    function copyInitialValues(initialValues, formData) {
        if (initialValues && Object.keys(initialValues).length) {
            Object.keys(formData).forEach(function (key) {
                if (typeof initialValues[key] !== "undefined" && initialValues[key] !== null) {
                    formData[key].value = initialValues[key];
                }
            })
        }
    }

    copyInitialValues(initialValues, formData);

    const cart = useMemo(() => ({actions: {}}), []);
    const methods = useMemo(() => ({actions: {}}), []);
    const authors = useMemo(() => ({actions: {}}), []);
    const summary = useMemo(() => ({actions: {}}), []);

    function getSummaryRows() {

        const method = methods.actions?.getPosts && methods.actions.getPosts();
        const selectedMethod = method && method[0];

        const price = selectedMethod?.price || 0;
        const vatRate = selectedMethod?.vatRate || 0;

        const total = cart.actions?.getTotal && cart.actions.getTotal() || {totalNet: 0, total: 0};

        return [
            {title: appContext.labels[name + "SummaryProducts"], ...total},
            {
                title: selectedMethod?.titleToInvoice || appContext.labels[name + "OtherFees"],
                totalNet: selectedMethod ? (price / (100 + vatRate)) * 100 : price,
                total: selectedMethod?.price || 0
            }
        ];
    }

    async function updateSummary() {
        const rows = getSummaryRows();
        await summary.actions.setRows(rows);
    }

    const formRef = useRef();

    async function updateShippingVisibility(formData) {

        if (methods.actions.getPosts) {

            const method = methods.actions.getPosts();
            const selectedMethod = method && method[0];
            const show = selectedMethod?.isShipping;

            let shouldUpdate = false;

            Object.keys(formData).forEach((key) => {
                if (key.match("shipping")) {
                    if (formData[key].hidden !== (!show)) {
                        formData[key].hidden = (!show);
                        shouldUpdate = true;
                    }
                }
            });

            if (shouldUpdate) {
                await formRef.current.setNewFormData(formData);
            }

            return shouldUpdate;

        }

    }

    Object.keys(formData).forEach((key) => {
        if (key.match("shipping")) {
            formData[key].hidden = true;
            formData[key].required = true;
        }
    });

    useEffect(() => {
        if (user?._id) {
            updateSummary();
            updateShippingVisibility(formData);
        }
    }, []);

    formData["record.method"].componentName = "PostsMethod";

    if (formData["_author"]) {
        formData["_author"].componentName = "PostsAuthor";
    }

    const fP = (typeof formProps == "function") ? formProps({formData}) : formProps;

    return (
        <div className={style.new}>
            <DefaultNew
                formProps={{
                    formData: formData,
                    onSubmit: onSubmit,
                    successMessage: appContext.messages["new" + N + "SuccessMessage"],
                    components: {
                        ...defaultComponents,
                        PostsMethod: {
                            ...defaultComponents.Posts,
                            props: {
                                ...defaultComponents.Posts.props,
                                getStageProps: () => ({
                                    style: postsStyle,
                                }),
                                effect: ({actions}) => {
                                    if (!Object.keys(methods.actions).length) {
                                        methods.actions = actions
                                        updateShippingVisibility(formData);
                                    } else {
                                        methods.actions = actions
                                    }
                                }
                            }
                        },
                        PostsAuthor: {
                            ...defaultComponents.Posts,
                            props: {
                                ...defaultComponents.Posts.props,
                                effect: ({actions}) => authors.actions = actions
                            }
                        },
                        Cart: {
                            props: {
                                effect: ({actions}) => cart.actions = actions,
                                label: "",
                                value: [],
                                error: false,
                                errors: [],
                                helperText: "",
                                editable: true,
                                enableAddProducts: false,
                                disabled: false,
                                name: "order",
                                postsProps: {
                                    getStageProps: () => ({
                                        tableProps: {
                                            components: tableComponents,
                                            icons: tableIcons,
                                            className: null
                                        },
                                    }),
                                    getDialogProps: () => ({
                                        tableProps: {
                                            components: tableComponents,
                                            icons: tableIcons,
                                            className: null
                                        },
                                    }),
                                },
                            },
                            Component: (props)=>{
                                return (
                                    <div>
                                        <div className={style.containerLabel}>{appContext.labels[name + "CartBoxLabel"]}</div>
                                        <Cart {...props} />
                                    </div>
                                )
                            }
                        },
                        Summary: {
                            props: {
                                effect: ({actions}) => summary.actions = actions,
                                value: 0,
                                error: false,
                                helperText: "",
                                name,
                                getRows: getSummaryRows,
                                callback: async () => await updateSummary()
                            },
                            Component: (props)=>{
                                return (
                                    <div>
                                        <div className={style.containerLabel}>{appContext.labels[name + "SummaryBoxLabel"]}</div>
                                        <Summary {...props} />
                                    </div>
                                )
                            }
                        }
                    },
                    ...fP,
                    ref: (form) => {
                        formRef.current = form;
                        if (typeof fP.ref == "function") {
                            fP.ref(form);
                        } else if (fP.ref) {
                            fP.ref.current = form;
                        }
                    },
                    onChange: async (e, key, formData) => {
                        const newSavedValues = Object.keys(formData)
                            .map((key) => typeof formData[key].value !== "undefined" ? [key, formData[key].value] : null)
                            .filter((a) => a)
                            .reduce((o, value) => {
                                const key = value[0];
                                o[key] = value[1];
                                return o;
                            }, {});

                        let shouldUpdate = false;

                        if (user?._status_isFeatured && key === "_author" && formData._author) {
                            const author = authors.actions.getPosts();
                            const selectedAuthor = author && author[0];
                            const initialValues = {};

                            if (selectedAuthor) {
                                newSavedValues._author = copyObject(selectedAuthor);
                            }

                            if (selectedAuthor?.company) {
                                Object.keys(selectedAuthor.company).forEach((key) => {
                                    initialValues["record.company." + key] = selectedAuthor.company[key]
                                });
                                shouldUpdate = true;
                            }
                            if (selectedAuthor?.shipping) {
                                Object.keys(selectedAuthor.shipping).forEach((key) => {
                                    initialValues["record.shipping." + key] = selectedAuthor.shipping[key]
                                });
                                shouldUpdate = true;
                            }
                            if (shouldUpdate) {
                                copyInitialValues(initialValues, formData)
                            }
                        }

                        const cart = newSavedValues["record.cart"];

                        storage({[storageName]: {["record.cart"]: cart}});
                        storage({
                            [storageName]: {
                                ...Object.keys(newSavedValues)
                                    .filter((key) => key !== "record.cart")
                                    .reduce((o, key) => {
                                        o[key] = newSavedValues[key];
                                        return o;
                                    }, {})
                            }
                        }, true);

                        const updated = await updateShippingVisibility(formData);

                        if (!updated && shouldUpdate) {
                            await formRef.current.setNewFormData(formData);
                        }

                        if (fP.onChange) {
                            await fP.onChange(e, key, formData)
                        }

                    }
                }}
            />
        </div>
    )
}

const WappComponent = withWapp(New);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
