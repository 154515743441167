import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "product";

    const n = name;
    const N = capitalize(n);
    const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
    const Ns = capitalize(ns);

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels,
            ["new" + N + "SubmitLabel"]: "Új mentése",
            ["restore" + N + "SubmitLabel"]: "Visszaállítás",
            ["save" + N + "SubmitLabel"]: "Mentés",
            ["cancel" + N + "Text"]: "Mégsem",
            ["delete" + N + "Text"]: "Törlés",
            ["delete" + Ns + "Text"]: "Termékek törlése",
            ["ban" + N + "Text"]: "Tiltás",
            ["approve" + N + "Text"]: "Jóváhagyás",
            ["protection" + N + "Text"]: "Védettnek jelölés",
            ["removeProtection" + N + "Text"]: "Védettség megszüntetése",
            ["select" + N + "Text"]: "Kiválasztás",
            ["add" + N + "Text"]: "Hozzáadás",
            ["date" + N + "Format"]: ({dateText = ""}) => {
                if (!dateText) {
                    return dateText;
                }
                try {
                    return new Date(dateText).toLocaleString("hu-HU");
                } catch (e) {
                }
                return dateText;
            },
            [ns + "SortRelevance"]: "Relevancia",

            [n + "TitleLabel"]: "Név",
            [n + "ImportSelectFileLabel"]: "File kiválasztása",
            [n + "ImportSavedAlreadyLabel"]: "Elmentve",
            [n + "ImportRestartLabel"]: "Újrakezdés",
            [n + "ImportAutoRunLabel"]: "Automatikusan mentse a következőt",
            [n + "ImportEditLabel"]: "Módosítás",
            [n + "ImportRestoreLabel"]: "Visszaállítás",
            ["empty" + Ns + "Text"]: "Ürítés",
            ["updateFromDistributorAll" + Ns + "Text"]: "Frissítés",
            ["updateAvailableFromWholesaleAll" + Ns + "Text"]: "Frissítés",
            ["updateCheapestPriceAll" + Ns + "Text"]: "Frissítés",

        },
        menus: {
            ...menus,
            ["user" + Ns + "Menu"]: "Termékek",
            ["my" + Ns + "Menu"]: "Termékeim",
            ["new" + N + "Menu"]: "Új termék",
            [ns + "Menu"]: "Termékek",
            ["deleted" + Ns + "Menu"]: "Törölt termékek",
            ["banned" + Ns + "Menu"]: "Véglegesen törölt termékek",
            ["protection" + Ns + "Menu"]: "Védett termékek",
            ["missingData" + Ns + "Menu"]: "Hiányzó adatok",
            ["lowAuthorStatus" + Ns + "Menu"]: "Szerző alacsony státusszal",

            ["edit" + N + "Menu"]: "Szerkesztés",
            ["delete" + N + "Menu"]: "Törlés",
            ["ban" + N + "Menu"]: "Tiltás",
            ["approve" + N + "Menu"]: "Jóváhagyás",
            ["protection" + N + "Menu"]: "Védettnek jelölés",
            ["removeProtection" + N + "Menu"]: "Védettség törlése",

            [ns + "ImportMenu"]: "Importálás",
            [ns + "ServerImportMenu"]: "Importálás a szerveren",
            [ns + "ExportMenu"]: "Exportálás",
            [ns + "ExportFromDistributorMenu"]: "Új termékek lekérdezése a gyártóktól",
            [n + "UpdateFromDistributor"]: "Adatok frissítése a gyártó oldaláról",
            [n + "UpdateAvailableFromWholesale"]: "Elérhetőség frissítése a nagykereskedés oldaláról",
            [n + "UpdateCheapestPrice"]: "Legalacsonyabb ár frissításe a Googleből",
        },
        messages: {
            ...messages,
            ["new" + N + "SuccessMessage"]: "Az új termék mentésre került",
            ["save" + N + "SuccessMessage"]: "A termék mentésre került",
            ["delete" + N + "Success"]: "A termék törölve lett",
            ["delete" + Ns + "Success"]: "A termékek törölve lettek",
            ["delete" + N + "Question"]: "Biztosan törli a terméket?",
            ["delete" + Ns + "Question"]: "Biztosan törli a termékekat?",
            ["ban" + N + "Success"]: "A termék véglegesen törölve lett",
            ["ban" + N + "Question"]: "Biztos, hogy a terméket véglegesen törölni akarod?",
            ["approve" + N + "Success"]: "A termék jóvá lett hagyva",
            ["approve" + N + "Question"]: "Biztosan jóváhagyod a terméket",
            ["protection" + N + "Success"]: "A termék védettnek jelölve",
            ["protection" + N + "Question"]: "Biztosan védettnek jelölöd a terméket?",
            ["removeProtection" + N + "Success"]: "A védettnek jelölés törölve lett",
            ["removeProtection" + N + "Question"]: "Biztosan törlöd a védettnek jelölést?",
            ["thereAreNo" + Ns]: "Nincsenek termékek",
            [n + "ImportXlsParseError"]: () => "Hibás érték",
            [n + "ImportFileError"]: "A file egyetlen sora sem tartalmazott helyes EAN számot",
            ["save" + N + "DefaultFail"]: "Hiba történt a bejegyzés mentése közben, próbálja újra",
            [n + "HelperTextStock"]: ({current, newValue}) => "Jelenlegi:" + current + ", új:" + newValue,
            [n + "HelperTextStockSaved"]: ({saved}) => "Mentett:" + saved,
            [n + "ImportImageCouldNotOpenError"]: "A képet nem lehetett megnyitni",
            ["emptyStock" + Ns + "Question"]: "Biztosan üríti a készletet a kijelölt termékeknél?",
            ["emptyStock" + Ns + "Success"]: "A termékek ürítve lettek",

            ["updateFromDistributorAll" + Ns + "Question"]: "Biztosan frissíti az adatokat a kijelölt termékeknél?",
            ["updateFromDistributorAll" + Ns + "Success"]: "A termékek frissítve lettek",

            ["updateAvailableFromWholesaleAll" + Ns + "Question"]: "Biztosan frissíti az elérhetőséget a kijelölt termékeknél?",
            ["updateAvailableFromWholesaleAll" + Ns + "Success"]: "A termékek elérhetősége frissítve lett",

            ["updateCheapestPriceAll" + Ns + "Question"]: "Biztosan frissíti a legolcsóbb árat a kijelölt termékeknél?",
            ["updateCheapestPriceAll" + Ns + "Success"]: "A termékek legolcsóbb ára frissítve lett"
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
            [n + "Title"]: "Termék",
            [ns + "Title"]: "Termékek",
            ["new" + N + "Title"]: "Új termék",
            ["edit" + N + "Title"]: "Termék szerkesztése",
            ["user" + Ns + "Title"]: "Termékek",
            ["userDeleted" + Ns + "Title"]: "Törölt termékek",
            ["my" + Ns + "Title"]: "Termékeim",
            ["myDeleted" + Ns + "Title"]: "Törölt termékeim",

            ["dialogDelete" + N + "Title"]: "Termék törlése",
            ["dialogBan" + N + "Title"]: "Termék törlése véglegesen",
            ["dialogApprove" + N + "Title"]: "Termék jóváhagyása",
            ["dialogProtection" + N + "Title"]: "Termék védettnek jelölése",
            ["dialogRemoveProtection" + N + "Title"]: "Termék védettségének megszüntetése",

            ["dialogDelete" + Ns + "Title"]: "Termékek törlése",

            ["statusBanned" + N + "Title"]: "Véglegesen törölt",
            ["statusDeleted" + N + "Title"]: "Törölt",
            ["statusAuthorDeleted" + N + "Title"]: "Szerző törölve",
            ["statusMissingData" + N + "Title"]: "Hiányzó adatok",
            ["statusApproved" + N + "Title"]: "Jóváhagyott",
            ["statusFeatured" + N + "Title"]: "Védett",
            ["statusCreated" + N + "Title"]: "Létrehozva",

            ["banned" + Ns + "Title"]: "Véglegesen törölt termékek",
            ["deleted" + Ns + "Title"]: "Törölt termékek",
            ["missingData" + Ns + "Title"]: "Hiányzó adatok",
            ["protected" + Ns + "Title"]: "Védett termékek",
            ["lowAuthorStatus" + Ns + "Title"]: "Szerző alacsony státusszal",

            ["select" + Ns]: "Termékek kiválasztása",
            ["select" + N]: "Termék kiválasztása",

            [ns + "ImportTitle"]: "Termékek importálása",
            [ns + "ImportSubtitle"]: "Válaszd ki az exceltáblát. Csak azokat a sorokat veszi figyelembe a program, amiben szerepel helyes '_id' vagy 'ean' vagy 'isbn' szám van. Ahol piros mező van, ott az adat nem megfelelő. Ellenőrízd le a beolvasott értékeket és nyomj az 'Új mentése' vagy a 'Módosítás' vagy 'Visszaállítás' gombra. A mentési képernyő egy felugró ablakban jelenik meg, már az excelből importált adatokkal. A 'Darab készlet' összeadódik, a többi mező felülíródik (ha megvan adva). Ebben az ablakban szerkesztheted a további adatokat is mentés előtt. Kattints az 'Új mentése' vagy a 'Módosítás' vagy 'Visszaállítás' gombra a felugró ablak jobb alsó sarkában. A mentés során ellenőrzésre kerülnek az adatok, ha hibás adat van pirossal jelölődik, ha sikerült a mentés bezáródik az ablak, és zölddel jelölődik a mentett sor. Ha az 'Automatikusan mentse a következőt' jelölőégyzet be van kapcsolva, akkor a folyamat magától végig fut az első mentés után. Csak akkor áll le, ha hibát észlel. A böngésző tárjában elmentődik ez a táblázat, így félbehagyhatod a munkát, amikor visszatérsz újra látható lesz a táblázat. Ha egy másik excelt szeretnél feltölteni kattints az 'Újrakezdés' gombra.",

            ["dialog" + Ns + "EmptyStockTitle"]: "Készlet ürítés",
            ["dialog" + Ns + "UpdateFromDistributorAllTitle"]: "Adatok frissítése a gyártó oldaláról",
            ["dialog" + Ns + "UpdateAvailableFromWholesaleAllTitle"]: "Elérhetőség frissítése a nagykeredkedés oldaláról",
            ["dialog" + Ns + "UpdateCheapestPriceAllTitle"]: "Legolcsóbb ár frissítése a Googleből",

        }
    }
}
