import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaymentsIcon from "@mui/icons-material/Payments";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Button from "@mui/material/Button";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

import {withMaterialStyles} from "../../../components/Template/withMaterial";

import PostContext from "../../../components/Post/context";
import Table, {valueToTableData} from "../../../components/Table";
import Cart from "../../../components/Cart";
import Summary from "../../../components/Summary";
import AppContext from "../../../components/App/context";
import Dialog from "../../../components/Dialog";
import Posts from "../../../components/Posts";

import {runPostTypesConfigSync} from "../../index";

import materialStyle from "./materialStyle";
import style from "./style.css";
import tableStyle from "./tableStyle.css";
import paymentsPostsStyle from "./paymentsPostsStyle.css";

import {tableComponents as paymentTableComponents, tableIcons as paymentTableIcons} from "../../payment/Content";

export const tableIcons = {
    cart: ShoppingCartIcon,
    orderStatus: DoneAllIcon,
    methodOfReceipt: LocalShippingIcon,
    methodOfPayment: PaymentsIcon,
    company: BusinessIcon,
    location: LocationOnIcon,
    tax: WorkOutlineIcon,
    addressee: ContactMailIcon,
    shipping: LocalShippingOutlinedIcon,
    phone: LocalPhoneIcon
};

export const tableComponents = {
    OrderStatus: {
        props: {
            value: 10,
            name: "order"
        },
        Component: (props) => {
            const {name} = props;
            const context = useContext(WappContext);
            const {wapp} = context;
            wapp.styles.use(tableStyle);

            const utils = getUtils(context);

            const formData = utils.getGlobalState("res.graphql.mutation." + name + "Save.formData") || {};
            const orderStatus = formData["record.orderStatus"] || {};
            const options = orderStatus.options;
            const foundOrderStatusOption = options && options.find(({value}) => value === props.value);
            const statusText = foundOrderStatusOption?.label;

            if (statusText) {
                return (
                    <div className={tableStyle.status}>
                        {statusText}
                    </div>
                )
            }

            return null;
        }
    }
};

function Content() {

    const container = useRef();
    const postContext = useContext(PostContext);
    const context = useContext(WappContext);
    const appContext = useContext(AppContext);

    const utils = getUtils(context);
    const {wapp} = context;
    wapp.styles.use(style);

    const {
        user = utils.getRequestUser(),
        name = "order",
        statusManager = wapp.getTargetObject().postTypes.findPostType({name: name}).statusManager
    } = postContext;

    const [post, setPost] = useState(postContext.post);

    const listDataObject = utils.getGlobalState("res.graphql.query." + name + "FindMany.listData");
    const tableData = valueToTableData({tableData: listDataObject.table, post});

    function getSummaryRows() {

        const methodPrice = post.methodPrice || 0;
        const methodPriceNet = post.methodPriceNet || 0;
        const total = post.total;
        const totalNet = post.totalNet;

        return [
            {
                title: appContext.labels[name + "SummaryProducts"],
                totalNet: totalNet - methodPriceNet,
                total: total - methodPrice
            },
            {
                title: post.methodOfReceiptTextToInvoice || appContext.labels[name + "OtherFees"],
                totalNet: methodPriceNet,
                total: methodPrice
            }
        ];
    }

    const formData = utils.getGlobalState("res.graphql.mutation." + name + "New.formData");

    const form = useRef();

    const dialog = useMemo(() => {
        return {}
    }, []);
    const dialogEffect = useMemo(() => ({actions}) => {
        dialog.actions = actions;
    }, [dialog]);

    const isAdmin = user && user._status_isFeatured;
    const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id));

    async function onClick(e) {

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (dialog.actions) {

            function DialogContent() {

                const context = useContext(WappContext);

                const {wapp, req, res} = context;

                const route = appContext.routes.paymentRoute;
                const path = route + "/new";

                const newContext = useMemo(() => {
                    return (path) ?
                        {
                            wapp,
                            req: {
                                user: req.user,
                                wappRequest: {
                                    user: req.wappRequest.user,
                                    query: {}
                                }
                            },
                            res: {
                                wappResponse: {
                                    route: {
                                        requestPath: path,
                                        params: {page: "new"},
                                    },
                                    store: res.wappResponse.store,
                                    status: res.wappResponse.status
                                }
                            }
                        } : context;
                }, [path, wapp, req.user, req.wappRequest.user, res.wappResponse.store, res.wappResponse.status, context]);

                const PostTypesComponent = (path) ? runPostTypesConfigSync({
                    action: "getComponent",
                    p: {context: newContext, appContext}
                }).filter((C) => !!(C))[0] : null;

                const newPost = {order: {...post}};

                const onSubmitForm = async (e, formData, defaultSubmit) => {
                    if (e) {
                        e.preventDefault();
                    }
                    if (form.current) {
                        const response = await defaultSubmit(e, formData);
                        if ((response && response.error) || (response && response.errors)) {
                            await dialog.actions.show();
                            return response;
                        } else if (response?.record._id) {
                            await dialog.actions.close();
                            await setPost(response.record.order);

                            res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                                type: "INS_RES",
                                name: "responses",
                                value: {[name + "FindById"]: response.record}
                            }));

                        }
                    }
                };

                return (
                    <WappContext.Provider value={newContext}>
                        <div>
                            <PostTypesComponent
                                getInitialResponse={() => newPost}
                                getMenu={() => null}
                                onBackClick={(e) => {
                                    e.preventDefault();
                                    dialog.actions.close()
                                }}
                                disableBack={true}
                                pageProps={{
                                    initialValues: {
                                        ...(newPost) ? Object.keys(newPost).reduce((o, key) => {
                                            o["record." + key] = newPost[key];
                                            return o
                                        }, {}) : {},
                                        ...(isAdmin) ? {_author: post._author} : {},
                                    },
                                    formProps: ({onSubmit}) => ({
                                        ref: form,
                                        onSubmit: (e, formData) => onSubmitForm(e, formData, onSubmit)
                                    }),
                                    disabled: true
                                }}
                            />
                        </div>
                    </WappContext.Provider>
                )
            }

            await dialog.actions.open({
                dialogTitle: "",
                dialogContent: <>
                    <DialogContent/>
                </>,
                cancelText: appContext.labels["cancelPaymentText"],
                onCancel: async (e) => {
                    e.preventDefault();
                    await dialog.actions.close();
                },
                dialogContentClassName: style.dialogContent
            });

        }
    }

    const paymentListDataObject = utils.getGlobalState("res.graphql.query.paymentFindMany.listData");
    const paymentTableData = Object.keys(paymentListDataObject.table).filter((key) => key !== "order").reduce((o, key) => {
        o[key] = paymentListDataObject.table[key];
        return o
    }, {});

    useEffect(() => {
        const autoOpenPayment = (post.orderStatus < 20 && post.isBankcard && isAuthor && !post.payments?.length);
        if (autoOpenPayment) {
            onClick();
        }
    }, []);

    return (
        <div className={style.post} ref={container}>
            <div className={style.content}>
                <div>
                    <Cart
                        {...{
                            value: post.cart,
                            editable: false,
                            enableCartItemNavigate: true,
                            name,
                        }}
                    />
                </div>
                <div>
                    <Summary
                        {...{
                            rows: getSummaryRows(),
                            name
                        }}
                    />
                </div>
                {post.content ?
                    <div className={style.comment}>
                        {(formData["record.content"] && formData["record.content"].label) ?
                            <div className={style.commentLabel}>{formData["record.content"].label}</div> : null}
                        <div>{post.content}</div>
                    </div> : null}
                <div className={style.dataContainer}>
                    {(tableData && Object.keys(tableData).length) ?
                        <div className={style.orderData}>
                            <div>
                                <div
                                    className={style.dataTableLabel}>{appContext.labels[name + "ContentOrderData"]}</div>
                                <Table
                                    tableData={tableData}
                                    post={post}
                                    user={user}
                                    name={name}
                                    statusManager={statusManager}
                                    icons={tableIcons}
                                    components={tableComponents}
                                />
                            </div>
                        </div>
                        : null
                    }
                    {(post.company || post.shipping && post.isShipping) ?
                        <div className={style.moreData}>
                            {(post.company) ?
                                <div>
                                    <div
                                        className={style.dataTableLabel}>{appContext.labels[name + "ContainerLabel_record_company"]}</div>
                                    <Table
                                        tableData={{
                                            "company.name": {
                                                value: post.company.name,
                                                label: formData["record.company.name"].label,
                                                icon: "company"
                                            },
                                            "company.address": {
                                                value: [post.company.addressPostalCode, post.company.addressCity, post.company.address].filter((d)=>d).join(", "),
                                                label: formData["record.company.address"].label,
                                                icon: "location"
                                            },
                                            "company.taxNumber": {
                                                value: post.company.taxNumber,
                                                label: formData["record.company.taxNumber"].label,
                                                icon: "tax"
                                            }
                                        }}
                                        post={post}
                                        user={user}
                                        name={name}
                                        statusManager={statusManager}
                                        icons={tableIcons}
                                        components={tableComponents}
                                    />
                                </div>
                                : null
                            }
                            {(post.shipping && post.isShipping) ?
                                <div>
                                    <div
                                        className={style.dataTableLabel}>{appContext.labels[name + "ContainerLabel_record_shipping"]}</div>
                                    <Table
                                        tableData={{
                                            "shipping.name": {
                                                value: post.shipping.name,
                                                label: formData["record.shipping.name"].label,
                                                icon: "addressee"
                                            },
                                            "shipping.address": {
                                                value: [post.shipping.addressPostalCode, post.shipping.addressCity, post.shipping.address].filter((d)=>d).join(", "),
                                                label: formData["record.shipping.address"].label,
                                                icon: "shipping"
                                            },
                                            "shipping.phone": {
                                                value: post.shipping.phone,
                                                label: formData["record.shipping.phone"].label,
                                                icon: "phone"
                                            }
                                        }}
                                        post={post}
                                        user={user}
                                        name={name}
                                        statusManager={statusManager}
                                        icons={tableIcons}
                                        components={tableComponents}
                                    />
                                </div>
                                : null
                            }
                        </div> : null
                    }
                </div>
                {post.payments?.length && post.payments[0]._id ?
                    <div className={style.payments}>
                        <div
                            className={style.dataTableLabel}>{appContext.labels[name + "ContainerLabel_record_payments"]}</div>
                        <Posts
                            style={paymentsPostsStyle}
                            type={"table"}
                            posts={post.payments}
                            pageInfo={{}}
                            disablePageInfo={true}
                            disableAvatars={true}
                            onClick={() => null}
                            name={"payment"}
                            tableProps={{
                                icons: paymentTableIcons,
                                components: paymentTableComponents,
                            }}
                            tableData={paymentTableData}
                            getMenu={() => null}
                        />
                    </div> : null
                }
                {((post.orderStatus < 20 && !post.isTransfer) || !post.payed && post.isTransfer) ?
                    (post.isBankcard && isAuthor || !post.isBankcard && isAdmin) ?
                        <div className={style.paymentButton}>
                            <Button
                                color={"secondary"}
                                variant={"contained"}
                                startIcon={<PointOfSaleIcon/>}
                                onClick={onClick}
                            >
                                {appContext.labels["newPaymentSubmitLabel"]}
                            </Button>
                        </div> : null : null
                }
            </div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                effect={dialogEffect}
                className={style.dialog}
                paperClassName={style.dialogPaper}
            />
        </div>
    )
}

const WappComponent = withWapp(Content);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
