// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cz-tF .McMrQ{background-color:rgba(227,157,104,.1411764706)}.cz-tF .EdLRV{overflow:hidden;text-overflow:ellipsis;display:-webkit-box;line-clamp:2;-webkit-box-orient:vertical;white-space:normal}.cz-tF .HGi5P>div,.cz-tF .HGi5P>div>span,.cz-tF .HGi5P svg{font-weight:var(--font-weight-bold);color:var(--secondary-bg) !important;-webkit-text-decoration:wavy;text-decoration:wavy;text-decoration-line:underline}.cz-tF .HGi5P:not(:has(.IG1n4)) .Mnh\\+7{position:absolute;top:0;height:100%;left:0;width:56px;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}.cz-tF .HGi5P:not(:has(.IG1n4)) .Mnh\\+7::before{background-color:var(--secondary-bg);position:absolute;width:8px;height:8px;content:\"\";display:block;border-radius:50%}.cz-tF .IG1n4>div{max-width:24px}.cz-tF ul hr:last-child{display:none}@media print{.cz-tF ._2UYQ0>span{font-weight:var(--font-weight-bold)}.cz-tF .bpGH2{visibility:hidden !important}.cz-tF ._2ysOj{height:auto !important;visibility:visible !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "cz-tF",
	"collapse": "McMrQ",
	"listItemSecondaryText": "EdLRV",
	"selectedMenu": "HGi5P",
	"listItemIcon": "IG1n4",
	"selectedMark": "Mnh+7",
	"collapseButtonLabel": "_2UYQ0",
	"collapseIcon": "bpGH2",
	"collapseContainer": "_2ysOj"
};
module.exports = ___CSS_LOADER_EXPORT___;
