import {runPostTypesConfigSync} from "../../postTypes";

const titles = {
    homeTitle: "Főoldal",
    homeSubtitle: "Könyvek és Játékok egy helyen",
    acceptCookiesTitle: "Cookie-k jóváhagyása",
    servicesTitle: "Tevékenységünk",
    ...runPostTypesConfigSync({action: "getConstants", rKey: "titles"}).reduce((a, v) => {
        return {...a, ...v}
    }, {})
};

export default titles;
