import React, {useContext, useEffect, useMemo, useRef} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";

import {withMaterialStyles} from "../../../components/Template/withMaterial";

import PostContext from "../../../components/Post/context";
import AppContext from "../../../components/App/context";
import Dialog from "../../../components/Dialog";
import MiniCart from "../../../components/Cart/MiniCart";
import AddToCart from "../../../components/Cart/AddToCart";
import capitalize from "../../../utils/capitalize";
import {postTypesConfig} from "../../index";

import materialStyle from "./materialStyle";
import style from "./style.css";
import {copyObject} from "wapplr/dist/common/utils";

function Posts(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const {wapp, req, res} = context;

    wapp.styles.use(style);

    const {
        DefaultPostsComponent,
        ...rest
    } = props;

    const orderPostTypeName = "order";
    const O = capitalize(orderPostTypeName);
    const user = req.wappRequest.user;

    const NewOrder = useMemo(() => postTypesConfig[orderPostTypeName] && postTypesConfig[orderPostTypeName].getPages ? postTypesConfig[orderPostTypeName].getPages().new : null, []);
    const orderStatusManager = useMemo(() => wapp.getTargetObject().postTypes.findPostType({name: orderPostTypeName}).statusManager, []);

    const dialog = useMemo(() => {
        return {}
    }, []);
    const dialogEffect = useMemo(() => ({actions}) => {
        dialog.actions = actions;
    }, [dialog]);
    const miniCart = useMemo(() => {
        return {}
    }, []);
    const miniCartEffect = useMemo(() => ({actions}) => {
        miniCart.actions = actions;
    }, [miniCart]);

    const form = useRef();

    function updateMiniCart() {
        if (miniCart.actions?.update) {
            miniCart.actions.update();
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (form.current) {
            const response = await form.current.onSubmit(e);
            if ((response && response.error) || (response && response.errors)) {

            } else if (response?.record._id) {
                await dialog.actions.close();
                wapp.client.history.push({
                    search: "",
                    hash: "",
                    pathname: appContext.routes[orderPostTypeName + "Route"] + "/" + response.record._id
                });
            }
        }
    };

    const onCancel = async (e) => {
        e.preventDefault();
        await dialog.actions.close();
        await new Promise((resolve) => setTimeout(resolve, 500));
        await reloadPage()
    };

    async function reloadPage() {
        if (postsRef.current?.actions?.update) {
            postsRef.current.actions.update();
        }
    }

    const open = useMemo(() => (e) => {
        if (e) {
            e.preventDefault();
        }
        if (dialog.actions) {

            dialog.actions.open({
                dialogTitle: user?._id ? appContext.titles["new" + O + "Title"] : "Belépés vagy regisztráció",
                dialogContent: <>
                    {(NewOrder) ?
                        <PostContext.Provider
                            value={{
                                name: orderPostTypeName,
                                user,
                                post: null,
                                parentRoute: appContext.routes[orderPostTypeName + "Route"],
                                statusManager: orderStatusManager
                            }}>
                            <NewOrder
                                {...{
                                    formProps: ({formData}) => ({
                                        ref: form,
                                        formData: {...formData, submit: {...formData.submit, hidden: true}},
                                        onChange: updateMiniCart
                                    }),
                                    requestProps: {redirect: null, go: 0}
                                }}
                            />
                        </PostContext.Provider>
                        : null
                    }
                </>,
                cancelText: user?._id ? appContext.labels["continuousShopping" + O + "Text"] : "Bezár",
                submitText: user?._id ? appContext.labels["new" + O + "SubmitLabel"] : "",
                onSubmit: onSubmit,
                onCancel: onCancel,
            })

        }
    }, []);

    useEffect(()=>{
        if (wapp.client.shouldOpenOrder) {
            open()
            wapp.client.shouldOpenOrder = false;
        }
    }, [])

    const route = res.wappResponse.route;
    const {params} = route;
    const {categories, ages} = params;

    const {storage} = appContext;
    const storageName = user?._id ? orderPostTypeName + "_" + user._id : orderPostTypeName;

    function getStorageData() {
        const localStorageData = storage()[storageName] || {};
        const memoData = storage(undefined, true)[storageName] || {};
        return {...localStorageData, ...memoData};
    }

    const postsRef = useRef({});

    return (
        <>
            <DefaultPostsComponent
                {...rest}
                effect={(p)=>{
                    postsRef.current.actions = p?.actions || {};
                    if (rest.effect) {
                        rest.effect(p)
                    }
                }}
                getFooterAction={(props) => {
                    return <AddToCart
                        {...props}
                        onChange={async ({post}) => {
                            const storageData = storage()[storageName] || {};
                            if (post?._id) {
                                const cart = (storageData["record.cart"]) ? JSON.parse(storageData["record.cart"]) : [];
                                const cartItem = cart.find((cartItem) => cartItem?._id === post._id);
                                if (cartItem) {
                                    if (cartItem.q !== post.q) {
                                        cartItem.q = post.q;
                                        const filteredCart = cart.filter((cartItem) => cartItem.q > 0);
                                        storage({
                                            [storageName]: {
                                                ...storageData,
                                                ["record.cart"]: JSON.stringify(filteredCart)
                                            }
                                        });
                                        await updateMiniCart();
                                    }
                                } else {

                                    const enableKeys = [
                                        "price",
                                        "q",
                                        "vatRate",
                                        "subtitle",
                                        "_id",
                                        "thumb",
                                        "title",
                                        "piecesInThePackage",
                                        "disableProduct",
                                        "available",
                                    ]

                                    cart.push({...enableKeys.reduce((cartItem, key)=>{
                                            cartItem[key] = post[key];
                                            return cartItem
                                        }, {})
                                    });

                                    const filteredCart = cart.filter((cartItem) => cartItem.q > 0);
                                    storage({
                                        [storageName]: {
                                            ...storageData,
                                            ["record.cart"]: JSON.stringify(filteredCart)
                                        }
                                    });
                                    await updateMiniCart();
                                }
                            }
                        }}
                    />
                }}
                beforeSetPosts={({posts}) => {
                    const storageData = getStorageData();
                    const cart = (storageData["record.cart"]) ? JSON.parse(storageData["record.cart"]) : [];
                    const newPosts = copyObject(posts);
                    cart.forEach((cartItem) => {
                        const post = (cartItem?._id && cartItem?.q) ? newPosts.find((p) => p._id === cartItem._id) : null;
                        if (post) {
                            post.q = cartItem.q;
                        }
                    });
                    newPosts.forEach((post)=>{
                        if (post?.q) {
                            const cartItem = cart.find((cartItem)=>cartItem?._id === post._id)
                            if (!cartItem?.q) {
                                post.q = 0
                            }
                        }
                    })
                    return newPosts;
                }}
            />
            <Dialog
                fullWidth
                maxWidth={"md"}
                effect={dialogEffect}
                className={style.dialog}
                paperClassName={style.dialogPaper}
            />
            {(categories || ages) ?
                <MiniCart
                    onClick={open}
                    effect={miniCartEffect}
                /> : null
            }
        </>
    )
}

const WappComponent = withWapp(Posts);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
