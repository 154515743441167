import React, {useContext} from "react";

import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import {WappContext} from "wapplr-react/dist/common/Wapp";

import Post from "../../components/Post";
import Posts from "../../components/Posts";
import capitalize from "../../utils/capitalize";
import AppContext from "../../components/App/context";

export function DefaultComponent(props) {
    return (
        <Post {...props}/>
    )
}

export function DefaultArchiveComponent(props) {

    const {name = "post"} = props;
    const context = useContext(WappContext);
    const appContext = useContext(AppContext);

    const N = capitalize(name);
    const Ns = (N.endsWith("y")) ? N.slice(0, -1) + "ies" : N + "s";

    const {wapp, req} = context;

    const wappRequest = req.wappRequest;
    const utils = getUtils(context);

    const isAdmin = wappRequest.user?._status_isFeatured;

    const selectable = typeof props.selectable !== "undefined" ? props.selectable : ({posts}) => {
        const deletionIsPossible = (posts?.length) ? posts.filter((post) => post._status_isNotDeleted) : false;
        return deletionIsPossible.length && deletionIsPossible.length === posts?.length && isAdmin;
    };
    const selectFunctions = (selectable) ? [
        {
            role: function ({posts}) {
                const deletionIsPossible = (posts?.length) ? posts.filter((post) => post._status_isNotDeleted) : false;
                return deletionIsPossible.length && deletionIsPossible.length === posts?.length;
            },
            label: appContext.titles["dialogDelete" + Ns + "Title"],
            func: (e, {dialog, selected}) => {

                dialog.actions.open({
                    dialogTitle: appContext.titles["dialogDelete" + Ns + "Title"],
                    dialogContent: appContext.messages["delete" + Ns + "Question"],
                    cancelText: appContext.labels["cancel" + N + "Text"],
                    submitText: appContext.labels["delete" + Ns + "Text"],
                    onSubmit: async function () {

                        const errors = [];

                        const responses = await Promise.allSettled(selected.value.map(async (postId) => {
                            return await utils.sendRequest({requestName: name + "Delete", args: {_id: postId}});
                        }));

                        const deletedFiles = responses.filter((r, i) => {
                            const {status} = r;
                            const resp = r.value;
                            if (!resp?.record?._id || status !== "fulfilled") {
                                errors.push({
                                    message: resp?.error?.message || (resp?.errors && resp.errors[0]?.message) || "Error",
                                    path: selected.value[i]
                                });
                                return false;
                            }
                            return true;
                        }).map((r) => {
                            return r.value
                        });

                        if (!errors.length && deletedFiles.length === selected.value.length) {

                            setTimeout(() => {
                                wapp.client.history.push(wapp.wappRequest.path);
                            }, 1000);

                            return true;
                        }
                        return {errors}

                    },
                    successMessage: appContext.messages["delete" + Ns + "Success"]
                })

            }
        }
    ] : null;

    return (
        <Posts
            selectable={selectable}
            selectFunctions={selectFunctions}
            multiple={selectable}
            type={"list"}
            {...props}
        />
    )
}

export default function getComponent(p = {}) {

    const {
        context,
        appContext,
        name = "post",
        archiveComponentProps = {},
        componentProps = {},
        Component = DefaultComponent,
        ArchiveComponent = DefaultArchiveComponent,
        ...rest
    } = p;

    const n = name;
    const ns = (n.endsWith("y")) ? n.slice(0, -1) + "ies" : n + "s";

    const {routes} = appContext;

    const {res} = context;
    const route = res.wappResponse.route;
    const requestPath = route.requestPath;

    return (requestPath.startsWith(routes[ns + "Route"])) ?
        (props) => <ArchiveComponent {...rest} {...archiveComponentProps} {...props} name={name}/> :
        (requestPath.startsWith(routes[n + "Route"])) ?
            (props) =>
                <Component {...(name === "post") ? {layoutType: "article"} : {}} {...rest} {...componentProps} {...props}
                           name={name}/>
            : null;

}
