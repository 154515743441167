import React, {useContext, useEffect, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";

import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import clsx from "clsx";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import AppContext from "../App/context";
import capitalize from "../../utils/capitalize";

import {withMaterialStyles} from "../Template/withMaterial";

import materialStyle from "./materialStyle";
import style from "./style.css";

function MiniCart(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const {wapp, req} = context;

    wapp.styles.use(style);

    const {name = "order", onClick = () => null} = props;

    const N = capitalize(name);
    const user = req.wappRequest.user;

    const utils = getUtils(context);
    const methods = utils.getGlobalState("res.responses.methods");
    const {storage} = appContext;
    const storageName = user?._id ? name + "_" + user._id : name;

    function getStorageData() {
        const localStorageData = storage()[storageName] || {};
        const memoData = storage(undefined, true)[storageName] || {};
        return {...localStorageData, ...memoData};
    }

    function cartTotal() {
        const storageData = getStorageData();
        const cartItems = (storageData["record.cart"]) ? JSON.parse(storageData["record.cart"]) : [];
        return cartItems.reduce((n, cartItem) => {
            const total = cartItem.price * cartItem.q || 0;
            n = n + total;
            return n;
        }, 0)
    }

    function getCartLength() {
        const storageData = getStorageData();
        const cartItems = (storageData["record.cart"]) ? JSON.parse(storageData["record.cart"]) : [];
        const cartItemsWithQ = cartItems.filter((cartItem) => cartItem.q > 0);
        return cartItemsWithQ.length;
    }

    function methodPrice() {
        const storageData = getStorageData();
        const method = (storageData["record.method"]?._id && methods?.items?.length) ? methods.items.find((p) => p._id === storageData["record.method"]._id) : null;
        return method?.price || 0;
    }

    const [total, setTotal] = useState(cartTotal() + methodPrice());
    const [cartLength, setCartLength] = useState(getCartLength());

    function update() {
        const totalFromStorage = cartTotal() + methodPrice();
        if (totalFromStorage !== total) {
            setTotal(totalFromStorage)
        }
        const cartLengthFromStorage = getCartLength();
        if (cartLengthFromStorage !== cartLength) {
            setCartLength(cartLengthFromStorage);
        }
    }

    useEffect(() => {
        update();
    }, [total]);

    useEffect(() => {
        if (props.effect) {
            props.effect({
                actions: {
                    update
                }
            })
        }
        return () => {
            if (props.effect) {
                props.effect({
                    actions: {
                        update: () => null
                    }
                })
            }
        }
    });

    if (!total || !cartLength) {
        return null;
    }

    return (
        <div
            className={style.miniCart}
            onClick={onClick}
        >
            <div className={style.row}>
                <div className={clsx(style.total, style.wide)}>{appContext.labels[name + "TotalText"]({total})}</div>
                <div>
                    <Button
                        className={style.wide}
                        onClick={onClick}
                        children={appContext.labels["checkout" + N + "Text"]}
                        variant={"contained"}
                        color={"secondary"}
                        startIcon={<ShoppingCartIcon/>}
                    />
                    <Button
                        className={style.narrow}
                        onClick={onClick}
                        children={appContext.labels[name + "TotalText"]({total})}
                        variant={"contained"}
                        color={"secondary"}
                        startIcon={<ShoppingCartIcon/>}
                    />
                </div>
            </div>
        </div>
    )
}

const WappComponent = withWapp(MiniCart);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
