import React from "react";
import clsx from "clsx";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";

import {withMaterialStyles} from "../Template/withMaterial";

import style from "./style.css";
import materialStyle from "./materialStyle";

import Player from "./Player.js";
import Controls from "./Controls.js";

class VideoPlayer extends React.Component {

    constructor(props, context) {

        super(props, context);

        this.refElements = {};

        const {src, cover, autoplay, title} = props;

        this.state = {
            isMounted: true,
            src: src,
            cover: cover,
            autoplay: autoplay,
            title: title
        };

        this.addStyle = this.addStyle.bind(this);
        this.setRef = this.setRef.bind(this);

        this.removeStyle = null;

        const {wapp} = context;
        if (wapp.target === "node") {
            this.addStyle();
        }

    }

    componentDidMount() {
        this.addStyle();
    }

    componentWillUnmount() {
        if (this.removeStyle) {
            this.removeStyle();
        }
        this.setBodyHeight().then();
    }

    addStyle = () => {
        if (this.removeStyle) {
            this.removeStyle();
        }
        const {wapp} = this.context;
        this.removeStyle = wapp.styles.add(style);
    };
    setRef = (a, e) => {
        this.refElements[a] = e;
    };

    getLocalStoreData = () => {
        const {appContext, id = "video1"} = this.props;
        const {storage} = appContext;
        const storeParamPrefix = "video_player_" + id + "_";
        return {storage, storeParamPrefix};
    };
    onPlay = () => {

        const media = this.refElements["media"];
        const duration = (media && media.getDuration) ? media.getDuration() : 0;
        const currentTime = (media && media.getCurrentTime && media.getCurrentTime()) ? media.getCurrentTime() : 0.025;
        const isPlaying = true;

        const r = {duration, currentTime, isPlaying};

        const controls = this.refElements["controls"];
        if (controls) {
            controls.setData(r);
            controls.activate({timeOut: 100});
        }

        this.setElementClassName().then();

    };
    onPause = async () => {

        const media = this.refElements["media"];
        const duration = (media && media.getDuration) ? media.getDuration() : 0;
        const currentTime = (media && media.getCurrentTime) ? media.getCurrentTime() : 0;
        const isPlaying = false;

        const r = {duration, currentTime, isPlaying};

        const controls = this.refElements["controls"];
        if (controls) {
            await controls.setData(r);
            controls.activate();
        }
        this.setElementClassName().then();

    };
    onStop = async () => {

        const r = {currentTime: 0, isPlaying: false};
        const controls = this.refElements["controls"];
        if (controls) {
            await controls.setData(r);
            controls.activate();
        }
        this.setElementClassName().then();

    };
    onMute = () => {
        const {storage, storeParamPrefix} = this.getLocalStoreData();
        storage({[storeParamPrefix + "muted"]: true});

        const r = {muted: true};
        const controls = this.refElements["controls"];
        if (controls) {
            controls.setData(r);
        }
    };
    onUnmute = () => {

        const {storage, storeParamPrefix} = this.getLocalStoreData();
        storage({[storeParamPrefix + "muted"]: false});

        const r = {muted: false};
        const controls = this.refElements["controls"];
        if (controls) {
            controls.setData(r);
        }

    };

    onClick = (data = {}) => {

        const player = this.refElements["player"];
        const controls = this.refElements["controls"];

        if (controls) {
            controls.activate();
        }

        const {
            type
        } = data;

        if (type === "playPause") {
            player.playPause();
        }

    };
    onEnterTopLayer = () => {
        const controls = this.refElements["controls"];
        if (controls) {
            controls.activate({disableAutoHide: true});
        }
    };
    onLeaveTopLayer = () => {
        const controls = this.refElements["controls"];
        if (controls) {
            controls.activate({disableAutoHide: false});
        }
    };

    //Controls

    play = () => {
        const player = this.refElements["player"];
        player.play();
    };
    pause = () => {
        const player = this.refElements["player"];
        player.pause();
    };
    stop = () => {
        const player = this.refElements["player"];
        player.stop();
    };
    mute = () => {
        const player = this.refElements["player"];
        player.mute();
    };
    unmute = () => {
        const player = this.refElements["player"];
        player.unmute();
    };

    getClassName = () => {

        const {
            enableBlackLayer,
            enableFullScreen,
            simpleControls,
            theme,
            backgroundColor,
            bigPlayButtonTheme,
            relative
        } = this.props;

        const player = this.refElements["player"];
        const isPlaying = !!(player && player.state && player.state.isPlaying);

        const fullscreenClass = (typeof enableFullScreen === "string") ? enableFullScreen : "enableFullScreen";

        return clsx(
            style.container,
            {
                [style[fullscreenClass]]: enableFullScreen,
                [style.enableBlackLayer]: enableBlackLayer,
                [style.relative]: relative,
                [style.simpleControls]: simpleControls,
                [style[theme]]: theme,
                [style[backgroundColor]]: backgroundColor,
                [style[bigPlayButtonTheme]]: bigPlayButtonTheme,
                [style.isPlaying]: isPlaying,
            }
        );

    };
    setBodyHeight = async () => {

        const {enableFullScreen} = this.props;

        if (typeof window !== "undefined" && enableFullScreen) {

            await new Promise((resolve => setTimeout(resolve, 1)));

            if (typeof this.tempBodyClassName === "undefined") {
                this.tempBodyClassName = document.body.className;
            }

            const player = this.refElements["player"];

            const isPlaying = !!(player && player.state && player.state.isPlaying);

            if (isPlaying) {

                const newClassName = clsx({
                    [this.tempBodyClassName]: this.tempBodyClassName,
                    [style.bodyClassWhenFullScreen]: true
                });

                if (document.body.className !== newClassName) {
                    document.body.className = newClassName;
                }

            } else {
                const newClassName = (this.tempBodyClassName) ? this.tempBodyClassName : "";
                if (newClassName !== document.body.className) {
                    document.body.className = newClassName;
                }
                this.tempBodyClassName = undefined;
            }
        }
    };
    setElementClassName = async () => {

        await new Promise((resolve) => setTimeout(resolve, 1));

        const playerRoot = this.refElements["playerRoot"];
        if (playerRoot) {
            const className = this.getClassName();
            if (playerRoot.className !== className) {
                playerRoot.className = className;
            }
        }

        this.setBodyHeight().then();
    };

    render() {

        const {enableFullScreen, enableBlackLayer, layerInteraction, bigPlayButton} = this.props;

        const {storage, storeParamPrefix} = this.getLocalStoreData();

        const setRef = this.setRef;
        const onPlay = this.onPlay;
        const onPause = this.onPause;
        const onStop = this.onStop;
        const onMute = this.onMute;
        const onUnmute = this.onUnmute;
        const onClick = this.onClick;
        const onEnterTopLayer = this.onEnterTopLayer;
        const onLeaveTopLayer = this.onLeaveTopLayer;

        const play = this.play;
        const pause = this.pause;
        const stop = this.stop;
        const mute = this.mute;
        const unmute = this.unmute;

        const state = this.state;

        const videoUrl = state.src;
        const title = state.title;
        const autoplay = state.autoplay;
        const muted = storage()[storeParamPrefix + "muted"];
        const className = this.getClassName();

        return (
            <div className={className} ref={function (e) {
                setRef("playerRoot", e)
            }}>
                {((videoUrl && enableBlackLayer) || (videoUrl && enableFullScreen)) ?
                    <div
                        onClick={function () {
                            onClick({type: "playPause"})
                        }}
                        className={style.blackLayer}
                    />
                    : null
                }
                {(videoUrl) ?
                    <div className={style.playerContainer}>
                        <Player
                            ref={(e) => setRef("player", e)}
                            style={style}
                            setRef={setRef}

                            onPlay={onPlay}
                            onPause={onPause}
                            onStop={onStop}
                            onMute={onMute}
                            onUnmute={onUnmute}

                            onClick={onClick}
                            onEnterTopLayer={onEnterTopLayer}
                            onLeaveTopLayer={onLeaveTopLayer}

                            src={videoUrl}
                            autoplay={autoplay}
                            muted={muted}
                            layerInteraction={layerInteraction}
                            cover={state.cover}
                            bigPlayButton={bigPlayButton}

                        />
                        <Controls
                            ref={(e) => setRef("controls", e)}
                            style={style}
                            setRef={setRef}
                            title={title}
                            isPlaying={false}
                            currentTime={0}
                            duration={0}
                            onPlay={play}
                            onPause={pause}
                            onStop={stop}
                            onMute={mute}
                            onUnmute={unmute}
                            enableMute={(layerInteraction === "pause")}
                            muted={muted}
                            autoHide={bigPlayButton}
                        />
                    </div>
                    : null
                }
            </div>
        )
    }

}

VideoPlayer.contextType = WappContext;

const WappComponent = withWapp(VideoPlayer);

export default withMaterialStyles(materialStyle, WappComponent);
