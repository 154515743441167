import React from "react";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import FeaturedForm from "./Forms/FeaturedForm";
import RemoveFeaturedForm from "./Forms/RemoveFeaturedForm";
import capitalize from "../../utils/capitalize";

export function getMenuProps({appContext, menuActions, dialog, utils, name, post, parentRoute, redirects = {}}) {

    const {titles, labels, messages} = appContext;

    const N = capitalize(name);

    return {
        name,
        post,
        appContext,
        onDelete: function () {
            menuActions?.actions.close();
            dialog.actions.open({
                dialogTitle: titles["dialogDelete" + N + "Title"],
                dialogContent: messages["delete" + N + "Question"],
                cancelText: labels["cancel" + N + "Text"],
                submitText: labels["delete" + N + "Text"],
                onSubmit: async function () {
                    return await utils.sendRequest({
                        requestName: name + "Delete",
                        args: {_id: post?._id},
                        redirect: redirects.onDeleteSuccess || {
                            pathname: parentRoute + "/" + post._id,
                            search: "",
                            hash: ""
                        },
                        timeOut: 1000
                    });
                },
                successMessage: messages["delete" + N + "Success"]
            })
        },
        onBan: function () {
            menuActions?.actions.close();
            dialog.actions.open({
                dialogTitle: titles["dialogBan" + N + "Title"],
                dialogContent: messages["ban" + N + "Question"],
                cancelText: labels["cancel" + N + "Text"],
                submitText: labels["ban" + N + "Text"],
                onSubmit: async function () {
                    return await utils.sendRequest({
                        requestName: name + "Ban",
                        args: {_id: post?._id},
                        redirect: redirects.onBanSuccess || {
                            pathname: parentRoute + "/" + post._id,
                            search: "",
                            hash: ""
                        },
                        timeOut: 1000
                    });
                },
                successMessage: messages["ban" + N + "Success"],
            })
        },
        onApprove: function () {
            menuActions?.actions.close();
            dialog.actions.open({
                dialogTitle: titles["dialogApprove" + N + "Title"],
                dialogContent: messages["approve" + N + "Question"],
                cancelText: labels["cancel" + N + "Text"],
                submitText: labels["approve" + N + "Text"],
                onSubmit: async function () {
                    return await utils.sendRequest({
                        requestName: name + "Approve",
                        args: {_id: post?._id},
                        redirect: redirects.onApproveSuccess || {
                            pathname: parentRoute + "/" + post._id,
                            search: "",
                            hash: ""
                        },
                        timeOut: 1000
                    });
                },
                successMessage: messages["approve" + N + "Success"],
            })
        },
        onFeatured: function () {
            menuActions?.actions.close();
            dialog.actions.open({
                dialogTitle: titles["dialogProtection" + N + "Title"],
                dialogContent: messages["protection" + N + "Question"],
                Form: (props) => <FeaturedForm {...props} name={name} post={post}/>,
                cancelText: labels["cancel" + N + "Text"],
                submitText: labels["protection" + N + "Text"],
                onSubmit: async function (e, formData) {
                    return await utils.sendRequest({
                        requestName: name + "Featured",
                        args: formData,
                        redirect: redirects.onFeaturedSuccess || {
                            pathname: parentRoute + "/" + post._id,
                            search: "",
                            hash: ""
                        },
                        timeOut: 1000
                    });
                },
                successMessage: messages["protection" + N + "Success"],
            })
        },
        onRemoveFeatured: function () {
            menuActions?.actions.close();
            dialog.actions.open({
                dialogTitle: titles["dialogRemoveProtection" + N + "Title"],
                dialogContent: messages["removeProtection" + N + "Question"],
                Form: (props) => <RemoveFeaturedForm {...props} name={name} post={post}/>,
                cancelText: labels["cancel" + N + "Text"],
                submitText: labels["removeProtection" + N + "Text"],
                onSubmit: async function (e, formData) {
                    return await utils.sendRequest({
                        requestName: name + "RemoveFeatured",
                        args: formData,
                        redirect: redirects.onRemoveFeaturedSuccess || {
                            pathname: parentRoute + "/" + post._id,
                            search: "",
                            hash: ""
                        },
                        timeOut: 1000
                    });
                },
                successMessage: messages["removeProtection" + N + "Success"]
            })
        },
    };
}

function getMenu(props = {}) {

    const {appContext, /*statusManager, */name} = props;
    const {menus, /*userStatusManager*/} = appContext;

    const N = capitalize(name);

    return [
        {
            name: menus["edit" + N + "Menu"],
            href: function (p) {
                return (props.getEditHref) ? props.getEditHref(p) : (p.post?._id) ? "/" + p.post._id + "/edit" : "/";
            },
            role: function (p) {
                const isAdmin = p.user && p.user._status_isFeatured;
                const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id));
                const isBanned = p.post && p.post._status_isBanned;
                const isFeatured = p.post && p.post._status_isFeatured;

                if ((isBanned && !isAdmin) || isFeatured) {
                    return false;
                }

                if (isAdmin || isAuthor) {
                    return !!(p.post?._id && p.page !== "edit" && p.page !== "new");
                }
                return false;
            },
            Icon: EditIcon,
            onlyIcon: true,
            featured: true
        },
        {
            name: menus["delete" + N + "Menu"],
            href: function (p) {
                return (p.post?._id) ? "/" + p.post._id + "/" : "/";
            },
            onClick: function (e, menuProperties) {
                const context = menuProperties.context;
                const utils = getUtils(context);
                if (props.onDelete) {
                    props.onDelete(e, utils);
                }
                e.preventDefault();
            },
            role: function (p) {
                const isAdmin = p.user && p.user._status_isFeatured;
                const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id));
                const isBanned = p.post && p.post._status_isBanned;
                const isFeatured = p.post && p.post._status_isFeatured;

                if ((isBanned && !isAdmin) || isFeatured) {
                    return false;
                }

                if (isAdmin || isAuthor) {
                    const isNotDeleted = p.post && p.post._status_isNotDeleted;
                    return !!(p.post?._id && isNotDeleted && p.page !== "new");
                }

                return false;
            },
            Icon: DeleteIcon,
            onlyIcon: true,
            featured: true
        },
        {
            name: menus["ban" + N + "Menu"],
            href: function (p) {
                return (p.post?._id) ? "/" + p.post._id + "/" : "/";
            },
            onClick: function (e, menuProperties) {
                const context = menuProperties.context;
                const utils = getUtils(context);
                if (props.onBan) {
                    props.onBan(e, utils);
                }
                e.preventDefault();
            },
            role: function (p) {
                const isAdmin = p.user && p.user._status_isFeatured;
                const isBanned = p.post && p.post._status_isBanned;
                const isFeatured = p.post && p.post._status_isFeatured;

                if (isBanned || isFeatured) {
                    return false;
                }

                if (isAdmin) {
                    return !!(p.post?._id && p.page !== "new");
                }

                return false;
            },
            Icon: BlockIcon,
        },
        {
            name: menus["approve" + N + "Menu"],
            href: function (p) {
                return (p.post?._id) ? "/" + p.post._id + "/" : "/";
            },
            onClick: function (e, menuProperties) {
                const context = menuProperties.context;
                const utils = getUtils(context);
                if (props.onApprove) {
                    props.onApprove(e, utils);
                }
                e.preventDefault();
            },
            role: function (p) {
                const isAdmin = p.user && p.user._status_isFeatured;
                const isValidated = p.post && p.post._status_isValidated;
                const isApproved = p.post && p.post._status_isApproved;
                const isFeatured = p.post && p.post._status_isFeatured;

                if (!isValidated || isApproved || isFeatured) {
                    return false;
                }

                if (isAdmin) {
                    return !!(p.post?._id && p.page !== "new");
                }

                return false;
            },
            Icon: CheckCircleIcon,
        },
        {
            name: menus["protection" + N + "Menu"],
            href: function (p) {
                return (p.post?._id) ? "/" + p.post._id + "/" : "/";
            },
            onClick: function (e, menuProperties) {
                const context = menuProperties.context;
                const utils = getUtils(context);
                if (props.onFeatured) {
                    props.onFeatured(e, utils);
                }
                e.preventDefault();
            },
            role: function (p) {
                const isAdmin = p.user && p.user._status_isFeatured;
                const isApproved = p.post && p.post._status_isApproved;
                const isFeatured = p.post && p.post._status_isFeatured;

                if (!isApproved || isFeatured) {
                    return false;
                }

                if (isAdmin) {
                    return !!(p.post?._id && p.page !== "new");
                }

                return false;
            },
            Icon: StarIcon,
        },
        {
            name: menus["removeProtection" + N + "Menu"],
            href: function (p) {
                return (p.post?._id) ? "/" + p.post._id + "/" : "/";
            },
            onClick: function (e, menuProperties) {
                const context = menuProperties.context;
                const utils = getUtils(context);
                if (props.onRemoveFeatured) {
                    props.onRemoveFeatured(e, utils);
                }
                e.preventDefault();
            },
            role: function (p) {
                const isAdmin = p.user && p.user._status_isFeatured;
                const isFeatured = p.post && p.post._status_isFeatured;

                if (!isFeatured) {
                    return false;
                }

                if (isAdmin) {
                    return !!(p.post?._id && p.page !== "new");
                }

                return false;
            },
            Icon: StarBorderIcon,
        },
    ];
}

export default getMenu;
