import React, {useContext, useEffect, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import {withMaterialStyles} from "../Template/withMaterial";
import materialStyle from "./materialStyle";
import style from "./style.css";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import clsx from "clsx";
import AppContext from "../App/context";

function Summary(props) {

    const appContext = useContext(AppContext);
    const {effect, callback, name = "post", onChange} = props;

    const context = useContext(WappContext);
    const [rows, _setRows] = useState(props.getRows ? props.getRows() : (props.rows) ? props.rows : []);
    const [error] = useState(props.error);
    const [helperText] = useState(props.helperText);

    const {wapp} = context;

    wapp.styles.use(style);

    async function setRows(newRows) {
        if (JSON.stringify(newRows) !== JSON.stringify(rows)) {
            await _setRows(newRows);

            const value = newRows.reduce((n, row) => {
                const total = row.total;
                n = n + total;
                return n;
            }, 0);

            await onChange({target: {value}})

        } else {
            const value = rows.reduce((n, row) => {
                const total = row.total;
                n = n + total;
                return n;
            }, 0);
            if (value !== props.value) {
                await onChange({target: {value}})
            }
        }
    }

    const rowsText = (rows) ? JSON.stringify(rows) : "";

    useEffect(() => {
        if (effect) {
            effect({actions: {setRows}})
        }
        return () => {
            if (effect) {
                effect({actions: {setRows: () => null}})
            }
        }
    });

    useEffect(() => {
        if (callback) {
            callback();
        }
    }, [rowsText]);

    return (
        <div className={style.summary}>
            {rows.length ?
                <Table className={style.table}>
                    <TableHead>
                        <TableRow className={style.tableRow}>
                            <TableCell className={style.tableCell}>
                                {""}
                            </TableCell>
                            <TableCell className={style.tableCell}>
                                {appContext.labels[name + "NetPriceLabel"]}
                            </TableCell>
                            <TableCell className={style.tableCell}>
                                {appContext.labels[name + "BrPriceLabel"]}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => {

                            const total = row.total;
                            const totalText = appContext.labels[name + "TotalText"]({total});
                            const totalNet = row.totalNet;
                            const totalNetText = appContext.labels[name + "TotalText"]({total: totalNet});

                            return (
                                <React.Fragment key={i}>
                                    <TableRow className={style.tableRow}>
                                        <TableCell className={style.tableCell}>
                                            {row.title}
                                        </TableCell>
                                        <TableCell className={style.tableCell}>
                                            {totalNetText}
                                        </TableCell>
                                        <TableCell className={style.tableCell}>
                                            {totalText}
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                        <TableRow className={style.tableRow}>
                            <TableCell
                                className={style.tableCell}>{appContext.labels[name + "SummaryLabel"]}</TableCell>
                            <TableCell className={style.tableCell}>
                                {appContext.labels[name + "TotalText"]({
                                    total: rows.reduce((n, row) => {
                                        const total = row.totalNet;
                                        n = n + total;
                                        return n;
                                    }, 0)
                                })}
                            </TableCell>
                            <TableCell className={style.tableCell}>
                                {appContext.labels[name + "TotalText"]({
                                    total: rows.reduce((n, row) => {
                                        const total = row.total;
                                        n = n + total;
                                        return n;
                                    }, 0)
                                })}
                            </TableCell>
                        </TableRow>
                        {(helperText) ?
                            <TableRow>
                                <TableCell
                                    className={clsx(
                                        style.helperText,
                                        {[style.error]: error}
                                    )}
                                >
                                    {helperText}
                                </TableCell>
                                <TableCell/>
                                <TableCell/>
                            </TableRow> : null
                        }
                    </TableBody>
                </Table>
                : null
            }
        </div>
    )
}

const WappComponent = withWapp(Summary);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
