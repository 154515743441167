import React, {useContext, useState} from "react";

import {WappContext} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import ListItem from "@mui/material/ListItem";
import Snackbar from "@mui/material/Snackbar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArticleOutlined from "@mui/icons-material/ArticleOutlined";
import ListItemText from "@mui/material/ListItemText";

import getDefaultMenu from "../../components/Post/menu";
import PostContext from "../../components/Post/context";

function UpdateFromDistributor(props) {

    const context = useContext(WappContext);
    const postContext = useContext(PostContext);
    const utils = getUtils(context);

    const [snackMessage, setSnackMessage] = useState("");

    const handleCloseSnackbar = function (/*e, reason*/) {
        if (snackMessage) {
            setSnackMessage("")
        }
    };

    const {post = postContext.post, name, label} = props;

    return (
        <ListItem
            style={{cursor: 'pointer'}}
            onClick={async () => {
                if (post?._id) {
                    const response = await utils.sendRequest({
                        requestName: name + "UpdateFromDistributor",
                        args: {
                            _id: post._id,
                            forceUpdate: typeof window !== "undefined" ? !!(window.forceUpdateFromDistributor) : false
                        }
                    });
                    if (response.replaceData) {
                        try {
                            response.replaceData = JSON.parse(response.replaceData)
                            setSnackMessage(
                                (response.wait) ? "Várj " + response.wait + " másodpercet" :
                                Object.keys(response.replaceData).length ?
                                "Frissített paraméterek: [" + Object.keys(response.replaceData).join(", ") + "]" :
                                "Nem kellett frissíteni semmit"
                            )
                        } catch (e) {
                        }
                    } else {
                        setSnackMessage("error")
                    }

                    console.log(response)
                }
            }}
        >
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={!!(snackMessage)}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackMessage}
            />
            <ListItemIcon>
                <ArticleOutlined/>
            </ListItemIcon>
            <ListItemText>
                {label}
            </ListItemText>
        </ListItem>
    )
}

function UpdateAvailableFromWholesale(props) {

    const context = useContext(WappContext);
    const postContext = useContext(PostContext);
    const utils = getUtils(context);

    const [snackMessage, setSnackMessage] = useState("");

    const handleCloseSnackbar = function (/*e, reason*/) {
        if (snackMessage) {
            setSnackMessage("")
        }
    };

    const {post = postContext.post, name, label} = props;

    return (
        <ListItem
            style={{cursor: 'pointer'}}
            onClick={async () => {
                if (post?._id) {
                    const response = await utils.sendRequest({
                        requestName: name + "UpdateAvailableFromWholesale",
                        args: {
                            _id: post._id,
                            forceUpdate: typeof window !== "undefined" ? !!(window.forceUpdateAvailableFromWholesale) : false
                        }
                    });
                    if (response.replaceData) {
                        try {
                            response.replaceData = JSON.parse(response.replaceData)
                            setSnackMessage(
                                (response.wait) ? "Várj " + response.wait + " másodpercet" :
                                    Object.keys(response.replaceData).length ?
                                        "Frissített paraméterek: [" + Object.keys(response.replaceData).join(", ") + "]" :
                                        "Nem kellett frissíteni semmit"
                            )
                        } catch (e) {
                        }
                    } else {
                        setSnackMessage("error")
                    }

                    console.log(response)
                }
            }}
        >
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={!!(snackMessage)}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackMessage}
            />
            <ListItemIcon>
                <ArticleOutlined/>
            </ListItemIcon>
            <ListItemText>
                {label}
            </ListItemText>
        </ListItem>
    )
}

function UpdateCheapestPrice(props) {

    const context = useContext(WappContext);
    const postContext = useContext(PostContext);
    const utils = getUtils(context);

    const [snackMessage, setSnackMessage] = useState("");

    const handleCloseSnackbar = function (/*e, reason*/) {
        if (snackMessage) {
            setSnackMessage("")
        }
    };

    const {post = postContext.post, name, label} = props;

    return (
        <ListItem
            style={{cursor: 'pointer'}}
            onClick={async () => {
                if (post?._id) {
                    const response = await utils.sendRequest({
                        requestName: name + "UpdateCheapestPrice",
                        args: {
                            _id: post._id,
                            forceUpdate: typeof window !== "undefined" ? !!(window.forceUpdateCheapestPrice) : false
                        }
                    });
                    if (response.replaceData) {
                        try {
                            response.replaceData = JSON.parse(response.replaceData)
                            setSnackMessage(
                                (response.wait) ? "Várj " + response.wait + " másodpercet" :
                                    Object.keys(response.replaceData).length ?
                                        "Frissített paraméterek: [" + Object.keys(response.replaceData).join(", ") + "]" :
                                        "Nem kellett frissíteni semmit"
                            )
                        } catch (e) {
                        }
                    } else {
                        setSnackMessage("error")
                    }

                    console.log(response)
                }
            }}
        >
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={!!(snackMessage)}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackMessage}
            />
            <ListItemIcon>
                <ArticleOutlined/>
            </ListItemIcon>
            <ListItemText>
                {label}
            </ListItemText>
        </ListItem>
    )
}

export default function getMenu(p) {

    const {
        name = "product",
        post,
        appContext,
        ...rest
    } = p;

    return [
        ...getDefaultMenu({name, post, appContext, ...rest}).map((m) => {
            delete m.featured;
            return m;
        }),
        {
            Element: function () {
                return (
                    <UpdateFromDistributor name={name} label={appContext.menus[name + "UpdateFromDistributor"]}
                                           post={post}/>
                )
            },
            role: function (p) {
                const isAdmin = p.user && p.user._status_isFeatured;
                const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id));
                const isBanned = p.post && p.post._status_isBanned;
                const isFeatured = p.post && p.post._status_isFeatured;

                if ((isBanned && !isAdmin) || isFeatured) {
                    return false;
                }

                if (isAdmin || isAuthor) {
                    return !!(p.post?._id && p.page !== "edit" && p.page !== "new");
                }
                return false;
            },
        },
        {
            Element: function () {
                return (
                    <UpdateAvailableFromWholesale name={name} label={appContext.menus[name + "UpdateAvailableFromWholesale"]}
                                           post={post}/>
                )
            },
            role: function (p) {
                const isAdmin = p.user && p.user._status_isFeatured;
                const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id));
                const isBanned = p.post && p.post._status_isBanned;
                const isFeatured = p.post && p.post._status_isFeatured;

                if ((isBanned && !isAdmin) || isFeatured) {
                    return false;
                }

                if (isAdmin || isAuthor) {
                    return !!(p.post?._id && p.page !== "edit" && p.page !== "new");
                }
                return false;
            },
        },
        {
            Element: function () {
                return (
                    <UpdateCheapestPrice name={name} label={appContext.menus[name + "UpdateCheapestPrice"]}
                                           post={post}/>
                )
            },
            role: function (p) {
                const isAdmin = p.user && p.user._status_isFeatured;
                const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id));
                const isBanned = p.post && p.post._status_isBanned;
                const isFeatured = p.post && p.post._status_isFeatured;

                if ((isBanned && !isAdmin) || isFeatured) {
                    return false;
                }

                if (isAdmin || isAuthor) {
                    return !!(p.post?._id && p.page !== "edit" && p.page !== "new");
                }
                return false;
            },
        },
    ]
}
