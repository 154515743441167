import getUserPostTypeConfig from "./user";
import getTagPostTypeConfig from "./tag";
import getFilePostTypeConfig from "./file";
import getPostPostTypeConfig from "./post";
import getDocumentPostTypeConfig from "./document";
import getEmailPostTypeConfig from "./email";

import getCategoryPostTypeConfig from "./category";
import getAgePostTypeConfig from "./age";
import getProductPostTypeConfig from "./product";
import getMethodPostTypeConfig from "./method";
import getOrderPostTypeConfig from "./order";
import getPaymentPostTypeConfig from "./payment";

export const postTypesConfig = {
    user: getUserPostTypeConfig,
    tag: getTagPostTypeConfig,
    file: getFilePostTypeConfig,
    post: getPostPostTypeConfig,
    document: getDocumentPostTypeConfig,
    email: getEmailPostTypeConfig,

    category: getCategoryPostTypeConfig,
    age: getAgePostTypeConfig,
    product: getProductPostTypeConfig,
    method: getMethodPostTypeConfig,
    order: getOrderPostTypeConfig,
    payment: getPaymentPostTypeConfig
};

export function runPostTypesConfigSync({action, rKey, p = {}}) {
    const config = postTypesConfig;
    return Object.keys(config).map(function (key) {
        const postTypeConfig = config[key];
        if (postTypeConfig[action]) {
            const r = postTypeConfig[action](p);
            return (rKey) ? r[rKey] : r;
        }
        return null;
    });
}

export async function runPostTypesConfig({action, rKey, p = {}}) {
    const config = postTypesConfig;
    return await Promise.all(
        Object.keys(config).map(async function (key) {
            const postType = config[key];
            if (postType[action]) {
                const r = await postType[action](p);
                return (rKey) ? r[rKey] : r;
            }
            return null;
        })
    );
}
