import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "email";

    const n = name;
    const N = capitalize(n);
    const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";
    const Ns = capitalize(ns);

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels,
            ["new" + N + "SubmitLabel"]: "Mentés",
            ["restore" + N + "SubmitLabel"]: "Visszaállítás",
            ["save" + N + "SubmitLabel"]: "Mentés",
            ["cancel" + N + "Text"]: "Mégsem",
            ["delete" + N + "Text"]: "Törlés",
            ["delete" + Ns + "Text"]: "Email sablonok törlése",
            ["ban" + N + "Text"]: "Tiltás",
            ["approve" + N + "Text"]: "Jóváhagyás",
            ["protection" + N + "Text"]: "Védettnek jelölés",
            ["removeProtection" + N + "Text"]: "Védettség megszüntetése",
            ["select" + N + "Text"]: "Kiválasztás",
            ["add" + N + "Text"]: "Hozzáadás",
            ["date" + N + "Format"]: ({dateText = ""}) => {
                if (!dateText) {
                    return dateText;
                }
                try {
                    return new Date(dateText).toLocaleString("hu-HU");
                } catch (e) {
                }
                return dateText;
            },
            [ns + "SortRelevance"]: "Relevancia",

            ["send" + N + "SubmitLabel"]: "Hírlevél küldése",
        },
        menus: {
            ...menus,
            ["user" + Ns + "Menu"]: "Email sablonok",
            ["my" + Ns + "Menu"]: "Email sablonaim",
            ["new" + N + "Menu"]: "Új email sablon",
            [ns + "Menu"]: "Email sablonok",
            ["deleted" + Ns + "Menu"]: "Törölt email sablonok",
            ["banned" + Ns + "Menu"]: "Véglegesen törölt email sablonok",
            ["protection" + Ns + "Menu"]: "Védett email sablonok",
            ["missingData" + Ns + "Menu"]: "Hiányzó adatok",
            ["lowAuthorStatus" + Ns + "Menu"]: "Szerző alacsony státusszal",

            ["edit" + N + "Menu"]: "Szerkesztés",
            ["delete" + N + "Menu"]: "Törlés",
            ["ban" + N + "Menu"]: "Tiltás",
            ["approve" + N + "Menu"]: "Jóváhagyás",
            ["protection" + N + "Menu"]: "Védettnek jelölés",
            ["removeProtection" + N + "Menu"]: "Védettség törlése",

            "sendNewsLetter": "Hírlevél küldése"
        },
        messages: {
            ...messages,
            ["new" + N + "SuccessMessage"]: "Az új email sablon mentésre került",
            ["save" + N + "SuccessMessage"]: "Az email mentésre került",
            ["delete" + N + "Success"]: "A email sablon törölve lett",
            ["delete" + Ns + "Success"]: "A email sablonok törölve lettek",
            ["delete" + N + "Question"]: "Biztosan törli a email sablonot?",
            ["delete" + Ns + "Question"]: "Biztosan törli a email sablonokat?",
            ["ban" + N + "Success"]: "A email sablon véglegesen törölve lett",
            ["ban" + N + "Question"]: "Biztos, hogy a email sablonot véglegesen törölni akarod?",
            ["approve" + N + "Success"]: "A email sablon jóvá lett hagyva",
            ["approve" + N + "Question"]: "Biztosan jóváhagyod a email sablonot",
            ["protection" + N + "Success"]: "A email sablon védettnek jelölve",
            ["protection" + N + "Question"]: "Biztosan védettnek jelölöd a email sablonot?",
            ["removeProtection" + N + "Success"]: "A védettnek jelölés törölve lett",
            ["removeProtection" + N + "Question"]: "Biztosan törlöd a védettnek jelölést?",
            ["thereAreNo" + Ns]: "Nincsenek email sablonok",

            ["send" + N + "SuccessMessage"]: "Hírlevél elküldve"
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
            [n + "Title"]: "Email sablon",
            [ns + "Title"]: "Email sablonok",
            ["new" + N + "Title"]: "Új email sablon",
            ["edit" + N + "Title"]: "Email sablon szerkesztése",
            ["user" + Ns + "Title"]: "Email sablonok",
            ["userDeleted" + Ns + "Title"]: "Törölt email sablonok",
            ["my" + Ns + "Title"]: "Email sablonaim",
            ["myDeleted" + Ns + "Title"]: "Törölt email sablonaim",

            ["dialogDelete" + N + "Title"]: "Email sablon törlése",
            ["dialogBan" + N + "Title"]: "Email sablon törlése véglegesen",
            ["dialogApprove" + N + "Title"]: "Email sablon jóváhagyása",
            ["dialogProtection" + N + "Title"]: "Email sablon védettnek jelölése",
            ["dialogRemoveProtection" + N + "Title"]: "Email sablon védettségének megszüntetése",

            ["dialogDelete" + Ns + "Title"]: "Email sablonok törlése",

            ["statusBanned" + N + "Title"]: "Véglegesen törölt",
            ["statusDeleted" + N + "Title"]: "Törölt",
            ["statusAuthorDeleted" + N + "Title"]: "Szerző törölve",
            ["statusMissingData" + N + "Title"]: "Hiányzó adatok",
            ["statusApproved" + N + "Title"]: "Jóváhagyott",
            ["statusFeatured" + N + "Title"]: "Védett",
            ["statusCreated" + N + "Title"]: "Létrehozva",

            ["banned" + Ns + "Title"]: "Véglegesen törölt email sablonok",
            ["deleted" + Ns + "Title"]: "Törölt email sablonok",
            ["missingData" + Ns + "Title"]: "Hiányzó adatok",
            ["protected" + Ns + "Title"]: "Védett email sablonok",
            ["lowAuthorStatus" + Ns + "Title"]: "Szerző alacsony státusszal",

            ["select" + Ns]: "Email sablonok kiválasztása",
            ["select" + N]: "Email sablon kiválasztása"
        }
    }
}
