import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import Button from "@mui/material/Button";

import {withMaterialStyles} from "../Template/withMaterial";
import AppContext from "../App/context";
import Logo from "../Logo";

import materialStyle from "./materialStyle";
import style from "./style.css";

import Posts from "../Posts";
import {copyObject} from "wapplr/dist/common/utils";

function Home(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const {wapp} = context;
    const utils = getUtils(context);

    const {subscribe} = props;

    wapp.styles.use(style);

    const [user, setUser] = useState(utils.getRequestUser());

    function onUserChange(user) {
        setUser((user?._id) ? user : null);
    }

    useEffect(function didMount() {
        const unsub = subscribe.userChange(onUserChange);
        return function willUnmount() {
            unsub();
        }
    }, [subscribe, user]);

    function onClick(e, menu) {

        const href = menu.href;

        wapp.client.history.push({
            search: "",
            hash: "",
            ...wapp.client.history.parsePath(href)
        });

        e.preventDefault();

    }

    const container = useRef();

    const [scale, setScale] = useState(1);

    useEffect(function didMount() {

        function onResize() {
            if (container.current) {
                setScale(
                    Math.max(container.current.offsetWidth / 1920, container.current.offsetHeight / 1080)
                )
            } else {
                setScale(
                    Math.max(window.innerWidth / 1920, window.innerHeight / 1080)
                )
            }
        }

        function addResizeListeners() {
            if (container.current && typeof ResizeObserver !== "undefined") {
                const resizeObserver = new ResizeObserver((entries) => {
                    onResize(entries);
                });
                resizeObserver.observe(container.current);
                return function removeEventListener() {
                    resizeObserver.disconnect();
                }
            } else {
                window.addEventListener("resize", onResize);
                return function removeEventListener() {
                    window.removeEventListener("resize", onResize);
                }
            }
        }

        const removeResizeListeners = addResizeListeners();

        return function willUnmount() {
            removeResizeListeners();
        }

    }, []);

    const homeServices = useMemo(() => utils.getGlobalState("res.responses.homeServices"), []);
    const services = copyObject(homeServices?.items);

    if (services?.length) {
        services.forEach((service) => {
            service.content_extract = service.content;
        });
    }

    const categoryMenuResponse = utils.getGlobalState("res.responses.categoryMenu");

    return (
        <div className={style.home}>

            <div id={"home"} className={style.first} ref={container}>
                <div className={style.slideContent}>
                    <div className={style.centerContainer}>
                        <div className={style.logoContainer}>
                            <div className={style.logo}>
                                <Logo type={"full"}/>
                            </div>
                            <div className={style.slogan}>
                                <div>
                                    {appContext.titles.homeSubtitle}
                                </div>
                            </div>
                        </div>
                        <div className={style.buttons}>
                            {(user?._id) ?
                                <>
                                    <Button
                                        onClick={(e) => onClick(e, {
                                            href: user?._status_isFeatured ?
                                                appContext.routes.productsRoute + "/limit/100" :
                                                (categoryMenuResponse?.items?.length) ?
                                                    appContext.routes.productsRoute + "/categories/" + categoryMenuResponse.items[0].slug :
                                                    appContext.routes.productsRoute
                                        })}
                                        children={appContext.menus.productsMenu}
                                        variant={"contained"}
                                        color={"secondary"}
                                    />
                                    <Button
                                        onClick={(e) => onClick(e, {href: appContext.routes.userRoute + "/" + user._id})}
                                        children={appContext.menus.dashboardMenu}
                                        variant={"contained"}
                                        color={"secondary"}
                                    />
                                </>
                                :
                                <>
                                    <Button
                                        onClick={(e) => onClick(e, {
                                            href: user?._status_isFeatured ?
                                                appContext.routes.productsRoute + "/limit/100" :
                                                (categoryMenuResponse?.items?.length) ?
                                                    appContext.routes.productsRoute + "/categories/" + categoryMenuResponse.items[0].slug :
                                                    appContext.routes.productsRoute
                                        })}
                                        children={appContext.menus.productsMenu}
                                        variant={"contained"}
                                        color={"secondary"}
                                    />
                                    <Button
                                        onClick={(e) => onClick(e, {href: appContext.routes.accountRoute + "/login"})}
                                        children={appContext.menus.loginMenu}
                                        variant={"contained"}
                                        color={"secondary"}
                                    />
                                    <Button
                                        onClick={(e) => onClick(e, {href: appContext.routes.accountRoute + "/signup"})}
                                        children={appContext.menus.signupMenu}
                                        variant={"contained"}
                                        color={"secondary"}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {(services?.length) ?
                <div id={"services"} className={style.section}>
                    <div className={style.sectionTitle}>{appContext.titles.servicesTitle}</div>
                    <div className={style.servicesContainer}>
                        <Posts
                            type={"card"}
                            posts={services}
                            pageInfo={{}}
                            name={"document"}
                            thereAreNoEntriesMessage={""}
                            disablePageInfo={true}
                            disableAvatars={true}
                            disableTable={true}
                            onClick={() => null}
                        />
                    </div>
                </div> : null
            }

        </div>
    )
}

const WappComponent = withWapp(Home);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
