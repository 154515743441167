import React from "react";

import EmailIcon from "@mui/icons-material/Email";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailOutlined from "@mui/icons-material/EmailOutlined";

import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";
import Content from "./Content";
import Send from "./Send";
import getConstants from "./constants";

import {defaultGetPageName} from "../../components/Post/utils";

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    title: {type: String},
                    content: {type: String},
                },
                ...(p.config) ? p.config : {}
            },
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        return defaultPostTypeConfig.setContents({
            ...p,
            name: "email"
        })
    },
    requestForUserPage: async function requestForUserPage(p = {}) {

        const {req, /*statusManager*/} = p;
        const wappRequest = req.wappRequest;
        const user = wappRequest.user;
        const isAdmin = user && user._status_isFeatured;

        if (isAdmin) {

            return await defaultPostTypeConfig.requestForUserPage({
                ...p,
                name: "email"
            })

        }

        return null;
    },
    getComponent: function (p) {

        const name = "email";
        const ns = (name.endsWith("y")) ? name.slice(0, -1) + "ies" : name + "s";

        const {context, appContext} = p;
        const {routes, /*userStatusManager*/} = appContext;

        const {res, req} = context;
        const route = res.wappResponse.route;
        const requestPath = route.requestPath;
        const {page} = route.params;
        const user = req.wappRequest.user;

        const isAdmin = user && user._status_isFeatured;

        const pages = {
            content: Content,
            send: Send
        };

        const getPageName = ({page, ...rest}) => {
            return (page === "send") ? "send" : defaultGetPageName({page, ...rest});
        };

        if ((requestPath.startsWith(routes[name + "Route"])) || (requestPath.startsWith(routes[ns + "Route"]))) {
            if (requestPath === routes[name + "Route"] + "/new" && !isAdmin && user) {
                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    componentProps: {pages, getPageName: () => "notFound"}
                });
            }

            if (requestPath.startsWith(routes[name + "Route"]) && page === "send" && !isAdmin && user) {
                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    componentProps: {pages, getPageName: () => "notFound"}
                });
            }

            if (requestPath.startsWith(routes[name + "Route"]) && page === "send") {
                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    componentProps: {pages, getPageName, disableBack: true}
                });
            }

            if (requestPath.startsWith(routes[ns + "Route"]) && !isAdmin) {
                return null;
            }

            if (requestPath.startsWith(routes[name + "Route"]) && !isAdmin) {
                return null;
            }

            return defaultPostTypeConfig.getComponent({...p, name, componentProps: {pages, getPageName}});
        }
    },
    userPageFunctions: function (p) {

        const {context, /*appContext, */postContext} = p;

        const n = "email";
        const ns = (n.endsWith("y")) ? n.slice(0, -1) + "ies" : n + "s";
        const N = capitalize(n);
        const Ns = capitalize(ns);

        const r = defaultPostTypeConfig.userPageFunctions({...p, name: n});

        const {res} = context;

        const wappResponse = res.wappResponse;
        const route = wappResponse.route;
        const {params} = route;
        const {pageType} = params;

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const {appContext/*, statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (isAuthor) ? menus["my" + Ns + "Menu"] : menus[postContext.name + Ns + "Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/" + p.post._id + routes[postContext.name + Ns + "Route"] : routes[postContext.name + Ns + "Route"];
                    },
                    role: function (p) {
                        return p.user && p.user._status_isFeatured;
                    },
                    Icon: EmailIcon,
                    order: 51,
                },
                {
                    name: function () {
                        return menus["deleted" + Ns + "Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/" + p.post._id + routes[postContext.name + Ns + "Route"] + "/deleted" : routes[postContext.name + Ns + "Route"] + "/deleted"
                    },
                    role: function (p) {
                        const isAdmin = p.user && p.user._status_isFeatured;
                        const isPostsPage = ((p.page === ns && !p.pageType) || (p.page === ns && !isNaN(Number(p.pageType)) && Number(pageType) >= 1));
                        return !!(isPostsPage && isAdmin);
                    },
                    Icon: DeleteIcon,
                    onlyIcon: true,
                    featured: true,
                },
            ];
        }

        function addContentMenuItems(props) {

            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            const newsletters = res.wappResponse.store.getState("res.responses.newsletters");

            return [
                ...(newsletters?.items?.length) ? [{
                    name: () => {
                        return (
                            <>
                                {menus["sendNewsLetter"]}
                            </>
                        )
                    },
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    Icon: EmailOutlined,
                    disableParentRoute: true,
                    order: 12,
                    items: [
                        ...(newsletters?.items?.length) ? newsletters.items.map((email) => ({
                            name: email.title,
                            disableParentRoute: true,
                            href: routes[n + "Route"] + "/" + email._id + "/send"
                        })) : []
                    ]
                }] : [],
                {
                    name: menus["new" + N + "Menu"],
                    href: routes[n + "Route"] + "/new",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return !!(isAdmin && isAuthor);
                    },
                    Icon: EmailIcon,
                    disableParentRoute: true,
                    order: 51,
                },
                {
                    name: function (p) {
                        const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                        return (isAuthor) ? menus["my" + Ns + "Menu"] : menus[postContext.name + Ns + "Menu"];
                    },
                    href: function (p) {
                        return (p?.post?._id) ? "/" + p.post._id + routes[postContext.name + Ns + "Route"] : routes[postContext.name + Ns + "Route"];
                    },
                    role: function () {
                        return false;
                    },
                    Icon: EmailIcon,
                    order: 52,
                },
            ]
        }

        function getPageName({user, post, page, /*statusManager*/}) {

            const isAdmin = user && user._status_isFeatured;
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id));
            const isAuthorOrAdmin = (isAdmin || isAuthor);

            return ((page === ns && !pageType) || (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) || (page === ns && pageType === "deleted" && isAuthorOrAdmin)) ? (isAdmin) ? page : "notFound" : null;

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function (p) {

        const {context, appContext} = p;
        const {res} = context;
        const {menus, routes} = appContext;

        const name = "email";
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({...p, name});
        defaultAdminMenu.Icon = EmailIcon;
        defaultAdminMenu.order = 6;
        defaultAdminMenu.items[0].Icon = EmailOutlined;

        const newsletters = res.wappResponse.store.getState("res.responses.newsletters");

        const sendMenu = {
            name: () => {
                return (
                    <>
                        {menus["sendNewsLetter"]}
                    </>
                )
            },
            Icon: EmailOutlined,
            disableParentRoute: true,
            order: 1,
            items: [
                ...(newsletters?.items?.length) ? newsletters.items.map((email) => ({
                    name: email.title,
                    disableParentRoute: true,
                    href: routes[name + "Route"] + "/" + email._id + "/send"
                })) : []
            ]
        };

        defaultAdminMenu.items = [...defaultAdminMenu.items.map((item, i) => {
            item.order = i;
            return item
        }), ...(newsletters?.items?.length) ? [sendMenu] : []];
        return defaultAdminMenu;
    }
};

export default postTypeConfig;
