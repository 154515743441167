// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CQfME>div{margin-bottom:16px}.CQfME>div:last-child{margin-bottom:0}._2bxwp{font-size:14px;line-height:20px;color:var(--font-color);letter-spacing:normal}._2bxwp pre{background-color:#303338 !important}._0z6Gs{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-align:start;align-items:flex-start;margin-bottom:16px}._8pfBM{-ms-flex:1 1 320px;flex:1 1 320px;max-width:50%}._6Bm\\+s{-ms-flex-preferred-size:auto;flex-basis:auto;width:50%}._0z6Gs>div>div{padding:8px}._0z6Gs>*:only-child{width:100%;max-width:none}.xMzNR{font-weight:500}@media(max-width: 621px){._8pfBM{max-width:none}._6Bm\\+s{width:100%}}@media(prefers-color-scheme: dark){._2bxwp pre{background:rgba(0, 0, 0, 0.14) !important}}@media(max-width: 799px){.fUklr{min-height:100%}.fUklr{border-radius:0 !important;max-width:100%;width:100%;margin:0;max-height:100%}.KzyYf{padding:16px}}.NPHK0{border-style:solid;border-width:1px;border-color:var(--border-color);border-radius:8px;padding:16px 16px 8px;margin-bottom:16px}.bjLNV{padding:8px;margin-bottom:16px}._6B-o8{font-weight:500;margin-bottom:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "CQfME",
	"content": "_2bxwp",
	"dataContainer": "_0z6Gs",
	"moreData": "_8pfBM",
	"orderData": "_6Bm+s",
	"dataTableLabel": "xMzNR",
	"dialogPaper": "fUklr",
	"dialogContent": "KzyYf",
	"payments": "NPHK0",
	"comment": "bjLNV",
	"commentLabel": "_6B-o8"
};
module.exports = ___CSS_LOADER_EXPORT___;
