import React from "react";

import style from "./style.css";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MaterialCheckbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

export default function Checkbox(props) {

    const {onChange, label = "", error, helperText, ...rest} = props;

    const value = (typeof props.value === "boolean") ? props.value : false;

    return (
        <div className={style.checkbox}>
            <FormControl
                error={error}
            >
                <FormControlLabel
                    label={label}
                    control={
                        <MaterialCheckbox
                            checked={value}
                            onChange={(e) => {
                                const value = (typeof e.target.checked === "boolean") ? e.target.checked : false;
                                onChange({target: {value}});
                            }}
                            {...rest}
                        />
                    }
                />
                {(helperText) ? <FormHelperText>{helperText}</FormHelperText> : null}
            </FormControl>
        </div>
    )
}
