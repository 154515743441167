import {runPostTypesConfigSync} from "../../postTypes";

const labels = {
    notFoundButtonBack: "Előző oldal",
    notFoundButtonHome: "Főoldal",
    notFoundButtonLogin: "Belépés",
    notFoundButtonSignup: "Regisztráció",
    acceptCookiesLabel: "Jóváhagyás",
    acceptCookiesClose: "Bezár",
    selectAnOperationLabel: "Művelet kiválasztása",
    searchLabel: "Keresés",
    ...runPostTypesConfigSync({action: "getConstants", rKey: "labels"}).reduce((a, v) => {
        return {...a, ...v}
    }, {})
};

export default labels;
