import defaultPostTypeConfig from "../post";

export default function getStatusManager(p = {}) {
    const statusManager = defaultPostTypeConfig.getStatusManager({
        config: {
            requiredDataForStatus: {
                name: {
                    first: {type: String},
                },
                email: {type: String},
                emailConfirmed: {type: Boolean},
                ...(p.config?.requiredDataForStatus) ? p.config.requiredDataForStatus : {}
            },
            ...(p.config) ? p.config : {}
        },
    });

    const setNewStatus = statusManager.setNewStatus;
    statusManager.setNewStatus = (doc) => {
        if (!doc._status_isApproved) {
            return setNewStatus(doc);
        }
        const currentStatus = (doc._status && !isNaN(Number(doc._status))) ? Number(doc._status) : 0;
        return currentStatus || statusManager.statuses["created"];
    };

    return statusManager;

}
