// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Daib7{height:100%;width:auto;display:-ms-inline-flexbox;display:inline-flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.sj9Tl{height:100%;width:auto;display:-ms-flexbox;display:flex;max-width:100%}.D9Kr5{fill:#c2cece}.x9POm{fill:#b1ceca}._7gjGk{fill:#db915d}.TUUli{fill:#b16a4c}.vv0fv{fill:#6f6e6e}.uwlbd{fill:#fff6d9}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Daib7",
	"svg": "sj9Tl",
	"cls-1": "D9Kr5",
	"cls-2": "x9POm",
	"cls-3": "_7gjGk",
	"cls-4": "TUUli",
	"cls-5": "vv0fv",
	"cls-6": "uwlbd"
};
module.exports = ___CSS_LOADER_EXPORT___;
